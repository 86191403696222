import { NotificationMessage } from '@oproma/prividox-orchestration-open-api';

export type NotificationMessages = NotificationMessage & {
  icon: string;
  iconStyle: string;
  time?: number;
};

export enum NotificationsErrorCodesEnum {
  GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED',
  GET_NOTIFICATION_PREFERENCES_FAILED = 'GET_NOTIFICATION_PREFERENCES_FAILED',
  EDIT_APP_LINK_NOTIFICATION_FAILED = 'EDIT_APP_LINK_NOTIFICATION_FAILED',
  EDIT_NEW_DEVICE_NOTIFICATION_FAILED = 'EDIT_NEW_DEVICE_NOTIFICATION_FAILED',
  EDIT_WORKSPACE_INVITE_NOTIFICATION_FAILED = 'EDIT_WORKSPACE_INVITE_NOTIFICATION_FAILED',
  EDIT_WORKSPACE_REMOVED_NOTIFICATION_FAILED = 'EDIT_WORKSPACE_REMOVED_NOTIFICATION_FAILED',
  EDIT_NEW_BROWSER_NOTIFICATION_FAILED = 'EDIT_NEW_BROWSER_NOTIFICATION_FAILED',
}

export type NotificationPreferenceType = keyof NotificationPreferenceTypes;

export type NotificationPreferenceTypes = {
  taskCreated: boolean;
  newDevice: boolean;
  taskIncomplete: boolean;
  taskCompleted: boolean;
  taskDeleted: boolean;
  projectRemoved: boolean;
  taskUpdated: boolean;
  projectInvite: boolean;
  appLinked: boolean;
  spaceUsage: boolean;
};

export interface INotificationState {
  readonly notifications: NotificationMessages[];
  readonly notificationPreferences: NotificationPreferenceTypes | null;
  readonly changedNotificationPreferences: NotificationPreferenceTypes | null;
  readonly loading: boolean;
  readonly error: {
    code: NotificationsErrorCodesEnum;
    message: string;
  } | null;
}
