import { Link } from '@oproma/router';
import clsx from 'clsx';
import { MouseEvent, ReactNode } from 'react';
import { Icon } from './icon.component';

interface LinkItemProps {
  tag?: string;
  link: string;
  icon?: string;
  text?: string;
  className?: string;
  children?: ReactNode;
  onClick?: (ev: MouseEvent<HTMLAnchorElement>) => void;
}

export const LinkItem = (props: LinkItemProps) => {
  return (
    <li>
      {props.tag !== 'a' ? (
        <Link to={props.link} {...props}>
          {props.icon && <Icon name={props.icon} />}{' '}
          <span>{props.text || props.children}</span>
        </Link>
      ) : (
        <a href={`${props.link}`} onClick={(ev) => ev.preventDefault()}>
          {props.icon && <Icon name={props.icon} />}{' '}
          <span>{props.text || props.children}</span>
        </a>
      )}
    </li>
  );
};

interface LinkListProps {
  opt?: boolean;
  className?: string;
  children?: ReactNode;
}

export const LinkList = (props: LinkListProps) => {
  const listClasses = clsx({
    'link-list': !props.opt,
    'link-list-opt': props.opt,
    [`${props.className}`]: props.className,
  });
  return <ul className={listClasses}>{props.children}</ul>;
};
