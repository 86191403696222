import { useTranslation } from '@oproma/i18n';
import {
  setDisplayMobileMenu,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link, useLocation } from '@oproma/router';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { Icon } from '../icon.component';
import { UserAvatar } from '../user-avatar.component';
import { findUpper, generateGravatarUrl } from '../utils';
import { Content } from './content.component';

interface PreferencesLayoutProps {
  children: JSX.Element | JSX.Element[];
  type: string;
}

export const PreferencesLayout = ({
  children,
  type,
}: PreferencesLayoutProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { name, email } = useAppSelector((state) => state.user);
  const { displayMobileMenu } = useAppSelector((state) => state.layout);
  const [sideBar, setSidebar] = useState(false);
  const [notificationView, setNotificationView] = useState(false);

  const changeDisplayMobileMenu = (payload: boolean) => {
    dispatch(setDisplayMobileMenu(payload));
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      changeDisplayMobileMenu(true);
    } else {
      changeDisplayMobileMenu(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener('load', viewChange);
    window.addEventListener('resize', viewChange);
    if (type === 'notifications') setNotificationView(true);

    document
      .getElementsByClassName('nk-header')[0]
      .addEventListener('click', () => {
        changeDisplayMobileMenu(false);
      });
    return () => {
      window.removeEventListener('resize', viewChange);
      window.removeEventListener('load', viewChange);
    };
  }, []);

  const toggle = () => {
    setSidebar(!sideBar);
  };

  return (
    <Content>
      <Card className="card-bordered">
        <div className="card-aside-wrap">
          <div
            className={clsx(
              'card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg',
              {
                'content-active': displayMobileMenu,
              },
            )}
          >
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="user-card">
                  {email ? (
                    <img
                      src={generateGravatarUrl(email)}
                      alt={name!}
                      className="user-avatar rounded-circle mr-2"
                    ></img>
                  ) : (
                    <UserAvatar text={findUpper(name)} theme="primary" />
                  )}
                  <div className="user-info">
                    <span className="lead-text">{name}</span>
                    <span className="sub-text">{email}</span>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0">
                <ul className="link-list-menu">
                  <li onClick={() => changeDisplayMobileMenu(false)}>
                    <Link
                      to="/preferences/personal"
                      className={clsx({
                        active: pathname.includes(`/preferences/personal`),
                      })}
                    >
                      <Icon name="user-fill-c"></Icon>
                      <span>{t('PREFERENCES.NAVBAR.PERSONAL')}</span>
                    </Link>
                  </li>
                  <li onClick={() => changeDisplayMobileMenu(false)}>
                    <Link
                      to="/preferences/notifications"
                      className={clsx({
                        active: pathname.includes(`/preferences/notifications`),
                      })}
                    >
                      <Icon name="bell-fill"></Icon>
                      <span>{t('PREFERENCES.NAVBAR.NOTIFICATIONS')}</span>
                    </Link>
                  </li>
                  <li onClick={() => changeDisplayMobileMenu(false)}>
                    <Link
                      to="/preferences/devices"
                      className={clsx({
                        active: pathname.includes(`/preferences/devices`),
                      })}
                    >
                      <Icon name="activity-round-fill"></Icon>
                      <span>{t('PREFERENCES.NAVBAR.DEVICES')}</span>
                    </Link>
                  </li>
                  <li onClick={() => changeDisplayMobileMenu(false)}>
                    <Link
                      to="/preferences/privacy"
                      className={clsx({
                        active: pathname.includes(`/preferences/privacy`),
                      })}
                    >
                      <Icon name="lock-alt-fill"></Icon>
                      <span>{t('PREFERENCES.NAVBAR.PRIVACY')}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-inner card-inner-lg">
            {displayMobileMenu && (
              <div
                className="toggle-overlay"
                onClick={() => changeDisplayMobileMenu(!displayMobileMenu)}
              ></div>
            )}
            {children}
          </div>
          {sideBar && (
            <div className="toggle-overlay" onClick={() => toggle()}></div>
          )}
        </div>
      </Card>
    </Content>
  );
};
