import { useTranslation } from '@oproma/i18n';
import { ProjectInformation } from '@oproma/prividox-orchestration-open-api';
import { MenuItem, useAppSelector } from '@oproma/prividox-store';
import { useLocation } from '@oproma/router';

export const useWorkspaces = () => {
  const { pathname } = useLocation();
  const workspaces = useAppSelector((state) => state.workspaces);
  const { t } = useTranslation();

  const createMenuFromWorkspaces = (
    workspaces: ProjectInformation[],
  ): MenuItem[] => {
    const menu = workspaces.map((workspace, index) => {
      return {
        key: index,
        icon: 'dashboard',
        text: workspace.name,
        link: `/${workspace.id}/file-manager`,
        active: pathname.includes(`/${workspace.id}`),
        subMenu: [
          {
            text: t('COMMON.DASHBOARD'),
            icon: 'dashboard',
            link: `/${workspace.id}/dashboard`,
          },
          {
            text: t('COMMON.FILES'),
            icon: 'folder',
            link: `/${workspace.id}/file-manager`,
          },
          {
            text: t('COMMON.TASKS'),
            icon: 'template',
            link: `/${workspace.id}/tasks`,
          },
          {
            text: t('COMMON.MEMBERS'),
            icon: 'users',
            link: `/${workspace.id}/members`,
          },
          {
            text: t('COMMON.GROUPS'),
            icon: 'user-list',
            link: `/${workspace.id}/groups`,
          },
          {
            text: t('COMMON.OPTIONS'),
            icon: 'setting-alt',
            link: `/${workspace.id}/options`,
          },
        ],
      };
    });
    return [
      {
        heading: t('COMMON.MENU'),
        badge: undefined,
      },
      ...menu,
    ];
  };
  return {
    ...workspaces,
    workspaceCount: workspaces.workspaces.length,
    workspacesMenu: createMenuFromWorkspaces(workspaces.workspaces),
  };
};
