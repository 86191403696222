import { useTranslation } from '@oproma/i18n';
import {
  deleteTask,
  toggleDisplayDeleteTaskModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { Col, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
// import { Icon } from '../../icon.component';

export const DeleteTaskModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { displayDeleteTaskModal, lastOpenedTask } = useAppSelector(
    (state) => state.tasks,
  );

  const toggleModal = () => dispatch(toggleDisplayDeleteTaskModal());

  const onDeleteTask = (id: string) => {
    if (!workspaceId) return;
    dispatch(
      deleteTask({
        task: id,
        workspaceId,
      }),
    );
    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={displayDeleteTaskModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('TASKS.DELETE_TASK.TITLE')}</h5>
          <div className="mt-4">
            <p>{t('TASKS.DELETE_TASK.HEADING')}</p>
            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li className="me-4">
                  <Button
                    color="primary"
                    onClick={() => {
                      onDeleteTask(lastOpenedTask!);
                    }}
                  >
                    {t('TASKS.DELETE_TASK.CONFIRM')}
                  </Button>
                </li>
                <li>
                  <Button onClick={handleCancel}>
                    {t('TASKS.DELETE_TASK.CANCEL')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
