import { ThemeModeEnum, useAppSelector } from '@oproma/prividox-store';
import clsx from 'clsx';
import { ReactNode, useEffect } from 'react';

export type AuthPageContainer = {
  children: ReactNode;
};

export const AuthLayout = ({ children }: AuthPageContainer) => {
  const { themeMode } = useAppSelector((state) => state.layout);

  useEffect(() => {
    document.body.className = clsx(
      `nk-body npc-default no-touch nk-nio-theme`,
      {
        'dark-mode': themeMode === ThemeModeEnum.DARK,
      },
    );
  }, [themeMode]);

  return (
    <div className="nk-app-root">
      <div className="nk-wrap nk-wrap-nosidebar">
        <div className="nk-content">{children}</div>
      </div>
    </div>
  );
};
