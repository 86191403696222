import clsx from 'clsx';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { FileManagerFiles } from './file-manager-files.component';
import { Select } from '../select.component';
import { useFileManagerTrash } from '../hooks';
import { Icon } from '../icon.component';

export const FileManagerTrash = () => {
  console.log('MyComponent re-rendered');

  const {
    t,
    dates,
    setDates,
    memberOptions,
    selectedMember,
    filteredTrashEntities,
    filterTrashEntities,
    handleEmptyingTrash,
    resetFilters,
    handleSelectChange,
    displayMobileRecoveryFilter,
  } = useFileManagerTrash();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Row>
        <Col xl="3" className="order-xl-12">
          <div
            className={clsx('nk-fmg-filter toggle-expand-content', {
              expanded: displayMobileRecoveryFilter,
            })}
          >
            <Form>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Button
                      type="button"
                      color="danger"
                      size="md"
                      onClick={toggle}
                    >
                      {t('FILE_MANAGER.TRASH.EMPTY')}
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg="12" md="4">
                  <FormGroup>
                    <label className="form-label">
                      {t('FILE_MANAGER.TRASH.FROM')}
                    </label>
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={dates.from}
                        onChange={(date) => setDates({ ...dates, from: date })}
                        className="form-control date-picker"
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12" md="4">
                  <FormGroup>
                    <label className="form-label">
                      {t('FILE_MANAGER.TRASH.TO')}
                    </label>
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={dates.to}
                        onChange={(date) => setDates({ ...dates, to: date })}
                        className="form-control date-picker"
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12" md="4">
                  <FormGroup>
                    <label className="form-label">
                      {t('FILE_MANAGER.TRASH.DELETED_BY')}
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        options={memberOptions}
                        placeholder={t(
                          'FILE_MANAGER.TRASH.DELETED_PLACEHOLDER',
                        )}
                        onChange={handleSelectChange}
                        value={selectedMember}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <div className="d-flex justify-between mt-1">
                    <button
                      type="reset"
                      className="link link-sm link-primary ms-n1"
                      onClick={resetFilters}
                    >
                      {t('FILE_MANAGER.TRASH.RESET_FILTER')}
                    </button>
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      onClick={filterTrashEntities}
                    >
                      {t('FILE_MANAGER.TRASH.Filter')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col xl="9" lg="12">
          <FileManagerFiles
            entities={filteredTrashEntities}
            entityDisplayType="list"
            layoutType="trash"
          />
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Confirm Empty Trash
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you would like to empty the trash of all of its
            contents?
          </p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{' '}
          <Button color="primary" onClick={handleEmptyingTrash}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
