import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  UserErrorCodesEnum,
  editLocation,
  editPhone,
  editTagline,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { RefObject } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { SubmitHandler, useForm } from 'react-hook-form';
import flags from 'react-phone-number-input/flags';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { Alert, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import * as yup from 'yup';
import { BlockHeadContent } from '../../block.component';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
import { retrieveApiErrorMessage } from '../../utils';

type OnboardingProfilePhaseProps = {
  triggerNextPhase: () => void;
};

const onboardingProfileSchema = yup
  .object({
    tagline: yup.string(),
    address: yup.string(),
    phoneNumber: yup.string().required('Phone number is required.'),
  })
  .required();

type OnboardingProfileFormValues = yup.InferType<
  typeof onboardingProfileSchema
>;

export const AuthOnboardingProfilePhase = ({
  triggerNextPhase,
}: OnboardingProfilePhaseProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { loading, error } = useAppSelector((state) => state.user);
  const {
    register: registerField,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useForm<OnboardingProfileFormValues>({
    resolver: yupResolver(onboardingProfileSchema),
  });

  const { ref: taglineRef, ...registerTagline } = registerField('tagline');

  const address = watch('address');
  const { ref: bootstrapRef } = usePlacesWidget({
    apiKey:
      import.meta.env.VITE_GOOGLE_MAPS_API_KEY ??
      'AIzaSyBPEqfrVwkfZsCixZWkq8nxI6U8gVweBkQ',
    onPlaceSelected: (place) => {
      clearErrors('address');
      setValue('address', place.formatted_address ?? '');
    },
  });

  const onFormSubmit: SubmitHandler<OnboardingProfileFormValues> = ({
    tagline,
    address,
    phoneNumber,
  }) => {
    if (tagline) {
      dispatch(editTagline(tagline));
    }
    if (address) {
      dispatch(editLocation(address));
    }
    if (phoneNumber) {
      dispatch(editPhone(phoneNumber));
    }
    if (
      !(
        error?.code === UserErrorCodesEnum.EDIT_LOCATION_FAILED ||
        error?.code === UserErrorCodesEnum.EDIT_PHONE_FAILED ||
        error?.code === UserErrorCodesEnum.EDIT_TAGLINE_FAILED
      )
    ) {
      triggerNextPhase();
    }
  };

  return (
    <>
      <BlockHeadContent>
        <h4>{t('ONBOARDING.CONFIGURATOR.1.HEADER')}</h4>
        <span className="sub-text sub-text-sm text-soft">
          {t('ONBOARDING.CONFIGURATOR.1.TIME_ESTIMATE')}
        </span>
      </BlockHeadContent>
      <p>{t('ONBOARDING.CONFIGURATOR.1.BODY')}</p>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        {(error?.code === UserErrorCodesEnum.EDIT_LOCATION_FAILED ||
          error?.code === UserErrorCodesEnum.EDIT_PHONE_FAILED ||
          error?.code === UserErrorCodesEnum.EDIT_TAGLINE_FAILED) && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              <Icon name="alert-circle" />{' '}
              <p
                dangerouslySetInnerHTML={{
                  __html: t(retrieveApiErrorMessage(error.message)),
                }}
              ></p>
            </Alert>
          </div>
        )}
        <FormGroup>
          <div className="form-label-group">
            <Label className="form-label" for="tagline">
              {t('ONBOARDING.CONFIGURATOR.1.FORM.TAGLINE')}
            </Label>
          </div>
          <div className="form-control-wrap">
            <Input
              type="text"
              id="tagline"
              placeholder="Enter a tagline"
              className="form-control"
              innerRef={taglineRef}
              {...registerTagline}
            />
            {errors.tagline && (
              <span className="invalid">{errors.tagline.message}</span>
            )}
          </div>
        </FormGroup>
        <FormGroup>
          <div className="form-label-group">
            <Label className="form-label" for="phoneNumber">
              {t('ONBOARDING.CONFIGURATOR.1.FORM.PHONE_NUMBER')}
            </Label>
          </div>
          <PhoneInputWithCountry
            name="phoneNumber"
            defaultCountry="CA"
            control={control}
            limitMaxLength={true}
            // rules={{ required: true, }}
            style={{
              'border-color': '#384d69',
              background: '#141c26',
              color: '#fff',
            }}
            flags={flags}
          />
          {errors.phoneNumber && (
            <span
              className="text-danger"
              style={{
                fontSize: '11px',
                fontStyle: 'italic',
              }}
            >
              {errors.phoneNumber.message}
            </span>
          )}
        </FormGroup>
        <FormGroup>
          <div className="form-label-group">
            <Label className="form-label" for="address">
              {t('ONBOARDING.CONFIGURATOR.1.FORM.LOCATION')}
            </Label>
          </div>
          <Input
            type="text"
            id="address"
            name="address"
            className="form-control"
            placeholder="Enter your location"
            value={address}
            innerRef={bootstrapRef as unknown as RefObject<HTMLInputElement>}
          />
          {errors.address && (
            <span className="invalid">{errors.address.message}</span>
          )}
        </FormGroup>
        <Button color="primary" disabled={!!error} type="submit">
          {loading ? <Spinner size="sm" color="light" /> : t('COMMON.CONFIRM')}
        </Button>
      </Form>
    </>
  );
};
