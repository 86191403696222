import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  LanguageEnum,
  editLocation,
  editNameOfUser,
  editPassword,
  editPhone,
  editSystemDate,
  editSystemTime,
  editTagline,
  getUserMetadata,
  requestEmailChange,
  resetChangedFields,
  resetTimeFormatChangeFlag,
  toggleLanguage,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const patchProfileSchema = yup
  .object({
    name: yup.string(),
    oldPassword: yup.string(),
    password: yup.string(),
    dateFormat: yup.string(),
    timeFormat: yup.string(),
    tagline: yup.string(),
    address: yup.string(),
    phoneNumber: yup.string().nullable().notRequired(),
    confirmedPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match'),
  })
  .required();

type PersonalPreferencesFormValues = yup.InferType<typeof patchProfileSchema>;

export const usePersonalPreferencesForm = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const {
    name,
    email,
    'app.settings.dateFormat': dateFormat,
    'app.settings.timeFormat': timeFormat,
    changedName,
    changedPassword,
    changedDateFormat,
    changedTimeFormat,
    loading,
    error,
  } = useAppSelector((state) => state.user);
  const {
    id: userId,
    changedLocation,
    changedTagline,
    changedPhone,
    address,
    phone,
    tagline,
  } = useAppSelector((state) => state.user);
  const { language } = useAppSelector((state) => state.layout);
  const lang = language === LanguageEnum.EN ? 'fr-FR' : 'en-US';
  const [modalTab, setModalTab] = useState('1');
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [displayPassword, setDisplayPassword] = useState<boolean>(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] =
    useState<boolean>(false);

  const {
    register: registerField,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<PersonalPreferencesFormValues>({
    resolver: yupResolver(patchProfileSchema),
  });

  const location = watch('address');
  const { ref: nameRef, ...registerName } = registerField('name');
  const { ref: taglineRef, ...registerTagline } = registerField('tagline');
  const { ref: oldPasswordRef, ...registerOldPassword } =
    registerField('oldPassword');
  const { ref: passwordRef, ...registerPassword } = registerField('password');
  const { ref: confirmedPasswordRef, ...registerConfirmedPassword } =
    registerField('confirmedPassword');
  const changableDateFormat = watch('dateFormat');
  const changableTimeFormat = watch('timeFormat');

  const [alertOpen, setAlertOpen] = useState(false);

  const toggleAlert = () => setAlertOpen(!alertOpen);

  useEffect(() => {
    if (changedDateFormat) {
      toast.success(t('PREFERENCES.PERSONAL.TOAST.DATE_CHANGED'));
    }
    if (changedTimeFormat) {
      toast.success(t('PREFERENCES.PERSONAL.TOAST.TIME_CHANGED'));
      dispatch(resetTimeFormatChangeFlag());
    }
  }, [changedDateFormat, changedTimeFormat]);

  const onFormSubmit: SubmitHandler<PersonalPreferencesFormValues> = ({
    name: changedName,
    oldPassword,
    confirmedPassword,
    password,
    tagline,
    address,
    phoneNumber: changedPhone,
  }) => {
    if (name && changedName && changedName !== name) {
      dispatch(editNameOfUser({ name: changedName }));
    }
    if (oldPassword && password && password === confirmedPassword) {
      dispatch(
        editPassword({
          old: oldPassword,
          pass: password,
        }),
      );
    }
    if (tagline) {
      dispatch(editTagline(tagline));
    }
    if (address) {
      dispatch(editLocation(address));
    }
    if (phone && changedPhone && changedPhone !== phone) {
      dispatch(editPhone(changedPhone));
    }
  };

  const changeLanguage = () => {
    dispatch(toggleLanguage());
    i18n.changeLanguage(lang);
  };

  const determineLanguage = () => {
    if (language === LanguageEnum.EN) {
      return 'Français';
    } else {
      return 'English';
    }
  };

  const onDateChange = async () => {
    if (!changableDateFormat) {
      return;
    }
    try {
      await Promise.all([
        dispatch(editSystemDate(changableDateFormat)),
        dispatch(getUserMetadata(userId)),
      ]);
      toast.success(t('PREFERENCES.PERSONAL.TOAST.DATE_CHANGED'));
    } catch (error) {
      toast.error(t('PREFERENCES.PERSONAL.TOAST.DATE_CHANGED_ERROR'));
    }
  };

  const onTimeChange = () => {
    if (!changableTimeFormat) {
      return;
    }
    dispatch(editSystemTime(changableTimeFormat));
    dispatch(getUserMetadata(userId));
  };

  const handleEmailClick = () => {
    dispatch(requestEmailChange());
    toast.success(t('PREFERENCES.PERSONAL.TOAST.EMAIL_CHANGED'), {
      autoClose: 5000,
    });
  };

  const resetChangedFieldsAndAlert = () => {
    dispatch(resetChangedFields());
    setAlertOpen(false);
  };

  useEffect(() => {
    if (
      changedName ||
      changedTagline ||
      changedLocation ||
      changedPhone ||
      changedPassword
    ) {
      setAlertOpen(true);
    }
  }, [
    changedName,
    changedTagline,
    changedLocation,
    changedPhone,
    changedPassword,
  ]);

  return {
    errors,
    name,
    email,
    dateFormat,
    timeFormat,
    changedName,
    changedPassword,
    changedDateFormat,
    changedTimeFormat,
    loading,
    error,
    userId,
    changedLocation,
    changedTagline,
    changedPhone,
    address,
    phone,
    tagline,
    language,
    lang,
    modalTab,
    setAlertOpen,
    setModalTab,
    displayModal,
    setDisplayModal,
    displayPassword,
    setDisplayPassword,
    displayConfirmPassword,
    setDisplayConfirmPassword,
    registerField,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    clearErrors,
    location,
    nameRef,
    registerName,
    taglineRef,
    registerTagline,
    oldPasswordRef,
    registerOldPassword,
    passwordRef,
    registerPassword,
    confirmedPasswordRef,
    registerConfirmedPassword,
    changableDateFormat,
    changableTimeFormat,
    alertOpen,
    toggleAlert,
    onFormSubmit,
    changeLanguage,
    determineLanguage,
    onDateChange,
    onTimeChange,
    handleEmailClick,
    resetChangedFieldsAndAlert,
  };
};
