import {
  ConvertDocumentRequest,
  CopyToRequest,
  CreateFolderRequest,
  DeleteChildrenRequest,
  ExportRequest,
  GetContentsRequest,
  RestoreToRequest,
  SetProtectionLevelRequest,
  UploadRequest,
} from '@oproma/prividox-orchestration-open-api';
import { filesApi, metadataApi } from './config';

const postFile = (
  file: File,
  metadata: UploadRequest,
  signal?: AbortSignal,
) => {
  return filesApi.uploadRaw(
    {
      ...metadata,
      body: file,
    },
    { signal },
  );
};

const fetchChildEntities = (entity: string) => {
  return filesApi.getChildMetadata({
    entity,
  });
};

const deleteFile = async (entityId: string) => {
  const response = await filesApi.deleteItemRaw({ entity: entityId });
  return response.raw.ok;
};

const postFolder = (payload: CreateFolderRequest) => {
  return filesApi.createFolder(payload);
};

const protectionTag = (payload: SetProtectionLevelRequest) => {
  return filesApi.setProtectionLevelRaw(payload);
};

const convertDocument = (payload: ConvertDocumentRequest) => {
  return filesApi.convertDocument(payload);
};

const fetchFile = async (
  entityId: string,
  onProgress: (progress: number) => void,
) => {
  try {
    const response = await filesApi.downloadRaw({ entity: entityId });
    const reader = (response.raw.body as ReadableStream).getReader();
    const contentLength = +(response.raw.headers.get('content-length') ?? 0);
    const chunks = [];
    let receivedLength = 0;
    let result;
    while (!result || !result.done) {
      result = await reader.read();
      if (result.value) {
        chunks.push(result.value);
        receivedLength += result.value.length;
        if (contentLength) {
          onProgress((receivedLength / contentLength) * 100);
        }
      }
    }
    const blob = new Blob(chunks, {
      type:
        response.raw.headers.get('content-type') || 'application/octet-stream',
    });
    return blob;
  } catch (error) {
    console.error('Error fetching file:', error);
  }
};

const fetchEntityParents = (entityId: string) => {
  return filesApi.getParents({
    entity: entityId,
  });
};

const fetchEntityParent = (entityId: string) => {
  return filesApi.getParent({
    entity: entityId,
  });
};

const entitiesExport = async (payload: ExportRequest) => {
  try {
    const response = await filesApi._exportRaw(payload);
    const reader = (response.raw.body as ReadableStream).getReader();
    const chunks = [];
    let result;
    while (!result || !result.done) {
      result = await reader.read();
      if (result.value) {
        chunks.push(result.value);
      }
    }
    const blob = new Blob(chunks, {
      type:
        response.raw.headers.get('content-type') || 'application/octet-stream',
    });
    return blob;
  } catch (error) {
    console.error('Error fetching file:', error);
  }
};

const previewFile = async (payload: GetContentsRequest) => {
  const response = await filesApi.getContentsRaw(payload);
  return response.raw.text();
};

const restoreFile = async (payload: RestoreToRequest) => {
  const response = await filesApi.restoreToRaw(payload);
  return response.raw.ok;
};

const permanentlyDeleteFile = (payload: DeleteChildrenRequest) => {
  return filesApi.deleteChildren(payload);
};

const patchFolderType = async (entity: string, value: string) => {
  const response = await metadataApi.setMetadataRaw({
    entity,
    key: 'type',
    valueSpec: {
      value,
    },
  });
  return response.raw.ok;
};

const copyEntity = async (payload: CopyToRequest) => {
  const response = await filesApi.copyToRaw(payload);
  return response.raw.ok;
};

const moveEntity = async (payload: CopyToRequest) => {
  const response = await filesApi.moveToRaw(payload);
  return response.raw.ok;
};

export const filesService = {
  postFile,
  fetchFile,
  copyEntity,
  moveEntity,
  postFolder,
  deleteFile,
  restoreFile,
  previewFile,
  protectionTag,
  entitiesExport,
  patchFolderType,
  convertDocument,
  fetchEntityParent,
  fetchChildEntities,
  fetchEntityParents,
  permanentlyDeleteFile,
};
