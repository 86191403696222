/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Child } from './Child';
import { ChildFromJSON, ChildFromJSONTyped, ChildToJSON } from './Child';
import type { DirectPermissionResult } from './DirectPermissionResult';
import {
  DirectPermissionResultFromJSON,
  DirectPermissionResultFromJSONTyped,
  DirectPermissionResultToJSON,
} from './DirectPermissionResult';

/**
 *
 * @export
 * @interface ChildPermission
 */
export interface ChildPermission {
  /**
   *
   * @type {Child}
   * @memberof ChildPermission
   */
  child?: Child;
  /**
   *
   * @type {DirectPermissionResult}
   * @memberof ChildPermission
   */
  permission?: DirectPermissionResult;
}

/**
 * Check if a given object implements the ChildPermission interface.
 */
export function instanceOfChildPermission(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ChildPermissionFromJSON(json: any): ChildPermission {
  return ChildPermissionFromJSONTyped(json, false);
}

export function ChildPermissionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChildPermission {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    child: !exists(json, 'child') ? undefined : ChildFromJSON(json['child']),
    permission: !exists(json, 'permission')
      ? undefined
      : DirectPermissionResultFromJSON(json['permission']),
  };
}

export function ChildPermissionToJSON(value?: ChildPermission | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    child: ChildToJSON(value.child),
    permission: DirectPermissionResultToJSON(value.permission),
  };
}
