import { useTranslation } from '@oproma/i18n';
import {
  convertDocument,
  setDisplayConvertConfirm,
  setDisplayPreviewFileModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Col, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
import { toast } from 'react-toastify';

export const ConvertFileModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayConvertConfirmModal, lastOpenedEntity } = useAppSelector(
    (state) => state.fileManager,
  );

  const toggleModal = () =>
    dispatch(setDisplayConvertConfirm(!displayConvertConfirmModal));

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={displayConvertConfirmModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {t('FILE_MANAGER.FILE_PREVIEW.CONVERT.TITLE')}
          </h5>
          <div className="mt-4">
            <p>{t('FILE_MANAGER.FILE_PREVIEW.CONVERT.HEADING')}</p>
            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li className="me-4">
                  <Button
                    color="primary"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(
                        convertDocument({ entity: lastOpenedEntity?.id ?? '' }),
                      );
                      toast.info(
                        t('FILE_MANAGER.FILE_PREVIEW.CONVERT.TOAST_PENDING'),
                      );
                      toggleModal();
                      dispatch(setDisplayPreviewFileModal(false));
                    }}
                  >
                    {t('MEMBERS.REMOVE_MEMBER.CONFIRM')}
                  </Button>
                </li>
                <li>
                  <Button onClick={handleCancel}>
                    {' '}
                    {t('MEMBERS.REMOVE_MEMBER.CANCEL')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
