import {
  BlockTitle,
  FileManagerContainer,
  FileManagerNavigationLayout,
  FileManagerStarredEntities,
  Layout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import { getWorkspaceEntities, useAppDispatch } from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';

const StarredEntitiesPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceEntities(workspaceId));
  }, [workspaceId]);

  return (
    <Layout>
      <FileManagerContainer>
        <FileManagerNavigationLayout
          title={
            <BlockTitle page>{t('FILE_MANAGER.STARRED.TITLE')}</BlockTitle>
          }
          entityFinder
        >
          <FileManagerStarredEntities />
        </FileManagerNavigationLayout>
      </FileManagerContainer>
    </Layout>
  );
};

export default StarredEntitiesPage;
