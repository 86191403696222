import {
  deleteWorkspaceMember,
  setOpenBulkDeleteMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { Icon } from '../../icon.component';
import { toast } from 'react-toastify';
import { useTranslation } from '@oproma/i18n';
import { useParams } from '@oproma/router';
import { MemberInfo } from '@oproma/prividox-orchestration-open-api';

interface BulkDeleteProps {
  selectedMembers: MemberInfo[];
}

export const BulkDeleteMembersModal = (props: BulkDeleteProps) => {
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const dispatch = useAppDispatch();
  const { deletedMemberMessage, displayBulkRemoveMemberModal } = useAppSelector(
    (state) => state.members,
  );

  const { reset } = useForm();

  const toggleModal = () => {
    dispatch(setOpenBulkDeleteMemberModal(!displayBulkRemoveMemberModal));
  };

  // bulk delete members
  const handleBulkDelete = async () => {
    if (props.selectedMembers) {
      await props.selectedMembers.reduce(async (previousPromise, member) => {
        await previousPromise;
        await new Promise((resolve) => setTimeout(resolve, 100)); // time delay to overcome concurrency issues
        dispatch(
          deleteWorkspaceMember({
            workspaceId: workspaceId as string,
            user: member.id as string,
          }),
        );
      }, Promise.resolve());
    }
    if (deletedMemberMessage) {
      toast.success(t('MEMBERS.REMOVE_MEMBER.SUCCESS'));
    }

    toggleModal();
    reset();
  };

  return (
    <Modal
      isOpen={displayBulkRemoveMemberModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('MEMBERS.BULK_DELETE.TITLE')}</h5>
          <div className="mt-4">
            <Col md="12">
              <div className="form-member">
                <p className="form-label">
                  {t('MEMBERS.BULK_DELETE.HEADING')}
                  {/* TODO:
                    implement translation */}
                </p>
              </div>
            </Col>

            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li>
                  <Button
                    className="btn btn-primary mt-3"
                    color="primary"
                    size="md"
                    onClick={handleBulkDelete}
                  >
                    {t('MEMBERS.BULK_DELETE.CONFIRM')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
