import clsx from 'clsx';
import { Notification, UserDropdown } from './dropdown';
import { HeaderFinder } from './header-finder.component';
import { Logo } from './logo.component';
import { ThemeConfigurator } from './theme-configurator.component';
import { Toggle } from './toggle.component';

interface HeaderProps {
  fixed?: boolean;
  theme?: string;
  className?: string;
  sidebarToggle: (ev: React.MouseEvent) => void;
  setVisibility: (visibility: boolean) => void;
  preview?: boolean;
}

export const Header = ({
  fixed,
  theme,
  preview,
  className,
  sidebarToggle,
  setVisibility,
}: HeaderProps) => {
  const headerClass = clsx({
    'nk-header': true,
    'nk-header-fixed': fixed,
    [`is-light`]: theme === 'white',
    [`is-${theme}`]: theme !== 'white' && theme !== 'light',
    [`${className}`]: className,
  });

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1"
              icon="menu"
              click={sidebarToggle}
            />
          </div>
          <div className="nk-header-brand d-xl-none">
            <Logo preview />
          </div>
          <div className="nk-header-search d-none d-xl-block">
            <HeaderFinder />
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li
                className="chats-dropdown me-n1"
                onClick={() => setVisibility(false)}
              >
                <ThemeConfigurator />
              </li>
              <li
                className="notification-dropdown me-n1"
                onClick={() => setVisibility(false)}
              >
                <Notification />
              </li>
              <li
                className="user-dropdown"
                onClick={() => setVisibility(false)}
              >
                <UserDropdown />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
