/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { ApplicationOptions, ResultSystemMessage } from '../models/index';
import {
  ApplicationOptionsFromJSON,
  ApplicationOptionsToJSON,
  ResultSystemMessageFromJSON,
  ResultSystemMessageToJSON,
} from '../models/index';

/**
 *
 */
export class AppApi extends runtime.BaseAPI {
  /**
   * Retrieves the global application options
   * Get Options
   */
  async getApplicationOptionsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ApplicationOptions>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/options`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ApplicationOptionsFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the global application options
   * Get Options
   */
  async getApplicationOptions(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ApplicationOptions> {
    const response = await this.getApplicationOptionsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of the currently enabled system messages.
   * List System Messages
   */
  async listSystemMessagesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultSystemMessage>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/messages`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultSystemMessageFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of the currently enabled system messages.
   * List System Messages
   */
  async listSystemMessages(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultSystemMessage> {
    const response = await this.listSystemMessagesRaw(initOverrides);
    return await response.value();
  }
}
