import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  useEffect,
  useState,
} from 'react';
import { useWizard } from './hooks';

interface ProcessesProps {
  onProcessChange?: () => void;
  startsFrom?: number;
  children: ReactNode;
}

export const Processes = ({
  startsFrom = 1,
  onProcessChange,
  children,
}: ProcessesProps) => {
  const { currentProcess, setCurrent, setCount } = useWizard();
  const [startingRender, setStartingRender] = useState<boolean>(true);

  useEffect(() => {
    setStartingRender(false);
    const count = Children.count(children);
    if (startsFrom > count) {
      setCurrent(1);
      console.warn(
        'React Wizard: startsFrom is greater than the number of processes. Starting process will be rendered by default.',
      );
    } else {
      setCurrent(startsFrom);
    }
  }, []);

  useEffect(() => {
    const count = Children.count(children);
    setCount(count);
  }, [children]);

  useEffect(() => {
    !startingRender && onProcessChange?.();
  }, [currentProcess]);

  const processes = Children.map(children, (child, index) => {
    const process = index + 1;
    const processChild = cloneElement(child as ReactElement<unknown>);
    return currentProcess === process && processChild;
  });

  return <>{processes}</>;
};
