import { useTranslation } from '@oproma/i18n';
import { Icon } from '../icon.component';

// TODO: Refactor to a finder component
export const HeaderFinder = () => {
  const { t } = useTranslation();
  return (
    // <>
    //   <Icon name="search"></Icon>
    //   <input
    //     className="form-control form-focus-none border-transparent"
    //     type="text"
    //     placeholder="Find..."
    //   />
    // </>
    <div className="nk-news-list">
      <a
        className="nk-news-item"
        href="https://kb.prividox.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="nk-news-icon">
          <Icon name="card-view" />
        </div>
        <div className="nk-news-text">
          <p>
            {t('COMMON.CHANGELOG.HEADING')}{' '}
            <span>{t('COMMON.CHANGELOG.BODY')}</span>
          </p>
          <Icon name="external" />
        </div>
      </a>
    </div>
  );
};
