/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Result
 */
export interface Result {
  /**
   *
   * @type {boolean}
   * @memberof Result
   */
  grant?: boolean;
  /**
   *
   * @type {string}
   * @memberof Result
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof Result
   */
  verb?: ResultVerbEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ResultVerbEnum {
  Read = 'Read',
  Write = 'Write',
  Delete = 'Delete',
}

/**
 * Check if a given object implements the Result interface.
 */
export function instanceOfResult(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultFromJSON(json: any): Result {
  return ResultFromJSONTyped(json, false);
}

export function ResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Result {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    grant: !exists(json, 'grant') ? undefined : json['grant'],
    source: !exists(json, 'source') ? undefined : json['source'],
    verb: !exists(json, 'verb') ? undefined : json['verb'],
  };
}

export function ResultToJSON(value?: Result | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    grant: value.grant,
    source: value.source,
    verb: value.verb,
  };
}
