import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  editEntityMetadata,
  toggleDisplayRenameEntityModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from '../svg-thumbnails.component';

const errorMessages = {
  english: {
    specialCharacters: 'Entity name must not contain special characters',
    required: 'Entity name is required',
  },
  french: {
    specialCharacters:
      "Le nom de l'entité ne doit pas contenir de caractères spéciaux",
    required: "Le nom de l'entité est requis",
  },
};

export const FileManagerRenameEntityModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayRenameEntityModal, lastOpenedEntity, loading } =
    useAppSelector((state) => state.fileManager);
  const { language } = useAppSelector((state) => state.layout);

  const renameEntitySchema = yup
    .object({
      name: yup
        .string()
        .matches(
          /^[^/\\":?<>|*,&!%@^~{}[\]\r\n]+$/,
          language === 'fr'
            ? errorMessages.french.specialCharacters
            : errorMessages.english.specialCharacters,
        )
        .required(
          language === 'fr'
            ? errorMessages.french.required
            : errorMessages.english.required,
        ),
    })
    .required();

  type RenameEntityFormValues = yup.InferType<typeof renameEntitySchema>;

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RenameEntityFormValues>({
    resolver: yupResolver(renameEntitySchema),
  });

  const { ref: nameRef, ...registerName } = registerField('name');

  const toggle = useCallback(() => {
    dispatch(toggleDisplayRenameEntityModal());
  }, [dispatch]);

  useEffect(() => {
    if (displayRenameEntityModal && lastOpenedEntity?.name) {
      reset({ name: lastOpenedEntity.name });
    }
  }, [displayRenameEntityModal, lastOpenedEntity?.name, reset]);

  const onFormSubmit: SubmitHandler<RenameEntityFormValues> = useCallback(
    ({ name }) => {
      const entityId =
        lastOpenedEntity?.id ??
        lastOpenedEntity?.folderId ??
        lastOpenedEntity?.fileId;

      if (!entityId) return;

      dispatch(
        editEntityMetadata({
          entityId,
          key: 'name',
          value: name,
        }),
      );

      toggle();
    },
    [dispatch, lastOpenedEntity, toggle],
  );

  return (
    <Modal isOpen={displayRenameEntityModal} size="xl" toggle={toggle}>
      <div className="modal-header align-center">
        <div className="nk-file-title">
          <div className="nk-file-icon">
            <div className="nk-file-icon-type">
              {getEntityThumbnail(
                (lastOpenedEntity?.contentType ??
                  lastOpenedEntity?.type ??
                  'others') as EntityThumbnail,
              )}
            </div>
          </div>
          <div className="nk-file-name">
            <div className="nk-file-name-text">
              <span className="title">{lastOpenedEntity?.name}</span>
            </div>
            <div className="nk-file-name-sub">
              {lastOpenedEntity?.type?.toUpperCase()}
            </div>
          </div>
        </div>
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
      </div>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <FormGroup>
            <Label htmlFor="name">
              {lastOpenedEntity?.type === 'folder'
                ? t('FILE_MANAGER.RENAME_ENTITY.FOLDER')
                : t('FILE_MANAGER.RENAME_ENTITY.FILENAME')}
            </Label>
            <div className="form-control-wrap">
              <Input
                type="text"
                id="name"
                className="form-control"
                placeholder={lastOpenedEntity?.name}
                innerRef={nameRef}
                {...registerName}
              />
              {errors?.name && (
                <span className="invalid">{errors.name.message}</span>
              )}
            </div>
          </FormGroup>
        </ModalBody>
        <div className="modal-footer modal-footer-stretch bg-light">
          <div className="modal-footer-between">
            <div className="d-flex"></div>
            <div className="g">
              <ul className="btn-toolbar g-3">
                <li>
                  <Button color="primary">
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      t('FILE_MANAGER.RENAME_ENTITY.SAVE')
                    )}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
