import clsx from 'clsx';
import { HTMLAttributes } from 'react';

interface OverlineTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  alt?: boolean;
  tag?: keyof JSX.IntrinsicElements;
}

export const OverlineTitle = ({
  className,
  alt,
  tag = 'h6',
  ...props
}: OverlineTitleProps) => {
  const classes = clsx('overline-title', className, {
    'overline-title-alt': alt,
  });

  const Tag = tag;

  return <Tag className={classes}>{props.children}</Tag>;
};
