/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateProjectSpec
 */
export interface CreateProjectSpec {
  /**
   * The name to give to the workspace
   * @type {string}
   * @memberof CreateProjectSpec
   */
  name: string;
  /**
   * The ID of the plan to associate with the new workspace.
   * @type {string}
   * @memberof CreateProjectSpec
   */
  plan: string;
  /**
   * The card token to link to the subscription. Not required when the user already has at least one credit card linked.
   * @type {string}
   * @memberof CreateProjectSpec
   */
  token?: string;
  /**
   * The permissions to apply to the default group.
   * @type {string}
   * @memberof CreateProjectSpec
   */
  perms?: CreateProjectSpecPermsEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateProjectSpecPermsEnum {
  INHERITED = 'INHERITED',
  NO_ACCESS = 'NO_ACCESS',
  VIEW = 'VIEW',
  MODIFY = 'MODIFY',
  FULL_CONTROL = 'FULL_CONTROL',
}

/**
 * Check if a given object implements the CreateProjectSpec interface.
 */
export function instanceOfCreateProjectSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'plan' in value;

  return isInstance;
}

export function CreateProjectSpecFromJSON(json: any): CreateProjectSpec {
  return CreateProjectSpecFromJSONTyped(json, false);
}

export function CreateProjectSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateProjectSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    plan: json['plan'],
    token: !exists(json, 'token') ? undefined : json['token'],
    perms: !exists(json, 'perms') ? undefined : json['perms'],
  };
}

export function CreateProjectSpecToJSON(value?: CreateProjectSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    plan: value.plan,
    token: value.token,
    perms: value.perms,
  };
}
