/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  editWorkspacePlan,
  toggleDisplayChangePlanModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Alert,
  Form,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
import { currencyFormatter, prettyBytes } from '../../utils';

const changePlanSchema = yup
  .object({
    plan: yup.string().required(),
  })
  .required();

type ChangePlanFormValues = yup.InferType<typeof changePlanSchema>;

// TODO: Revisit and clean this component up
export const ChangePlanModal = () => {
  const dispatch = useAppDispatch();
  const {
    displayChangePlanModal,
    plan: existingPlan,
    metadata,
  } = useAppSelector((state) => state.workspace);
  const { pricingPlans } = useAppSelector((state) => state.finance);

  const toggle = () => {
    dispatch(toggleDisplayChangePlanModal());
  };

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ChangePlanFormValues>({
    resolver: yupResolver(changePlanSchema),
    defaultValues: {
      plan: existingPlan ? existingPlan.id : '',
    },
  });

  console.log(errors);

  useEffect(() => {
    if (existingPlan?.id) {
      setValue('plan', existingPlan.id);
    }
  }, [existingPlan]);

  // TODO: Move to i18n files. This has to be changed
  const getPlanText = (plan: string | undefined) => {
    if (!plan) return "Couldn't find plan description.";
    const [_, memberLimit, storageLimit] = plan.split('-');
    return `Plan - ${parseInt(memberLimit, 10)} Members - ${parseInt(
      storageLimit,
      10,
    )} GB`;
  };

  const onFormSubmit: SubmitHandler<ChangePlanFormValues> = ({ plan }) => {
    if (plan === existingPlan?.id) {
      return toast.error("Can't change to existing plan.");
    }
    if (!metadata?.id) {
      return;
    }

    dispatch(
      editWorkspacePlan({
        workspaceId: metadata?.id,
        updatePlanSpec: {
          plan,
        },
      }),
    );
  };

  return (
    <Modal isOpen={displayChangePlanModal} size="lg" toggle={toggle}>
      <a
        href="/"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <em className="icon ni ni-cross"></em>
      </a>
      <ModalBody className="modal-body-md">
        <div className="sp-package text-center">
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="sp-package-head">
              <h4 className="title">Change Plan</h4>
              <p className="text-soft">
                This change will become effective on start of the next billing
                cycle.
              </p>
            </div>

            <Nav tabs className="sp-package-plan nav-switch">
              <NavItem>
                <NavLink
                  href="#"
                  active
                  // active={
                  //   existingPlan?.meta?.['package-i18n-name']?.split('-')[0] ===
                  //   'bc'
                  // }
                  // Can't downgrade if on the comprehensive plan
                  disabled={
                    existingPlan?.meta?.['package-i18n-name']?.split('-')[0] ===
                    'bsp'
                  }
                >
                  Starter
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  // active={
                  //   existingPlan?.meta?.['package-i18n-name']?.split('-')[0] ===
                  //   'bsp'
                  // }
                >
                  Comprehensive
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab="1">
              <TabPane tabId="1">
                <ul className="sp-package-list">
                  {pricingPlans &&
                  existingPlan?.interval &&
                  existingPlan?.meta ? (
                    pricingPlans?.[existingPlan.interval]?.[
                      existingPlan.meta?.['package-i18n-name']?.split('-')[0]
                    ]?.map((plan) => (
                      <li className="sp-package-item" key={plan.id}>
                        <Controller
                          name="plan"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              className="sp-package-choose"
                              type="radio"
                              id={plan.id}
                              value={plan.id}
                              checked={field.value === plan.id}
                              onChange={() => setValue('plan', plan.id!)}
                            />
                          )}
                        />
                        <label className="sp-package-desc" htmlFor={plan.id}>
                          <span className="sp-package-info">
                            <span className="sp-package-title title text-primary">
                              {getPlanText(plan.meta?.['package-i18n-name'])}{' '}
                              {plan.id === existingPlan.id && (
                                <span className="badge bg-primary rounded-pill">
                                  Current
                                </span>
                              )}
                            </span>
                            <span className="sp-package-detail">
                              {prettyBytes(
                                parseInt(plan.meta?.['bytes-limit'] ?? '0', 10),
                              )}{' '}
                              / {plan.meta?.['members-limit']} Members /{' '}
                              {`$${currencyFormatter(
                                plan.amount ?? -1,
                              )} ${plan.currency?.toUpperCase()}`}{' '}
                              / {plan.interval?.toUpperCase()}
                            </span>
                          </span>
                        </label>
                      </li>
                    ))
                  ) : (
                    <Alert color="danger" className="alert-icon">
                      <Icon name="alert-circle" /> Can't change plan at this
                      time. Failed to fetch plan details. Please try again.
                    </Alert>
                  )}
                </ul>
              </TabPane>
              <TabPane tabId="2">Example</TabPane>
            </TabContent>

            <div className="sp-package-action">
              <Button color="primary" type="submit">
                Change Plan
              </Button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};
