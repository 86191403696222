import { yupResolver } from '@hookform/resolvers/yup';
import {
  setDisplayBulkDistributeModal,
  setDisplayDistributeEntityModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { createEntityDistributionLink } from '../utils';

type SelectedMembers = {
  id: string;
  name: string;
  email: string;
}[];

const distributeEntitySchema = yup
  .object({
    members: yup.array().of(yup.string()).required(),
  })
  .required();

type DistributeEntityFormValues = yup.InferType<typeof distributeEntitySchema>;

export const useFileManagerDistributionEntityForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, entityId } = useParams();
  const {
    entityParent,
    lastOpenedEntity,
    displayBulkDistributeModal,
    displayDistributeEntityModal,
  } = useAppSelector((state) => state.fileManager);
  const { members } = useAppSelector((state) => state.members);
  const [selectedMembers, setSelectedMembers] = useState<SelectedMembers>([]);
  const [displayEmailButtons, setDisplayEmailButtons] = useState(false);
  const openedWorkspace = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === state.workspaces.lastOpenedWorkspace,
    ),
  );

  const handleSelectChange: (
    newValue: ReadonlyArray<{ value: string; label: string }>,
    actionMeta: any,
  ) => void = (newValue) => {
    const selectedOptions: SelectedMembers = newValue.map((option) => ({
      id: option.value,
      name: option.label,
      email: option.value,
    }));
    setSelectedMembers(selectedOptions);
  };

  const membersOptions = members.map((member) => {
    return {
      value: member.email!,
      label: member.name
        ? `${member.name} &lt;${member.email}&gt;`
        : `<em>${t('FILE_MANAGER.DISTRIBUTE.UNREGISTERED')}</em> &lt;${
            member.email
          }&gt;`,
    };
  });

  const {
    reset,
    register: registerField,
    formState: { errors },
  } = useForm<DistributeEntityFormValues>({
    resolver: yupResolver(distributeEntitySchema),
    defaultValues: {
      members: [],
    },
  });

  const _distributionLink = () => {
    if (displayDistributeEntityModal) {
      return createEntityDistributionLink(
        lastOpenedEntity,
        workspaceId,
        entityParent,
        openedWorkspace,
      );
    }
    if (displayBulkDistributeModal) {
      const origin = window.location.origin;
      if (entityId) {
        return `${origin}/${workspaceId}/file-manager/entities/${entityId}`;
      } else {
        return `${origin}/${workspaceId}/file-manager/entities`;
      }
    }
  };

  const distributionLink = _distributionLink() as string;

  const getEmailUrl = async (mailto = false, gmail = false) => {
    const url = distributionLink;
    if (!url) return;

    const title = lastOpenedEntity?.name;
    const recipients = selectedMembers.map((member) => member.email).join(';');

    return `${encodeURIComponent(recipients)}${mailto ? '?' : '&'}su${
      gmail ? '' : 'bject'
    }=${encodeURIComponent('Share ' + title)}&body=${encodeURIComponent(url)}`;
  };

  const handleNativeEmailing = async () => {
    if (navigator.share) {
      const url = distributionLink;
      if (!url) return;
      const title = lastOpenedEntity?.name;
      const shareData = { title, url };

      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    }
  };

  const handleEmailWithGmail = async () => {
    const url = await getEmailUrl();
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${url}`,
      '',
      'noopener,noreferrer',
    );
  };

  const handleEmailWithOutlook = async () => {
    const url = await getEmailUrl(false, true);
    window.open(
      `https://outlook.office.com/owa/?path=/mail/action/compose&to=${url}`,
      '',
      'noopener,noreferrer',
    );
  };

  const handleEmailShareYahoo = async () => {
    const url = await getEmailUrl();
    window.open(
      `https://compose.mail.yahoo.com/?to=${url}`,
      '',
      'noopener,noreferrer',
    );
  };

  const handleDispatchClick = async () => {
    const url = await getEmailUrl(true);
    if (window.top) {
      window.top.location = `mailto:${url}`;
    }
  };

  const toggle = () => {
    dispatch(setDisplayDistributeEntityModal(false));
    dispatch(setDisplayBulkDistributeModal(false));
    setDisplayEmailButtons(false);
    reset();
  };

  return {
    entity: lastOpenedEntity,
    errors,
    toggle,
    getEmailUrl,
    workspaceId,
    registerField,
    membersOptions,
    distributionLink,
    lastOpenedEntity,
    displayEmailButtons,
    handleSelectChange,
    handleDispatchClick,
    handleNativeEmailing,
    handleEmailWithGmail,
    handleEmailWithOutlook,
    handleEmailShareYahoo,
    setDisplayEmailButtons,
    displayBulkDistributeModal,
    displayDistributeEntityModal,
  };
};
