import clsx from 'clsx';
import { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Logo } from './logo.component';
import { Menu } from './menu.component';
import { Toggle } from './toggle.component';

interface SidebarProps {
  fixed?: boolean;
  theme?: string;
  sidebarToggle: (ev: React.MouseEvent) => void;
  mobileView?: boolean;
  className?: string;
  preview?: boolean;
}

export const Sidebar = ({
  fixed,
  theme,
  sidebarToggle,
  mobileView,
  className,
  preview,
}: SidebarProps) => {
  // TODO: Move to global redux state
  const [collapseSidebar, setSidebar] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);

  const toggleCollapse = () => {
    setSidebar(!collapseSidebar);
  };

  const handleMouseEnter = () => setMouseEnter(true);
  const handleMouseLeave = () => setMouseEnter(false);

  const classes = clsx({
    'nk-sidebar': true,
    'nk-sidebar-fixed': fixed,
    'is-compact': collapseSidebar,
    'has-hover': collapseSidebar && mouseEnter,
    [`is-light`]: theme === 'white',
    [`is-${theme}`]: theme !== 'white' && theme !== 'light',
    [`${className}`]: className,
  });
  return (
    <div className={classes}>
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-menu-trigger">
          <Toggle
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none me-n2"
            icon="arrow-left"
            click={sidebarToggle}
          />
          <Toggle
            className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${
              collapseSidebar ? 'compact-active' : ''
            }`}
            click={toggleCollapse}
            icon="menu"
          />
        </div>
        <div className="nk-sidebar-brand">
          <Logo preview={preview} />
        </div>
      </div>
      <div
        className="nk-sidebar-content"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SimpleBar className="nk-sidebar-menu">
          <Menu
            sidebarToggle={sidebarToggle}
            mobileView={mobileView}
            preview={preview}
          />
        </SimpleBar>
      </div>
    </div>
  );
};
