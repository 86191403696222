import {
  editTaskMetadata,
  setColumnOrder,
  setColumns,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { TasksColumn } from './tasks-column.component';

export const TasksBoard = () => {
  const { workspaceId } = useParams();
  const dispatch = useAppDispatch();
  const { columnOrder, columns, tasks } = useAppSelector(
    (state) => state.tasks,
  );

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination, draggableId, type } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      dispatch(setColumnOrder(newColumnOrder));

      return;
    }

    const home = columns.find((column) => column.id === source.droppableId);
    const foreign = columns.find(
      (column) => column.id === destination.droppableId,
    );

    // check for same column
    if (home === foreign) {
      const items = Array.from(home?.tasks || []);
      items.splice(source.index, 1);
      items.splice(destination.index, 0, draggableId);

      // find the column and update the state
      const index = columns.findIndex((column) => column.id === home?.id);
      if (index === -1) return;
      const newColumns = Array.from(columns);
      newColumns[index].tasks = items;

      // Dispatch editTaskMetadata for each task
      items.forEach((taskId, order) => {
        if (!home?.id || !home?.name || !workspaceId) return;
        dispatch(
          editTaskMetadata({
            taskId,
            workspaceId,
            columnTheme: home?.theme,
            order: order.toString(),
            columnId: home?.id,
            columnName: home?.name,
            columnOrder: index.toString(),
          }),
        );
      });
      toast.success('Task has been moved.');

      dispatch(setColumns(newColumns));
      return;
    } else {
      // moving from one list to another
      const hometasks = Array.from(home?.tasks || []);
      hometasks.splice(source.index, 1);

      const foreigntasks = Array.from(foreign?.tasks || []);
      foreigntasks.splice(destination.index, 0, draggableId);

      // find the column and update the state
      const homeIndex = columns.findIndex((column) => column.id === home?.id);
      const foreignIndex = columns.findIndex(
        (column) => column.id === foreign?.id,
      );
      if (homeIndex === -1 || foreignIndex === -1) return;
      const newColumns = Array.from(columns);
      newColumns[homeIndex] = {
        ...newColumns[homeIndex],
        tasks: hometasks,
      };
      newColumns[foreignIndex] = {
        ...newColumns[foreignIndex],
        tasks: foreigntasks,
      };

      // Dispatch editTaskMetadata for each task in home and foreign columns
      [hometasks, foreigntasks].forEach((tasks, index) => {
        const column = newColumns[index === 0 ? homeIndex : foreignIndex];
        tasks.forEach((taskId, order) => {
          if (!workspaceId) return;
          dispatch(
            editTaskMetadata({
              taskId,
              workspaceId,
              columnTheme: column.theme,
              order: order.toString(),
              columnId: column.id,
              columnName: column.name,
              columnOrder: index.toString(),
            }),
          );
        });
      });

      toast.success('Task has been moved.');

      dispatch(setColumns(newColumns));
    }
  };

  return (
    <div className="nk-kanban">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {(provided) => (
            <div
              className="kanban-container"
              id="kanban-container"
              style={{ width: `${columnOrder.length * 320}px` }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columnOrder.map((columnId, index) => {
                const column = columns.find((column) => column.id === columnId);
                if (!column) return undefined;
                return (
                  <TasksColumn
                    columnOrder={columnOrder}
                    tasks={tasks}
                    column={column}
                    key={index}
                    index={index}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
