import { MemberBulkOperation } from './members-table-bulk.component';
import MobileMembersTableToggle from './members-table-toggle.component';

export const MemberFunctions = () => {
  return (
    <div className="card-title-group">
      <MemberBulkOperation />
      <MobileMembersTableToggle />
    </div>
  );
};
