import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';

interface ColProps extends HTMLAttributes<HTMLDivElement> {
  xs?: number | string;
  sm?: number | string;
  lg?: number | string;
  md?: number | string;
  xl?: number | string;
  xxl?: number | string;
  size?: number | string;
}

export const Col: React.FC<ColProps> = ({
  xs,
  sm,
  lg,
  md,
  xl,
  xxl,
  size,
  className,
  ...props
}) => {
  const classes = clsx({
    [`col-sm-${xs}`]: xs,
    [`col-sm-${sm}`]: sm,
    [`col-lg-${lg}`]: lg,
    [`col-md-${md}`]: md,
    [`col-xl-${xl}`]: xl,
    [`col-xxl-${xxl}`]: xxl,
    [`col-${size}`]: size,
    [`${className}`]: className,
  });

  return (
    <div className={classes} {...props}>
      {props.children}
    </div>
  );
};

type RowProps = HTMLAttributes<HTMLDivElement>;

export const Row: React.FC<RowProps> = ({ className, ...props }) => {
  const rowClass = clsx({
    row: true,
    [`${className}`]: className,
  });

  return (
    <div className={rowClass} {...props}>
      {props.children}
    </div>
  );
};
