/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChargeInfo } from './ChargeInfo';
import {
  ChargeInfoFromJSON,
  ChargeInfoFromJSONTyped,
  ChargeInfoToJSON,
} from './ChargeInfo';

/**
 *
 * @export
 * @interface TransactionList
 */
export interface TransactionList {
  /**
   *
   * @type {boolean}
   * @memberof TransactionList
   */
  more?: boolean;
  /**
   *
   * @type {Array<ChargeInfo>}
   * @memberof TransactionList
   */
  invoices?: Array<ChargeInfo>;
}

/**
 * Check if a given object implements the TransactionList interface.
 */
export function instanceOfTransactionList(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TransactionListFromJSON(json: any): TransactionList {
  return TransactionListFromJSONTyped(json, false);
}

export function TransactionListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    more: !exists(json, 'more') ? undefined : json['more'],
    invoices: !exists(json, 'invoices')
      ? undefined
      : (json['invoices'] as Array<any>).map(ChargeInfoFromJSON),
  };
}

export function TransactionListToJSON(value?: TransactionList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    more: value.more,
    invoices:
      value.invoices === undefined
        ? undefined
        : (value.invoices as Array<any>).map(ChargeInfoToJSON),
  };
}
