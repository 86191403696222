import { useAppSelector } from '@oproma/prividox-store';
import ReactDatePicker from 'react-datepicker';
import { Alert, Form, Input, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { useEditTaskForm } from '../../hooks';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';

export const EditTaskModal = () => {
  const { lastOpenedTask } = useAppSelector((state) => state.tasks);
  const {
    expiry,
    toggle,
    errors,
    nameRef,
    loading,
    setValue,
    registerName,
    handleSubmit,
    onFormSubmit,
    memberOptions,
    columnsOptions,
    descriptionRef,
    lastOpenedColumn,
    registerDescription,
    displayEditTaskModal,
  } = useEditTaskForm(lastOpenedTask);
  return (
    <Modal size="lg" isOpen={displayEditTaskModal} toggle={toggle}>
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Edit Task</h5>
          <div className="mt-4">
            {columnsOptions.length < 1 && (
              <Alert className="alert-icon" color="danger">
                <Icon name="cross-circle" />
                <strong>Missing Columns</strong>! Please create a column first.
              </Alert>
            )}
            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <Input type="text" innerRef={nameRef} {...registerName} />
                  {errors.name && (
                    <span className="invalid">{errors.name.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Column</label>
                  {/* TODO: Prefill the column if the create task is clicked from a column */}
                  <Select
                    isDisabled={true}
                    options={columnsOptions}
                    defaultValue={columnsOptions.find(
                      (columnOption) => columnOption.value === lastOpenedColumn,
                    )}
                    placeholder="Pick a column"
                  />
                </div>
              </Col>
              <Col className="col-12">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control no-resize"
                    ref={descriptionRef}
                    {...registerDescription}
                  />
                  {errors.description && (
                    <span className="invalid">
                      {errors.description.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">User</label>
                  <Select
                    options={memberOptions}
                    // isMulti
                    defaultValue={memberOptions[0]}
                    onChange={(ev) => {
                      if (!ev?.value) return;
                      setValue('assignee', ev.value, {
                        shouldDirty: true,
                      });
                    }}
                  />

                  {errors.assignee && (
                    <span className="invalid">{errors.assignee.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Date</label>
                  <ReactDatePicker
                    selected={expiry || new Date()}
                    onChange={(date) => {
                      if (!date) return;
                      setValue('expiry', date, {
                        shouldDirty: true,
                      });
                    }}
                    className="form-control date-picker"
                  />
                  {errors.expiry && (
                    <span className="invalid">{errors.expiry.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Linked Files</label>
                  {/* <Select
                options={tagSet}
                // isMulti
                defaultValue={formData.tags}
                onChange={(ev: any) =>
                  setFormData({ ...formData, tags: ev })
                }
              /> */}
                </div>
              </Col>
              <Col className="col-12">
                <ul className="d-flex justify-content-between mt-3">
                  <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                    <li>
                      <Button
                        color="primary"
                        size="md"
                        type="submit"
                        disabled={columnsOptions.length < 1 || loading}
                      >
                        Edit Task
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
