import {
  nextProcess,
  prevProcess,
  setCurrentProcess,
  setProcess,
  setProcessesCount,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';

export const useWizard = () => {
  const dispatch = useAppDispatch();
  const { wizard } = useAppSelector((state) => state.layout);

  const isLast = wizard.currentProcess === wizard.overallProcesses;
  const isFirst = wizard.currentProcess === 1;
  const hasPrev = wizard.currentProcess > 1;
  const hasNext = wizard.currentProcess < wizard.overallProcesses;
  const progress = Number(
    ((wizard.currentProcess - 1) / (wizard.overallProcesses - 1)).toFixed(2),
  );

  return {
    ...wizard,
    next: () => dispatch(nextProcess()),
    prev: () => dispatch(prevProcess()),
    navigate: (process: number) => dispatch(setProcess(process)),
    setCurrent: (current: number) => dispatch(setCurrentProcess(current)),
    setCount: (size: number) => dispatch(setProcessesCount(size)),
    isFirst,
    isLast,
    hasPrev,
    hasNext,
    progress,
  };
};
