/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProjectInformation
 */
export interface ProjectInformation {
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  bytesLimit?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  filesLimit?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  foldersLimit?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  membersLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  subscribed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  zipExpand?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  filePublish?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  forceSecurity?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowProduction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowRedaction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowReview?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowConversions?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  root?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  amOwner?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  level?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  access?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ProjectInformation
   */
  metadata?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  admin?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  isAdmin?: boolean;
}

/**
 * Check if a given object implements the ProjectInformation interface.
 */
export function instanceOfProjectInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ProjectInformationFromJSON(json: any): ProjectInformation {
  return ProjectInformationFromJSONTyped(json, false);
}

export function ProjectInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProjectInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    bytesLimit: !exists(json, 'bytesLimit') ? undefined : json['bytesLimit'],
    filesLimit: !exists(json, 'filesLimit') ? undefined : json['filesLimit'],
    foldersLimit: !exists(json, 'foldersLimit')
      ? undefined
      : json['foldersLimit'],
    membersLimit: !exists(json, 'membersLimit')
      ? undefined
      : json['membersLimit'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    subscribed: !exists(json, 'subscribed') ? undefined : json['subscribed'],
    zipExpand: !exists(json, 'zipExpand') ? undefined : json['zipExpand'],
    filePublish: !exists(json, 'filePublish') ? undefined : json['filePublish'],
    forceSecurity: !exists(json, 'forceSecurity')
      ? undefined
      : json['forceSecurity'],
    allowProduction: !exists(json, 'allowProduction')
      ? undefined
      : json['allowProduction'],
    allowRedaction: !exists(json, 'allowRedaction')
      ? undefined
      : json['allowRedaction'],
    allowReview: !exists(json, 'allowReview') ? undefined : json['allowReview'],
    allowConversions: !exists(json, 'allowConversions')
      ? undefined
      : json['allowConversions'],
    root: !exists(json, 'root') ? undefined : json['root'],
    amOwner: !exists(json, 'amOwner') ? undefined : json['amOwner'],
    level: !exists(json, 'level') ? undefined : json['level'],
    access: !exists(json, 'access') ? undefined : json['access'],
    metadata: !exists(json, 'metadata') ? undefined : json['metadata'],
    admin: !exists(json, 'admin') ? undefined : json['admin'],
    isAdmin: !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
  };
}

export function ProjectInformationToJSON(
  value?: ProjectInformation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    bytesLimit: value.bytesLimit,
    filesLimit: value.filesLimit,
    foldersLimit: value.foldersLimit,
    membersLimit: value.membersLimit,
    enabled: value.enabled,
    subscribed: value.subscribed,
    zipExpand: value.zipExpand,
    filePublish: value.filePublish,
    forceSecurity: value.forceSecurity,
    allowProduction: value.allowProduction,
    allowRedaction: value.allowRedaction,
    allowReview: value.allowReview,
    allowConversions: value.allowConversions,
    root: value.root,
    amOwner: value.amOwner,
    level: value.level,
    access: value.access,
    metadata: value.metadata,
    admin: value.admin,
    isAdmin: value.isAdmin,
  };
}
