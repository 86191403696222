import { useState } from 'react';

export type ContextMenuState = {
  display: boolean;
  position: ContextMenuPosition;
  activeEntityId: string | null;
};

export type ContextMenuPosition = { x: number; y: number };

export const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useState<ContextMenuState>({
    display: false,
    position: { x: 0, y: 0 },
    activeEntityId: null,
  });

  const handleContextMenu = ({
    position,
    entityId,
  }: {
    position: ContextMenuPosition;
    entityId: string;
  }) => {
    setContextMenu({
      display: true,
      position,
      activeEntityId: entityId,
    });
  };

  // Close the context menu
  const closeContextMenu = () => {
    setContextMenu({ ...contextMenu, display: false });
  };

  return { contextMenu, handleContextMenu, closeContextMenu };
};
