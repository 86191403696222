import { Input, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { ColorOptions } from '../../color-options.component';
import { Col } from '../../grid.component';
import { useCreateTaskColumnForm } from '../../hooks';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';

export const TasksCreateColumnModal = () => {
  const {
    themes,
    toggle,
    errors,
    nameRef,
    setValue,
    registerName,
    handleSubmit,
    onFormSubmit,
    registerColour,
    displayCreateColumnModal,
  } = useCreateTaskColumnForm();

  // TODO: Translate
  return (
    <Modal size="lg" isOpen={displayCreateColumnModal} toggle={toggle}>
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Create Column</h5>
          <div className="mt-4">
            <form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
              <Col className="col-12">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <Input type="text" innerRef={nameRef} {...registerName} />
                  {errors.name && (
                    <span className="invalid">{errors.name.message}</span>
                  )}
                </div>
              </Col>
              <Col className="col-12">
                <div className="form-group">
                  <label className="form-label">Colour</label>
                  <div className="form-control-select">
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      formatOptionLabel={ColorOptions}
                      options={themes}
                      placeholder="Pick a column colour"
                      {...registerColour}
                      onChange={(ev) => {
                        if (ev?.value) setValue('colour', ev?.value);
                      }}
                    />
                    {errors.colour && (
                      <span className="invalid">{errors.colour.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="col-12">
                <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      Create Column
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
