import { useTranslation } from '@oproma/i18n';
import {
  WorkspaceMember,
  setPaginatedPage,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { PaginationComponent } from '../data-table-pagination.component';
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
} from '../data-table.component';
import { MemberFunctions } from './members-table-functions.component';
import MemberQuery from './members-table-query.component';
import { MemberRow } from './members-table-row.component';

export const MembersTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    members,
    memberSortOrder,
    memberSortValue,
    memberQuery,
    permissionLevelFilter,
    currentPaginatedPage,
    entriesPerPaginatedPage,
  } = useAppSelector((state) => state.members);

  // local state for managing member operations
  const [localMembers, setLocalMembers] = useState(members);

  // Get current list, pagination
  const indexOfLastEntry = currentPaginatedPage * entriesPerPaginatedPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPaginatedPage;
  const paginatedMemberEntries = localMembers.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );

  const isWorkspaceOwner = (member: WorkspaceMember) => {
    return member.accessLevel?.toString() === '127';
  };

  const processMembers = useCallback(() => {
    let processedMembers = [...members];
    if (memberQuery) {
      processedMembers = processedMembers.filter((member) => {
        return (
          member.name?.toLowerCase().includes(memberQuery.toLowerCase()) ||
          member.email?.toLowerCase().includes(memberQuery.toLowerCase())
        );
      });
    }

    if (permissionLevelFilter) {
      processedMembers = processedMembers.filter(
        (member) =>
          member.accessLevel?.toString() === permissionLevelFilter.toString(),
      );
    }

    if (permissionLevelFilter === 0) {
      processedMembers = processedMembers.filter(
        (member) => member.accessLevel?.toString() === '0',
      );
    }

    if (memberSortValue) {
      processedMembers.sort((a, b) => {
        if (memberSortValue === 'workspaceOwner') {
          const aIsOwner = isWorkspaceOwner(a);
          const bIsOwner = isWorkspaceOwner(b);
          if (aIsOwner === bIsOwner && a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return memberSortOrder === 'asc'
            ? aIsOwner
              ? -1
              : 1
            : aIsOwner
            ? 1
            : -1;
        } else {
          const sortKey = memberSortValue as keyof WorkspaceMember;
          const valueA = a[sortKey];
          const valueB = b[sortKey];

          if (valueA == null || valueB == null) return 0;

          const compareResult = String(valueA).localeCompare(String(valueB));
          return memberSortOrder === 'asc' ? compareResult : -compareResult;
        }
      });
    }
    return processedMembers;
  }, [
    memberQuery,
    permissionLevelFilter,
    memberSortOrder,
    memberSortValue,
    members,
  ]);

  useEffect(() => {
    const processedMembers = processMembers();
    setLocalMembers(processedMembers);
  }, [processMembers]);

  // function to toggle every member's checked property
  const onCheckEveryMember = (ev: ChangeEvent<HTMLInputElement>) => {
    const checked = localMembers.map((member) => ({
      ...member,
      checked: ev.currentTarget.checked,
    }));
    setLocalMembers(checked);
  };

  // Change Page
  const paginate = (page: number) => dispatch(setPaginatedPage(page));

  return (
    <DataTable className="card-stretch">
      <div className="card-inner position-relative card-tools-toggle">
        <MemberFunctions />
        <MemberQuery />
      </div>
      <DataTableBody>
        <DataTableHead>
          <DataTableRow className="nk-tb-col-check">
            <div className="custom-control custom-control-sm custom-checkbox notext">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={(ev) => onCheckEveryMember(ev)}
                id="uid"
              />
              <label className="custom-control-label" htmlFor="uid"></label>
            </div>
          </DataTableRow>
          <DataTableRow>
            <span className="sub-text">
              {t('MEMBERS.TABLE_COLUMNS.MEMBER')}
            </span>
          </DataTableRow>
          <DataTableRow size="mb">
            <span className="sub-text">
              {t('MEMBERS.TABLE_COLUMNS.PERMISSION')}
            </span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">
              {t('MEMBERS.TABLE_COLUMNS.CONFIRMATION')}
            </span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">
              {t('MEMBERS.TABLE_COLUMNS.PRESENCE')}
            </span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">
              {t('MEMBERS.TABLE_COLUMNS.OPTIONS')}
            </span>
          </DataTableRow>
        </DataTableHead>
        {paginatedMemberEntries.map((memberEntry, i) => (
          <MemberRow memberEntry={memberEntry} key={memberEntry.id ?? i} />
        ))}
      </DataTableBody>
      <div className="card-inner">
        {paginatedMemberEntries.length > 0 ? (
          <PaginationComponent
            itemPerPage={entriesPerPaginatedPage}
            totalItems={paginatedMemberEntries.length}
            paginate={paginate}
            currentPage={currentPaginatedPage}
          />
        ) : (
          <div className="text-center">
            <span className="text-silent">{t('DASHBOARD.NO_ACTIVITY')}</span>
          </div>
        )}
      </div>
    </DataTable>
  );
};
