import { yupResolver } from '@hookform/resolvers/yup';
import {
  setCreateWorkpaceFormField,
  useAppDispatch,
} from '@oproma/prividox-store';
import { size } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form, Input } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useWizard } from '../hooks';

const permissionOptions = [
  { value: 'inherited', label: 'Inherited' },
  { value: 'no-access', label: 'No Access' },
  { value: 'view', label: 'Read' },
  { value: 'modify', label: 'Edit' },
  { value: 'full-control', label: 'Full Control' },
];

const onboardWorkspaceBasicsFormSchema = yup
  .object({
    name: yup.string().min(3).max(24).required(),
    rootFolderPermissions: yup.string().required(),
  })
  .required();

type OnboardWorkspaceBasicsFormValues = yup.InferType<
  typeof onboardWorkspaceBasicsFormSchema
>;

export const OnboardWorkspaceBasicsForm = () => {
  const { next } = useWizard();
  const dispatch = useAppDispatch();

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardWorkspaceBasicsFormValues>({
    resolver: yupResolver(onboardWorkspaceBasicsFormSchema),
    defaultValues: {
      rootFolderPermissions: 'inherited',
    },
  });

  const { ref: nameRef, ...registerName } = registerField('name');
  const { ref: rootFolderPermissionsRef, ...registerRootFolderPermissions } =
    registerField('rootFolderPermissions');

  const onFormSubmit: SubmitHandler<OnboardWorkspaceBasicsFormValues> = ({
    name,
    rootFolderPermissions,
  }) => {
    dispatch(
      setCreateWorkpaceFormField({
        name,
        rootFolderPermissions,
      }),
    );
    next();
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} className="gy-3">
      <Row className="g-3 align-center">
        <Col lg="5">
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              Workspace Name
            </label>
            <span className="form-note">
              Provide the name of the worskpace to create.
            </span>
          </div>
        </Col>
        <Col lg="7">
          <div className="form-group">
            <div className="form-control-wrap">
              <Input
                type="text"
                id="name"
                placeholder="Example"
                innerRef={nameRef}
                {...registerName}
              />
              {errors.name && <p className="invalid">{errors.name.message}</p>}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="g-3 align-center">
        <Col lg="5">
          <div className="form-group">
            <label className="form-label">
              Default Root Folder Permisssions
            </label>
            <span className="form-note">
              Provide the default permissions for the root folder of your
              workspace.
            </span>
          </div>
        </Col>
        <Col lg="7">
          <div className="form-group">
            <div className="form-control-select">
              <Input
                type="select"
                id="permissions-select"
                innerRef={rootFolderPermissionsRef}
                {...registerRootFolderPermissions}
              >
                {permissionOptions.map((option, i) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Input>
              {errors.rootFolderPermissions && (
                <p className="invalid">
                  {errors.rootFolderPermissions.message}
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg="7" className="offset-lg-5">
          <div className="form-group mt-2">
            <Button
              color="primary"
              className="btn-block"
              size="md"
              type="submit"
              disabled={size(errors) >= 1}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
      <div className="actions clearfix"></div>
    </Form>
  );
};
