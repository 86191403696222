/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChargeInfo
 */
export interface ChargeInfo {
  /**
   *
   * @type {string}
   * @memberof ChargeInfo
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof ChargeInfo
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof ChargeInfo
   */
  created?: number;
  /**
   *
   * @type {string}
   * @memberof ChargeInfo
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof ChargeInfo
   */
  description?: string;
}

/**
 * Check if a given object implements the ChargeInfo interface.
 */
export function instanceOfChargeInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ChargeInfoFromJSON(json: any): ChargeInfo {
  return ChargeInfoFromJSONTyped(json, false);
}

export function ChargeInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChargeInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    amount: !exists(json, 'amount') ? undefined : json['amount'],
    created: !exists(json, 'created') ? undefined : json['created'],
    currency: !exists(json, 'currency') ? undefined : json['currency'],
    description: !exists(json, 'description') ? undefined : json['description'],
  };
}

export function ChargeInfoToJSON(value?: ChargeInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    amount: value.amount,
    created: value.created,
    currency: value.currency,
    description: value.description,
  };
}
