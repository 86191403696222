import {
  Block,
  Content,
  CreateGroupModal,
  EditGroupModal,
  DeleteGroupModal,
  GroupsHeader,
  GroupsTable,
  Head,
  Layout,
} from '@oproma/core-components';
import { getWorkspaceGroups, useAppDispatch } from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const GroupsPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(
      getWorkspaceGroups({
        workspaceId,
      }),
    );
  }, [dispatch, workspaceId]);

  return (
    <>
      <Head heading={t('GROUPS.GROUP_TABLE.HEADER.TITLE')} />
      <Layout>
        <Content>
          <GroupsHeader />
          <Block>
            <GroupsTable />
          </Block>
          <CreateGroupModal />
          <EditGroupModal />
          <DeleteGroupModal />
        </Content>
      </Layout>
    </>
  );
};

export default GroupsPage;
