import { useTranslation } from '@oproma/i18n';
import {
  setDisplayMobileMenu,
  setDisplayOnboardMembersModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../block.component';
import { Button } from '../button.component';
import { Icon } from '../icon.component';

export const MembersHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayMobileMenu } = useAppSelector((state) => state.layout);
  const { displayOnboardMembersModal, members } = useAppSelector(
    (state) => state.members,
  );

  const changeDisplayMobileMenu = (payload: boolean) => {
    dispatch(setDisplayMobileMenu(payload));
  };

  const toggleOnboardMembersModal = () => {
    dispatch(setDisplayOnboardMembersModal(!displayOnboardMembersModal));
  };
  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
          <BlockTitle tag="h3" page>
            {t('MEMBERS.HEADER.TITLE')}
          </BlockTitle>
          <BlockDes className="text-soft">
            <p>
              {members.length} {t('MEMBERS.HEADER.COUNT')}
            </p>
          </BlockDes>
        </BlockHeadContent>
        <BlockHeadContent>
          <div className="toggle-wrap nk-block-tools-toggle">
            <Button
              className={clsx('btn-icon btn-trigger toggle-expand me-n1', {
                active: displayMobileMenu,
              })}
              onClick={() => changeDisplayMobileMenu(!displayMobileMenu)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
            <div
              className="toggle-expand-content"
              style={{ display: displayMobileMenu ? 'block' : 'none' }}
            >
              <ul className="nk-block-tools g-3">
                <li>
                  <Button color="light" outline className="btn-white">
                    <Icon name="download-cloud"></Icon>
                    <span>{t('MEMBERS.HEADER.EXPORT')}</span>
                  </Button>
                </li>
                <li className="nk-block-tools-opt">
                  <Button
                    color="primary"
                    className="btn-icon"
                    onClick={toggleOnboardMembersModal}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </BlockHeadContent>
      </BlockBetween>
    </BlockHead>
  );
};
