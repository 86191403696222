import { yupResolver } from '@hookform/resolvers/yup';
import {
  createDeviceFingerPrint,
  login,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useNavigate } from '@oproma/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToggleDisplayingPassswordText } from './use-toggle-displaying-passsword-text.hook';

const lockSchema = yup
  .object({
    password: yup.string().min(8).max(24).required(),
  })
  .required();

type LockFormValues = yup.InferType<typeof lockSchema>;

export const useLockForm = () => {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state) => state.user);
  const { displayPasswordText, toggleDisplayingPasswordText } =
    useToggleDisplayingPassswordText();

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<LockFormValues>({ resolver: yupResolver(lockSchema) });

  const { ref: passwordRef, ...registerPassword } = registerField('password');

  const onFormSubmit: SubmitHandler<LockFormValues> = ({ password }) => {
    if (!email) {
      return router('/auth/login', { replace: true });
    }
    dispatch(
      login({
        email,
        pass: password,
        fingerprint: createDeviceFingerPrint(),
        exp: 1800000, // expiry time in milliseconds
      }),
    );
  };

  return {
    errors,
    email,
    passwordRef,
    onFormSubmit,
    handleSubmit,
    registerPassword,
    displayPasswordText,
    toggleDisplayingPasswordText,
  };
};
