import {
  Block,
  BlockTitle,
  FileManagerContainer,
  FileManagerNavigationLayout,
  Icon,
  Layout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';

const FileManagerPlansPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <FileManagerContainer>
        <FileManagerNavigationLayout
          title={<BlockTitle page>{t('FILE_MANAGER.PLANS.TITLE')}</BlockTitle>}
          entityFinder
        >
          <Block size="xs" className="pt-0">
            <div className="user-plan-wrap">
              <div className="nk-block-subhead">
                <h6 className="overline-title">
                  {t('FILE_MANAGER.PLANS.HEADING')}
                </h6>
              </div>
              <div className="user-plan">
                <div className="user-plan-info">
                  <div className="user-plan-title">
                    <Icon name="hard-drive"></Icon>{' '}
                    <span>
                      {/* {fileManager.data.plans.find((item) => item.id === fileManager.currentPlan).title} plan */}
                      S
                    </span>
                  </div>
                  <div className="user-plan-status">
                    {/* 12.47 GB / {fileManager.data.plans.find((item) => item.id === fileManager.currentPlan).memory} GB */}
                  </div>
                </div>
                <div className="user-plan-actions">
                  <ul className="btn-toolbar align-center g-4">
                    <li className="order-2 order-sm-1">
                      <a
                        href="#"
                        className="link link-primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          // toggle("billing");
                        }}
                      >
                        {t('FILE_MANAGER.PLANS.ALL_PLANS')}
                      </a>
                    </li>
                    <li className="order-1 order-sm-2">
                      <a
                        href="#"
                        className="btn btn-sm btn-primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          // toggle("billing");
                        }}
                      >
                        {t('FILE_MANAGER.PLANS.UPGRADE')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="user-plan-progress">
                  <div className="progress progress-md bg-light">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${
                          1200 /
                          //   fileManager.data.plans.find((item) => item.id === fileManager.currentPlan).memory
                          10000
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </Block>
        </FileManagerNavigationLayout>
      </FileManagerContainer>
    </Layout>
  );
};

export default FileManagerPlansPage;
