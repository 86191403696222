import { FileWithPath, useAppSelector } from '@oproma/prividox-store';
import { Progress } from 'reactstrap';
import { Icon } from '../icon.component';
import { prettyBytes } from '../utils';

export interface FileTreeNode {
  name: string;
  type: 'file' | 'folder';
  children?: FileTreeNode[];
  file?: FileWithPath;
  filepath?: string;
  level: number;
}

export const FileTreeNodes = ({
  node,
  onRemove,
  index,
  processUid,
  cancelUpload,
}: {
  node: FileTreeNode;
  onRemove: (filepath: string, index: number) => void;
  index: number;
  processUid?: string;
  cancelUpload?: (processUid: string) => void;
}) => {
  const progress = useAppSelector(
    (state) => state.fileManager.entityCreationProgress[processUid || ''] || 0,
  );
  const error = useAppSelector(
    (state) =>
      state.fileManager.entityCreationProgress[processUid || ''] === -1,
  );
  const cancelled = useAppSelector(
    (state) =>
      state.fileManager.entityCreationProgress[processUid || ''] === -2,
  );
  const fileUploading = useAppSelector(
    (state) => state.fileManager.fileUploading[processUid || ''],
  );

  return (
    <div
      className="file-tree-node"
      style={{ marginLeft: `${node.level * 20}px` }}
    >
      <div className="d-flex align-items-center">
        <Icon
          name={node.type === 'folder' ? 'folder' : 'file'}
          className="me-2"
        />
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            <span>{node.name}</span>
            {node.type === 'file' && node.file && (
              <span className="text-muted ms-2">
                ({prettyBytes(node.file.size)})
              </span>
            )}
          </div>
          {node.type === 'file' && processUid && (
            <div className="mt-1" style={{ maxWidth: '200px' }}>
              <Progress
                striped
                className="progress-sm"
                color={cancelled ? 'warning' : error ? 'danger' : 'primary'}
                value={cancelled || error ? 100 : progress}
              >
                <small className="progress-text">
                  {cancelled
                    ? 'Cancelled'
                    : error
                    ? 'Error'
                    : progress > 0
                    ? `${progress}%`
                    : ''}
                </small>
              </Progress>
            </div>
          )}
        </div>
        {node.type === 'file' && node.filepath && (
          <div className="ms-auto d-flex align-items-center">
            {fileUploading && processUid && cancelUpload && (
              <a
                href="#cancel"
                className="btn btn-sm btn-icon btn-soft-warning me-1"
                onClick={(e) => {
                  e.preventDefault();
                  cancelUpload(processUid);
                }}
              >
                <Icon name="x-circle" />
              </a>
            )}
            <a
              href="#remove"
              className="btn btn-sm btn-icon btn-soft-danger"
              onClick={(e) => {
                e.preventDefault();
                if (processUid && cancelUpload && fileUploading) {
                  cancelUpload(processUid);
                }
                onRemove(node.filepath!, index);
              }}
            >
              <Icon name="trash" />
            </a>
          </div>
        )}
      </div>
      {node.children &&
        node.children.map((child, childIndex) => (
          <FileTreeNodes
            key={childIndex}
            node={child}
            onRemove={onRemove}
            index={index + childIndex}
            processUid={processUid}
            cancelUpload={cancelUpload}
          />
        ))}
    </div>
  );
};
