import { useTranslation } from '@oproma/i18n';
import { useAppSelector } from '@oproma/prividox-store';
import { useNavigate } from '@oproma/router';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../../block.component';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';

export const MemberHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { member } = useAppSelector((state) => state.members);

  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
          <BlockTitle tag="h3" page>
            {t('MEMBERS.MEMBER_HEADER.TITLE')}/{' '}
            <strong className="text-primary small">{member?.name}</strong>
          </BlockTitle>
        </BlockHeadContent>
        <BlockHeadContent>
          <Button
            color="light"
            outline
            className="d-none d-sm-inline-flex bg-white"
            onClick={() => navigate(-1)}
          >
            <Icon name="arrow-left"></Icon>
            <span>{t('MEMBERS.MEMBER_HEADER.BACK_BUTTON')}</span>
          </Button>
          <a
            href="#back"
            onClick={(ev) => {
              ev.preventDefault();
              navigate(-1);
            }}
            className="btn btn-icon btn-outline-light d-inline-flex d-sm-none bg-white"
          >
            <Icon name="arrow-left"></Icon>
          </a>
        </BlockHeadContent>
      </BlockBetween>
    </BlockHead>
  );
};
