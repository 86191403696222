/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdatePasswordSpec
 */
export interface UpdatePasswordSpec {
  /**
   * The old/current password of the current user. The min. length will eventually be increased to 12.
   * @type {string}
   * @memberof UpdatePasswordSpec
   */
  old: string;
  /**
   * The new password to associate with the current user.
   * @type {string}
   * @memberof UpdatePasswordSpec
   */
  pass: string;
}

/**
 * Check if a given object implements the UpdatePasswordSpec interface.
 */
export function instanceOfUpdatePasswordSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'old' in value;
  isInstance = isInstance && 'pass' in value;

  return isInstance;
}

export function UpdatePasswordSpecFromJSON(json: any): UpdatePasswordSpec {
  return UpdatePasswordSpecFromJSONTyped(json, false);
}

export function UpdatePasswordSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdatePasswordSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    old: json['old'],
    pass: json['pass'],
  };
}

export function UpdatePasswordSpecToJSON(
  value?: UpdatePasswordSpec | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    old: value.old,
    pass: value.pass,
  };
}
