/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchDocument } from './SearchDocument';
import {
  SearchDocumentFromJSON,
  SearchDocumentFromJSONTyped,
  SearchDocumentToJSON,
} from './SearchDocument';

/**
 *
 * @export
 * @interface SearchResults
 */
export interface SearchResults {
  /**
   *
   * @type {string}
   * @memberof SearchResults
   */
  nextCursorMark?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResults
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof SearchResults
   */
  numFound?: number;
  /**
   *
   * @type {Array<SearchDocument>}
   * @memberof SearchResults
   */
  results?: Array<SearchDocument>;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof SearchResults
   */
  facets?: { [key: string]: { [key: string]: number } };
}

/**
 * Check if a given object implements the SearchResults interface.
 */
export function instanceOfSearchResults(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SearchResultsFromJSON(json: any): SearchResults {
  return SearchResultsFromJSONTyped(json, false);
}

export function SearchResultsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchResults {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    nextCursorMark: !exists(json, 'nextCursorMark')
      ? undefined
      : json['nextCursorMark'],
    message: !exists(json, 'message') ? undefined : json['message'],
    numFound: !exists(json, 'numFound') ? undefined : json['numFound'],
    results: !exists(json, 'results')
      ? undefined
      : (json['results'] as Array<any>).map(SearchDocumentFromJSON),
    facets: !exists(json, 'facets') ? undefined : json['facets'],
  };
}

export function SearchResultsToJSON(value?: SearchResults | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    nextCursorMark: value.nextCursorMark,
    message: value.message,
    numFound: value.numFound,
    results:
      value.results === undefined
        ? undefined
        : (value.results as Array<any>).map(SearchDocumentToJSON),
    facets: value.facets,
  };
}
