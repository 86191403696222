import { yupResolver } from '@hookform/resolvers/yup';
import {
  LanguageEnum,
  activateUser,
  appService,
  register,
  setRegistrationEmailDispatched,
  useAppDispatch,
} from '@oproma/prividox-store';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToggleDisplayingPassswordText } from './use-toggle-displaying-passsword-text.hook';

let ReCAPTCHAKey: string;
if (import.meta.env.VITE_RECAPTCHA_KEY) {
  ReCAPTCHAKey = import.meta.env.VITE_RECAPTCHA_KEY;
} else {
  appService.fetchAppConfigs().then((keys) => {
    if (keys.grecaptchaKey) {
      ReCAPTCHAKey = keys.grecaptchaKey;
    }
  });
}

const registerSchema = yup
  .object({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, 'Please enter proper name.')
      .required(),
    email: yup.string().email().required(),
    password: yup.string().min(12).max(32).required(),
    confirmPassword: yup
      .string()
      .min(12)
      .max(32)
      .required()
      .oneOf([yup.ref('password')], 'Passwords must match'),
    recaptcha: yup.string().required(),
  })
  .required();

type RegisterFormValues = yup.InferType<typeof registerSchema>;

export const useRegisterForm = (registerAsMember?: string) => {
  const dispatch = useAppDispatch();
  const { displayPasswordText, toggleDisplayingPasswordText } =
    useToggleDisplayingPassswordText();
  const {
    displayPasswordText: displayConfirmPasswordText,
    toggleDisplayingPasswordText: toggleDisplayingConfirmPasswordText,
  } = useToggleDisplayingPassswordText();

  const {
    register: registerField,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<RegisterFormValues>({ resolver: yupResolver(registerSchema) });

  const { ref: emailRef, ...registerEmail } = registerField('email');
  const { ref: nameRef, ...registerName } = registerField('name');
  const { ref: passwordRef, ...registerPassword } = registerField('password');
  const { ref: passwordConfirmRef, ...registerConfirmPassword } =
    registerField('confirmPassword');

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const onFormSubmit: SubmitHandler<RegisterFormValues> = ({
    email,
    name,
    password,
    recaptcha,
  }) => {
    if (registerAsMember) {
      dispatch(
        activateUser({
          user: registerAsMember,
          activateAccountSpec: {
            name,
            pass: password,
          },
        }),
      );
    } else {
      dispatch(
        register({
          email,
          name,
          lang: LanguageEnum.EN.toString(),
          pass: password,
          recaptcha,
        }),
      );
    }
  };

  const resetRegistrationEmailDispatched = () => {
    dispatch(setRegistrationEmailDispatched(false));
  };

  return {
    errors,
    password,
    reset,
    nameRef,
    emailRef,
    passwordRef,
    onFormSubmit,
    handleSubmit,
    registerName,
    registerEmail,
    confirmPassword,
    registerPassword,
    passwordConfirmRef,
    displayPasswordText,
    registerConfirmPassword,
    displayConfirmPasswordText,
    toggleDisplayingPasswordText,
    toggleDisplayingConfirmPasswordText,
    resetRegistrationEmailDispatched,
    ReCAPTCHA: (
      <ReCAPTCHA
        theme="dark"
        sitekey={ReCAPTCHAKey}
        onChange={(captcha) => captcha && setValue('recaptcha', captcha)}
      />
    ),
  };
};
