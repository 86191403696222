import clsx from 'clsx';
import ReactSelect, { GroupBase, Props } from 'react-select';

interface SelectProps {
  className?: string;
}

export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  ...props
}: SelectProps & Props<Option, IsMulti, Group>) => {
  return (
    <div className="form-control-select">
      <ReactSelect
        className={clsx(`react-select-container`, className)}
        classNamePrefix="react-select"
        {...props}
      />
    </div>
  );
};
