import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import { SetPermissionRequest } from '@oproma/prividox-orchestration-open-api';
import {
  GroupRow,
  WorkspaceMember,
  editMemberPermission,
  getWorkspacePermissions,
  setDisplayGroupFinderForPermissions,
  setDisplayMemberFinderForPermissions,
  setGroupPaginatedPage,
  toggleDisplayPermissionsModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  Form,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { PaginationComponent } from '../../data-table-pagination.component';
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from '../../data-table.component';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { UserAvatar } from '../../user-avatar.component';
import { findUpper, generateGravatarUrl } from '../../utils';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from '../svg-thumbnails.component';

const editPermissionsSchema = yup.object({
  permissions: yup
    .array()
    .of(
      yup.object({
        type: yup.string().required(),
        user: yup.string().required(),
        entity: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .default([]),
});

type EditPermissionsFormValues = yup.InferType<typeof editPermissionsSchema>;

export const EntityPermissionsModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { lastOpenedEntity, loading } = useAppSelector(
    (state) => state.fileManager,
  );
  const { members } = useAppSelector((state) => state.members);
  const { groups } = useAppSelector((state) => state.groups);
  const { id: userId } = useAppSelector((state) => state.user);
  const {
    displayPermissionsModal,
    permissions,
    displayMemberFinderForPermissions,
    displayGroupFinderForPermissions,
  } = useAppSelector((state) => state.permissions);
  const [localGroups, setLocalGroups] = useState(groups);
  const [localMembers, setLocalMembers] = useState(members);
  const [entityPermissions, setEntityPermissions] = useState(permissions);
  const [groupQuery, setGroupQuery] = useState('');
  const [memberQuery, setMemberQuery] = useState('');
  const [activeTab, setActivetab] = useState('1');

  const currentPaginatedPage = 1;
  const entriesPerPaginatedPage = 10;
  // Get current list, pagination
  const indexOfLastEntry = currentPaginatedPage * entriesPerPaginatedPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPaginatedPage;
  const paginatedGroupEntries = localGroups?.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const indexOfLastMemberEntry = currentPaginatedPage * entriesPerPaginatedPage;
  const indexOfFirstMemberEntry = indexOfLastEntry - entriesPerPaginatedPage;
  const filteredMembers = localMembers.filter(
    (member) =>
      member.id !== userId && member.accessLevel?.toString() !== '126',
  );
  const paginatedMemberEntries = filteredMembers.slice(
    indexOfFirstMemberEntry,
    indexOfLastMemberEntry,
  );

  type PermissionOption = {
    value: string;
    label: string;
  };

  const permissionLabels: PermissionOption[] = [
    {
      value: 'NO_ACCESS',
      label: t('FILE_MANAGER.PERMISSIONS.LABELS.NO_CONTROL'),
    },
    {
      value: 'INHERITED',
      label: t('FILE_MANAGER.PERMISSIONS.LABELS.INHERITED'),
    },
    { value: 'VIEW', label: t('FILE_MANAGER.PERMISSIONS.LABELS.VIEW') },
    { value: 'EDIT', label: t('FILE_MANAGER.PERMISSIONS.LABELS.EDIT') },
    {
      value: 'FULL_CONTROL',
      label: t('FILE_MANAGER.PERMISSIONS.LABELS.FULL_CONTROL'),
    },
  ];

  // Change Page
  const paginate = (page: number) => dispatch(setGroupPaginatedPage(page));

  const toggleMemberFinder = () => {
    dispatch(
      setDisplayMemberFinderForPermissions(!displayMemberFinderForPermissions),
    );
  };

  const toggleGroupFinder = () => {
    dispatch(
      setDisplayGroupFinderForPermissions(!displayGroupFinderForPermissions),
    );
  };

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<EditPermissionsFormValues>({
    resolver: yupResolver(editPermissionsSchema),
    defaultValues: {
      permissions: entityPermissions.map((perm) => ({
        type: perm.permissionType as string,
        user: perm.entityId as string,
        entity: lastOpenedEntity?.id as string,
        value: perm.permission as string,
      })),
    },
  });

  const watchedPermissions = watch('permissions');

  useEffect(() => {
    if (groupQuery !== '') {
      const queriedGroups = [...groups].filter((group) => {
        return group.name?.toLowerCase().includes(groupQuery.toLowerCase());
      });
      setLocalGroups(queriedGroups);
    } else {
      setLocalGroups(groups);
    }
  }, [groupQuery, groups]);

  useEffect(() => {
    if (memberQuery !== '') {
      const queriedMembers = [...members].filter((member) => {
        return member.name?.toLowerCase().includes(memberQuery.toLowerCase());
      });
      setLocalMembers(queriedMembers);
    } else {
      setLocalMembers(members);
    }
  }, [memberQuery, members]);

  useEffect(() => {
    setLocalGroups(groups);
  }, [groups]);

  useEffect(() => {
    setLocalMembers(members);
  }, [members]);

  useEffect(() => {
    setEntityPermissions(permissions);
  }, [permissions]);

  useEffect(() => {
    if (!lastOpenedEntity || !workspaceId) {
      return;
    }
    const entityId =
      lastOpenedEntity.id ??
      lastOpenedEntity.folderId ??
      lastOpenedEntity.fileId;
    if (!entityId) {
      return;
    }

    dispatch(
      getWorkspacePermissions({
        workspaceId: workspaceId as string,
        entityId,
      }),
    );
  }, [lastOpenedEntity, workspaceId]);

  const toggle = useCallback(() => {
    dispatch(toggleDisplayPermissionsModal());
  }, [dispatch]);

  const handleMemberPermissionChange = (
    type: string,
    member: WorkspaceMember,
    optionValue: string,
  ) => {
    const existingIndex = watchedPermissions?.findIndex(
      (perm) => perm.type === type && perm.user === member.id,
    );

    if (existingIndex !== -1) {
      const updatedPermissions = [...watchedPermissions];
      updatedPermissions[existingIndex].value = optionValue;
      setValue('permissions', updatedPermissions);
    } else {
      setValue('permissions', [
        ...watchedPermissions,
        {
          type,
          user: member.id as string,
          entity: lastOpenedEntity?.id as string,
          value: optionValue,
        },
      ]);
    }
  };

  const onFormSubmit: SubmitHandler<EditPermissionsFormValues> = async (
    payload,
  ) => {
    payload.permissions.map((permission) =>
      dispatch(
        editMemberPermission({
          ...permission,
          body: permission.value,
        } as SetPermissionRequest),
      ),
    );
    toast.success(t('FILE_MANAGER.PERMISSIONS.SUCCESS'));
    toggle();
  };

  const handleGroupPermissionChange = (
    type: string,
    group: GroupRow,
    optionValue: string,
  ) => {
    const existingIndex = watchedPermissions?.findIndex(
      (perm) => perm.type === type && perm.user === group.id,
    );

    if (existingIndex !== -1) {
      const updatedPermissions = [...watchedPermissions];
      updatedPermissions[existingIndex].value = optionValue;
      setValue('permissions', updatedPermissions);
    } else {
      setValue('permissions', [
        ...watchedPermissions,
        {
          type,
          user: group.id as string,
          entity: lastOpenedEntity?.id as string,
          value: optionValue,
        },
      ]);
    }
  };

  useEffect(() => {
    setValue(
      'permissions',
      entityPermissions.map((perm) => ({
        type: perm.permissionType as string,
        user: perm.entityId as string,
        entity: lastOpenedEntity?.id as string,
        value: perm.permission as string,
      })),
      { shouldValidate: true },
    );
  }, [permissions]);

  return (
    <Modal isOpen={displayPermissionsModal} size="xl" toggle={toggle}>
      <div className="modal-header align-center">
        <h5 className="modal-title">{t('FILE_MANAGER.PERMISSIONS.TITLE')}</h5>
      </div>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className="d-flex align-items-center mb-3">
            <div className="nk-file-title">
              <div className="nk-file-icon">
                <div className="nk-file-icon-type">
                  {getEntityThumbnail(
                    (lastOpenedEntity?.contentType ??
                      lastOpenedEntity?.type ??
                      'others') as EntityThumbnail,
                  )}
                </div>
              </div>
              <div className="nk-file-name">
                <div className="nk-file-name-text">
                  <span className="title">{lastOpenedEntity?.name}</span>
                </div>
                <div className="nk-file-name-sub">
                  {lastOpenedEntity?.type?.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
          <Card className="card-bordered">
            <div className="card-content">
              <Nav tabs className="nav-tabs-mb-icon nav-tabs-card">
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={clsx({ active: activeTab === '1' })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab('1');
                    }}
                  >
                    <Icon name="user-list" />{' '}
                    <span>{t('FILE_MANAGER.PERMISSIONS.TABLE.GROUPS')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={clsx({ active: activeTab === '2' })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab('2');
                    }}
                  >
                    <Icon name="users" />{' '}
                    <span>{t('FILE_MANAGER.PERMISSIONS.TABLE.MEMBERS')}</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="card-inner">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <DataTable className="mb-1">
                      <div className="position-relative card-tools-toggle">
                        <a
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleGroupFinder();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>

                        {displayGroupFinderForPermissions && (
                          <div
                            className={clsx('card-search search-wrap', {
                              active: displayGroupFinderForPermissions,
                            })}
                          >
                            <div className="card-body">
                              <div className="search-content">
                                <input
                                  type="text"
                                  className="form-focus-none form-control border-transparent"
                                  placeholder={
                                    t(
                                      'FILE_MANAGER.PERMISSIONS.FIND_GROUP',
                                    ) as string
                                  }
                                  value={groupQuery}
                                  onChange={(ev) =>
                                    setGroupQuery(ev.target.value)
                                  }
                                />
                                <Button
                                  className="search-back btn-icon toggle-search active"
                                  onClick={() => {
                                    setGroupQuery('');
                                    toggleGroupFinder();
                                  }}
                                >
                                  <Icon name="arrow-left"></Icon>
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <DataTableBody>
                        <DataTableHead>
                          <DataTableRow>
                            <span className="sub-text">
                              {t('FILE_MANAGER.PERMISSIONS.TABLE.GROUPS')}
                            </span>
                          </DataTableRow>
                          <DataTableRow size="mb">
                            <span className="sub-text">
                              {t('FILE_MANAGER.PERMISSIONS.TABLE.PERMISSION')}
                            </span>
                          </DataTableRow>
                        </DataTableHead>
                        {paginatedGroupEntries.map(
                          (groupEntry: GroupRow, index: number) => {
                            return (
                              <DataTableItem key={groupEntry.id ?? index}>
                                <DataTableRow>
                                  <div className="user-card">
                                    <UserAvatar
                                      theme={groupEntry.thumbnailBackground}
                                      text={findUpper(groupEntry.name)}
                                      image={groupEntry.thumbnail}
                                    ></UserAvatar>
                                    <div className="user-info">
                                      <span className="tb-lead">
                                        {groupEntry.name}
                                        <span className="text-muted font-italic">
                                          {' '}
                                          - ({groupEntry.memberCount}{' '}
                                          {t(
                                            'FILE_MANAGER.PERMISSIONS.TABLE.MEMBERS',
                                          )}
                                          )
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </DataTableRow>
                                <DataTableRow size="md">
                                  <Select
                                    options={permissionLabels.map((option) => ({
                                      value: option.value,
                                      label: option.label,
                                    }))}
                                    id={`group-${groupEntry.id}`}
                                    defaultValue={(() => {
                                      const permission = entityPermissions.find(
                                        (perm) =>
                                          perm.permissionType === 'group' &&
                                          perm.entityId === groupEntry.id,
                                      );
                                      return {
                                        label: permissionLabels.find(
                                          (option) =>
                                            option.value ===
                                            permission?.permission?.toString(),
                                        )?.label,
                                        value:
                                          permission?.permission?.toString(),
                                      };
                                    })()}
                                    name={`group-${groupEntry.id}`}
                                    onChange={(selectedOption) =>
                                      handleGroupPermissionChange(
                                        'group',
                                        groupEntry,
                                        selectedOption?.value ?? '',
                                      )
                                    }
                                  />
                                  {errors.permissions && (
                                    <span className="text-danger">
                                      {errors.permissions.message}
                                    </span>
                                  )}
                                </DataTableRow>
                              </DataTableItem>
                            );
                          },
                        )}
                      </DataTableBody>
                    </DataTable>
                    <div className="card-inner">
                      {paginatedGroupEntries.length || groups.length > 0 ? (
                        <PaginationComponent
                          itemPerPage={entriesPerPaginatedPage}
                          totalItems={paginatedGroupEntries.length}
                          paginate={paginate}
                          currentPage={currentPaginatedPage}
                        />
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">
                            {' '}
                            {t('GROUPS.GROUP_TABLE.NO_GROUP')}
                          </span>
                        </div>
                      )}
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div>
                      <DataTable>
                        <div className="position-relative card-tools-toggle">
                          <a
                            href="#search"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleMemberFinder();
                            }}
                            className="btn btn-icon search-toggle toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </a>
                          {displayMemberFinderForPermissions && (
                            <div
                              className={clsx('card-search search-wrap', {
                                active: displayMemberFinderForPermissions,
                              })}
                            >
                              <div className="card-body">
                                <div className="search-content">
                                  <input
                                    type="text"
                                    className="form-focus-none form-control border-transparent"
                                    placeholder={
                                      t(
                                        'MEMBERS.TABLE_HEADER.SEARCH_PLACEHOLDER',
                                      ) as string
                                    }
                                    value={memberQuery}
                                    onChange={(ev) =>
                                      setMemberQuery(ev.target.value)
                                    }
                                  />
                                  <Button
                                    className="search-back btn-icon toggle-search active"
                                    onClick={() => {
                                      setMemberQuery('');
                                      toggleMemberFinder();
                                    }}
                                  >
                                    <Icon name="arrow-left"></Icon>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <DataTableBody>
                          <DataTableHead>
                            <DataTableRow>
                              <span className="sub-text">
                                {t('FILE_MANAGER.PERMISSIONS.TABLE.MEMBERS')}
                              </span>
                            </DataTableRow>
                            <DataTableRow size="mb">
                              <span className="sub-text">
                                {t('FILE_MANAGER.PERMISSIONS.TABLE.PERMISSION')}
                              </span>
                            </DataTableRow>
                          </DataTableHead>
                          {paginatedMemberEntries.map(
                            (memberEntry: WorkspaceMember, index: number) => {
                              return (
                                <DataTableItem key={memberEntry.id ?? index}>
                                  <DataTableRow>
                                    <div className="user-card">
                                      {memberEntry.thumbnail ? (
                                        <img
                                          src={generateGravatarUrl(
                                            memberEntry.email!,
                                          )}
                                          alt={memberEntry.name}
                                          className="user-avatar rounded-circle mr-2"
                                        ></img>
                                      ) : (
                                        <UserAvatar
                                          theme={
                                            memberEntry.thumbnailBackground
                                          }
                                          text={findUpper(memberEntry.name)}
                                          image={memberEntry.thumbnail}
                                        ></UserAvatar>
                                      )}
                                      <div className="user-info">
                                        <span className="tb-lead">
                                          {memberEntry.name ? (
                                            memberEntry.name
                                          ) : (
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: `${
                                                  memberEntry.email
                                                } <em>${t(
                                                  'FILE_MANAGER.PERMISSIONS.TABLE.UNREGISTERED',
                                                )}</em>`,
                                              }}
                                            />
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </DataTableRow>
                                  <DataTableRow size="md">
                                    <Select
                                      options={permissionLabels.map(
                                        (option) => ({
                                          value: option.value,
                                          label: option.label,
                                        }),
                                      )}
                                      defaultValue={(() => {
                                        const permission =
                                          entityPermissions.find(
                                            (perm) =>
                                              perm.permissionType ===
                                                'member' &&
                                              perm.entityId === memberEntry.id,
                                          );
                                        return {
                                          label: permissionLabels.find(
                                            (option) =>
                                              option.value ===
                                              permission?.permission?.toString(),
                                          )?.label,
                                          value:
                                            permission?.permission?.toString(),
                                        };
                                      })()}
                                      id={`member-${memberEntry.id}`}
                                      name={`member-${memberEntry.id}`}
                                      onChange={(selectedOption) =>
                                        handleMemberPermissionChange(
                                          'member',
                                          memberEntry,
                                          selectedOption?.value ?? '',
                                        )
                                      }
                                    />
                                    {errors.permissions && (
                                      <span className="text-danger">
                                        {errors.permissions.message}
                                      </span>
                                    )}
                                  </DataTableRow>
                                </DataTableItem>
                              );
                            },
                          )}
                        </DataTableBody>
                      </DataTable>
                      <div className="card-inner">
                        {paginatedMemberEntries.length || members.length > 0 ? (
                          <PaginationComponent
                            itemPerPage={entriesPerPaginatedPage}
                            totalItems={paginatedMemberEntries.length}
                            paginate={paginate}
                            currentPage={currentPaginatedPage}
                          />
                        ) : (
                          <div className="text-center">
                            <span className="text-silent">
                              {t('MEMBERS.NO_MEMBERS')}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </Card>
        </ModalBody>
        <div className="modal-footer modal-footer-stretch bg-light">
          <div className="modal-footer-between">
            <div className="g">
              <a
                href="$"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate({ pathname: `/${workspaceId}/members` });
                }}
                className="link link-primary"
              >
                {t('FILE_MANAGER.PERMISSIONS.MANAGE_TEAM')}
              </a>
            </div>

            <div className="g">
              <ul className="btn-toolbar g-3">
                <li>
                  <Button color="primary" type="submit">
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      t('FILE_MANAGER.RENAME_ENTITY.SAVE')
                    )}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
