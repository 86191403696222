/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CodeSpec
 */
export interface CodeSpec {
  /**
   * The current TOTP code.
   * @type {number}
   * @memberof CodeSpec
   */
  code: number;
}

/**
 * Check if a given object implements the CodeSpec interface.
 */
export function instanceOfCodeSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'code' in value;

  return isInstance;
}

export function CodeSpecFromJSON(json: any): CodeSpec {
  return CodeSpecFromJSONTyped(json, false);
}

export function CodeSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CodeSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
  };
}

export function CodeSpecToJSON(value?: CodeSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
  };
}
