/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Block,
  Content,
  EditMemberModal,
  Head,
  Layout,
  MembersHeader,
  MembersTable,
  OnboardMemberModal,
  RemoveMemberModal,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getWorkspaceMembers,
  setDisplayMemberFinder,
  useAppDispatch,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';

const MembersPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceMembers(workspaceId));
    return () => {
      dispatch(setDisplayMemberFinder(false));
    };
  }, [dispatch, workspaceId]);

  return (
    <>
      <Head heading={t('MEMBERS.TITLE')} />
      <Layout>
        <Content>
          <MembersHeader />
          <Block>
            <MembersTable />
          </Block>
          <OnboardMemberModal />
          <EditMemberModal />
          <RemoveMemberModal />
        </Content>
      </Layout>
    </>
  );
};

export default MembersPage;
