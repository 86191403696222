export const footerLinks = [
  {
    title: 'FOOTER.DOCUMENTATION',
    href: 'https://kb.prividox.com/',
  },
  {
    title: 'FOOTER.PRIVACY_POLICY',
    href: 'https://oproma.com/privacy',
  },
  {
    title: 'FOOTER.WEBSITE',
    href: 'https://oproma.com/prividox/',
  },
];
