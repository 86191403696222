import { useTranslation } from '@oproma/i18n';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Icon } from '../icon.component';

type WorkspaceFilterDropdownProps = {
  filter: string;
  onFilter: (filter: string) => void;
};

export const WorkspaceFilterDropdown = ({
  filter,
  onFilter,
}: WorkspaceFilterDropdownProps) => {
  const { t } = useTranslation();

  const filterOptions: string[] = [
    t('COMMON.WORKSPACES.FILTER.FILTER_OPTION_EVERY'),
    t('COMMON.WORKSPACES.FILTER.FILTER_OPTION_OWNER'),
    t('COMMON.WORKSPACES.FILTER.FILTER_OPTION_MEMBER'),
  ];
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
      >
        <Icon name="filter-alt" className="d-none d-sm-inline"></Icon>
        <span>
          {filter
            ? `Filtered on ${filter}`
            : t('COMMON.WORKSPACES.FILTER.FILTER_HEADING')}
        </span>
        <Icon name="chevron-right" className="dd-indc"></Icon>
      </DropdownToggle>
      <DropdownMenu end>
        <ul className="link-list-opt no-bdr">
          {filterOptions.map((option, i) => (
            <li key={i}>
              <DropdownItem
                tag="a"
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFilter(option);
                }}
              >
                <span>{option}</span>
              </DropdownItem>
            </li>
          ))}
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
