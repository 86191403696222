import {
  getMFABackupCodes,
  setValidMFACode,
  toggleDisplayMFAModel,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { useWizard } from '../hooks';
import { Icon } from '../icon.component';
import { Processes } from '../wizard.component';
import { MFACompletedBanner } from './mfa-completed-banner.component';
import { MFAConfigureAuthenticator } from './mfa-configure-authenticator.component';
import { MFAOnboardAuthenticator } from './mfa-onboard-authenticator.component';
import { MFARecoveryCodes } from './mfa-recovery-codes.component';

export const MFAModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentProcess } = useWizard();
  const { displayMFAModal } = useAppSelector((state) => state.workspace);

  const toggle = () => {
    dispatch(toggleDisplayMFAModel());
  };

  useEffect(() => {
    dispatch(setValidMFACode(null));
    dispatch(getMFABackupCodes());
  }, []);

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={displayMFAModal}
      toggle={toggle}
    >
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <ModalBody className="modal-body-md">
        <h5 className="title mb-3">{t('AUTH.MFA.SETUP_TITLE')}</h5>
        <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
          <div className="steps clearfix">
            <ul className="steps-list">
              <li className={currentProcess >= 1 ? 'first done' : 'first'}>
                <a href="#wizard-01-h" onClick={(ev) => ev.preventDefault()}>
                  <span className="number">01</span>{' '}
                  <h5>Choose Authenticator</h5>
                </a>
              </li>
              <li className={clsx({ done: currentProcess >= 2 })}>
                <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
                  <span className="number">02</span>{' '}
                  <h5>Configure Authenticator</h5>
                </a>
              </li>
              <li className={clsx(currentProcess >= 3 ? 'last done' : 'last')}>
                <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                  <span className="number">03</span> <h5>Recovery Codes</h5>
                </a>
              </li>
              <li className={currentProcess === 4 ? 'last done' : 'last'}>
                <a href="#wizard-01-h-3" onClick={(ev) => ev.preventDefault()}>
                  <span className="current-info audible">current step: </span>
                  <span className="number">04</span> <h5>Complete</h5>
                </a>
              </li>
            </ul>
            <Processes>
              <MFAOnboardAuthenticator />
              <MFAConfigureAuthenticator />
              <MFARecoveryCodes />
              <MFACompletedBanner />
            </Processes>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
