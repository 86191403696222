/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationMessage } from './NotificationMessage';
import {
  NotificationMessageFromJSON,
  NotificationMessageFromJSONTyped,
  NotificationMessageToJSON,
} from './NotificationMessage';

/**
 *
 * @export
 * @interface PublishedItemSpec
 */
export interface PublishedItemSpec {
  /**
   *
   * @type {string}
   * @memberof PublishedItemSpec
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof PublishedItemSpec
   */
  creator?: string;
  /**
   *
   * @type {string}
   * @memberof PublishedItemSpec
   */
  creatorName?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof PublishedItemSpec
   */
  meta?: { [key: string]: object };
  /**
   *
   * @type {Array<NotificationMessage>}
   * @memberof PublishedItemSpec
   */
  log?: Array<NotificationMessage>;
}

/**
 * Check if a given object implements the PublishedItemSpec interface.
 */
export function instanceOfPublishedItemSpec(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PublishedItemSpecFromJSON(json: any): PublishedItemSpec {
  return PublishedItemSpecFromJSONTyped(json, false);
}

export function PublishedItemSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PublishedItemSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    path: !exists(json, 'path') ? undefined : json['path'],
    creator: !exists(json, 'creator') ? undefined : json['creator'],
    creatorName: !exists(json, 'creatorName') ? undefined : json['creatorName'],
    meta: !exists(json, 'meta') ? undefined : json['meta'],
    log: !exists(json, 'log')
      ? undefined
      : (json['log'] as Array<any>).map(NotificationMessageFromJSON),
  };
}

export function PublishedItemSpecToJSON(value?: PublishedItemSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    path: value.path,
    creator: value.creator,
    creatorName: value.creatorName,
    meta: value.meta,
    log:
      value.log === undefined
        ? undefined
        : (value.log as Array<any>).map(NotificationMessageToJSON),
  };
}
