import { useAppSelector } from '@oproma/prividox-store';
import { Input, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { ColorOptions } from '../../color-options.component';
import { Col } from '../../grid.component';
import { useEditTaskColumnForm } from '../../hooks';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';

export const TasksEditColumnModal = () => {
  const { lastOpenedColumn } = useAppSelector((state) => state.tasks);
  const {
    name,
    colour,
    themes,
    toggle,
    errors,
    nameRef,
    setValue,
    handleSubmit,
    onFormSubmit,
    registerColour,
    registerName,
    displayEditColumnModal,
  } = useEditTaskColumnForm(lastOpenedColumn);

  //   TODO: Translate
  return (
    <Modal size="lg" isOpen={displayEditColumnModal} toggle={toggle}>
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Edit Column</h5>
          <div className="mt-4">
            <form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
              <Col className="col-12">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <Input
                    type="text"
                    innerRef={nameRef}
                    {...registerName}
                    defaultValue={name}
                  />
                  {errors.name && (
                    <span className="invalid">{errors.name.message}</span>
                  )}
                </div>
              </Col>
              <Col className="col-12">
                <div className="form-group">
                  <label className="form-label">Color</label>
                  <div className="form-control-select">
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      formatOptionLabel={ColorOptions}
                      options={themes}
                      {...registerColour}
                      defaultValue={
                        themes.filter((theme) => theme.value === colour)[0]
                      }
                      onChange={(ev) => {
                        setValue('colour', ev?.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col className="col-12">
                <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      Edit Board
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
