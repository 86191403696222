export enum LanguageEnum {
  EN = 'en',
  FR = 'fr',
}

export enum LayoutThemeTypeEnum {
  DEFAULT = 'default',
  CLEAN = 'clean',
  SHADY = 'shady',
  SOFTY = 'softy',
}

export enum GeneralThemeTypeEnum {
  WHITE = 'white',
  DARK = 'dark',
  LIGHT = 'light',
  THEME = 'theme',
}

export enum ThemeModeEnum {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ToastMetadata {
  display: boolean;
  header: string | null;
  body: string | null;
  closeString: string | null;
}

export interface Wizard {
  currentProcess: number;
  overallProcesses: number;
}

export interface ILayoutState {
  readonly language: LanguageEnum;
  readonly displayUserDropdownMenu: boolean;
  readonly displayFooterLanguageDropdownMenu: boolean;
  readonly layoutThemeType: LayoutThemeTypeEnum;
  readonly sidebarThemeType: GeneralThemeTypeEnum;
  readonly headerThemeType: GeneralThemeTypeEnum;
  readonly themeMode: ThemeModeEnum;
  readonly displayMobileMenu: boolean;
  readonly displaySidebar: boolean;
  readonly mobileMode: boolean;
  readonly wizard: Wizard;
}
