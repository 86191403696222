/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CompletedSpec
 */
export interface CompletedSpec {
  /**
   * Whether the task is completed or not.
   * @type {boolean}
   * @memberof CompletedSpec
   */
  completed: boolean;
}

/**
 * Check if a given object implements the CompletedSpec interface.
 */
export function instanceOfCompletedSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'completed' in value;

  return isInstance;
}

export function CompletedSpecFromJSON(json: any): CompletedSpec {
  return CompletedSpecFromJSONTyped(json, false);
}

export function CompletedSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CompletedSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completed: json['completed'],
  };
}

export function CompletedSpecToJSON(value?: CompletedSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completed: value.completed,
  };
}
