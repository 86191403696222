import { useTranslation } from '@oproma/i18n';
import {
  getEntityParent,
  getWorkspaceMembers,
  useAppDispatch,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Alert, Input, InputGroup, InputGroupText, Modal } from 'reactstrap';
import { Button } from '../../button.component';
import { ClipboardCopy } from '../../clipboard-copy.component';
import { useFileManagerDistributionEntityForm } from '../../hooks';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { prettyBytes } from '../../utils';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from '../svg-thumbnails.component';

export const FileManagerDistributeEntityModal = () => {
  const { t } = useTranslation();
  const { entityId } = useParams();
  const dispatch = useAppDispatch();
  const {
    entity,
    toggle,
    errors,
    workspaceId,
    membersOptions,
    distributionLink,
    lastOpenedEntity,
    handleSelectChange,
    handleDispatchClick,
    displayEmailButtons,
    handleNativeEmailing,
    handleEmailWithGmail,
    handleEmailShareYahoo,
    handleEmailWithOutlook,
    setDisplayEmailButtons,
    displayBulkDistributeModal,
    displayDistributeEntityModal,
  } = useFileManagerDistributionEntityForm();
  const noMembers = membersOptions.length < 1;

  useEffect(() => {
    if (!workspaceId || !entityId) return;
    dispatch(getWorkspaceMembers(workspaceId));
    dispatch(getEntityParent(entityId));
  }, [workspaceId, entityId]);

  return (
    <Modal
      isOpen={displayDistributeEntityModal || displayBulkDistributeModal}
      size="xl"
      toggle={toggle}
    >
      <div className="modal-header align-center">
        {displayDistributeEntityModal && (
          <div className="nk-file-title">
            <div className="nk-file-icon">
              <div className="nk-file-icon-type">
                {getEntityThumbnail(
                  (lastOpenedEntity?.contentType ??
                    lastOpenedEntity?.type ??
                    'others') as EntityThumbnail,
                )}
              </div>
            </div>
            <div className="nk-file-name">
              <div className="nk-file-name-text">
                <span className="title">{entity?.name}</span>
              </div>
              <div className="nk-file-name-sub">
                {prettyBytes(entity?.size ?? 0)}
              </div>
            </div>
          </div>
        )}
        {displayBulkDistributeModal && (
          <div className="nk-file-title">
            <h5 className="modal-title">
              {t('FILE_MANAGER.DISTRIBUTE.SHARE')}
            </h5>
          </div>
        )}
      </div>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="modal-body p-0">
        {noMembers && (
          <Alert className="alert-icon" color="warning">
            <Icon name="alert-circle" />
            <strong>{t('FILE_MANAGER.DISTRIBUTE.NO_MEMBERS')}</strong>
          </Alert>
        )}
        <div className="nk-file-share-header">
          <div className="nk-file-share-group d-flex">
            <Input
              type="text"
              className="form-control-lg"
              value={distributionLink}
            />
            <div className="p-1">
              <ClipboardCopy
                text={distributionLink}
                onCopy={() => {
                  if (distributionLink) {
                    toast.success(t('FILE_MANAGER.DISTRIBUTE.TOAST'));
                  } else {
                    toast.error(t('FILE_MANAGER.DISTRIBUTE.TOAST_ERROR'));
                  }
                }}
              >
                <a
                  href="link"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="btn btn-outline-light btn-white"
                >
                  <Icon name="copy"></Icon>&nbsp;
                  {t('FILE_MANAGER.DISTRIBUTE.COPY_LINK')}
                </a>
              </ClipboardCopy>
            </div>
            <div className="p-1">
              <Button
                onClick={handleNativeEmailing}
                className="btn btn-outline-light btn-white"
                disabled={
                  noMembers ||
                  !(
                    navigator.canShare &&
                    navigator.canShare({ url: distributionLink })
                  )
                }
              >
                <Icon name="share-fill"> </Icon>&nbsp;
                {t('FILE_MANAGER.DISTRIBUTE.EMAIL_NATIVELY')}
              </Button>
            </div>
            <div className="p-1">
              <Button
                onClick={() => setDisplayEmailButtons(!displayEmailButtons)}
                className="btn btn-outline-light btn-white"
                disabled={noMembers}
              >
                <Icon name="mail"></Icon>
                &nbsp;{t('FILE_MANAGER.DISTRIBUTE.EMAIL')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {displayEmailButtons && (
        <div className="nk-file-share-header">
          <div className="nk-file-share-group">
            <div className="nk-file-share-input-group">
              <div className="nk-file-share-input nk-file-share-input-to">
                <InputGroup>
                  <InputGroupText>
                    {t('FILE_MANAGER.DISTRIBUTE.RECIPIENT')}
                  </InputGroupText>
                  <Select
                    closeMenuOnSelect={false}
                    className="input-mail w-400px"
                    isMulti
                    placeholder={t(
                      'FILE_MANAGER.DISTRIBUTE.RECIPIENT_PLACEHOLDER',
                    )}
                    options={membersOptions}
                    onChange={handleSelectChange}
                    formatOptionLabel={({ label }) => (
                      <div dangerouslySetInnerHTML={{ __html: label }} />
                    )}
                  />
                  {errors.members && (
                    <div className="invalid-feedback">
                      {errors.members.message}
                    </div>
                  )}
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="modal-footer bg-light d-flex justify-content-start">
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="link me-auto"
        >
          {t('FILE_MANAGER.DISTRIBUTE.CANCEL')}
        </a>
        {displayEmailButtons && (
          <ul className="btn-toolbar g-3">
            <li>
              <Button
                onClick={handleEmailWithOutlook}
                className="btn btn-outline-light btn-white"
                disabled={noMembers}
              >
                <Icon name="microsoft"> </Icon> &nbsp;
                {t('FILE_MANAGER.DISTRIBUTE.EMAIL_WITH_OUTLOOK')}&trade;
              </Button>
            </li>
            <li>
              <Button
                onClick={handleEmailWithGmail}
                className="btn btn-outline-light btn-white"
                disabled={noMembers}
              >
                <Icon name="google"> </Icon> &nbsp;
                {t('FILE_MANAGER.DISTRIBUTE.EMAIL_WITH_GMAIL')}&trade;
              </Button>
            </li>
            <li>
              <Button
                onClick={handleEmailShareYahoo}
                className="btn btn-outline-light btn-white"
                disabled={noMembers}
              >
                <Icon name="yahoo"> </Icon> &nbsp;
                {t('FILE_MANAGER.DISTRIBUTE.EMAIL_WITH_YAHOO')}&trade;
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                name="mail"
                onClick={() => handleDispatchClick()}
                disabled={noMembers}
              >
                {t('FILE_MANAGER.DISTRIBUTE.DISPATCH_BUTTON')}
              </Button>
            </li>
          </ul>
        )}
      </div>
    </Modal>
  );
};
