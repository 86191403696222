import { useTranslation } from '@oproma/i18n';
import {
  EntityActionsEnum,
  FileWithPath,
  copyEntity,
  setDisplayCreateFileModal,
  setDisplayCreateFolderModal,
  setEntityQuery,
  setQueuedFiles,
  toggleDisplayMobileFinder,
  toggleDisplayMobileNavbar,
  toggleDisplayMobileRecoveryFilter,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useLocation, useParams } from '@oproma/router';
import clsx from 'clsx';
import { ReactNode, useEffect, useLayoutEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../block.component';
import { Icon } from '../icon.component';
import { fetchFilesRecursively } from './dropzone.util';
import {
  FileManagerDisplayTypeFilter,
  displayTypeOptions,
} from './file-manager-display-type-filter.component';
import { FileManagerFileActions } from './file-manager-file-actions.component';
import { FileManagerFiles } from './file-manager-files.component';
import {
  BulkDeleteEntityModal,
  ConvertFileModal,
  EntityPermissionsModal,
  FileManagerCreateFolderModal,
  FileManagerDistributeEntityModal,
  FileManagerMetadataModal,
  FileManagerMoveFileEntityModal,
  FileManagerPreviewModal,
  FileManagerRenameEntityModal,
  RemoveEntityModal,
} from './modals';

type FileManagerNavigatorProps = {
  entityFinder?: boolean;
  title?: ReactNode;
  displayTypeFilter?: boolean;
  children?: ReactNode;
};

export const FileManagerNavigationLayout = ({
  title,
  entityFinder,
  displayTypeFilter,
  children,
}: FileManagerNavigatorProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { entityId } = useParams();
  const {
    entities,
    entityQuery,
    bulkAction,
    queuedFiles,
    displayType,
    displayMobileFinder,
    displayCreateFileModal,
    displayMobileRecoveryFilter,
  } = useAppSelector((state) => state.fileManager);
  const isTrashPage = pathname.includes('/trash');

  const openCreatFolderModal = () =>
    dispatch(setDisplayCreateFolderModal(true));
  const openCreateFileModal = () => dispatch(setDisplayCreateFileModal(true));
  const toggleMobileFinder = () => dispatch(toggleDisplayMobileFinder());
  const toggleMobileRecoveryFilter = () =>
    dispatch(toggleDisplayMobileRecoveryFilter());
  const handleOnQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEntityQuery(event.target.value));
  };

  const toggle = () =>
    !displayCreateFileModal && dispatch(setDisplayCreateFileModal(true));

  const uniqueFiles = (droppedFiles: FileWithPath[]) => {
    return droppedFiles.filter(
      (droppedFile) =>
        !queuedFiles.some((file) => file.name === droppedFile.name),
    );
  };

  useLayoutEffect(() => {
    dispatch(setEntityQuery(''));
  }, []);

  useEffect(() => {
    if (bulkAction) return;
    const handlePaste = (ev: ClipboardEvent) => {
      const pastedContent = ev.clipboardData?.getData('text');
      if (entityId) {
        const urlSearchParams = new URLSearchParams(pastedContent);
        const action = urlSearchParams.get('action');
        const entity = urlSearchParams.get('entity');
        if (action === EntityActionsEnum.COPY && entity) {
          dispatch(
            copyEntity({
              entity,
              destination: entityId,
            }),
          );
          toast.success(t('FILE_MANAGER.COPY.PASTED'));
        }
      } else {
        toast.error(t('FILE_MANAGER.UTILITY_ACTIONS.TOAST.INVALID_ACTION'));
      }
    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [entityId, dispatch]);

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
    };

    const handleDrop = async (evt: DragEvent) => {
      evt.preventDefault();
      if (!evt.dataTransfer?.items) return;
      const files: FileWithPath[] = await fetchFilesRecursively(
        evt.dataTransfer.items,
      );
      toggle();
      const filePayload = uniqueFiles(files);
      dispatch(setQueuedFiles([...queuedFiles, ...filePayload]));
    };

    document.body.addEventListener('dragover', handleDragOver);
    document.body.addEventListener('drop', handleDrop);

    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, [queuedFiles]);

  // TODO: Refactor this as this is a naive way to query for entities
  const queriedEntities = [
    ...entities.filter((item) =>
      item.name?.toLowerCase().includes(entityQuery.toLowerCase()),
    ),
  ];

  return (
    <>
      {entityFinder && (
        <div className="nk-fmg-body-head d-none d-lg-flex">
          <div className="nk-fmg-search">
            <Icon name="search"></Icon>
            <input
              type="text"
              className="form-control border-transparent form-focus-none"
              placeholder={t('FILE_MANAGER.NAVIGATION.SEARCH_PLACEHOLDER')}
              value={entityQuery}
              onChange={handleOnQuery}
            />
          </div>
          {!isTrashPage && (
            <div className="nk-fmg-actions">
              <ul className="nk-block-tools g-3">
                <li>
                  <Button
                    onClick={(ev) => {
                      ev.preventDefault();
                      openCreatFolderModal();
                    }}
                    className="btn btn-light"
                  >
                    <Icon name="folder-plus"></Icon>{' '}
                    <span>{t('FILE_MANAGER.NAVIGATION.CREATE_FOLDER')}</span>
                  </Button>
                </li>
                <li>
                  <Button color="primary" onClick={() => openCreateFileModal()}>
                    <Icon name="upload-cloud"></Icon>{' '}
                    <span>{t('FILE_MANAGER.NAVIGATION.UPLOAD_BUTTON')}</span>
                  </Button>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
      <FileManagerFileActions />
      <div className="nk-fmg-body-content">
        <BlockHead size="sm">
          <BlockBetween className="position-relative">
            <BlockHeadContent>
              {title && entityQuery === '' && title}
              {entityQuery !== '' && (
                <BlockTitle page>
                  {t('FILE_MANAGER.NAVIGATION.QUERY')} :{' '}
                  <span className="fw-normal ms-2 text-muted">
                    {entityQuery}
                  </span>
                </BlockTitle>
              )}
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-1">
                {entityFinder && (
                  <li className="d-lg-none">
                    <a
                      href="#folder"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleMobileFinder();
                      }}
                      className="btn btn-trigger btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                )}

                {(displayTypeFilter || entityQuery !== '') && (
                  <li className="d-lg-none">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        href="#toggle"
                        onClick={(ev) => ev.preventDefault()}
                        className="btn btn-trigger btn-icon"
                      >
                        <Icon
                          name={
                            displayTypeOptions.filter(
                              (item) => item.option === displayType,
                            )[0].icon
                          }
                        ></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <FileManagerDisplayTypeFilter displayTooltip />
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                )}

                {displayMobileRecoveryFilter && (
                  <li className="d-lg-none">
                    <a
                      href="#folder"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleMobileRecoveryFilter();
                      }}
                      className="btn btn-trigger btn-icon toggle-expand"
                    >
                      <Icon name="opt"></Icon>
                    </a>
                  </li>
                )}

                <li className="d-lg-none">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="#toggle"
                      onClick={(ev) => ev.preventDefault()}
                      className="btn btn-trigger btn-icon"
                    >
                      <Icon name="plus"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#upload"
                            onClick={(ev) => {
                              ev.preventDefault();
                              openCreateFileModal();
                            }}
                          >
                            <Icon name="upload-cloud"></Icon>
                            <span>
                              {t('FILE_MANAGER.NAVIGATION.CREATE_FILE')}
                            </span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#upload"
                            onClick={(ev) => {
                              ev.preventDefault();
                              openCreatFolderModal();
                            }}
                          >
                            <Icon name="folder-plus"></Icon>
                            <span>
                              {t('FILE_MANAGER.NAVIGATION.CREATE_FOLDER')}
                            </span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li className="d-lg-none me-n1">
                  <a
                    href="#folder"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(toggleDisplayMobileNavbar());
                    }}
                    className="btn btn-trigger btn-icon toggle"
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </a>
                </li>
              </ul>
            </BlockHeadContent>
            {entityFinder && (
              <div
                className={clsx('search-wrap px-2 d-lg-none', {
                  active: displayMobileFinder,
                })}
              >
                <div className="search-content">
                  <a
                    href="#toggle"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleMobileFinder();
                    }}
                    className="search-back btn btn-icon toggle-search"
                  >
                    <Icon name="arrow-left"></Icon>
                  </a>
                  <input
                    type="text"
                    className="form-control border-transparent form-focus-none"
                    placeholder={t(
                      'FILE_MANAGER.NAVIGATION.SEARCH_PLACEHOLDER',
                    )}
                    value={entityQuery}
                    onChange={handleOnQuery}
                  />
                  <button className="search-submit btn btn-icon">
                    <Icon name="search"></Icon>
                  </button>
                </div>
              </div>
            )}

            {(displayTypeFilter || entityQuery !== '') && (
              <BlockHeadContent className="d-none d-lg-block">
                <FileManagerDisplayTypeFilter />
              </BlockHeadContent>
            )}
            {displayMobileRecoveryFilter && (
              <BlockHeadContent className="d-none d-lg-flex d-xl-none">
                <a
                  href="#folder"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleMobileRecoveryFilter();
                  }}
                  className="btn btn-trigger btn-icon toggle-expand"
                >
                  <Icon name="opt"></Icon>
                </a>
              </BlockHeadContent>
            )}
          </BlockBetween>
        </BlockHead>
        {entityQuery === '' ? (
          children
        ) : (
          <FileManagerFiles entities={queriedEntities} />
        )}
      </div>
      <FileManagerCreateFolderModal />
      <FileManagerDistributeEntityModal />
      <FileManagerRenameEntityModal />
      <EntityPermissionsModal />
      <FileManagerMoveFileEntityModal />
      <BulkDeleteEntityModal />
      <ConvertFileModal />
      <FileManagerPreviewModal />
      <FileManagerMetadataModal />
      <RemoveEntityModal />
    </>
  );
};
