import { useTranslation } from '@oproma/i18n';
import {
  deleteFile,
  setDisplayBulkDeleteEntity,
  setSelectedEntityIds,
  trackDeletedEntity,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { toast } from 'react-toastify';
import { Col, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';

export const BulkDeleteEntityModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayBulkDeleteEntityModal, selectedEntityIds, bulkAction } =
    useAppSelector((state) => state.fileManager);
  const { id } = useAppSelector((state) => state.user);

  const toggleModal = () =>
    dispatch(setDisplayBulkDeleteEntity(!displayBulkDeleteEntityModal));

  const onDelete = async (entityId: string) => {
    dispatch(deleteFile(entityId));
    dispatch(trackDeletedEntity([entityId, id, new Date()]));
    toggleModal();
    dispatch(setSelectedEntityIds([]));
  };

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={displayBulkDeleteEntityModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('FILE_MANAGER.BULK_DELETE.TITLE')}</h5>
          <div className="mt-4">
            <p>{t('FILE_MANAGER.BULK_DELETE.HEADING')}</p>
            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li className="me-4">
                  <Button
                    color="primary"
                    onClick={() => {
                      selectedEntityIds.map((entityId) =>
                        onDelete(entityId ?? 'N/A'),
                      );
                      if (bulkAction) {
                        toast.success(t('FILE_MANAGER.BULK_DELETE.TOAST'));
                      }
                    }}
                  >
                    {t('MEMBERS.REMOVE_MEMBER.CONFIRM')}
                  </Button>
                </li>
                <li>
                  <Button onClick={handleCancel}>
                    {' '}
                    {t('MEMBERS.REMOVE_MEMBER.CANCEL')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
