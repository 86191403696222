import { useTranslation } from '@oproma/i18n';
import { NotificationMessages, useAppSelector } from '@oproma/prividox-store';
import clsx from 'clsx';
import { useMemo } from 'react';
import Moment from 'react-moment';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Icon } from '../../icon.component';
import { Loader } from '../../loader.component';
import { useNotificationContent, useNotificationState } from '../../utils';
interface NotificationItemProps {
  notification: NotificationMessages;
  id: string;
}

const NotificationItem = ({ notification, id }: NotificationItemProps) => {
  const state = useNotificationState(notification);
  const notificationContent = useNotificationContent(notification, state);

  return (
    <div className="nk-notification-item" key={id} id={id}>
      <div className="nk-notification-icon">
        <Icon
          name={notification.icon}
          className={clsx('icon-circle', notification.iconStyle)}
        />
      </div>
      <div className="nk-notification-content">
        <div
          className="nk-notification-text"
          dangerouslySetInnerHTML={{
            __html: notificationContent as string,
          }}
        />
        <div className="nk-notification-time">
          <Moment format="LLL">{notification.time}</Moment>
        </div>
      </div>
    </div>
  );
};

export const Notification = () => {
  const { notifications: rawNotifications, loading } = useAppSelector(
    (state) => state.notifications,
  );

  const filteredNotifications = rawNotifications.filter(
    (notification) => notification.eventType !== 'TRASHED',
  );

  const notifications = filteredNotifications.reduce<NotificationMessages[]>(
    (acc, current) => {
      const previous = acc[acc.length - 1];
      if (
        previous &&
        (current.eventType === 'LOGIN' || current.eventType === 'LOGOUT') &&
        previous.eventType === current.eventType
      ) {
        return acc;
      } else {
        return [...acc, current as NotificationMessages];
      }
    },
    [],
  );

  if (loading) return <Loader />;

  return (
    <UncontrolledDropdown className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-info">
          <Icon name="bell" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          {/* <a href="#markasread" onClick={(ev) => ev.preventDefault()}>
            Mark as read
          </a> */}
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            {notifications.map((item, i) => (
              <NotificationItem key={i} id={`${i}`} notification={item} />
            ))}
          </div>
        </div>
        {/* <div className="dropdown-foot center">
          <a href="#viewall" onClick={(ev) => ev.preventDefault()}>
            Load more notifications
          </a>
        </div> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
