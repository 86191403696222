/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuerySpec } from './QuerySpec';
import {
  QuerySpecFromJSON,
  QuerySpecFromJSONTyped,
  QuerySpecToJSON,
} from './QuerySpec';

/**
 *
 * @export
 * @interface BatchUpdateSpec
 */
export interface BatchUpdateSpec {
  /**
   *
   * @type {QuerySpec}
   * @memberof BatchUpdateSpec
   */
  query: QuerySpec;
  /**
   * The set of metadata to apply to the entities matching the query..
   * @type {{ [key: string]: string; }}
   * @memberof BatchUpdateSpec
   */
  meta: { [key: string]: string };
}

/**
 * Check if a given object implements the BatchUpdateSpec interface.
 */
export function instanceOfBatchUpdateSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'query' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function BatchUpdateSpecFromJSON(json: any): BatchUpdateSpec {
  return BatchUpdateSpecFromJSONTyped(json, false);
}

export function BatchUpdateSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BatchUpdateSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    query: QuerySpecFromJSON(json['query']),
    meta: json['meta'],
  };
}

export function BatchUpdateSpecToJSON(value?: BatchUpdateSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    query: QuerySpecToJSON(value.query),
    meta: value.meta,
  };
}
