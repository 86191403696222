import { yupResolver } from '@hookform/resolvers/yup';
import {
  getGroupMembers,
  getWorkspaceMembers,
  createGroupMember,
  renameGroup,
  setDisplayEditGroupModal,
  useAppDispatch,
  useAppSelector,
  setLastOpenedGroup,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Form, Input, Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { useTranslation } from 'react-i18next';

const editGroupSchema = yup.object({
  name: yup.string().required(),
});
//.required();     TODO: Check

type EditGroupFormValues = yup.InferType<typeof editGroupSchema>;

interface EditGroupForm {
  id: string;
  isDefaultGroup: boolean;
  memberCount: number;
  name: string;
}

export const EditGroupModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, groupId } = useParams();
  const { displayEditGroupModal } = useAppSelector((state) => state.groups);
  const lastOpenedGroup = useAppSelector((state) =>
    state.groups.groups.find(
      (group) => group.id === state.groups.lastOpenedGroup,
    ),
  );
  const [formData, setFormData] = useState<EditGroupForm>({
    name: '',
    id: '',
    isDefaultGroup: false,
    memberCount: 0,
  });
  const { members } = useAppSelector((state) => state.members);
  const groupMembers = useAppSelector((state) => state.groups.groupMembers);
  const nonGroupMembers = members.filter(
    (member) =>
      !groupMembers.find((groupMember) => groupMember.id === member.id),
  );

  const {
    register: registerField,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<EditGroupFormValues>({
    resolver: yupResolver(editGroupSchema),
  });

  const { ref: groupNameRef, ...registerGroupName } = registerField('name');

  const changeableGroupName = watch('name');

  const onFormSubmit: SubmitHandler<EditGroupFormValues> = ({ name }) => {
    if (!workspaceId) return;
    dispatch(
      renameGroup({
        groupId: lastOpenedGroup?.id ?? (groupId as string),
        nameSpec: { name: name },
        workspaceId,
      }),
    );
    if (changeableGroupName !== lastOpenedGroup?.name) {
      toast.success('Group name has been successfully changed.');
    }
    toggleModal();
  };

  useEffect(() => {
    dispatch(getGroupMembers(lastOpenedGroup?.id as string));
    dispatch(getWorkspaceMembers(workspaceId as string));
  }, [dispatch]);

  const handleAddMember = (body: string) => {
    const groupId = lastOpenedGroup?.id ?? '';
    dispatch(createGroupMember({ groupId, body }));
    toast.success('Member has been successfully added.'); // TODO: Change this to use state
    toggleModal();
  };

  const toggleModal = () => {
    dispatch(setDisplayEditGroupModal(!displayEditGroupModal));
    reset();
  };

  useEffect(() => {
    if (displayEditGroupModal && lastOpenedGroup?.name != null) {
      const groupName = lastOpenedGroup?.name;
      reset({ name: groupName ?? '' });
    }
  }, [displayEditGroupModal, lastOpenedGroup?.name, reset]);

  return (
    <Modal
      isOpen={displayEditGroupModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('GROUPS.EDIT_GROUP.TITLE')}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    {t('GROUPS.EDIT_GROUP.NAME')}
                  </label>
                  <div className="form-control-wrap">
                    <Input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder={lastOpenedGroup?.name}
                      innerRef={groupNameRef}
                      {...registerGroupName}
                    />
                    {errors.name && (
                      <span className="text-danger">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    {' '}
                    {t('GROUPS.EDIT_GROUP.MEMBERS')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-wrap">
                      <div className="d-flex">
                        <Col md="10">
                          <Select
                            options={nonGroupMembers.map((item) => ({
                              label: item.name ?? item.email,
                              value: item.id,
                            }))}
                            placeholder={t(
                              'GROUPS.EDIT_GROUP.MEMBERS_PLACEHOLDER',
                            )}
                            onChange={(ev) =>
                              setFormData({
                                ...formData,
                                id: ev?.value as string,
                              })
                            }
                          />
                        </Col>
                        <Col md="2">
                          <div className="input-group-append">
                            <Button
                              className="btn btn-primary"
                              type="button"
                              onClick={(ev) => {
                                handleAddMember(formData.id);
                              }}
                            >
                              {t('GROUPS.EDIT_GROUP.ADD')}
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                  <li className="me-4">
                    <Button color="primary" size="md" type="submit">
                      {t('GROUPS.EDIT_GROUP.CONFIRM')}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
