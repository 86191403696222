import { MD5 } from 'crypto-js';

export const createDeviceFingerPrint = () =>
  MD5(
    navigator.appCodeName +
      navigator.appName +
      navigator.doNotTrack +
      navigator.cookieEnabled +
      navigator.language +
      navigator.hardwareConcurrency +
      navigator.platform +
      navigator.product +
      navigator.productSub +
      navigator.vendor,
  ).toString();
