export const config = {
  url:
    import.meta.env.VITE_UNLEASH_API_URL ??
    'https://unleash.prividox.com/api/frontend', // front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey:
    import.meta.env.VITE_UNLEASH_CLIENT_KEY ??
    'default:development.unleash-not-safe-frontend-api-token', // A client-side API token OR one of the proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: parseInt(
    import.meta.env.VITE_UNLEASH_REFRESH_TIMER ?? '20',
    10,
  ), // How often (in seconds) the client should poll the proxy for updates
  appName: import.meta.env.VITE_UNLEASH_APP_NAME ?? 'default', // The name of your application. It's only used for identifying the application
};
