/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LockSpec
 */
export interface LockSpec {
  /**
   * Whether the file is being locked or unlocked.
   * @type {boolean}
   * @memberof LockSpec
   */
  locked: boolean;
  /**
   * Whether the requested lock will make the file accessible publicly
   * @type {boolean}
   * @memberof LockSpec
   */
  publish?: boolean;
}

/**
 * Check if a given object implements the LockSpec interface.
 */
export function instanceOfLockSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locked' in value;

  return isInstance;
}

export function LockSpecFromJSON(json: any): LockSpec {
  return LockSpecFromJSONTyped(json, false);
}

export function LockSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LockSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locked: json['locked'],
    publish: !exists(json, 'publish') ? undefined : json['publish'],
  };
}

export function LockSpecToJSON(value?: LockSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locked: value.locked,
    publish: value.publish,
  };
}
