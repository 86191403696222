import {
  toggleDisplayOnboardWorkspaceModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { Modal, ModalBody } from 'reactstrap';
import { useWizard } from '../hooks';
import { Icon } from '../icon.component';
import { Processes } from '../wizard.component';
import { OnboardWorkspaceBasicsForm } from './onboard-workspace-basics-form.component';
import { OnboardWorkspaceCCForm } from './onboard-workspace-cc-form.component';
import { OnboardWorkspaceCompletedBanner } from './onboard-workspace-completed-banner.component';
import { OnboardWorkspacePricingPlansForm } from './onboard-workspace-pricing-plans-form.component';

export const OnboardWorkspaceModal = () => {
  const dispatch = useAppDispatch();
  const { currentProcess } = useWizard();
  const { displayOnboardWorkspaceModal } = useAppSelector(
    (state) => state.workspaces,
  );

  const toggle = () => {
    dispatch(toggleDisplayOnboardWorkspaceModal());
  };

  // TODO: Move to translation files
  return (
    <Modal
      isOpen={displayOnboardWorkspaceModal}
      className="modal-dialog-centered"
      size="lg"
      toggle={toggle}
    >
      <a
        href="#dropdownitem"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <ModalBody className="modal-body-md">
        <h5 className="title mb-3">Create Workspace</h5>
        <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
          <div className="steps clearfix">
            <ul className="steps-list">
              <li className={currentProcess >= 1 ? 'first done' : 'first'}>
                <a href="#wizard-01-h" onClick={(ev) => ev.preventDefault()}>
                  <span className="number">01</span> <h5>Basics</h5>
                </a>
              </li>
              <li className={clsx({ done: currentProcess >= 2 })}>
                <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
                  <span className="number">02</span> <h5>Credit Card</h5>
                </a>
              </li>
              <li className={clsx(currentProcess >= 3 ? 'last done' : 'last')}>
                <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                  <span className="number">03</span> <h5>Pricing Plan</h5>
                </a>
              </li>
              <li className={currentProcess === 4 ? 'last done' : 'last'}>
                <a href="#wizard-01-h-3" onClick={(ev) => ev.preventDefault()}>
                  <span className="current-info audible">current step: </span>
                  <span className="number">04</span> <h5>Complete</h5>
                </a>
              </li>
            </ul>
            <Processes>
              <OnboardWorkspaceBasicsForm />
              <OnboardWorkspaceCCForm />
              <OnboardWorkspacePricingPlansForm />
              <OnboardWorkspaceCompletedBanner />
            </Processes>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
