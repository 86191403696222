import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import fr from './translations/fr.json';

export const defaultNS = 'translation';
export const resources = {
  'en-US': {
    translation: en,
  },
  'fr-FR': {
    translation: fr,
  },
} as const;

export const registerTranslations = () => {
  return i18n
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: 'en-US',
      debug: true,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};
