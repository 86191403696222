import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  FilesErrorCodesEnum,
  FolderTypeEnum,
  createFolder,
  resetErrorMessage,
  setDisplayCreateFolderModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Input, Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { FolderCalendarSvg, FolderGallerySvg, FolderSvg } from '../../svg';
import { retrieveApiErrorMessage } from '../../utils';

type FolderType = {
  id: number;
  value: FolderTypeEnum;
  label: string;
  svg: JSX.Element;
};

export const folderTypes: FolderType[] = [
  {
    id: 1,
    value: FolderTypeEnum.GENERAL,
    label: 'General',
    svg: <FolderSvg />,
  },
  {
    id: 2,
    value: FolderTypeEnum.CALENDAR,
    label: 'Calendar',
    svg: <FolderCalendarSvg />,
  },
  {
    id: 3,
    value: FolderTypeEnum.GALLERY,
    label: 'Gallery',
    svg: <FolderGallerySvg />,
  },
];

export const FileManagerCreateFolderModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayCreateFolderModal, error } = useAppSelector(
    (state) => state.fileManager,
  );
  const { entityId, workspaceId } = useParams();
  const rootFolder = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === workspaceId,
    ),
  )?.root;

  const createFolderSchema = yup
    .object({
      name: yup
        .string()
        .matches(/^[^\s/\\":?<>|*\r\n][^/\\":?<>|*\r\n]*$/)
        .trim()
        .required(),
      folderType: yup
        .mixed<FolderTypeEnum>()
        .oneOf(Object.values(FolderTypeEnum)),
      ignore: yup.boolean(),
    })
    .required();

  type CreateFolderFormValues = yup.InferType<typeof createFolderSchema>;

  const {
    register: registerField,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CreateFolderFormValues>({
    resolver: yupResolver(createFolderSchema),
    defaultValues: {
      folderType: folderTypes[0].value,
    },
  });

  const { ref: folderNameRef, ...registerFolderName } = registerField('name');
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null,
  );

  const toggle = useCallback(() => {
    dispatch(setDisplayCreateFolderModal(!displayCreateFolderModal));
    reset();
  }, [dispatch, displayCreateFolderModal, reset]);

  const onFormSubmit: SubmitHandler<CreateFolderFormValues> = useCallback(
    ({ name, folderType = FolderTypeEnum.GENERAL, ignore = false }) => {
      dispatch(
        createFolder({
          entity: entityId ?? (rootFolder as string),
          newItemSpec: {
            name,
            ignore,
          },
          type: folderType,
        }),
      );
      reset();
    },
    [dispatch, entityId, toggle],
  );

  useEffect(() => {
    if (inputElement) {
      inputElement.focus();
    }
  }, [inputElement]);

  return (
    <Modal {...{ isOpen: displayCreateFolderModal, toggle }}>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          dispatch(resetErrorMessage(null));
          toggle();
          reset();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <ModalBody className="modal-body-md">
        <div className="nk-upload-form mb-0">
          <h5 className="title mb-3">{t('FILE_MANAGER.FOLDER.TITLE')}</h5>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <label className="form-label">
                {t('FILE_MANAGER.FOLDER.NAME')}
              </label>
              <Input
                type="text"
                id="folderName"
                placeholder={
                  t('FILE_MANAGER.FOLDER.NAME_PLACEHOLDER') as string
                }
                className="form-control"
                innerRef={(e) => {
                  folderNameRef(e);
                  if (e && e instanceof HTMLInputElement) {
                    setInputElement(e);
                  }
                }}
                {...registerFolderName}
              />
              {errors.name && (
                <span className="invalid">
                  {t('FILE_MANAGER.FOLDER.ERROR')}
                </span>
              )}
              {error?.code === FilesErrorCodesEnum.NAME_EXISTS_FAILED && (
                <Alert className="alert-icon" color="danger">
                  <Icon name="cross-circle" />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(retrieveApiErrorMessage(error.message)),
                    }}
                  ></p>
                </Alert>
              )}
            </div>
            <div className="form-group">
              <label className="form-label">
                {t('FILE_MANAGER.FOLDER.TYPE')}
              </label>
              <Select
                options={folderTypes}
                defaultValue={folderTypes[0]}
                onChange={(ev) =>
                  setValue(
                    'folderType',
                    (ev?.value as FolderTypeEnum) ?? FolderTypeEnum.GENERAL,
                  )
                }
              />
            </div>
            <ul className="btn-toolbar g-4 align-center justify-end">
              <li>
                <Button color="primary" type="submit">
                  {t('FILE_MANAGER.FOLDER.CREATE')}
                </Button>
              </li>
            </ul>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
