/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NameSpec
 */
export interface NameSpec {
  /**
   * The name to give to the group.
   * @type {string}
   * @memberof NameSpec
   */
  name: string;
}

/**
 * Check if a given object implements the NameSpec interface.
 */
export function instanceOfNameSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function NameSpecFromJSON(json: any): NameSpec {
  return NameSpecFromJSONTyped(json, false);
}

export function NameSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NameSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
  };
}

export function NameSpecToJSON(value?: NameSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
