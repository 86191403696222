/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
} from './Project';

/**
 *
 * @export
 * @interface Folder
 */
export interface Folder {
  /**
   *
   * @type {Set<Folder>}
   * @memberof Folder
   */
  folders?: Set<Folder>;
  /**
   *
   * @type {string}
   * @memberof Folder
   */
  name?: string;
  /**
   *
   * @type {Set<any>}
   * @memberof Folder
   */
  files?: Set<any>;
  /**
   *
   * @type {Folder}
   * @memberof Folder
   */
  parent?: Folder;
  /**
   *
   * @type {string}
   * @memberof Folder
   */
  createdById?: string;
  /**
   *
   * @type {Project}
   * @memberof Folder
   */
  rootOf?: Project;
  /**
   *
   * @type {Project}
   * @memberof Folder
   */
  trashOf?: Project;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  deleted?: boolean;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  lastModified?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Folder
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  root?: boolean;
  /**
   *
   * @type {Project}
   * @memberof Folder
   */
  project?: Project;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  totalSubfolderCount?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  totalSubfileCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  trashCan?: boolean;
  /**
   *
   * @type {Set<string>}
   * @memberof Folder
   */
  allDescendantIDs?: Set<string>;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  allChildrenDeleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  deletedDirect?: boolean;
}

/**
 * Check if a given object implements the Folder interface.
 */
export function instanceOfFolder(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FolderFromJSON(json: any): Folder {
  return FolderFromJSONTyped(json, false);
}

export function FolderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Folder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    folders: !exists(json, 'folders')
      ? undefined
      : new Set((json['folders'] as Array<any>).map(FolderFromJSON)),
    name: !exists(json, 'name') ? undefined : json['name'],
    files: !exists(json, 'files') ? undefined : json['files'],
    parent: !exists(json, 'parent')
      ? undefined
      : FolderFromJSON(json['parent']),
    createdById: !exists(json, 'createdById') ? undefined : json['createdById'],
    rootOf: !exists(json, 'rootOf')
      ? undefined
      : ProjectFromJSON(json['rootOf']),
    trashOf: !exists(json, 'trashOf')
      ? undefined
      : ProjectFromJSON(json['trashOf']),
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    lastModified: !exists(json, 'lastModified')
      ? undefined
      : json['lastModified'],
    size: !exists(json, 'size') ? undefined : json['size'],
    totalSize: !exists(json, 'totalSize') ? undefined : json['totalSize'],
    version: !exists(json, 'version') ? undefined : json['version'],
    id: !exists(json, 'id') ? undefined : json['id'],
    root: !exists(json, 'root') ? undefined : json['root'],
    project: !exists(json, 'project')
      ? undefined
      : ProjectFromJSON(json['project']),
    totalSubfolderCount: !exists(json, 'totalSubfolderCount')
      ? undefined
      : json['totalSubfolderCount'],
    totalSubfileCount: !exists(json, 'totalSubfileCount')
      ? undefined
      : json['totalSubfileCount'],
    trashCan: !exists(json, 'trashCan') ? undefined : json['trashCan'],
    allDescendantIDs: !exists(json, 'allDescendantIDs')
      ? undefined
      : json['allDescendantIDs'],
    allChildrenDeleted: !exists(json, 'allChildrenDeleted')
      ? undefined
      : json['allChildrenDeleted'],
    deletedDirect: !exists(json, 'deletedDirect')
      ? undefined
      : json['deletedDirect'],
  };
}

export function FolderToJSON(value?: Folder | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    folders:
      value.folders === undefined
        ? undefined
        : Array.from(value.folders as Set<any>).map(FolderToJSON),
    name: value.name,
    files:
      value.files === undefined
        ? undefined
        : Array.from(value.files as Set<any>),
    parent: FolderToJSON(value.parent),
    createdById: value.createdById,
    rootOf: ProjectToJSON(value.rootOf),
    trashOf: ProjectToJSON(value.trashOf),
    deleted: value.deleted,
    lastModified: value.lastModified,
    size: value.size,
    totalSize: value.totalSize,
    version: value.version,
    id: value.id,
    root: value.root,
    project: ProjectToJSON(value.project),
    totalSubfolderCount: value.totalSubfolderCount,
    totalSubfileCount: value.totalSubfileCount,
    trashCan: value.trashCan,
    allDescendantIDs:
      value.allDescendantIDs === undefined
        ? undefined
        : Array.from(value.allDescendantIDs as Set<any>),
    allChildrenDeleted: value.allChildrenDeleted,
    deletedDirect: value.deletedDirect,
  };
}
