/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TextLocation } from './TextLocation';
import {
  TextLocationFromJSON,
  TextLocationFromJSONTyped,
  TextLocationToJSON,
} from './TextLocation';

/**
 *
 * @export
 * @interface PdfStats
 */
export interface PdfStats {
  /**
   *
   * @type {Array<{ [key: string]: number; }>}
   * @memberof PdfStats
   */
  pages?: Array<{ [key: string]: number }>;
  /**
   *
   * @type {number}
   * @memberof PdfStats
   */
  pageCount?: number;
  /**
   *
   * @type {{ [key: string]: Array<TextLocation>; }}
   * @memberof PdfStats
   */
  text?: { [key: string]: Array<TextLocation> };
  /**
   *
   * @type {Set<string>}
   * @memberof PdfStats
   */
  entities?: Set<string>;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof PdfStats
   */
  keyedEntities?: { [key: string]: Array<string> };
}

/**
 * Check if a given object implements the PdfStats interface.
 */
export function instanceOfPdfStats(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PdfStatsFromJSON(json: any): PdfStats {
  return PdfStatsFromJSONTyped(json, false);
}

export function PdfStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PdfStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    pageCount: !exists(json, 'pageCount') ? undefined : json['pageCount'],
    text: !exists(json, 'text') ? undefined : json['text'],
    entities: !exists(json, 'entities') ? undefined : json['entities'],
    keyedEntities: !exists(json, 'keyedEntities')
      ? undefined
      : json['keyedEntities'],
  };
}

export function PdfStatsToJSON(value?: PdfStats | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pages: value.pages,
    pageCount: value.pageCount,
    text: value.text,
    entities:
      value.entities === undefined
        ? undefined
        : Array.from(value.entities as Set<any>),
    keyedEntities: value.keyedEntities,
  };
}
