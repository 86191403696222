/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectInformation } from './ProjectInformation';
import {
  ProjectInformationFromJSON,
  ProjectInformationFromJSONTyped,
  ProjectInformationToJSON,
} from './ProjectInformation';

/**
 *
 * @export
 * @interface ResultProjectInformation
 */
export interface ResultProjectInformation {
  /**
   *
   * @type {Array<ProjectInformation>}
   * @memberof ResultProjectInformation
   */
  items?: Array<ProjectInformation>;
}

/**
 * Check if a given object implements the ResultProjectInformation interface.
 */
export function instanceOfResultProjectInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultProjectInformationFromJSON(
  json: any,
): ResultProjectInformation {
  return ResultProjectInformationFromJSONTyped(json, false);
}

export function ResultProjectInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultProjectInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(ProjectInformationFromJSON),
  };
}

export function ResultProjectInformationToJSON(
  value?: ResultProjectInformation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(ProjectInformationToJSON),
  };
}
