/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PrincipalInformation
 */
export interface PrincipalInformation {
  /**
   *
   * @type {string}
   * @memberof PrincipalInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PrincipalInformation
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PrincipalInformation
   */
  email?: string;
  /**
   *
   * @type {Array<PrincipalInformation>}
   * @memberof PrincipalInformation
   */
  members?: Array<PrincipalInformation>;
}

/**
 * Check if a given object implements the PrincipalInformation interface.
 */
export function instanceOfPrincipalInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PrincipalInformationFromJSON(json: any): PrincipalInformation {
  return PrincipalInformationFromJSONTyped(json, false);
}

export function PrincipalInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PrincipalInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    members: !exists(json, 'members')
      ? undefined
      : (json['members'] as Array<any>).map(PrincipalInformationFromJSON),
  };
}

export function PrincipalInformationToJSON(
  value?: PrincipalInformation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    email: value.email,
    members:
      value.members === undefined
        ? undefined
        : (value.members as Array<any>).map(PrincipalInformationToJSON),
  };
}
