import { useTranslation } from '@oproma/i18n';
import { PlanInformation } from '@oproma/prividox-orchestration-open-api';
import clsx from 'clsx';
import { Dictionary } from 'lodash';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Block } from '../block.component';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { Icon } from '../icon.component';
import { currencyFormatter, prettyBytes } from '../utils';

type PricingPlansProps = {
  plans: Record<string, Dictionary<PlanInformation[]>>;
  selectedPlan?: string;
  handlePlanSelection: (plan?: string) => void;
  errorMessage: string | undefined;
};

export const PricingPlans = ({
  plans,
  selectedPlan,
  handlePlanSelection,
  errorMessage,
}: PricingPlansProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [billingFrequency, setBillingFrequency] = useState('month');

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getPricingPlanTranslation = (planName?: string) => {
    const [name, membersLimit, storageLimit] = planName?.split('-') ?? [];
    return t(`PLANS.${name.toUpperCase() + '_MEMBERS_STORAGE'}`, {
      membersLimit: Number(membersLimit),
      storageLimit: Number(storageLimit),
    });
  };

  const getPricingPlanTypeTranslation = (planType?: string) => {
    return t(`PLANS.${planType?.toUpperCase()}`);
  };

  return (
    <Block>
      {errorMessage && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorMessage}
          </Alert>
        </div>
      )}
      <div className="d-flex justify-center">
        <p className="me-2">Monthly</p>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitch1"
            onChange={() => {
              if (billingFrequency === 'month') {
                setBillingFrequency('year');
              } else if (billingFrequency === 'year') {
                setBillingFrequency('month');
              }
            }}
          />
          <label className="custom-control-label" htmlFor="customSwitch1">
            Yearly <Badge color="primary">20% cheaper</Badge>
          </label>
        </div>
      </div>
      <Nav tabs className="mt-n3 nav-tabs-s2">
        {Object.keys(plans[billingFrequency]).map((planType, i) => (
          <NavItem key={i}>
            <NavLink
              className={clsx({ active: activeTab === i })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleTab(i);
              }}
            >
              {getPricingPlanTypeTranslation(planType)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {Object.values(plans[billingFrequency]).map((plans, i) => (
        <TabContent activeTab={activeTab} key={i}>
          <TabPane tabId={i}>
            <Row className="g-gs">
              {plans.map((plan) => (
                <Col md={6} xxl={6} key={plan.id}>
                  <Card
                    className={`card-bordered pricing text-center ${
                      selectedPlan === plan.id ? 'border-primary' : ''
                    }`}
                  >
                    {selectedPlan === plan.id && (
                      <Badge color="primary" className="pricing-badge">
                        Current Plan
                      </Badge>
                    )}
                    <div className="pricing-head">
                      <div className="pricing-title">
                        <h4 className="card-title title">
                          {getPricingPlanTranslation(
                            plan.meta?.['package-i18n-name'],
                          )}
                        </h4>
                        <p className="sub-text">
                          {plan.meta?.['package-i18n-desc']}
                        </p>
                      </div>
                      <div className="card-text">
                        <Row>
                          <Col size={6}>
                            <span className="h4 fw-500">
                              {plan.meta?.['members-limit']}
                            </span>
                            <span className="sub-text">Max Members</span>
                          </Col>
                          <Col size={6}>
                            <span className="h4 fw-500">
                              {prettyBytes(
                                parseInt(plan.meta?.['bytes-limit'] ?? '0', 10),
                              )}
                            </span>
                            <span className="sub-text">Storage</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="pricing-features">
                        <li>
                          <span className="w-50">Conversions</span> -{' '}
                          <span className="ms-auto">
                            {plan.meta?.['conversions-allowed'] === 'true' ? (
                              <Icon name="check-thick" />
                            ) : (
                              <Icon name="cross" />
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="w-50">Expansion</span> -{' '}
                          <span className="ms-auto">
                            {plan.meta?.['expansion-allowed'] === 'true' ? (
                              <Icon name="check-thick" />
                            ) : (
                              <Icon name="cross" />
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="w-50">Produce</span> -{' '}
                          <span className="ms-auto">
                            {plan.meta?.['produce-allowed'] === 'true' ? (
                              <Icon name="check-thick" />
                            ) : (
                              <Icon name="cross" />
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="w-50">Publish</span> -{' '}
                          <span className="ms-auto">
                            {plan.meta?.['publish-allowed'] === 'true' ? (
                              <Icon name="check-thick" />
                            ) : (
                              <Icon name="cross" />
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="w-50">Redactions</span> -{' '}
                          <span className="ms-auto">
                            {plan.meta?.['redactions-allowed'] === 'true' ? (
                              <Icon name="check-thick" />
                            ) : (
                              <Icon name="cross" />
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="w-50">Review</span> -{' '}
                          <span className="ms-auto">
                            {plan.meta?.['review-allowed'] === 'true' ? (
                              <Icon name="check-thick" />
                            ) : (
                              <Icon name="cross" />
                            )}
                          </span>
                        </li>
                      </ul>
                      <h5 className="fw-500 mt-2">
                        Billed $
                        {`${currencyFormatter(
                          plan.amount ?? -1,
                        )} ${plan.currency?.toUpperCase()}`}
                        /{billingFrequency}
                      </h5>
                      <div className="pricing-action">
                        {selectedPlan === plan.id ? (
                          <Button color="primary" type="button" disabled>
                            Plan Selected
                          </Button>
                        ) : (
                          <Button
                            color="light"
                            type="button"
                            outline
                            onClick={() => {
                              handlePlanSelection(plan.id);
                            }}
                          >
                            Choose this plan
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
      ))}
    </Block>
  );
};
