/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateAccountSpec
 */
export interface CreateAccountSpec {
  /**
   * The email to associate with the user.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  email: string;
  /**
   * The password to associate with the user.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  pass: string;
  /**
   * The display name to associate with the user.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  name: string;
  /**
   * The recaptcha token used to validate the initiator of the request is human.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  recaptcha: string;
  /**
   * The language in which to send out the invitation email. Should be one of 'en', 'fr', 'en_fr', or 'fr_en'.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  lang?: string;
  /**
   * The OAuth client_id of the application initiating the invite. The application will redirect to that consumer's app instead of the application root.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  clientId?: string;
}

/**
 * Check if a given object implements the CreateAccountSpec interface.
 */
export function instanceOfCreateAccountSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'pass' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'recaptcha' in value;

  return isInstance;
}

export function CreateAccountSpecFromJSON(json: any): CreateAccountSpec {
  return CreateAccountSpecFromJSONTyped(json, false);
}

export function CreateAccountSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateAccountSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    pass: json['pass'],
    name: json['name'],
    recaptcha: json['recaptcha'],
    lang: !exists(json, 'lang') ? undefined : json['lang'],
    clientId: !exists(json, 'clientId') ? undefined : json['clientId'],
  };
}

export function CreateAccountSpecToJSON(value?: CreateAccountSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    pass: value.pass,
    name: value.name,
    recaptcha: value.recaptcha,
    lang: value.lang,
    clientId: value.clientId,
  };
}
