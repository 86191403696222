import { useTranslation } from '@oproma/i18n';
import { Link } from '@oproma/router';
import { footerLinks } from '../footer-links.menu';

const year = new Date().getFullYear();

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div
            className="nk-footer-copyright"
            dangerouslySetInnerHTML={{
              __html: t('FOOTER.REMARK', {
                year,
                version: import.meta.env.VITE_APP_VERSION ?? '0.0.1',
              }),
            }}
          />
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              {footerLinks.map((link) => (
                <li className="nav-item" key={link.title}>
                  <Link to={link.href} className="nav-link">
                    {t(link.title)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
