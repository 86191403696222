export const bulkOperationOptions = [{ value: 'delete', label: 'Delete' }];

export const permissionLevels = [
  { value: '-127', label: 'MEMBERS.ONBOARDING.PERMISSIONS.REGULAR' },
  { value: '-128', label: 'MEMBERS.ONBOARDING.PERMISSIONS.RESTRICTED' },
  { value: '0', label: 'MEMBERS.ONBOARDING.PERMISSIONS.SECURE' },
  { value: '126', label: 'MEMBERS.ONBOARDING.PERMISSIONS.ADMINISTRATOR' },
  { value: '127', label: 'MEMBERS.ONBOARDING.PERMISSIONS.OWNER' },
];

export const memberStatuses = [
  { value: 'true', label: 'Registered' },
  { value: 'false', label: 'Pending Registration' },
];

export type LanguageType = 'en' | 'fr' | 'en_fr' | 'fr_en';

export const languages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'en_fr', label: 'English · Français' },
  { value: 'fr_en', label: 'Français · English' },
];

export const getMemberPermissionLevelLabel = (
  permissionLevel: string | undefined,
) => {
  switch (permissionLevel) {
    case '-127':
      return 'MEMBERS.ONBOARDING.PERMISSIONS.REGULAR';
    case '-128':
      return 'MEMBERS.ONBOARDING.PERMISSIONS.RESTRICTED';
    case '0':
      return 'MEMBERS.ONBOARDING.PERMISSIONS.SECURE';
    case '126':
      return 'MEMBERS.ONBOARDING.PERMISSIONS.ADMINISTRATOR';
    case '127':
      return 'MEMBERS.ONBOARDING.PERMISSIONS.OWNER';
    default:
      return 'MEMBERS.ONBOARDING.PERMISSIONS.UNKNOWN';
  }
};
