import { useTranslation } from '@oproma/i18n';
import { useAppSelector } from '@oproma/prividox-store';
import { groupBy, map } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import { Tooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

type HeatMapBlock = {
  date: string;
  count: number;
  uniqueId: string;
};

export const MemberHeatmap = () => {
  const { memberReport } = useAppSelector((state) => state.members);
  const [hoveredBlock, setHoveredBlock] = useState<HeatMapBlock | null>(null);
  const [heatmap, setHeatmap] = useState<HeatMapBlock[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const formattedMemberReport = memberReport.map((msg, index) => {
      return {
        // Convert epoch time to formatted date string
        date: moment(msg.time).format('YYYY-MM-DD'),
      };
    });

    // Group by the date and then count
    const grouped = groupBy(formattedMemberReport, 'date');
    const countedReport = map(grouped, (reports, date) => ({
      date,
      count: reports.length,
      uniqueId: 'id-' + uuidv4(),
    }));
    console.log(countedReport);
    setHeatmap(countedReport);
  }, [memberReport]);

  return (
    <>
      <CalendarHeatmap
        startDate={moment().subtract(1, 'year').toDate()}
        endDate={moment().toDate()}
        values={heatmap}
        // monthLabels={[
        //   'Jan',
        //   'Feb',
        //   'Mar',
        // ]}
        showWeekdayLabels={true}
        // weekdayLabels={['M', 'W', 'F']}
        tooltipDataAttrs={(value: HeatMapBlock) => {
          return {
            id: value.uniqueId,
          };
        }}
        onMouseOver={(ev, value) => setHoveredBlock(value)}
        onMouseLeave={() => setHoveredBlock(null)}
      />

      {hoveredBlock && (
        <Tooltip
          isOpen={true}
          target={hoveredBlock.uniqueId}
          direction="bottom"
        >
          {t('MEMBERS.MEMBER_HEATMAP.CONTRIBUTED')} {hoveredBlock.count}{' '}
          {t('MEMBERS.MEMBER_HEATMAP.TIMES_ON')} {hoveredBlock.date}
        </Tooltip>
      )}
    </>
  );
};
