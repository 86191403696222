import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  GeneralThemeTypeEnum,
  ILayoutState,
  LanguageEnum,
  LayoutThemeTypeEnum,
  ThemeModeEnum,
} from './types';

const initialState: ILayoutState = {
  mobileMode: false,
  wizard: {
    currentProcess: 1,
    overallProcesses: 0,
  },
  displaySidebar: false,
  displayMobileMenu: false,
  language: LanguageEnum.EN,
  themeMode: ThemeModeEnum.DARK,
  displayUserDropdownMenu: false,
  displayFooterLanguageDropdownMenu: false,
  headerThemeType: GeneralThemeTypeEnum.DARK,
  sidebarThemeType: GeneralThemeTypeEnum.DARK,
  layoutThemeType: LayoutThemeTypeEnum.DEFAULT,
};

export const layoutSlice = createSlice({
  name: '@@layout',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageEnum>) => {
      state.language = action.payload;
    },
    toggleLanguage: (state) => {
      if (state.language === LanguageEnum.EN) {
        state.language = LanguageEnum.FR;
      } else {
        state.language = LanguageEnum.EN;
      }
    },
    toggleDisplayMobileMenu: (state) => {
      state.displayMobileMenu = !state.displayMobileMenu;
    },
    setDisplayMobileMenu: (state, action: PayloadAction<boolean>) => {
      state.displayMobileMenu = action.payload;
    },
    toggleDisplayUserDropdownMenu: (state) => {
      state.displayUserDropdownMenu = !state.displayUserDropdownMenu;
    },
    toggleDisplayFooterLanguageDropdownMenu: (state) => {
      state.displayFooterLanguageDropdownMenu =
        !state.displayFooterLanguageDropdownMenu;
    },
    setMobileMode: (state, action: PayloadAction<boolean>) => {
      state.mobileMode = action.payload;
    },
    setDisplaySidebar: (state, action: PayloadAction<boolean>) => {
      state.displaySidebar = action.payload;
    },
    // Wizard
    setCurrentProcess: (state, action: PayloadAction<number>) => {
      state.wizard.currentProcess = action.payload;
    },
    setProcessesCount: (state, action: PayloadAction<number>) => {
      state.wizard.overallProcesses = action.payload;
    },
    nextProcess: (state) => {
      const nextStep = state.wizard.currentProcess + 1;
      if (nextStep <= state.wizard.overallProcesses) {
        state.wizard.currentProcess = nextStep;
      }
    },
    prevProcess: (state) => {
      const prevStep = state.wizard.currentProcess - 1;
      if (prevStep >= 1) {
        state.wizard.currentProcess = prevStep;
      }
    },
    setProcess: (state, action) => {
      const step = action.payload;
      if (step >= 1 && step <= state.wizard.overallProcesses) {
        state.wizard.currentProcess = step;
      }
    },
    // Theme
    setHeaderThemeType: (
      state,
      action: PayloadAction<GeneralThemeTypeEnum>,
    ) => {
      state.headerThemeType = action.payload;
    },
    setSidebarThemeType: (
      state,
      action: PayloadAction<GeneralThemeTypeEnum>,
    ) => {
      state.sidebarThemeType = action.payload;
    },
    setLayoutThemeType: (state, action: PayloadAction<LayoutThemeTypeEnum>) => {
      state.layoutThemeType = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<ThemeModeEnum>) => {
      state.themeMode = action.payload;
    },
  },
});

export const {
  setProcess,
  nextProcess,
  prevProcess,
  setLanguage,
  setThemeMode,
  setMobileMode,
  toggleLanguage,
  setProcessesCount,
  setDisplaySidebar,
  setCurrentProcess,
  setHeaderThemeType,
  setLayoutThemeType,
  setSidebarThemeType,
  setDisplayMobileMenu,
  toggleDisplayMobileMenu,
  toggleDisplayUserDropdownMenu,
  toggleDisplayFooterLanguageDropdownMenu,
} = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
