import { useTranslation } from '@oproma/i18n';
import { useAppSelector } from '@oproma/prividox-store';
import { Link } from '@oproma/router';
import Moment from 'react-moment';
import { Alert, Badge, Card } from 'reactstrap';
import { Block } from '../block.component';
import { Col, Row } from '../grid.component';
import { Icon } from '../icon.component';
import { currencyFormatter, prettyBytes } from '../utils';

export const ManagePlans = () => {
  const { t } = useTranslation();
  const {
    plan: currentPlan,
    metadata: workspaceMetadata,
    bundles,
  } = useAppSelector((state) => state.workspace);

  const baseStorageGB = prettyBytes(
    parseInt(currentPlan?.meta?.['bytes-limit'] ?? '0', 10),
  );
  const addedStorageGB =
    (bundles[0]?.count ?? 0) *
    parseInt(bundles[0]?.meta?.count ?? '0', 10) *
    1024 *
    1024 *
    1024;
  const formattedAddedStorage = prettyBytes(addedStorageGB);

  return (
    <Block>
      {currentPlan ? (
        <Card className="card-bordered sp-plan">
          <Row className="no-gutters">
            <Col md="12">
              <div className="sp-plan-info card-inner">
                <Row className="gx-0 gy-3">
                  <Col xl="9" sm="8">
                    <div className="sp-plan-name">
                      <h6 className="title">
                        <Link to={`plan-details/${currentPlan.id}`}>
                          {currentPlan.meta?.['package-i18n-name']}{' '}
                          <Badge
                            color={
                              // plan.status === 'Active' ? 'success' : 'light'
                              'success'
                            }
                            className="rounded-pill"
                          >
                            {/* {plan.status} */}
                            {t('COMMON.CURRENT')}
                          </Badge>
                        </Link>
                      </h6>
                      <p>
                        {t('COMMON.PLAN')}:{' '}
                        <span className="text-base">{currentPlan.id}</span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="sp-plan-desc card-inner">
                <Row className="gx-1">
                  <Col lg="3">
                    <p>
                      <span className="text-soft">
                        {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.STARTED_ON')}
                      </span>{' '}
                      <Moment format="L">{workspaceMetadata?.created}</Moment>
                    </p>
                  </Col>
                  <Col lg="3">
                    <p>
                      <span className="text-soft">
                        {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.RECURRING')}
                      </span>{' '}
                      {currentPlan.interval?.toUpperCase()}
                    </p>
                  </Col>
                  <Col lg="3">
                    <p>
                      <span className="text-soft">{t('COMMON.PRICE')}</span>
                      {`${currencyFormatter(
                        currentPlan.amount ?? -1,
                      )} ${currentPlan.currency?.toUpperCase()}`}
                    </p>
                  </Col>
                  <Col lg="3">
                    <p>
                      <span className="text-soft">
                        {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.STORAGE_LIMIT')}
                      </span>{' '}
                      {addedStorageGB > 0
                        ? `${baseStorageGB} + ${formattedAddedStorage}`
                        : baseStorageGB}{' '}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      ) : (
        <Alert className="alert-icon" color="danger">
          <Icon name="cross-circle" />
          {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.PLAN_NOT_FOUND')}
        </Alert>
      )}
    </Block>
  );
};
