import { url } from '../utils';

export const previewMenu = [
  {
    heading: 'Components',
  },
  {
    icon: 'layers',
    text: 'Ui Elements',
    link: url('/'),
    active: false,
    badge: undefined,
    subMenu: [
      {
        text: 'Alerts',
        link: url('/components/alerts'),
      },
      {
        text: 'Accordions',
        link: url('/components/accordions'),
      },
      {
        text: 'Avatar',
        link: url('/components/avatar'),
      },
      {
        text: 'Badges',
        link: url('/components/badges'),
      },
      {
        text: 'Buttons',
        link: url('/components/buttons'),
      },
      {
        text: 'Button Group',
        link: url('/components/button-group'),
      },
      {
        text: 'Breadcrumbs',
        link: url('/components/breadcrumbs'),
      },
      {
        text: 'Cards',
        link: url('/components/cards'),
      },
      {
        text: 'Carousel',
        link: url('/components/carousel'),
      },
      {
        text: 'Dropdowns',
        link: url('/components/dropdowns'),
      },
      {
        text: 'Modals',
        link: url('/components/modals'),
      },
      {
        text: 'Pagination',
        link: url('/components/pagination'),
      },
      {
        text: 'Popovers',
        link: url('/components/popovers'),
      },
      {
        text: 'Progress',
        link: url('/components/progress'),
      },
      {
        text: 'Spinner',
        link: url('/components/spinner'),
      },
      {
        text: 'Tabs',
        link: url('/components/tabs'),
      },
      {
        text: 'Toast',
        link: url('/components/toast'),
      },
      {
        text: 'Typography',
        link: url('/components/typography'),
      },
      {
        text: 'Tooltips',
        link: url('/components/tooltips'),
      },
      {
        text: 'Utilities',
        active: false,
        subMenu: [
          {
            text: 'Borders',
            link: url('/components/util-border'),
          },
          {
            text: 'Colors',
            link: url('/components/util-colors'),
          },
          {
            text: 'Display',
            link: url('/components/util-display'),
          },
          {
            text: 'Embeded',
            link: url('/components/util-embeded'),
          },
          {
            text: 'Flex',
            link: url('/components/util-flex'),
          },
          {
            text: 'Text',
            link: url('/components/util-text'),
          },
          {
            text: 'Dimensions',
            link: url('/components/util-dimensions'),
          },
          {
            text: 'Padding and Margin',
            link: url('/components/util-padding-and-margin'),
          },
          {
            text: 'Others',
            link: url('/components/util-others'),
          },
        ],
      },
    ],
  },
  {
    icon: 'dot-box',
    text: 'Crafted Icons',
    active: false,
    subMenu: [
      {
        text: 'SVG Icon-Exclusive',
        link: url('/svg-icons'),
      },
      {
        text: 'Icons - HandCrafted',
        link: url('/custom-icons'),
      },
    ],
  },
  {
    icon: 'table-view',
    text: 'Tables',
    active: false,
    subMenu: [
      {
        text: 'Basic Tables',
        link: url('/table-basic'),
      },
      // {
      //   text: 'Special Tables',
      //   link: url('/table-special'),
      // },
      {
        text: 'DataTables',
        link: url('/table-datatable'),
      },
    ],
  },
  {
    icon: 'card-view',
    text: 'Forms',
    active: false,
    subMenu: [
      {
        text: 'Form Elements',
        link: url('/components/form-elements'),
      },
      {
        text: 'Checkbox Radio',
        link: url('/components/checkbox-radio'),
      },
      {
        text: 'Advanced Controls',
        link: url('/components/advanced-controls'),
      },
      {
        text: 'Input Group',
        link: url('/components/input-group'),
      },
      {
        text: 'Form Upload',
        link: url('/components/form-upload'),
      },
      {
        text: 'Date Time Picker',
        link: url('/components/datetime-picker'),
      },
      {
        text: 'Number Picker',
        link: url('/components/number-picker'),
      },
      // {
      //   text: 'noUiSlider',
      //   link: url('/components/nouislider'),
      // },
      {
        text: 'Form Layouts',
        link: url('/components/form-layouts'),
      },
      {
        text: 'Form Validation',
        link: url('/components/form-validation'),
      },
      {
        text: 'Wizard Basic',
        link: url('/components/wizard-basic'),
      },
      {
        text: 'Rich Editor',
        active: false,
        subMenu: [
          {
            text: 'Quill',
            link: url('/components/quill'),
          },
          {
            text: 'Tinymce',
            link: url('/components/tinymce'),
          },
        ],
      },
    ],
  },
  {
    icon: 'pie',
    text: 'Charts',
    active: false,
    subMenu: [
      {
        text: 'Chart Js',
        link: url('/charts/chartjs'),
      },
      // {
      //   text: 'Knobs',
      //   link: url('/charts/knobs'),
      // },
    ],
  },
  {
    icon: 'puzzle',
    text: 'Widgets',
    active: false,
    subMenu: [
      {
        text: 'Card Widgets',
        link: url('/components/widgets/cards'),
      },
      // {
      //   text: 'Chart Widgets',
      //   link: url('/components/widgets/charts'),
      // },
      {
        text: 'Rating Widgets',
        link: url('/components/widgets/rating'),
      },
    ],
  },
  {
    icon: 'block-over',
    text: 'Miscellaneous',
    active: false,
    subMenu: [
      // {
      //   text: 'Flicking',
      //   link: url('/components/misc/flicking'),
      // },
      // {
      //   text: 'React Arborist',
      //   link: url('/components/misc/jsTree'),
      // },
      {
        text: 'React Toastify',
        link: url('/components/misc/toastify'),
      },
      // {
      //   text: 'Sweet Alert',
      //   link: url('/components/misc/sweet-alert'),
      // },
      // {
      //   text: 'React DualListBox',
      //   link: url('/components/misc/dual-list'),
      // },
      {
        text: 'React Beautiful Dnd',
        link: url('/components/misc/beautiful-dnd'),
      },
      {
        text: 'Google Map',
        link: url('/components/misc/map'),
      },
    ],
  },
];
