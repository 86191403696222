/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CardInfo } from './CardInfo';
import {
  CardInfoFromJSON,
  CardInfoFromJSONTyped,
  CardInfoToJSON,
} from './CardInfo';

/**
 *
 * @export
 * @interface ResultCardInfo
 */
export interface ResultCardInfo {
  /**
   *
   * @type {Array<CardInfo>}
   * @memberof ResultCardInfo
   */
  items?: Array<CardInfo>;
}

/**
 * Check if a given object implements the ResultCardInfo interface.
 */
export function instanceOfResultCardInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultCardInfoFromJSON(json: any): ResultCardInfo {
  return ResultCardInfoFromJSONTyped(json, false);
}

export function ResultCardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultCardInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(CardInfoFromJSON),
  };
}

export function ResultCardInfoToJSON(value?: ResultCardInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(CardInfoToJSON),
  };
}
