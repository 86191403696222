import { yupResolver } from '@hookform/resolvers/yup';
import {
  createGroup,
  setDisplayOnboardGroupsModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { Icon } from '../../icon.component';

const createGroupSchema = yup
  .object({
    name: yup.string().required().trim().notOneOf([''], 'Name cannot be blank'),
  })
  .required();

type CreateGroupModalValues = yup.InferType<typeof createGroupSchema>;

export const CreateGroupModal = () => {
  const { workspaceId } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayOnboardGroupsModal } = useAppSelector((state) => state.groups);

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CreateGroupModalValues>({
    resolver: yupResolver(createGroupSchema),
  });

  // submit function to add a new item
  const onFormSubmit: SubmitHandler<CreateGroupModalValues> = ({ name }) => {
    if (!workspaceId) return;
    dispatch(
      createGroup({
        workspaceId,
        nameSpec: {
          name: name,
        },
      }),
    );
    toggleModal();
    reset();
  };

  const toggleModal = () => {
    dispatch(setDisplayOnboardGroupsModal(!displayOnboardGroupsModal));
    reset();
  };

  return (
    <Modal
      isOpen={displayOnboardGroupsModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
            reset();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('GROUPS.CREATE_GROUP.TITLE')}</h5>
          <div className="mt-4">
            <Form
              className="row gy-4"
              noValidate
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    {t('GROUPS.CREATE_GROUP.NAME')}
                  </label>
                  <div className="form-control-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t('GROUPS.CREATE_GROUP.NAME_PLACEHOLDER')}
                      onChange={(ev) => setValue('name', ev.target.value)}
                    ></Input>
                    {errors.name && (
                      <span className="text-danger">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>

              <Col size="12">
                <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {t('GROUPS.CREATE_GROUP.CONFIRM')}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
