/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationMessage } from './NotificationMessage';
import {
  NotificationMessageFromJSON,
  NotificationMessageFromJSONTyped,
  NotificationMessageToJSON,
} from './NotificationMessage';

/**
 *
 * @export
 * @interface ResultNotificationMessage
 */
export interface ResultNotificationMessage {
  /**
   *
   * @type {Array<NotificationMessage>}
   * @memberof ResultNotificationMessage
   */
  items?: Array<NotificationMessage>;
}

/**
 * Check if a given object implements the ResultNotificationMessage interface.
 */
export function instanceOfResultNotificationMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultNotificationMessageFromJSON(
  json: any,
): ResultNotificationMessage {
  return ResultNotificationMessageFromJSONTyped(json, false);
}

export function ResultNotificationMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultNotificationMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(NotificationMessageFromJSON),
  };
}

export function ResultNotificationMessageToJSON(
  value?: ResultNotificationMessage | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(NotificationMessageToJSON),
  };
}
