import {
  GroupRow,
  editDefaultGroup,
  getGroupMembers,
  setDisplayEditGroupModal,
  setDisplayGroupFinder,
  setDisplayMobileGroupsTable,
  setGroupPaginatedPage,
  setGroupQuery,
  setLastOpenedGroup,
  setOpenBulkDeleteGroupModal,
  setOpenDeleteGroupModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Button } from '../button.component';
import { PaginationComponent } from '../data-table-pagination.component';
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from '../data-table.component';
import { Icon } from '../icon.component';
import { Select } from '../select.component';
import { Tooltip } from '../tooltip.component';
import { UserAvatar } from '../user-avatar.component';
import { findUpper } from '../utils';
import { bulkOperationOptions } from './constants';
import { GroupsTablePaginator } from './groups-table-paginator.component';
import { useParams, Link } from '@oproma/router';
import { BulkDeleteGroupsModal } from './modals';
import { useTranslation } from 'react-i18next';

export const GroupsTable = () => {
  const param = useParams();
  const { t } = useTranslation();
  const workspaceId = param.workspaceId as string;
  const dispatch = useAppDispatch();
  const { id: userId } = useAppSelector((state) => state.user);
  const [bulkOperationOption, setBulkOperationOption] = useState<string>(
    bulkOperationOptions[0].value,
  );
  const {
    groups,
    displayGroupFinder,
    displayEditGroupModal,
    displayDeleteGroupModal,
    displayMobileGroupsTable,
    groupSortOrder,
    groupQuery,
    permissionLevelFilter,
    currentPaginatedPage,
    entriesPerPaginatedPage,
  } = useAppSelector((state) => state.groups);
  // local state for managing groups operations
  const [localGroups, setLocalGroups] = useState(groups);

  // Get current list, pagination
  const indexOfLastEntry = currentPaginatedPage * entriesPerPaginatedPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPaginatedPage;
  const paginatedGroupEntries = localGroups?.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const [selectedGroupsForDeletion, setSelectedGroupsForDeletion] =
    useState<GroupRow[]>();

  useEffect(() => {
    // Reflecting changes to groups in local state
    setLocalGroups(groups);
  }, [groups]);

  useEffect(() => {
    // Sorting groups
    if (groupSortOrder === 'asc' || groupSortOrder === 'desc') {
      const sortedGroups = [...(localGroups ?? [])].sort((a, b) => {
        if (!a.name || !b.name) return 0;

        return groupSortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      });

      setLocalGroups(sortedGroups);
    }
  }, [groupSortOrder]);

  useEffect(() => {
    // Query groups
    if (groupQuery !== '') {
      const queriedGroups = [...groups].filter((group) => {
        return group.name?.toLowerCase().includes(groupQuery.toLowerCase());
      });
      setLocalGroups(queriedGroups);
    } else {
      setLocalGroups(groups);
    }
  }, [groupQuery, groups]);

  useEffect(() => {
    // Filter groups by permission level
    if (permissionLevelFilter) {
      const filteredGroups = [...groups].filter(
        (group) => group.isDefaultGroup === permissionLevelFilter,
      );
      setLocalGroups(filteredGroups);
    } else {
      setLocalGroups(groups);
    }
  }, [groups, permissionLevelFilter]);

  const getSelectedGroups = () => {
    return localGroups.filter((member) => member.checked);
  };

  const selectedGroups = getSelectedGroups();

  const onBulkOperationOptionClick = () => {
    if (bulkOperationOption === 'delete') {
      if (selectedGroups.length > 1) {
        onBulkDeleteGroup(selectedGroups);
      } else if (selectedGroups.length === 1) {
        const groupId = selectedGroups[0].id;
        onDeleteGroup(groupId as string);
      }
    }
  };

  const onBulkDeleteGroup = (selectedGroups: GroupRow[]) => {
    // Delete multiple groups
    dispatch(setOpenBulkDeleteGroupModal(true));
    setSelectedGroupsForDeletion(selectedGroups);
  };

  // function to toggle every group's checked property
  const onCheckEveryGroup = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = localGroups.map((group) => ({
      ...group,
      checked: e.currentTarget.checked,
    }));
    setLocalGroups(checked);
  };

  // function to change the checked property of a group
  const onCheckGroup = (e: ChangeEvent<HTMLInputElement>, groupId: string) => {
    const groups = [...localGroups];
    const index = groups.findIndex((group) => group.id === groupId);
    groups[index] = { ...groups[index], checked: e.currentTarget.checked };
    setLocalGroups(groups);
  };

  const toggleGroupFinder = () => {
    dispatch(setDisplayGroupFinder(!displayGroupFinder));
  };

  const toggleMobileGroupsTable = () => {
    dispatch(setDisplayMobileGroupsTable(!displayMobileGroupsTable));
  };

  // function for when edit button is clicked on row
  const onEditGroup = (groupId: string | null) => {
    dispatch(setLastOpenedGroup(groupId));
    dispatch(setDisplayEditGroupModal(!displayEditGroupModal));
  };

  const handleRowClick = (groupId: string) => {
    dispatch(getGroupMembers(groupId));
  };

  const handleDefaultClick = (body: string) => {
    dispatch(editDefaultGroup({ workspaceId, body }));
  };

  const onDeleteGroup = (groupId: string | null) => {
    dispatch(setLastOpenedGroup(groupId));
    dispatch(setOpenDeleteGroupModal(!displayDeleteGroupModal));
  };

  // Change Page
  const paginate = (page: number) => dispatch(setGroupPaginatedPage(page));

  return (
    <DataTable className="card-stretch">
      <div className="card-inner position-relative card-tools-toggle">
        <div className="card-title-group">
          <div className="card-tools">
            <div className="form-inline gx-3 flex-nowrap">
              <div className="form-wrap">
                <Select
                  options={bulkOperationOptions}
                  className="w-130px"
                  placeholder={t('GROUPS.BULK_DELETE.PLACEHOLDER')}
                  isSearchable={false}
                  onChange={(e) =>
                    setBulkOperationOption(
                      e?.value ?? bulkOperationOptions[0].value,
                    )
                  }
                />
              </div>
              <div className="btn-wrap">
                <span className="d-none d-md-block">
                  <Button
                    disabled={!bulkOperationOption}
                    color="light"
                    outline
                    className="btn-dim"
                    onClick={onBulkOperationOptionClick}
                  >
                    {t('GROUPS.BULK_DELETE.CONFIRM')}
                  </Button>
                </span>
                <span className="d-md-none">
                  <Button
                    color="light"
                    outline
                    disabled={!bulkOperationOption}
                    className="btn-dim btn-icon"
                    onClick={onBulkOperationOptionClick}
                  >
                    <Icon name="arrow-right"></Icon>
                  </Button>
                </span>
              </div>
            </div>
          </div>
          <div className="card-tools me-n1">
            <ul className="btn-toolbar gx-1">
              <li>
                <a
                  href="#search"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleGroupFinder();
                  }}
                  className="btn btn-icon search-toggle toggle-search"
                >
                  <Icon name="search"></Icon>
                </a>
              </li>
              <li className="btn-toolbar-sep"></li>
              <li>
                <div className="toggle-wrap">
                  <Button
                    className={classNames('btn-icon btn-trigger toggle', {
                      active: displayMobileGroupsTable,
                    })}
                    onClick={toggleMobileGroupsTable}
                  >
                    <Icon name="menu-right"></Icon>
                  </Button>
                  <div
                    className={classNames('toggle-content', {
                      'content-active': displayMobileGroupsTable,
                    })}
                  >
                    <ul className="btn-toolbar gx-1">
                      <li className="toggle-close">
                        <Button
                          className="btn-icon btn-trigger toggle"
                          onClick={toggleMobileGroupsTable}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                      </li>
                      <BulkDeleteGroupsModal
                        selectedGroups={selectedGroupsForDeletion}
                      />

                      <li>
                        <GroupsTablePaginator />
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={classNames('card-search search-wrap', {
            active: displayGroupFinder,
          })}
        >
          <div className="card-body">
            <div className="search-content">
              <Button
                className="search-back btn-icon toggle-search active"
                onClick={() => {
                  dispatch(setGroupQuery(''));
                  toggleGroupFinder();
                }}
              >
                <Icon name="arrow-left"></Icon>
              </Button>
              <input
                type="text"
                className="form-focus-none form-control border-transparent"
                placeholder={t('GROUPS.PLACEHOLDER')}
                value={groupQuery}
                onChange={(e) => dispatch(setGroupQuery(e.target.value))}
              />
              <Button className="search-submit btn-icon">
                <Icon name="search"></Icon>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <DataTableBody>
        <DataTableHead>
          <DataTableRow className="nk-tb-col-check">
            <div className="custom-control custom-control-sm custom-checkbox notext">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={(e) => onCheckEveryGroup(e)}
                id="uid"
              />
              <label className="custom-control-label" htmlFor="uid"></label>
            </div>
          </DataTableRow>
          <DataTableRow>
            <span className="sub-text">{t('GROUPS.GROUP_TABLE.TITLE')}</span>
          </DataTableRow>
          <DataTableRow size="mb">
            <span className="sub-text">{t('GROUPS.GROUP_TABLE.MEMBERS')}</span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">{t('GROUPS.GROUP_TABLE.TYPE')}</span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text">{t('GROUPS.GROUP_TABLE.OPTIONS')}</span>
          </DataTableRow>
        </DataTableHead>
        {paginatedGroupEntries.map((groupEntry: GroupRow, index: number) => {
          return (
            <DataTableItem
              key={groupEntry.id ?? index}
              onClick={() => {
                handleRowClick(groupEntry.id as string);
              }}
            >
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={groupEntry.checked}
                    id={groupEntry.id}
                    onChange={(e) =>
                      onCheckGroup(e, groupEntry.id ?? index.toString())
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={groupEntry.id}
                  />
                </div>
              </DataTableRow>
              <DataTableRow>
                <Link to={`/${workspaceId}/groups/${groupEntry.id}`}>
                  <div className="user-card">
                    <UserAvatar
                      theme={groupEntry.thumbnailBackground}
                      text={findUpper(groupEntry.name)}
                      image={groupEntry.thumbnail}
                    ></UserAvatar>
                    <div className="user-info">
                      <span className="tb-lead">{groupEntry.name} </span>
                    </div>
                  </div>
                </Link>
              </DataTableRow>
              <DataTableRow size="md">
                <span>{groupEntry.memberCount}</span>
              </DataTableRow>
              <DataTableRow size="mb">
                <span className="tb-amount">
                  {groupEntry.isDefaultGroup
                    ? `${t('GROUPS.GROUP_TABLE.DEFAULT')}`
                    : `${t('GROUPS.GROUP_TABLE.CUSTOM')}`}
                </span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools">
                {groupEntry.id !== userId && (
                  <ul className="nk-tb-actions gx-1">
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        onEditGroup(groupEntry.id ?? null);
                        dispatch(setLastOpenedGroup(groupEntry.id as string));
                      }}
                    >
                      <Tooltip
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={
                          'edit_' +
                          (groupEntry.id ? groupEntry.id : index.toString())
                        }
                        icon="edit-alt-fill"
                        direction="top"
                        text={t('GROUPS.GROUP_TABLE.EDIT')}
                      />
                    </li>
                    {groupEntry.isDefaultGroup !== true && (
                      <li
                        className="nk-tb-action-hidden"
                        onClick={() => onDeleteGroup(groupEntry.id ?? null)}
                      >
                        <Tooltip
                          tag="a"
                          containerClassName="btn btn-trigger btn-icon"
                          id={
                            'remove_' +
                            (groupEntry.id ? groupEntry.id : index.toString())
                          }
                          icon="trash-fill"
                          direction="top"
                          text={t('GROUPS.GROUP_TABLE.REMOVE')}
                        />
                      </li>
                    )}
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-icon btn-trigger"
                        >
                          <Icon name="more-h"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li
                              onClick={() => onEditGroup(groupEntry.id ?? null)}
                            >
                              <DropdownItem
                                tag="a"
                                href="#edit"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit"></Icon>
                                <span>{t('GROUPS.GROUP_TABLE.EDIT')}</span>
                              </DropdownItem>
                            </li>
                            {groupEntry.isDefaultGroup !== true && (
                              <>
                                <li className="divider"></li>
                                <li
                                  onClick={() =>
                                    onDeleteGroup(groupEntry.id ?? null)
                                  }
                                >
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="na"></Icon>
                                    <span>
                                      {t('GROUPS.GROUP_TABLE.REMOVE')}
                                    </span>
                                  </DropdownItem>
                                </li>
                              </>
                            )}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                )}
              </DataTableRow>
            </DataTableItem>
          );
        })}
      </DataTableBody>

      <div className="card-inner">
        {paginatedGroupEntries.length > 0 ? (
          <PaginationComponent
            itemPerPage={entriesPerPaginatedPage}
            totalItems={paginatedGroupEntries.length}
            paginate={paginate}
            currentPage={currentPaginatedPage}
          />
        ) : (
          <div className="text-center">
            <span className="text-silent">
              {' '}
              {t('GROUPS.GROUP_TABLE.NO_GROUP')}
            </span>
          </div>
        )}
      </div>
    </DataTable>
  );
};
