import { Button } from '../button.component';
import { Icon } from '../icon.component';
import { MembersTableFilter } from './members-table-filter.component';
import { MembersTablePaginator } from './members-table-paginator.component';
import { MembersTableSort } from './members-table-sort.component';
import {
  setDisplayMemberFinder,
  setDisplayMobileMembersTable,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
const MobileMembersTableToggle = () => {
  const dispatch = useAppDispatch();
  const { displayMobileMembersTable, displayMemberFinder } = useAppSelector(
    (state) => state.members,
  );

  const toggleMemberFinder = () => {
    dispatch(setDisplayMemberFinder(!displayMemberFinder));
  };

  const toggleMobileMembersTable = () => {
    dispatch(setDisplayMobileMembersTable(!displayMobileMembersTable));
  };

  return (
    <div className="card-tools me-n1">
      <ul className="btn-toolbar gx-1">
        <li>
          <a
            href="#search"
            onClick={(ev) => {
              ev.preventDefault();
              toggleMemberFinder();
            }}
            className="btn btn-icon search-toggle toggle-search"
          >
            <Icon name="search"></Icon>
          </a>
        </li>
        <li className="btn-toolbar-sep"></li>
        <li>
          <div className="toggle-wrap">
            <Button
              className={clsx('btn-icon btn-trigger toggle', {
                active: displayMobileMembersTable,
              })}
              onClick={toggleMobileMembersTable}
            >
              <Icon name="menu-right"></Icon>
            </Button>
            <div
              className={clsx('toggle-content', {
                'content-active': displayMobileMembersTable,
              })}
            >
              <ul className="btn-toolbar gx-1">
                <li className="toggle-close">
                  <Button
                    className="btn-icon btn-trigger toggle"
                    onClick={toggleMobileMembersTable}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </li>
                <li>
                  <MembersTableSort />
                </li>
                <li>
                  <MembersTableFilter />
                </li>
                <li>
                  <MembersTablePaginator />
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default MobileMembersTableToggle;
