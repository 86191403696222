import {
  SortGroupOrder,
  setGroupEntriesPerPaginatedPage,
  setGroupSortOrder,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Icon } from '../icon.component';
import { useTranslation } from 'react-i18next';

export const GroupsTablePaginator = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { groupSortOrder, entriesPerPaginatedPage } = useAppSelector(
    (state) => state.groups,
  );

  const handleEntriesPerPage = (value: number) => {
    dispatch(setGroupEntriesPerPaginatedPage(value));
  };

  const handleSort = (value: SortGroupOrder) => {
    dispatch(setGroupSortOrder(value));
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        color="tranparent"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <Icon name="setting"></Icon>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xs">
        <ul className="link-check">
          <li>
            <span>{t('GROUPS.PAGINATOR.DISPLAY')}</span>
          </li>
          <li className={clsx({ active: entriesPerPaginatedPage === 10 })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleEntriesPerPage(10);
              }}
            >
              10
            </DropdownItem>
          </li>
          <li className={clsx({ active: entriesPerPaginatedPage === 15 })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleEntriesPerPage(15);
              }}
            >
              15
            </DropdownItem>
          </li>
        </ul>
        <ul className="link-check">
          <li>
            <span>{t('GROUPS.PAGINATOR.ORDER')}</span>
          </li>
          <li className={clsx({ active: groupSortOrder === 'asc' })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleSort('asc');
                // sortFunc('asc');
              }}
            >
              ASC
            </DropdownItem>
          </li>
          <li className={clsx({ active: groupSortOrder === 'desc' })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleSort('desc');
                // sortFunc('dsc');
              }}
            >
              DESC
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
