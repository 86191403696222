/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApplicationOptions
 */
export interface ApplicationOptions {
  /**
   *
   * @type {boolean}
   * @memberof ApplicationOptions
   */
  allowProjectCreation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationOptions
   */
  allowAccountCreation?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  stripePubKey?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  grecaptchaKey?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  mapKey?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationOptions
   */
  siteId?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationOptions
   */
  sessionTimeout?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  validatePasswordScript?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  validatePasswordTemplate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  passwordValidationAPI?: string;
}

/**
 * Check if a given object implements the ApplicationOptions interface.
 */
export function instanceOfApplicationOptions(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ApplicationOptionsFromJSON(json: any): ApplicationOptions {
  return ApplicationOptionsFromJSONTyped(json, false);
}

export function ApplicationOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApplicationOptions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    allowProjectCreation: !exists(json, 'allowProjectCreation')
      ? undefined
      : json['allowProjectCreation'],
    allowAccountCreation: !exists(json, 'allowAccountCreation')
      ? undefined
      : json['allowAccountCreation'],
    stripePubKey: !exists(json, 'stripePubKey')
      ? undefined
      : json['stripePubKey'],
    grecaptchaKey: !exists(json, 'grecaptchaKey')
      ? undefined
      : json['grecaptchaKey'],
    mapKey: !exists(json, 'mapKey') ? undefined : json['mapKey'],
    siteId: !exists(json, 'siteId') ? undefined : json['siteId'],
    sessionTimeout: !exists(json, 'sessionTimeout')
      ? undefined
      : json['sessionTimeout'],
    validatePasswordScript: !exists(json, 'validatePasswordScript')
      ? undefined
      : json['validatePasswordScript'],
    validatePasswordTemplate: !exists(json, 'validatePasswordTemplate')
      ? undefined
      : json['validatePasswordTemplate'],
    passwordValidationAPI: !exists(json, 'passwordValidationAPI')
      ? undefined
      : json['passwordValidationAPI'],
  };
}

export function ApplicationOptionsToJSON(
  value?: ApplicationOptions | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    allowProjectCreation: value.allowProjectCreation,
    allowAccountCreation: value.allowAccountCreation,
    stripePubKey: value.stripePubKey,
    grecaptchaKey: value.grecaptchaKey,
    mapKey: value.mapKey,
    siteId: value.siteId,
    sessionTimeout: value.sessionTimeout,
    validatePasswordScript: value.validatePasswordScript,
    validatePasswordTemplate: value.validatePasswordTemplate,
    passwordValidationAPI: value.passwordValidationAPI,
  };
}
