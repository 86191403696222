import { yupResolver } from '@hookform/resolvers/yup';
import {
  createColumn,
  toggleDisplayCreateColumnModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

export const themes = [
  { value: 'primary', label: 'Primary' },
  { value: 'secondary', label: 'Secondary' },
  { value: 'info', label: 'Info' },
  { value: 'danger', label: 'Danger' },
  { value: 'warning', label: 'Warning' },
  { value: 'success', label: 'Success' },
  { value: 'dark', label: 'Dark' },
  { value: 'light', label: 'Light' },
];

const createTaskColumnSchema = yup
  .object({
    name: yup.string().required(),
    colour: yup.string().required(),
  })
  .required();

type CreateTaskColumnFormValues = yup.InferType<typeof createTaskColumnSchema>;

export const useCreateTaskColumnForm = () => {
  const dispatch = useAppDispatch();
  const { displayCreateColumnModal, columnOrder } = useAppSelector(
    (state) => state.tasks,
  );
  const {
    reset,
    setValue,
    handleSubmit,
    register: registerField,
    formState: { errors },
  } = useForm<CreateTaskColumnFormValues>({
    resolver: yupResolver(createTaskColumnSchema),
  });

  const { ref: nameRef, ...registerName } = registerField('name');
  const { ref: colourRef, ...registerColour } = registerField('colour');

  const onFormSubmit: SubmitHandler<CreateTaskColumnFormValues> = ({
    name,
    colour,
  }) => {
    console.log(name, colour);
    dispatch(
      createColumn({
        id: uuidv4(),
        name: name,
        theme: colour,
        order: columnOrder.length.toString(),
        tasks: [],
      }),
    );
    toggle();
  };

  const toggle = () => {
    dispatch(toggleDisplayCreateColumnModal());
    reset();
  };

  return {
    themes,
    errors,
    toggle,
    nameRef,
    setValue,
    colourRef,
    handleSubmit,
    onFormSubmit,
    registerName,
    registerColour,
    displayCreateColumnModal,
  };
};
