import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDashboardState, SortOrder } from './types';

const initialState: IDashboardState = {
  dashboardQuery: '',
  dashboardSortOrder: 'asc',
  displayMobileDashboardTable: false,
  displayDashboardFinder: false,
  currentPaginatedPage: 1,
  entriesPerPaginatedPage: 10,
};

const dashboardSlice = createSlice({
  name: '@@dashboard',
  initialState,
  reducers: {
    setDashboardQuery: (state, action: PayloadAction<string>) => {
      state.dashboardQuery = action.payload;
    },
    setDashboardSortOrder: (state, action: PayloadAction<SortOrder>) => {
      state.dashboardSortOrder = action.payload;
    },
    setDashboardEntriesPerPaginatedPage: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.entriesPerPaginatedPage = action.payload;
    },
    setDashboardPaginatedPage: (state, action: PayloadAction<number>) => {
      state.currentPaginatedPage = action.payload;
    },
    setDisplayMobileDashboardTable: (state, action: PayloadAction<boolean>) => {
      state.displayMobileDashboardTable = action.payload;
    },
    setDisplayDashboardFinder: (state, action: PayloadAction<boolean>) => {
      state.displayDashboardFinder = action.payload;
    },
  },
});

export const {
  setDashboardQuery,
  setDashboardSortOrder,
  setDashboardEntriesPerPaginatedPage,
  setDisplayDashboardFinder,
  setDashboardPaginatedPage,
  setDisplayMobileDashboardTable,
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;
