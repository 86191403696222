/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CardInfo
 */
export interface CardInfo {
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  brand?: string;
  /**
   *
   * @type {number}
   * @memberof CardInfo
   */
  expMonth?: number;
  /**
   *
   * @type {number}
   * @memberof CardInfo
   */
  expYear?: number;
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  last4?: string;
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  cardToken?: string;
  /**
   *
   * @type {boolean}
   * @memberof CardInfo
   */
  def?: boolean;
}

/**
 * Check if a given object implements the CardInfo interface.
 */
export function instanceOfCardInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CardInfoFromJSON(json: any): CardInfo {
  return CardInfoFromJSONTyped(json, false);
}

export function CardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CardInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    brand: !exists(json, 'brand') ? undefined : json['brand'],
    expMonth: !exists(json, 'exp_month') ? undefined : json['exp_month'],
    expYear: !exists(json, 'exp_year') ? undefined : json['exp_year'],
    last4: !exists(json, 'last4') ? undefined : json['last4'],
    cardToken: !exists(json, 'cardToken') ? undefined : json['cardToken'],
    def: !exists(json, 'def') ? undefined : json['def'],
  };
}

export function CardInfoToJSON(value?: CardInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    brand: value.brand,
    exp_month: value.expMonth,
    exp_year: value.expYear,
    last4: value.last4,
    cardToken: value.cardToken,
    def: value.def,
  };
}
