/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DirectPermissionResult
 */
export interface DirectPermissionResult {
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  permission?: DirectPermissionResultPermissionEnum;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  effective?: DirectPermissionResultEffectiveEnum;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  source?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DirectPermissionResultPermissionEnum {
  INHERITED = 'INHERITED',
  NO_ACCESS = 'NO_ACCESS',
  VIEW = 'VIEW',
  MODIFY = 'MODIFY',
  FULL_CONTROL = 'FULL_CONTROL',
}
/**
 * @export
 * @enum {string}
 */
export enum DirectPermissionResultEffectiveEnum {
  INHERITED = 'INHERITED',
  NO_ACCESS = 'NO_ACCESS',
  VIEW = 'VIEW',
  MODIFY = 'MODIFY',
  FULL_CONTROL = 'FULL_CONTROL',
}

/**
 * Check if a given object implements the DirectPermissionResult interface.
 */
export function instanceOfDirectPermissionResult(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function DirectPermissionResultFromJSON(
  json: any,
): DirectPermissionResult {
  return DirectPermissionResultFromJSONTyped(json, false);
}

export function DirectPermissionResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DirectPermissionResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    permission: !exists(json, 'permission') ? undefined : json['permission'],
    name: !exists(json, 'name') ? undefined : json['name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    effective: !exists(json, 'effective') ? undefined : json['effective'],
    source: !exists(json, 'source') ? undefined : json['source'],
  };
}

export function DirectPermissionResultToJSON(
  value?: DirectPermissionResult | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entityId: value.entityId,
    permission: value.permission,
    name: value.name,
    email: value.email,
    effective: value.effective,
    source: value.source,
  };
}
