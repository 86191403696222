import { yupResolver } from '@hookform/resolvers/yup';
import {
  requireMFA,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Card, Form } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useWizard } from '../hooks';
import { Icon } from '../icon.component';

const configureAutheticatorFormSchema = yup
  .object({
    downloadedCodes: yup
      .bool()
      .required('Please download the recovery codes to proceed.'),
  })
  .required();

type ConfigureAutheticatorFormValues = yup.InferType<
  typeof configureAutheticatorFormSchema
>;

export const MFARecoveryCodes = () => {
  const { next, prev } = useWizard();
  const dispatch = useAppDispatch();
  const { mfa, loading } = useAppSelector((state) => state.user);
  const codes = mfa?.codes;

  const handleDownloadCodes = () => {
    if (codes) {
      setValue('downloadedCodes', true);
      const element = document.createElement('a');
      const file = new Blob([[...codes].join('\n')], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'prividox_mfa_recovery_codes.txt';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfigureAutheticatorFormValues>({
    resolver: yupResolver(configureAutheticatorFormSchema),
    defaultValues: {
      downloadedCodes: false,
    },
  });

  const onFormSubmit: SubmitHandler<ConfigureAutheticatorFormValues> = async ({
    downloadedCodes,
  }) => {
    if (downloadedCodes) {
      dispatch(requireMFA());
      next();
    }
  };

  const navigateBack = () => prev();

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} className="gy-3">
      <div className="p-2">
        <h5 className="title">Download Recovery Codes</h5>
        <div className="mt-4">
          <p>
            Download your recovery codes below and store them in a secure
            location. A recovery code will be required in the event you need to
            reset your password or if you lose access to your authenticator
            application. A recovery code can only be used once.
          </p>
        </div>
      </div>
      <Row className="g-3">
        {errors.downloadedCodes && (
          <Alert className="alert-icon" color="danger">
            <Icon name="cross-circle" />
            {errors.downloadedCodes.message}
          </Alert>
        )}
        <Card className={`card-bordered pricing`}>
          <div className="pricing-head">
            <div className="pricing-title">
              <h4 className="card-title title">Recovery Codes</h4>
            </div>
          </div>
          <div className="pricing-body">
            <ul className="pricing-features">
              {codes &&
                Array.from(codes).map((code, index) => (
                  <li key={index}>
                    <span className="w-full">{code}</span>
                  </li>
                ))}
            </ul>
            <div className="pricing-action">
              <Button color="light" outline onClick={handleDownloadCodes}>
                Download
              </Button>
            </div>
          </div>
        </Card>
      </Row>
      <Row className="g-3">
        <Col lg="5">
          <div className="form-group mt-2">
            <Button
              color="primary"
              outline
              className="btn-block"
              size="md"
              onClick={navigateBack}
            >
              Back
            </Button>
          </div>
        </Col>

        <Col lg="7">
          <div className="form-group mt-2">
            <Button
              color="primary"
              className="btn-block"
              size="md"
              type="submit"
              disabled={loading}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
