import {
  DeleteMetadataRequest,
  ValueSpec,
} from '@oproma/prividox-orchestration-open-api';
import { metadataApi } from './config';

export const fetchMetadataKey = <T>(entity: string, key: string) => {
  return metadataApi.getMetadataKey({
    entity,
    key,
  }) as Promise<T>;
};

export const fetchMetadata = <T>(entity: string) => {
  return metadataApi.getEntityMetadata({
    entity,
  }) as Promise<T>;
};

const patchEntityMetadata = async (
  entity: string,
  key: string,
  value: string,
) => {
  const response = await metadataApi.setMetadataRaw({
    entity,
    key,
    valueSpec: {
      value,
    },
  });
  return response.raw.ok;
};

const patchUserPhoneNumber = async (userId: string, phoneNumber: string) => {
  const response = await metadataApi.setMetadataRaw({
    entity: userId,
    key: 'phone',
    valueSpec: {
      value: phoneNumber,
    },
  });
  return response.raw.ok;
};

const patchTagline = async (userId: string, tagline: string) => {
  const response = await metadataApi.setMetadataRaw({
    entity: userId,
    key: 'tagline',
    valueSpec: {
      value: tagline,
    },
  });
  return response.raw.ok;
};

const patchSystemLanguage = async (userId: string, language: string) => {
  const response = await metadataApi.setMetadataRaw({
    entity: userId,
    key: 'lang',
    valueSpec: {
      value: language,
    },
  });
  return response.raw.ok;
};

const patchSystemDate = async (userId: string, date: string) => {
  const response = await metadataApi.setMetadataRaw({
    entity: userId,
    key: 'app.settings.dateFormat',
    valueSpec: {
      value: date,
    },
  });
  return response.raw.ok;
};

const patchSystemTime = async (userId: string, time: string) => {
  const response = await metadataApi.setMetadataRaw({
    entity: userId,
    key: 'app.settings.timeFormat',
    valueSpec: {
      value: time,
    },
  });
  return response.raw.ok;
};

const patchUserAddress = async (userId: string, address: string) => {
  const response = await metadataApi.setMetadataRaw({
    entity: userId,
    key: 'address',
    valueSpec: {
      value: address,
    },
  });
  return response.raw.ok;
};

const deleteMetadata = async (payload: DeleteMetadataRequest) => {
  const response = await metadataApi.deleteMetadataRaw(payload);
  return response.raw.ok;
};

const patchDashboardMetadata = async (
  entity: string,
  key: string,
  value: string,
) => {
  // Fetch the current dashboard values
  const response = await metadataApi.getMetadataKey({
    entity,
    key: 'dashboard',
  });
  const currentValue = response.value;

  let parsedValue = [];
  if (currentValue) {
    try {
      parsedValue = JSON.parse(currentValue);
    } catch (e) {
      throw new Error('Failed to parse current metadata value');
    }
  }

  let keyExists = false;

  parsedValue = parsedValue.map((section: any[]) => {
    return section.map((item: any) => {
      if (item.name === key) {
        keyExists = true;
        return { ...item, values: [value] };
      }
      return item;
    });
  });

  if (!keyExists) {
    parsedValue.push([{ name: key, values: [value] }]);
  }

  // Re-stringify the updated value
  const valueSpec: ValueSpec = {
    value: JSON.stringify(parsedValue),
  };

  // Send the updated value back to the API
  await metadataApi.setMetadata({
    entity,
    key: 'dashboard',
    valueSpec: valueSpec,
  });

  return value;
};

export const metadataService = {
  patchTagline,
  fetchMetadataKey,
  fetchMetadata,
  deleteMetadata,
  patchSystemDate,
  patchSystemTime,
  patchUserAddress,
  patchEntityMetadata,
  patchSystemLanguage,
  patchUserPhoneNumber,
  patchDashboardMetadata,
};
