import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  editWorkspaceName,
  getWorkspacePlan,
  toggleDisplayBundlesModal,
  toggleDisplayChangePlanModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form, FormGroup, Input } from 'reactstrap';
import * as yup from 'yup';
import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../../block.component';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { Loader } from '../../loader.component';
import { CreditCards, ManagePlans, PaymentHistory } from '../../plans';

const workspaceOptionsSchema = yup
  .object({
    workspaceName: yup.string().required(),
  })
  .required();

type WorkspaceOptionsFormValues = yup.InferType<typeof workspaceOptionsSchema>;

export const WorkspaceOptionsGeneralTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const activePlan = useAppSelector((state) => state.workspace.plan !== null);
  const { metadata: workspaceMetadata, loading } = useAppSelector(
    (state) => state.workspace,
  );

  const {
    register: registerField,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkspaceOptionsFormValues>({
    resolver: yupResolver(workspaceOptionsSchema),
    defaultValues: {
      workspaceName: workspaceMetadata?.name,
    },
  });

  useEffect(() => {
    if (workspaceMetadata?.name) {
      setValue('workspaceName', workspaceMetadata?.name);
    }
  }, [workspaceMetadata]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(getWorkspacePlan(workspaceId));
    }
  }, [workspaceId]);

  const { ref: nameRef, ...registerName } = registerField('workspaceName');

  const onFormSubmit: SubmitHandler<WorkspaceOptionsFormValues> = ({
    workspaceName,
  }) => {
    dispatch(
      editWorkspaceName({
        workspaceId: workspaceId as string,
        workspaceName,
      }),
    );
  };

  if (loading) return <Loader />;

  const toggleBundles = () => {
    dispatch(toggleDisplayBundlesModal());
  };

  const toggleChangePlan = () => {
    dispatch(toggleDisplayChangePlanModal());
  };

  return (
    <>
      {/*  General Options */}
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h6">
              {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.1.HEADING')}
            </BlockTitle>
            <BlockDes>
              <p>{t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.1.DESCRIPTION')}</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <BlockContent>
        <div className="gy-3">
          <div className="g-item">
            <Form className="row" onSubmit={handleSubmit(onFormSubmit)}>
              <Col md="12">
                <FormGroup>
                  <label className="form-label">
                    {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.1.FORM.NAME')}
                  </label>
                  <Input
                    type="text"
                    innerRef={nameRef}
                    {...registerName}
                    defaultValue={workspaceMetadata?.name}
                  />
                  {errors.workspaceName && (
                    <span className="invalid">
                      {errors.workspaceName.message as string}
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Button
                    // className="btn-block"
                    type="submit"
                    color="primary"
                  >
                    {t('COMMON.CONFIRM')}
                  </Button>
                </FormGroup>
              </Col>
            </Form>
          </div>
        </div>
      </BlockContent>
      {/* End of General Options */}

      {/* Plan Options */}
      {activePlan && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h6">
                  {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.HEADING')}
                </BlockTitle>
                <BlockDes>
                  <p>{t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.DESCRIPTION')}</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <ul className="nk-block-tools gx-3">
                  <li className="order-md-last">
                    <button
                      className="btn btn-primary"
                      onClick={toggleChangePlan}
                    >
                      <span>
                        {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.CHANGE_PLAN')}
                      </span>
                    </button>
                  </li>
                  <li className="order-md-last">
                    <button
                      className="btn btn-white btn-dim btn-outline-primary"
                      onClick={toggleBundles}
                    >
                      <span>
                        {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.BUNDLES')}
                      </span>
                    </button>
                  </li>
                </ul>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <BlockContent>
            <ManagePlans />
            <PaymentHistory />
            <CreditCards />
          </BlockContent>
        </>
      )}
      {/* End of Plan Options */}
    </>
  );
};
