import { ActionFunction, LoaderFunction } from 'react-router-dom';

interface FallbackProps {
  fallback?: React.ReactNode;
}

interface RouteCommon {
  loader?: LoaderFunction;
  action?: ActionFunction;
  ErrorBoundary?: React.ComponentType<FallbackProps>;
}

interface Route extends RouteCommon {
  path: string;
  Element: React.ComponentType;
}

export interface FileBasedRoutes {
  [key: string]: {
    default: React.ComponentType;
  } & RouteCommon;
}

export const createRoutes = (fileRoutes: FileBasedRoutes) => {
  const routes: Route[] = [];
  const regex = new RegExp(/\.\/views\/(.*)\.tsx$/);
  let UniversalErrorBoundary;

  for (const path of Object.keys(fileRoutes)) {
    const fileName = path.match(regex)?.[1];

    if (fileName) {
      const routerCompatiblePathName = fileName
        .replace(/\[(.*?)\]/g, ':$1')
        .replace(/\/index/, '');

      if (fileName === '404') {
        UniversalErrorBoundary = fileRoutes[path].default;
        continue;
      }

      routes.push({
        path: fileName === 'index' ? '/' : `/${routerCompatiblePathName}`,
        Element: fileRoutes[path].default,
        loader: fileRoutes[path]?.loader as LoaderFunction | undefined,
        action: fileRoutes[path]?.action as ActionFunction | undefined,
        ErrorBoundary: fileRoutes[path]?.ErrorBoundary,
      });
    }
  }

  if (UniversalErrorBoundary) {
    routes.push({
      path: '*',
      Element: UniversalErrorBoundary,
    });
  }

  return routes.map(({ Element, ErrorBoundary, ...rest }) => ({
    ...rest,
    element: <Element />,
    ...(ErrorBoundary && { errorElement: <ErrorBoundary /> }),
  }));
};
