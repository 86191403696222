import { useTranslation } from '@oproma/i18n';
import {
  LanguageEnum,
  UserErrorCodesEnum,
  logout,
  toggleDisplayUserDropdownMenu,
  toggleLanguage,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link } from '@oproma/router';
import { Alert, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useProfile } from '../../hooks';
import { Icon } from '../../icon.component';
import { LinkItem, LinkList } from '../../links.component';
import { Loader } from '../../loader.component';
import { UserAvatar } from '../../user-avatar.component';

export const UserDropdown = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { language, displayUserDropdownMenu } = useAppSelector(
    (state) => state.layout,
  );
  const lang = language === LanguageEnum.EN ? 'fr-FR' : 'en-US';
  const { loading, error, name, email, initials } = useProfile();

  const toggle = () => dispatch(toggleDisplayUserDropdownMenu());

  const logoutUser = () => {
    dispatch(logout());
    toggle();
  };

  const changeLanguage = () => {
    dispatch(toggleLanguage());
    toggle();
    i18n.changeLanguage(lang);
  };

  if (loading) return <Loader />;

  if (error?.code === UserErrorCodesEnum.GET_USER_METADATA_FAILED)
    return (
      <Alert className="alert-icon" color="danger">
        <Icon name="cross-circle" />
        {error.message}
      </Alert>
    );

  return (
    <Dropdown
      isOpen={displayUserDropdownMenu}
      className="user-dropdown"
      toggle={toggle}
    >
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <UserAvatar icon="user-alt" className="sm" />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>{initials}</span>
            </div>
            <div className="user-info">
              <span className="lead-text">{name}</span>
              <span className="sub-text">{email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link="#"
              icon="globe"
              onClick={(ev) => {
                ev.preventDefault();
                changeLanguage();
              }}
            >
              {t('COMMON.USER_OPTIONS.CHANGE_LANGUAGE')}
            </LinkItem>
            <LinkItem
              link="/preferences/personal"
              icon="setting-alt"
              onClick={toggle}
            >
              {t('COMMON.OPTIONS')}
            </LinkItem>
            <LinkItem link="/docs" icon="file-docs" onClick={toggle}>
              {t('COMMON.USER_OPTIONS.DOCS')}
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <Link
              to="/auth/logout"
              onClick={(ev) => {
                ev.preventDefault();
                logoutUser();
              }}
            >
              <Icon name="signout"></Icon>
              <span>{t('COMMON.USER_OPTIONS.LOGOUT')}</span>
            </Link>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
