import { Link } from '@oproma/router';

const logoMedia = {
  src: '/prividox-logo.svg',
  width: 100,
  height: 100,
};

export const AuthLogo = () => {
  return (
    <div className="brand-logo pb-4 text-center">
      <Link to="/" className="logo-link">
        <img
          className="logo-light logo-img logo-img-lg"
          {...logoMedia}
          alt="logo"
        />
        <img
          className="logo-dark logo-img logo-img-lg"
          {...logoMedia}
          alt="logo-dark"
        />
      </Link>
    </div>
  );
};
