import { toggleDisplayMFAModel, useAppDispatch } from '@oproma/prividox-store';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';

export const MFACompletedBanner = () => {
  const dispatch = useAppDispatch();

  const toggleModal = () => {
    dispatch(toggleDisplayMFAModel());
  };
  return (
    <div>
      <div className="p-2">
        <h5 className="title">Completed</h5>
        <div className="mt-4">
          <p>
            Congratulations, you’ve successfully setup enhanced security. Click
            “Done” to close the wizard.
          </p>
        </div>
      </div>
      <Row>
        <Col></Col>
      </Row>
      <Row>
        <Col lg="7">
          <div className="form-group mt-2">
            <Button
              color="primary"
              className="btn-block"
              size="md"
              type="button"
              onClick={toggleModal}
            >
              Confirm
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
