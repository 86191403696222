import {
  Icon,
  Layout,
  PreferencesHeader,
  PreferencesLayout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getUserSessions,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect } from 'react';
import { Alert } from 'reactstrap';

const UserDevicesPreferencesPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sessions, error } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUserSessions());
  }, []);

  if (error)
    return (
      <Alert className="alert-icon" color="danger">
        <Icon name="cross-circle" />
        {error.message}
      </Alert>
    );

  return (
    <Layout>
      <PreferencesLayout type="devices">
        <PreferencesHeader type="devices" />
        {/* TODO: Move the logic to its own component  */}
        <table className="table-ulogs">
          <thead className="bg-lighter">
            <tr>
              <th className="tb-col-os">
                <span className="overline-title">
                  {t('PREFERENCES.DEVICES.BROWSER')}
                </span>
              </th>
              <th className="tb-col-ip">
                <span className="overline-title">
                  {t('PREFERENCES.DEVICES.USER_AGENT')}
                </span>
              </th>
              <th className="tb-col-time">
                <span className="overline-title">
                  {t('PREFERENCES.DEVICES.LOCATION')}
                </span>
              </th>
              <th className="tb-col-action">
                <span className="overline-title">&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(sessions).map((item, idx) => {
              return (
                <tr key={idx}>
                  <td className="tb-col-os">
                    {item.Browser} / {item.IP}
                  </td>
                  <td className="tb-col-ip">
                    <span className="sub-text">{item['User-Agent']}</span>
                  </td>
                  <td className="tb-col-time">
                    <span className="sub-text">{item.Location}</span>
                  </td>
                  <td className="tb-col-action">
                    <a
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="link-cross me-sm-n1"
                    >
                      <Icon name="cross"></Icon>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </PreferencesLayout>
    </Layout>
  );
};

export default UserDevicesPreferencesPage;
