/* eslint-disable react/jsx-no-useless-fragment */
import { useTranslation } from '@oproma/i18n';
import {
  authenticationService,
  getCreditCardCount,
  getCreditCards,
  getNotifications,
  getPricingPlans,
  getUserMetadata,
  getWorkspaces,
  refreshAuth,
  setExpiredAuth,
  setIsAuthenticated,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link, useLocation, useNavigate } from '@oproma/router';
import { ReactNode, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

type AuthCheckProps = {
  children: ReactNode;
  fallback?: ReactNode;
};

export const AuthCheck = ({ children, fallback }: AuthCheckProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { expiredAuth, isAuthenticated, uid } = useAppSelector(
    (state) => state.auth,
  );

  const checkAuthStatus = async () => {
    const toastId = 'session-expiration-toast';

    try {
      const timeLeft = await authenticationService.pingUser();
      if (timeLeft <= 60000 && !toast.isActive(toastId)) {
        toast.info(
          () => (
            <>
              <div style={{ marginBottom: '10px' }}>
                {t('AUTH.EXPIRY.TEXT')}
              </div>
              <Button
                size="sm"
                className="btn-primary"
                type="submit"
                color="primary"
                onClick={() => {
                  dispatch(refreshAuth());
                  toast.dismiss();
                }}
              >
                {t('AUTH.EXPIRY.BUTTON')}
              </Button>
            </>
          ),
          {
            toastId, // Use the unique ID
            autoClose: false,
          },
        );
      }
      if (timeLeft <= 0) {
        dispatch(setExpiredAuth(true));
        dispatch(setIsAuthenticated(false));
        toast.dismiss(toastId);
        return navigate(
          {
            pathname: '/auth/lock',
            search: `?from=${pathname.split('?')[0]}`,
          },
          {
            replace: true,
          },
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(setExpiredAuth(true));
      dispatch(setIsAuthenticated(false));
      toast.dismiss(toastId);
      return navigate(
        {
          pathname: '/auth/lock',
          search: `?from=${pathname.split('?')[0]}`,
        },
        {
          replace: true,
        },
      );
    }
  };

  useEffect(() => {
    if (!isAuthenticated && !expiredAuth) {
      navigate(
        {
          pathname: '/auth/login',
          search: `?from=${pathname.split('?')[0]}`,
        },
        {
          replace: true,
        },
      );
    } else {
      console.log('Dispatching user metadata and workspaces related actions.');
      dispatch(getWorkspaces());
      dispatch(getNotifications());
      dispatch(getUserMetadata(uid));
      dispatch(getPricingPlans());
      dispatch(getCreditCards());
      dispatch(getCreditCardCount());
      checkAuthStatus(); // Call it right when component mounts
      const intervalId = setInterval(checkAuthStatus, 600000); // Call every 10 minutes
      return () => clearInterval(intervalId); // Clear the interval when the component unmounts
    }
  }, [navigate, isAuthenticated, expiredAuth]);

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <>{fallback}</> || <Link to="/auth/login">{t('AUTH.FALLBACK')}</Link>
  );
};
