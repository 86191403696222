import { ProjectInformation } from '@oproma/prividox-orchestration-open-api';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { useWorkspaces } from './use-workspaces.hook';

// Fuzzy query hook for workspaces
export const useQueryWorkspaces = (query: string | undefined) => {
  const { workspaces, workspaceCount } = useWorkspaces();
  const [queryResults, setQueryResults] = useState<ProjectInformation[]>([]);
  useEffect(() => {
    const fuse = new Fuse(workspaces, {
      keys: ['id', 'name'],
      includeScore: true,
    });

    if (query) {
      setQueryResults(fuse.search(query).map((result) => result.item));
    } else {
      setQueryResults(workspaces);
    }
  }, [query, workspaces]);

  return { workspaces: queryResults, workspaceCount };
};
