/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Child } from './Child';
import { ChildFromJSON, ChildFromJSONTyped, ChildToJSON } from './Child';

/**
 *
 * @export
 * @interface ResultChild
 */
export interface ResultChild {
  /**
   *
   * @type {Array<Child>}
   * @memberof ResultChild
   */
  items?: Array<Child>;
}

/**
 * Check if a given object implements the ResultChild interface.
 */
export function instanceOfResultChild(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultChildFromJSON(json: any): ResultChild {
  return ResultChildFromJSONTyped(json, false);
}

export function ResultChildFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultChild {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(ChildFromJSON),
  };
}

export function ResultChildToJSON(value?: ResultChild | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(ChildToJSON),
  };
}
