import copy from 'copy-to-clipboard';
import React, { ReactElement, useCallback } from 'react';

interface ClipboardCopyProps {
  text: string;
  children: ReactElement;
  onCopy?: (text: string, result: boolean) => void;
  options?: {
    debug?: boolean;
    message?: string;
    format?: string;
  };
}

export const ClipboardCopy = ({
  text,
  children,
  onCopy,
  options,
}: ClipboardCopyProps) => {
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      const elem = React.Children.only(children) as ReactElement;
      const result = copy(text, options);

      if (onCopy) {
        onCopy(text, result);
      }

      // Bypass onClick if it was present
      if (elem.props.onClick) {
        elem.props.onClick(event);
      }
    },
    [text, children, onCopy, options],
  );

  const elem = React.Children.only(children) as ReactElement;

  return React.cloneElement(elem, { onClick });
};
