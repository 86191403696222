import {
  DeleteTaskRequest,
  UpdateTaskRequest,
} from '@oproma/prividox-orchestration-open-api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import { extractApiError, metadataService, tasksService } from '../api';
import {
  Column,
  CreateTaskPayload,
  EditTaskMetadataPayload,
  ITasksState,
  Task,
  TaskMetadata,
} from './types';

const initialState: ITasksState = {
  tasks: [],
  error: null,
  columns: [],
  loading: false,
  columnOrder: [],
  lastOpenedTask: null,
  lastOpenedColumn: null,
  displayEditTaskModal: false,
  displayCreateTaskModal: false,
  displayDeleteTaskModal: false,
  displayEditColumnModal: false,
  displayCreateColumnModal: false,
};

export const getTasks = createAsyncThunk(
  '@@tasks/getTasks',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const tasksResponse = await tasksService.fetchTasks(workspaceId);
      if (!tasksResponse.items) {
        return { tasks: [], columns: [], columnOrder: [] };
      }

      const columnsMap: Map<string, Column> = new Map();
      const columnOrderMap: Map<string, string> = new Map();
      const tasks: Task[] = [];

      // Default column values
      const DEFAULT_COLUMN_ID = 'default';
      const DEFAULT_COLUMN_NAME = 'Default';
      const DEFAULT_COLUMN_ORDER = '0';

      // Initialize the default column
      if (!columnsMap.has(DEFAULT_COLUMN_ID)) {
        columnsMap.set(DEFAULT_COLUMN_ID, {
          id: DEFAULT_COLUMN_ID,
          name: DEFAULT_COLUMN_NAME,
          order: DEFAULT_COLUMN_ORDER,
          theme: 'default',
          tasks: [],
        });
        columnOrderMap.set(DEFAULT_COLUMN_ID, DEFAULT_COLUMN_ORDER);
      }

      const tasksPromises = tasksResponse.items.map(async (task) => {
        if (!task.id) return null;

        const taskMetadata = await metadataService.fetchMetadata<TaskMetadata>(
          task.id,
        );

        const columnId = taskMetadata?.columnId || DEFAULT_COLUMN_ID;
        const columnName = taskMetadata?.columnName || DEFAULT_COLUMN_NAME;
        const columnOrder = taskMetadata?.columnOrder || DEFAULT_COLUMN_ORDER;

        const formattedExpiry = moment(task.expiry).format('LLL');
        const formattedCreated = moment(task.created).format('LLL');

        const taskPayload: Task = {
          id: task.id,
          name: task.title,
          description: task.description,
          column: taskMetadata.columnId,
          metadata: {
            users: [
              {
                value: task.assignee?.id,
                label: task.assignee?.name,
                theme: 'light',
              },
            ],
            tags: [
              {
                value: task.assigner?.id,
                label: `Creator: ${task.assigner?.name}`,
                theme: 'info',
              },
              {
                value: task.created?.toString(),
                label: `Created: ${formattedCreated}`,
                theme: 'primary',
              },
            ],
            date: formattedCreated,
            files: Array.from(task.attachments || []),
            due: formattedExpiry,
          },
        };

        tasks.push(taskPayload);

        if (!columnOrderMap.has(columnId)) {
          columnOrderMap.set(columnId, columnOrder);
        }

        // Construct columns
        if (!columnsMap.has(columnId)) {
          columnsMap.set(columnId, {
            id: columnId,
            name: columnName,
            theme: taskMetadata?.columnTheme || 'primary',
            order: columnOrder,
            tasks: [],
          });
        }
        const column = columnsMap.get(columnId);
        column?.tasks.push(task.id);

        // Track column order
        if (taskMetadata.columnId && taskMetadata.columnOrder) {
          columnOrderMap.set(taskMetadata.columnId, taskMetadata.columnOrder);
        }
        return taskPayload;
      });

      await Promise.all(tasksPromises.filter(Boolean));

      // Create columns array
      const columns = Array.from(columnsMap.values());

      // Create columnOrder array from the sorted map
      const columnOrder = Array.from(columnOrderMap)
        .sort((a, b) => Number(a[1]) - Number(b[1])) // Sort by column order
        .map((item) => item[0]); // Extract column IDs

      return { tasks, columns, columnOrder };
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const createTask = createAsyncThunk(
  '@@tasks/createTask',
  async (payload: CreateTaskPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await tasksService.createTask(payload);
      // TODO: Add translations for toast messages
      toast.success('Task creation completed');
      dispatch(getTasks(payload.workspaceId));
      return response;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editTask = createAsyncThunk(
  '@@tasks/editTask',
  async (payload: UpdateTaskRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await tasksService.patchTask(payload);
      if (response) {
        toast.success('Task has been edited');
        dispatch(getTasks(payload.workspaceId));
      }
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editTaskMetadata = createAsyncThunk(
  '@@tasks/editTaskMetadata',
  async (
    { taskId, workspaceId, ...payload }: EditTaskMetadataPayload,
    { dispatch, rejectWithValue },
  ) => {
    try {
      await tasksService.patchTaskMetadata(taskId, payload);
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const deleteTask = createAsyncThunk(
  '@@tasks/deleteTask',
  async (payload: DeleteTaskRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await tasksService.deleteTask(payload);
      if (response) {
        toast.success('Task has been deleted');
        dispatch(getTasks(payload.workspaceId));
      }
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

const tasksSlice = createSlice({
  name: '@@tasks',
  initialState,
  reducers: {
    setLastOpenedTask: (state, action: PayloadAction<string>) => {
      state.lastOpenedTask = action.payload;
    },
    setLastOpenedTaskColumn: (state, action: PayloadAction<string>) => {
      state.lastOpenedColumn = action.payload;
    },
    toggleDisplayCreateColumnModal: (state) => {
      state.displayCreateColumnModal = !state.displayCreateColumnModal;
    },
    toggleDisplayCreateTaskModal: (state) => {
      state.displayCreateTaskModal = !state.displayCreateTaskModal;
    },
    toggleDisplayEditColumnModal: (state) => {
      state.displayEditColumnModal = !state.displayEditColumnModal;
    },
    toggleDisplayDeleteTaskModal: (state) => {
      state.displayDeleteTaskModal = !state.displayDeleteTaskModal;
    },
    toggleDisplayEditTaskModal: (state) => {
      state.displayEditTaskModal = !state.displayEditTaskModal;
    },
    // Remark: Unique requirement where if a task doesn't exist on a column, then the column doesn't exist in the backend. So has to be stored locally.
    createColumn: (state, action: PayloadAction<Column>) => {
      const { id, name: heading, tasks, theme, order } = action.payload;
      state.columns.push({
        id,
        name: heading,
        order,
        theme,
        tasks,
      });
      state.columnOrder.push(id);
    },
    setColumns: (state, action: PayloadAction<Column[]>) => {
      state.columns = action.payload;
    },
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        //   Object.assign(state, action.payload);
        const { tasks, columnOrder, columns } = action.payload;
        state.tasks = tasks;
        state.columns = columns;
        state.columnOrder = columnOrder;
        state.loading = false;
        state.error = null;
      });
  },
});

export const {
  setColumns,
  createColumn,
  setColumnOrder,
  setLastOpenedTask,
  setLastOpenedTaskColumn,
  toggleDisplayEditTaskModal,
  toggleDisplayCreateTaskModal,
  toggleDisplayEditColumnModal,
  toggleDisplayDeleteTaskModal,
  toggleDisplayCreateColumnModal,
} = tasksSlice.actions;

export const tasksReducer = tasksSlice.reducer;
