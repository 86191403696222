/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import { GroupFromJSON, GroupFromJSONTyped, GroupToJSON } from './Group';
import type { IPrincipal } from './IPrincipal';
import {
  IPrincipalFromJSON,
  IPrincipalFromJSONTyped,
  IPrincipalToJSON,
} from './IPrincipal';
import type { Project } from './Project';
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
} from './Project';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 *
 * @export
 * @interface Task
 */
export interface Task {
  /**
   *
   * @type {string}
   * @memberof Task
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof Task
   */
  created?: Date;
  /**
   *
   * @type {Date}
   * @memberof Task
   */
  updated?: Date;
  /**
   *
   * @type {Date}
   * @memberof Task
   */
  expiry?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Task
   */
  completed?: boolean;
  /**
   *
   * @type {Project}
   * @memberof Task
   */
  project?: Project;
  /**
   *
   * @type {User}
   * @memberof Task
   */
  assigner?: User;
  /**
   *
   * @type {User}
   * @memberof Task
   */
  assignedUser?: User;
  /**
   *
   * @type {Group}
   * @memberof Task
   */
  assignedGroup?: Group;
  /**
   *
   * @type {IPrincipal}
   * @memberof Task
   */
  assignee?: IPrincipal;
  /**
   *
   * @type {User}
   * @memberof Task
   */
  updater?: User;
  /**
   *
   * @type {Set<any>}
   * @memberof Task
   */
  attachments?: Set<any>;
  /**
   *
   * @type {number}
   * @memberof Task
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  id?: string;
}

/**
 * Check if a given object implements the Task interface.
 */
export function instanceOfTask(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TaskFromJSON(json: any): Task {
  return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Task {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
    updated: !exists(json, 'updated') ? undefined : new Date(json['updated']),
    expiry: !exists(json, 'expiry') ? undefined : new Date(json['expiry']),
    completed: !exists(json, 'completed') ? undefined : json['completed'],
    project: !exists(json, 'project')
      ? undefined
      : ProjectFromJSON(json['project']),
    assigner: !exists(json, 'assigner')
      ? undefined
      : UserFromJSON(json['assigner']),
    assignedUser: !exists(json, 'assignedUser')
      ? undefined
      : UserFromJSON(json['assignedUser']),
    assignedGroup: !exists(json, 'assignedGroup')
      ? undefined
      : GroupFromJSON(json['assignedGroup']),
    assignee: !exists(json, 'assignee')
      ? undefined
      : IPrincipalFromJSON(json['assignee']),
    updater: !exists(json, 'updater')
      ? undefined
      : UserFromJSON(json['updater']),
    attachments: !exists(json, 'attachments') ? undefined : json['attachments'],
    version: !exists(json, 'version') ? undefined : json['version'],
    name: !exists(json, 'name') ? undefined : json['name'],
    id: !exists(json, 'id') ? undefined : json['id'],
  };
}

export function TaskToJSON(value?: Task | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    created:
      value.created === undefined ? undefined : value.created.toISOString(),
    updated:
      value.updated === undefined ? undefined : value.updated.toISOString(),
    expiry: value.expiry === undefined ? undefined : value.expiry.toISOString(),
    completed: value.completed,
    project: ProjectToJSON(value.project),
    assigner: UserToJSON(value.assigner),
    assignedUser: UserToJSON(value.assignedUser),
    assignedGroup: GroupToJSON(value.assignedGroup),
    assignee: IPrincipalToJSON(value.assignee),
    updater: UserToJSON(value.updater),
    attachments:
      value.attachments === undefined
        ? undefined
        : Array.from(value.attachments as Set<any>),
    version: value.version,
    name: value.name,
    id: value.id,
  };
}
