/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Child
 */
export interface Child {
  /**
   *
   * @type {string}
   * @memberof Child
   */
  folderId?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  fileId?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  created?: string;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  tsize?: number;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  uploading?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  subscribed?: boolean;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  modified?: number;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  locktype?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  creator?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  creatorname?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  editor?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  editorname?: string;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  numversion?: number;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  author?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  geoLocation?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  timeCreation?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  view?: string;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  comments?: number;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  produce?: boolean;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  level?: string;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  encrypted?: boolean;
}

/**
 * Check if a given object implements the Child interface.
 */
export function instanceOfChild(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ChildFromJSON(json: any): Child {
  return ChildFromJSONTyped(json, false);
}

export function ChildFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Child {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    folderId: !exists(json, 'folderId') ? undefined : json['folderId'],
    fileId: !exists(json, 'fileId') ? undefined : json['fileId'],
    name: !exists(json, 'name') ? undefined : json['name'],
    created: !exists(json, 'created') ? undefined : json['created'],
    size: !exists(json, 'size') ? undefined : json['size'],
    tsize: !exists(json, 'tsize') ? undefined : json['tsize'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    uploading: !exists(json, 'uploading') ? undefined : json['uploading'],
    subscribed: !exists(json, 'subscribed') ? undefined : json['subscribed'],
    modified: !exists(json, 'modified') ? undefined : json['modified'],
    locktype: !exists(json, 'locktype') ? undefined : json['locktype'],
    creator: !exists(json, 'creator') ? undefined : json['creator'],
    creatorname: !exists(json, 'creatorname') ? undefined : json['creatorname'],
    editor: !exists(json, 'editor') ? undefined : json['editor'],
    editorname: !exists(json, 'editorname') ? undefined : json['editorname'],
    numversion: !exists(json, 'numversion') ? undefined : json['numversion'],
    contentType: !exists(json, 'contentType') ? undefined : json['contentType'],
    author: !exists(json, 'author') ? undefined : json['author'],
    title: !exists(json, 'title') ? undefined : json['title'],
    geoLocation: !exists(json, 'geo_location')
      ? undefined
      : json['geo_location'],
    timeCreation: !exists(json, 'time_creation')
      ? undefined
      : json['time_creation'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    type: !exists(json, 'type') ? undefined : json['type'],
    view: !exists(json, 'view') ? undefined : json['view'],
    comments: !exists(json, 'comments') ? undefined : json['comments'],
    produce: !exists(json, 'produce') ? undefined : json['produce'],
    level: !exists(json, 'level') ? undefined : json['level'],
    encrypted: !exists(json, 'encrypted') ? undefined : json['encrypted'],
  };
}

export function ChildToJSON(value?: Child | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    folderId: value.folderId,
    fileId: value.fileId,
    name: value.name,
    created: value.created,
    size: value.size,
    tsize: value.tsize,
    deleted: value.deleted,
    uploading: value.uploading,
    subscribed: value.subscribed,
    modified: value.modified,
    locktype: value.locktype,
    creator: value.creator,
    creatorname: value.creatorname,
    editor: value.editor,
    editorname: value.editorname,
    numversion: value.numversion,
    contentType: value.contentType,
    author: value.author,
    title: value.title,
    geo_location: value.geoLocation,
    time_creation: value.timeCreation,
    notes: value.notes,
    type: value.type,
    view: value.view,
    comments: value.comments,
    produce: value.produce,
    level: value.level,
    encrypted: value.encrypted,
  };
}
