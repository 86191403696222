import {
  useAppSelector,
  useAppDispatch,
  toggleDisplayEditDescriptionModal,
  patchDashboardTitle,
  patchDashboardDescription,
} from '@oproma/prividox-store';
import { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { Icon } from '../../icon.component';
import { useTranslation } from 'react-i18next';
import { useParams } from '@oproma/router';

export const EditDashboardDescriptionModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { displayEditDescriptionModal, dashboardTitle, dashboardDescription } =
    useAppSelector((state) => state.workspace);
  const [title, setTitle] = useState(dashboardTitle);
  const [description, setDescription] = useState(dashboardDescription);
  const toggleModal = () => {
    dispatch(toggleDisplayEditDescriptionModal());
  };

  const handleSave = async () => {
    if (!workspaceId) return;

    try {
      await dispatch(
        patchDashboardTitle({
          entity: workspaceId,
          title,
        }),
      ).unwrap();

      await dispatch(
        patchDashboardDescription({
          entity: workspaceId,
          description,
        }),
      ).unwrap();

      toggleModal();
    } catch (error) {
      console.error('Failed to update dashboard metadata:', error);
    }
  };

  useEffect(() => {
    setDescription(dashboardDescription);
    setTitle(dashboardTitle);
  }, [dashboardTitle, dashboardDescription, displayEditDescriptionModal]);

  return (
    <Modal
      isOpen={displayEditDescriptionModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('DASHBOARD.EDIT_DASHBOARD')}</h5>
          <Form>
            <FormGroup>
              <Label for="title">{t('DASHBOARD.TITLE_LABEL')}</Label>
              <Input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">
                {t('DASHBOARD.DESCRIPTION_LABEL')}
              </Label>
              <Input
                type="textarea"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
            <div className="d-flex justify-content-end">
              <Button color="primary" onClick={handleSave} className="ml-2">
                {t('COMMON.CONFIRM')}
              </Button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};
