/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BundleSpec
 */
export interface BundleSpec {
  /**
   * The type of bundle to enable for the workspace.
   * @type {string}
   * @memberof BundleSpec
   */
  type: BundleSpecTypeEnum;
  /**
   * The number of times the bundle should be applied to expand the workspace limits.
   * @type {number}
   * @memberof BundleSpec
   */
  count: number;
}

/**
 * @export
 * @enum {string}
 */
export enum BundleSpecTypeEnum {
  users = 'users',
  space = 'space',
}

/**
 * Check if a given object implements the BundleSpec interface.
 */
export function instanceOfBundleSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'count' in value;

  return isInstance;
}

export function BundleSpecFromJSON(json: any): BundleSpec {
  return BundleSpecFromJSONTyped(json, false);
}

export function BundleSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BundleSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    count: json['count'],
  };
}

export function BundleSpecToJSON(value?: BundleSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    count: value.count,
  };
}
