import { useTranslation } from '@oproma/i18n';
import {
  setDashboardPaginatedPage,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect, useMemo, useState } from 'react';
import { PaginationComponent } from '../data-table-pagination.component';
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from '../data-table.component';
import { NotificationMessage } from '@oproma/prividox-orchestration-open-api';
import MobileDashboardActivityTableToggle from './dashboard-activity-table-toggle.component';
import DashboardQuery from './dashboard-query.component';
import { getActivityContent, getActivityState } from '../utils';
import Moment from 'react-moment';

export const DashboardActivityTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { activity = [] } = useAppSelector((state) => state.workspace);
  const {
    dashboardQuery,
    dashboardSortOrder,
    currentPaginatedPage,
    entriesPerPaginatedPage,
  } = useAppSelector((state) => state.dashboard);
  const { timeFormat } = useAppSelector((state) => state.user);

  // store activities with translated content
  const [translatedActivities, setTranslatedActivities] = useState<
    Array<NotificationMessage & { activityContent: string }>
  >([]);

  const formatTime = (timestamp: number | undefined, timeFormat: string) => {
    return timeFormat === 'HH:mm' ? (
      <Moment format="L HH:mm">{timestamp}</Moment>
    ) : (
      <Moment format="L HH:mm:ss">{timestamp}</Moment>
    );
  };

  // prepopulate translations on mount
  useEffect(() => {
    const prepopulateTranslations = () => {
      const activitiesWithContent = activity.map((item) => {
        const state = getActivityState(item);
        const { key, state: translationState } = getActivityContent(
          item,
          state,
        );
        const activityContent = t(
          `NOTIFICATION_BODIES.${key}`,
          translationState,
        );
        return { ...item, activityContent };
      });
      setTranslatedActivities(activitiesWithContent);
    };

    if (activity.length > 0) {
      prepopulateTranslations();
    }
  }, [activity, t]);

  // filter activities
  const processedActivities = useMemo(() => {
    let filteredActivities = translatedActivities.filter((item) => {
      if (!dashboardQuery) return true; // If no query, show all
      const lowerQuery = dashboardQuery.toLowerCase();
      return item.activityContent.toLowerCase().includes(lowerQuery);
    });

    // sort filtered activities
    if (dashboardSortOrder === 'asc' || dashboardSortOrder === 'desc') {
      filteredActivities = filteredActivities.sort((a, b) => {
        const dateA = new Date(a.time || 0).getTime();
        const dateB = new Date(b.time || 0).getTime();
        return dashboardSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }

    const startIndex = (currentPaginatedPage - 1) * entriesPerPaginatedPage;
    const paginatedActivities = filteredActivities.slice(
      startIndex,
      startIndex + entriesPerPaginatedPage,
    );

    return {
      paginatedActivities,
      totalItems: filteredActivities.length,
    };
  }, [
    translatedActivities,
    dashboardQuery,
    dashboardSortOrder,
    currentPaginatedPage,
    entriesPerPaginatedPage,
  ]);

  const { paginatedActivities, totalItems } = processedActivities;

  return (
    <DataTable className="card-stretch">
      <div className="card-inner position-relative card-tools-toggle">
        <MobileDashboardActivityTableToggle />
        <DashboardQuery />
      </div>
      <DataTableBody>
        <DataTableHead>
          <DataTableRow>
            <span className="sub-text" style={{ whiteSpace: 'nowrap' }}>
              {t('DASHBOARD.TABLE_COLUMNS.DATE')}
            </span>
          </DataTableRow>
          <DataTableRow size="mb">
            <span className="sub-text">
              {t('DASHBOARD.TABLE_COLUMNS.DESCRIPTION')}
            </span>
          </DataTableRow>
        </DataTableHead>
        {paginatedActivities.length > 0 ? (
          paginatedActivities.map((item, index) => (
            <DataTableItem key={index}>
              <DataTableRow>
                <div className="user-info" style={{ whiteSpace: 'nowrap' }}>
                  <span className="tb-lead">
                    {item.time ? formatTime(item.time, timeFormat) : 'N/A'}
                  </span>
                </div>
              </DataTableRow>
              <DataTableRow>
                <div className="user-info">
                  <span className="tb-lead">
                    <div
                      className="nk-activity-content"
                      dangerouslySetInnerHTML={{
                        __html: item.activityContent,
                      }}
                    />
                  </span>
                </div>
              </DataTableRow>
            </DataTableItem>
          ))
        ) : (
          <DataTableItem>
            <DataTableRow>
              <div className="text-center">
                <span className="text-silent">No data available</span>
              </div>
            </DataTableRow>
          </DataTableItem>
        )}
      </DataTableBody>{' '}
      <div className="card-inner">
        {paginatedActivities.length > 0 ? (
          <PaginationComponent
            itemPerPage={entriesPerPaginatedPage}
            totalItems={totalItems}
            paginate={(pageNumber) =>
              dispatch(setDashboardPaginatedPage(pageNumber))
            }
            currentPage={currentPaginatedPage}
          />
        ) : (
          <div className="text-center">
            <span className="text-silent">{t('MEMBERS.NO_MEMBERS')}</span>
          </div>
        )}
      </div>
    </DataTable>
  );
};
