/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  DirectPermissionResult,
  ResultDirectPermissionResult,
  ResultString,
} from '../models/index';
import {
  DirectPermissionResultFromJSON,
  DirectPermissionResultToJSON,
  ResultDirectPermissionResultFromJSON,
  ResultDirectPermissionResultToJSON,
  ResultStringFromJSON,
  ResultStringToJSON,
} from '../models/index';

export interface AmAdminRequest {
  entity: string;
}

export interface GetGrantedVerbsRequest {
  entity: string;
}

export interface GetPermissionsRequest {
  entity: string;
}

export interface GetUserPermissionsRequest {
  entity: string;
  userId: string;
}

export interface SetPermissionRequest {
  entity: string;
  user: string;
  body: string;
}

/**
 *
 */
export class PermissionsApi extends runtime.BaseAPI {
  /**
   * Checks whether the current user is an administrator of the workspace the specified entity belongs to.
   * Am Admin
   */
  async amAdminRaw(
    requestParameters: AmAdminRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling amAdmin.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/admin/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Checks whether the current user is an administrator of the workspace the specified entity belongs to.
   * Am Admin
   */
  async amAdmin(
    requestParameters: AmAdminRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.amAdminRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves  list of verbs for which the permission has been granted on the specified for the current user.
   * Get Granted Verbs
   */
  async getGrantedVerbsRaw(
    requestParameters: GetGrantedVerbsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultString>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getGrantedVerbs.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/permissions/verbs/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultStringFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves  list of verbs for which the permission has been granted on the specified for the current user.
   * Get Granted Verbs
   */
  async getGrantedVerbs(
    requestParameters: GetGrantedVerbsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultString> {
    const response = await this.getGrantedVerbsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the permissions associated with the specified entity.
   * Get Permissions
   */
  async getPermissionsRaw(
    requestParameters: GetPermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultDirectPermissionResult>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getPermissions.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/permissions/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultDirectPermissionResultFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the permissions associated with the specified entity.
   * Get Permissions
   */
  async getPermissions(
    requestParameters: GetPermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultDirectPermissionResult> {
    const response = await this.getPermissionsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the permissions associated with the provided entity and principal (user of group).
   * Get User Permissions
   */
  async getUserPermissionsRaw(
    requestParameters: GetUserPermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<DirectPermissionResult>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getUserPermissions.',
      );
    }

    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getUserPermissions.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/permissions/{entity}/{userId}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters.userId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DirectPermissionResultFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the permissions associated with the provided entity and principal (user of group).
   * Get User Permissions
   */
  async getUserPermissions(
    requestParameters: GetUserPermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DirectPermissionResult> {
    const response = await this.getUserPermissionsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Sets the specified permission.
   * Set Permission
   */
  async setPermissionRaw(
    requestParameters: SetPermissionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling setPermission.',
      );
    }

    if (
      requestParameters.user === null ||
      requestParameters.user === undefined
    ) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter requestParameters.user was null or undefined when calling setPermission.',
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling setPermission.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/permissions/{entity}/{user}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'user'}}`,
            encodeURIComponent(String(requestParameters.user)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Sets the specified permission.
   * Set Permission
   */
  async setPermission(
    requestParameters: SetPermissionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.setPermissionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
