import { Link } from '@oproma/router';
import { url } from '../utils';

type LogoProps = {
  preview?: boolean;
};

export const Logo = ({ preview }: LogoProps) => {
  const logoConfig = {
    src: preview ? url('/prividox-logo.svg') : '/prividox-logo.svg',
    width: 100,
    height: 100,
  };

  return (
    <Link to="/" className="logo-link">
      <img className="logo-light logo-img" {...logoConfig} alt="logo" />
      {/* Dark Logo */}
      <img className="logo-dark logo-img" {...logoConfig} alt="logo-dark" />
      {/* Mobile Logo */}
      <img
        className="logo-small logo-img logo-img-small"
        {...logoConfig}
        alt="logo-mobile"
      />
    </Link>
  );
};
