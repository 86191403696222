import clsx from 'clsx';
import { ReactNode } from 'react';
import { Icon } from './icon.component';

interface UserAvatarProps {
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  theme?: string;
  icon?: string;
  text?: string;
  image?: string;
  imageAlt?: string;
  color?: string;
  children?: ReactNode;
}

export const UserAvatar = ({
  className,
  size,
  theme,
  icon,
  text,
  image,
  imageAlt,
  children,
}: UserAvatarProps) => {
  const classes = clsx({
    'user-avatar': true,
    [`${className}`]: className,
    [`user-avatar-${size}`]: size,
    [`bg-${theme}`]: theme,
  });
  return (
    <div className={classes}>
      {icon ? <Icon name={icon} /> : null}
      {image && <img src={image} alt={imageAlt} />}
      {text && !image && <span>{text}</span>}
      {children}
    </div>
  );
};
