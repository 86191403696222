/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProduceInformation } from './ProduceInformation';
import {
  ProduceInformationFromJSON,
  ProduceInformationFromJSONTyped,
  ProduceInformationToJSON,
} from './ProduceInformation';

/**
 *
 * @export
 * @interface ResultProduceInformation
 */
export interface ResultProduceInformation {
  /**
   *
   * @type {Array<ProduceInformation>}
   * @memberof ResultProduceInformation
   */
  items?: Array<ProduceInformation>;
}

/**
 * Check if a given object implements the ResultProduceInformation interface.
 */
export function instanceOfResultProduceInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultProduceInformationFromJSON(
  json: any,
): ResultProduceInformation {
  return ResultProduceInformationFromJSONTyped(json, false);
}

export function ResultProduceInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultProduceInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(ProduceInformationFromJSON),
  };
}

export function ResultProduceInformationToJSON(
  value?: ResultProduceInformation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(ProduceInformationToJSON),
  };
}
