import { yupResolver } from '@hookform/resolvers/yup';
import {
  disableMFA,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToggleDisplayingPassswordText } from './use-toggle-displaying-passsword-text.hook';

const disableSchema = yup
  .object({
    password: yup.string().min(8).max(24).required(),
  })
  .required();

type DisableFormValues = yup.InferType<typeof disableSchema>;

export const useDisableMFAForm = () => {
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector((state) => state.auth);
  const { displayPasswordText, toggleDisplayingPasswordText } =
    useToggleDisplayingPassswordText();

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<DisableFormValues>({ resolver: yupResolver(disableSchema) });

  const { ref: passwordRef, ...registerPassword } = registerField('password');

  const onFormSubmit: SubmitHandler<DisableFormValues> = ({ password }) => {
    dispatch(disableMFA(password));
  };

  return {
    error,
    errors,
    loading,
    passwordRef,
    onFormSubmit,
    handleSubmit,
    registerPassword,
    displayPasswordText,
    toggleDisplayingPasswordText,
  };
};
