import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { Collapse } from 'reactstrap';
import SimpleBar from 'simplebar-react';
export interface AccordionItemProps {
  id: string;
  title: ReactNode;
  content: ReactNode;
  onClick?: () => void;
}
export interface AccordionProps {
  className?: string;
  variation?: string;

  accordionItems: AccordionItemProps[];
}

interface AccordionBodyProps {
  isOpen: boolean;
  className?: string;
  children: React.ReactNode;
}

export const AccordionBody = ({
  isOpen,
  className,
  children,
}: AccordionBodyProps) => {
  return (
    <Collapse
      className={`accordion-body ${className ? className : ''}`}
      isOpen={isOpen}
    >
      <div className="accordion-inner">{children}</div>
    </Collapse>
  );
};

export const AccordionItem = ({
  id,
  title,
  content,
  onClick,
}: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState<string | null>(null);

  const toggleCollapse = (id: string) => {
    setIsOpen((prevOpen) => (prevOpen === id ? null : id));
  };

  return (
    <div key={id} className="accordion-item" onClick={onClick}>
      <div
        className={clsx(`accordion-head`, {
          collapsed: isOpen !== id,
        })}
        onClick={() => toggleCollapse(id)}
      >
        <div className="title">{title}</div>
        <span className="accordion-icon"></span>
      </div>

      <AccordionBody isOpen={isOpen === id}>{content}</AccordionBody>
    </div>
  );
};

export const Accordion = ({
  className,
  variation,
  accordionItems,
}: AccordionProps) => {
  return (
    <div
      className={`accordion ${
        variation && `accordion-s${variation}`
      } ${className}`}
    >
      {accordionItems.map((item) => (
        <AccordionItem {...item} />
      ))}
    </div>
  );
};
