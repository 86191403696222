import { NavLink as RNavlink, useLocation } from '@oproma/router';
import clsx from 'clsx';
import { useEffect } from 'react';
import { NavLink } from 'reactstrap';
import { useWorkspaces } from '../hooks';
import { Icon } from '../icon.component';
import { Loader } from '../loader.component';
import { previewMenu } from './preview.menu';

type Theme =
  | 'bg-purple-dim'
  | 'bg-orange-dim'
  | 'bg-primary-dim'
  | 'bg-success-dim'
  | 'bg-info-dim';

interface SubMenu {
  text: string;
  link?: string;
  icon?: string;
  theme?: Theme;
  newTab?: boolean;
  active?: boolean;
  subMenu?: SubMenu[];
}

export interface MenuItem {
  heading?: string;
  icon?: string;
  text?: string;
  link?: string;
  dashboard?: boolean;
  badge?: undefined;
  active?: boolean;
  subMenu?: SubMenu[];
  newTab?: boolean;
  theme?: string;
}

interface MenuHeadingProps {
  heading: string;
}

export const MenuHeading = ({ heading }: MenuHeadingProps) => {
  return (
    <li className="nk-menu-heading">
      <h6 className="overline-title text-primary-alt">{heading}</h6>
    </li>
  );
};

interface MenuItemProps {
  icon?: string;
  link?: string;
  text?: string;
  sub?: MenuItem[];
  newTab?: boolean;
  mobileView?: boolean;
  sidebarToggle: (ev: React.MouseEvent) => void;
  badge?: string | number;
  active?: boolean;
}

export const MenuItem = ({
  icon,
  link,
  text,
  sub,
  newTab,
  mobileView,
  sidebarToggle,
  badge,
  active,
}: MenuItemProps) => {
  const { pathname } = useLocation();

  const toggleActionSidebar = (ev: React.MouseEvent<HTMLLIElement>) => {
    if (!sub && !newTab && mobileView) {
      sidebarToggle(ev);
    }
  };

  const menuHeight = (el?: HTMLCollection | NodeListOf<ChildNode>): number => {
    const totalHeight = [];
    if (el) {
      for (let i = 0; i < el.length; i++) {
        const element = el[i] as HTMLElement;
        const margin =
          parseInt(window.getComputedStyle(element).marginTop.slice(0, -2)) +
          parseInt(window.getComputedStyle(element).marginBottom.slice(0, -2));
        const padding =
          parseInt(window.getComputedStyle(element).paddingTop.slice(0, -2)) +
          parseInt(window.getComputedStyle(element).paddingBottom.slice(0, -2));
        const height = element.clientHeight + margin + padding;
        totalHeight.push(height);
      }

      return totalHeight.reduce((sum, value) => (sum += value));
    }
    return 0;
  };

  const makeParentActive = (el: HTMLElement, childHeight?: number) => {
    const element = el.parentElement?.parentElement?.parentElement;
    const wrap = el.parentElement?.parentElement;
    if (element?.classList[0] === 'nk-menu-item') {
      element.classList.add('active');
      const subMenuHeight = menuHeight(el.parentNode?.children);
      if (wrap && childHeight) {
        wrap.style.height = subMenuHeight + childHeight - 50 + 'px';
      }
      makeParentActive(element);
      //TODO: set as active workspace here
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName(
      'nk-menu-item active current-page',
    );
    const arrayElement = Array.from(element);

    arrayElement.forEach((dom) => {
      if (
        dom.parentElement?.parentElement?.parentElement?.classList[0] ===
        'nk-menu-item'
      ) {
        dom.parentElement.parentElement.parentElement.classList.add('active');
        const subMenuHeight = menuHeight(dom.parentNode?.children);
        dom.parentElement.parentElement.style.height = subMenuHeight + 'px';
        makeParentActive(
          dom.parentElement.parentElement.parentElement,
          subMenuHeight,
        );
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuToggle = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    const self = (ev.target as HTMLAnchorElement).closest('.nk-menu-toggle');
    const parent = self?.parentElement;
    const subMenu = self?.nextSibling;
    const subMenuItem = subMenu?.childNodes;
    const parentSiblings = parent?.parentElement?.childNodes;
    const parentMenu = parent?.closest('.nk-menu-wrap');
    //For Sub Menu Height
    const subMenuHeight = menuHeight(subMenuItem);

    // Get parent elements
    const getParents = (el: HTMLElement) => {
      let parentSelector = document.querySelector(
        '.nk-menu-md',
      ) as unknown as Document;
      if (parentSelector === undefined) {
        parentSelector = document;
      }
      const parents = [];
      let p = el.parentNode;
      while (p !== parentSelector) {
        const o = p;
        parents.push(o);
        p = o!.parentNode;
      }
      parents.push(parentSelector);
      return parents;
    };

    const parentMenus = getParents(self as HTMLElement);
    if (!parent?.classList.contains('active')) {
      // For Parent Siblings
      for (let j = 0; j < parentSiblings!.length; j++) {
        (parentSiblings![j] as HTMLElement).classList.remove('active');
        if (
          typeof (parentSiblings![j] as HTMLElement).childNodes[1] !==
          'undefined'
        ) {
          (parentSiblings![j].childNodes[1] as HTMLElement).style.height = '0';
        }
      }
      if (parentMenu !== null) {
        if (!parentMenu?.classList.contains('sub-opened')) {
          parentMenu?.classList.add('sub-opened');

          for (let l = 0; l < parentMenus.length; l++) {
            if (typeof parentMenus !== 'undefined') {
              if (
                (parentMenus[l] as HTMLElement).classList.contains(
                  'nk-menu-wrap',
                )
              ) {
                (parentMenus[l] as HTMLElement).style.height =
                  subMenuHeight +
                  (parentMenus[l] as HTMLElement).clientHeight +
                  'px';
              }
            }
          }
        }
      }
      // For Current Element
      parent?.classList.add('active');
      (subMenu as HTMLElement).style.height = subMenuHeight + 'px';
    } else {
      parent.classList.remove('active');
      if (parentMenu !== null) {
        parentMenu?.classList.remove('sub-opened');
        for (let k = 0; k < parentMenus.length; k++) {
          if (typeof parentMenus !== 'undefined') {
            if (
              (parentMenus[k] as HTMLElement).classList.contains('nk-menu-wrap')
            ) {
              (parentMenus[k] as HTMLElement).style.height =
                (parentMenus[k] as HTMLElement).clientHeight -
                subMenuHeight +
                'px';
            }
          }
        }
      }
      (subMenu as HTMLElement).style.height = '0';
    }
  };

  const menuItemClass = clsx({
    'nk-menu-item': true,
    'has-sub': sub,
    'active current-page':
      typeof active !== 'undefined' ? active : pathname.includes(link ?? ''),
  });
  return (
    <li className={menuItemClass} onClick={(ev) => toggleActionSidebar(ev)}>
      {newTab ? (
        <a
          href={`${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="nk-menu-link"
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text">{text}</span>
        </a>
      ) : (
        <NavLink
          tag={RNavlink}
          to={link}
          className={clsx(`nk-menu-link`, { 'nk-menu-toggle': sub })}
          // exact
          // activeClassName="active"
          onClick={sub ? menuToggle : undefined}
        >
          {icon && (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          )}
          <span className="nk-menu-text">{text}</span>
          {badge && <span className="nk-menu-badge">{badge}</span>}
        </NavLink>
      )}
      {sub && (
        <div className="nk-menu-wrap">
          <MenuSub
            sub={sub}
            sidebarToggle={sidebarToggle}
            mobileView={mobileView}
          />
        </div>
      )}
    </li>
  );
};

interface MenuSubProps {
  icon?: string;
  link?: string;
  text?: string;
  sub?: MenuItem[];
  sidebarToggle: (ev: React.MouseEvent) => void;
  mobileView?: boolean;
  style?: React.CSSProperties;
}

const MenuSub = ({
  icon,
  link,
  text,
  sub,
  sidebarToggle,
  mobileView,
  ...props
}: MenuSubProps) => {
  return (
    <ul className="nk-menu-sub" style={props.style}>
      {sub?.map((item, index) => (
        <MenuItem
          link={item.link}
          icon={item.icon}
          text={item.text}
          sub={item.subMenu}
          key={item.text}
          badge={item.badge}
          newTab={item.newTab}
          sidebarToggle={sidebarToggle}
          mobileView={mobileView}
          active={item.active}
        />
      ))}
    </ul>
  );
};

interface MenuProps {
  sidebarToggle: (ev: React.MouseEvent) => void;
  mobileView?: boolean;
  preview?: boolean;
}

export const Menu = ({ sidebarToggle, mobileView, preview }: MenuProps) => {
  const { workspacesMenu: workspaces, loading } = useWorkspaces();

  if (loading) return <Loader />;

  return (
    <ul className="nk-menu nk-menu-md">
      {preview
        ? previewMenu.map((item, i) =>
            item.heading ? (
              <MenuHeading heading={item.heading} key={i} />
            ) : (
              item.text && (
                <MenuItem
                  key={i}
                  link={item.link}
                  icon={item.icon}
                  text={item.text}
                  badge={item.badge}
                  sub={item.subMenu}
                  active={item.active}
                  sidebarToggle={sidebarToggle}
                  mobileView={mobileView}
                />
              )
            ),
          )
        : workspaces.map((item, i) =>
            item.heading ? (
              <MenuHeading heading={item.heading} key={i} />
            ) : (
              item.text && (
                <MenuItem
                  key={i}
                  link={item.link}
                  icon={item.icon}
                  text={item.text}
                  badge={item.badge}
                  sub={item.subMenu}
                  sidebarToggle={sidebarToggle}
                  mobileView={mobileView}
                />
              )
            ),
          )}
    </ul>
  );
};
