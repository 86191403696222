import { appApi } from './config';

const fetchAppConfigs = async () => {
  const keys = await appApi.getApplicationOptions();
  console.log(keys);
  return keys;
};

export const appService = {
  fetchAppConfigs,
};
