import { useTranslation } from '@oproma/i18n';
import { Icon } from '../icon.component';

type WorkspaceFinderProps = {
  onQuery: (query: string) => void;
};

export const WorkspaceFinder = ({ onQuery }: WorkspaceFinderProps) => {
  const { t } = useTranslation();
  return (
    <div className="form-control-wrap">
      <div className="form-icon form-icon-right">
        <Icon name="search"></Icon>
      </div>
      <input
        type="text"
        className="form-control"
        id="workspace-finder"
        onChange={(ev) => onQuery(ev.target.value)}
        placeholder={
          t('COMMON.WORKSPACES.FINDER.QUERY_WORKSPACES_PLACEHOLDER') ??
          'Query for workspaces...'
        }
      />
    </div>
  );
};
