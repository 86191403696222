type ColorOptionsProps = {
  value: string;
  label: string;
};

export const ColorOptions = ({ value, label }: ColorOptionsProps) => {
  return (
    <div className="d-flex">
      <span className={`dot dot-${value} m-1`}></span>
      {label}
    </div>
  );
};
