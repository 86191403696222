/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AccessLevelSpec,
  BundleSpec,
  CompletedSpec,
  CreateProjectSpec,
  CreateTrialSpec,
  InviteMemberSpec,
  PagerMemberInfo,
  PriceSpec,
  ResultMapStringObject,
  ResultPriceSpec,
  ResultProjectInformation,
  TaskSpec,
  UpdatePlanSpec,
} from '../models/index';
import {
  AccessLevelSpecFromJSON,
  AccessLevelSpecToJSON,
  BundleSpecFromJSON,
  BundleSpecToJSON,
  CompletedSpecFromJSON,
  CompletedSpecToJSON,
  CreateProjectSpecFromJSON,
  CreateProjectSpecToJSON,
  CreateTrialSpecFromJSON,
  CreateTrialSpecToJSON,
  InviteMemberSpecFromJSON,
  InviteMemberSpecToJSON,
  PagerMemberInfoFromJSON,
  PagerMemberInfoToJSON,
  PriceSpecFromJSON,
  PriceSpecToJSON,
  ResultMapStringObjectFromJSON,
  ResultMapStringObjectToJSON,
  ResultPriceSpecFromJSON,
  ResultPriceSpecToJSON,
  ResultProjectInformationFromJSON,
  ResultProjectInformationToJSON,
  TaskSpecFromJSON,
  TaskSpecToJSON,
  UpdatePlanSpecFromJSON,
  UpdatePlanSpecToJSON,
} from '../models/index';

export interface AddCustomEntitiesRequest {
  workspaceId: string;
  body: string;
}

export interface ChangeMemberAccessLevelRequest {
  workspaceId: string;
  user: string;
  accessLevelSpec: AccessLevelSpec;
}

export interface CreateTrialRequest {
  createTrialSpec: CreateTrialSpec;
}

export interface CreateWorkspaceRequest {
  createProjectSpec: CreateProjectSpec;
}

export interface DeleteTaskRequest {
  workspaceId: string;
  task: string;
}

export interface DeleteWorkspaceRequest {
  workspaceId: string;
}

export interface EmptyTrashRequest {
  workspaceId: string;
}

export interface EnableBundleRequest {
  workspaceId: string;
  bundleSpec: BundleSpec;
}

export interface GetAccessLevelRequest {
  workspaceId: string;
}

export interface GetAvailableBundlesRequest {
  workspaceId: string;
}

export interface GetBrandingIconRequest {
  workspaceId: string;
}

export interface GetCustomEntitiesRequest {
  workspaceId: string;
}

export interface GetWorkspaceMembersRequest {
  workspaceId: string;
  sort?: string;
  desc?: boolean;
  start?: string;
  end?: string;
}

export interface GetWorkspacePlanRequest {
  workspaceId: string;
}

export interface GetWorkspaceRootRequest {
  workspaceId: string;
}

export interface GetWorkspaceTrashRequest {
  workspaceId: string;
}

export interface InviteWorkspaceMemberRequest {
  workspaceId: string;
  inviteMemberSpec: Array<InviteMemberSpec>;
}

export interface RemoveCustomEntityRequest {
  workspaceId: string;
  entity: string;
}

export interface RemoveWorkspaceMemberRequest {
  workspaceId: string;
  user: string;
}

export interface SetTaskCompletedRequest {
  workspaceId: string;
  task: string;
  completedSpec: CompletedSpec;
}

export interface UpdateTaskRequest {
  workspaceId: string;
  task: string;
  taskSpec: TaskSpec;
}

export interface UpdateWorkspacePlanRequest {
  workspaceId: string;
  updatePlanSpec: UpdatePlanSpec;
}

/**
 *
 */
export class WorkspaceApi extends runtime.BaseAPI {
  /**
   * Adds the specified form file as a custom entity definition for the workspace.
   * Add Custom Entity
   */
  async addCustomEntitiesRaw(
    requestParameters: AddCustomEntitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling addCustomEntities.',
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling addCustomEntities.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/entities`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Adds the specified form file as a custom entity definition for the workspace.
   * Add Custom Entity
   */
  async addCustomEntities(
    requestParameters: AddCustomEntitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addCustomEntitiesRaw(requestParameters, initOverrides);
  }

  /**
   * Updates the workspace access level associated with the specified user.
   * Change Member Access Level
   */
  async changeMemberAccessLevelRaw(
    requestParameters: ChangeMemberAccessLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling changeMemberAccessLevel.',
      );
    }

    if (
      requestParameters.user === null ||
      requestParameters.user === undefined
    ) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter requestParameters.user was null or undefined when calling changeMemberAccessLevel.',
      );
    }

    if (
      requestParameters.accessLevelSpec === null ||
      requestParameters.accessLevelSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'accessLevelSpec',
        'Required parameter requestParameters.accessLevelSpec was null or undefined when calling changeMemberAccessLevel.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/members/{user}`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'user'}}`,
            encodeURIComponent(String(requestParameters.user)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AccessLevelSpecToJSON(requestParameters.accessLevelSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the workspace access level associated with the specified user.
   * Change Member Access Level
   */
  async changeMemberAccessLevel(
    requestParameters: ChangeMemberAccessLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.changeMemberAccessLevelRaw(requestParameters, initOverrides);
  }

  /**
   * Creates a new workspaces using the provided coupon code
   * Create Trial
   */
  async createTrialRaw(
    requestParameters: CreateTrialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.createTrialSpec === null ||
      requestParameters.createTrialSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'createTrialSpec',
        'Required parameter requestParameters.createTrialSpec was null or undefined when calling createTrial.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/trial`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateTrialSpecToJSON(requestParameters.createTrialSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Creates a new workspaces using the provided coupon code
   * Create Trial
   */
  async createTrial(
    requestParameters: CreateTrialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.createTrialRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Creates a new workspace using the provided information. Using a new card token for the creation requires the Super scope.
   * Create Workspace
   */
  async createWorkspaceRaw(
    requestParameters: CreateWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.createProjectSpec === null ||
      requestParameters.createProjectSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'createProjectSpec',
        'Required parameter requestParameters.createProjectSpec was null or undefined when calling createWorkspace.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateProjectSpecToJSON(requestParameters.createProjectSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Creates a new workspace using the provided information. Using a new card token for the creation requires the Super scope.
   * Create Workspace
   */
  async createWorkspace(
    requestParameters: CreateWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.createWorkspaceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Deletes the specified task.
   * Delete Task
   */
  async deleteTaskRaw(
    requestParameters: DeleteTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling deleteTask.',
      );
    }

    if (
      requestParameters.task === null ||
      requestParameters.task === undefined
    ) {
      throw new runtime.RequiredError(
        'task',
        'Required parameter requestParameters.task was null or undefined when calling deleteTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/tasks/{task}`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'task'}}`,
            encodeURIComponent(String(requestParameters.task)),
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes the specified task.
   * Delete Task
   */
  async deleteTask(
    requestParameters: DeleteTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteTaskRaw(requestParameters, initOverrides);
  }

  /**
   * Deletes the specified workspace.
   * Delete Workspace
   */
  async deleteWorkspaceRaw(
    requestParameters: DeleteWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling deleteWorkspace.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes the specified workspace.
   * Delete Workspace
   */
  async deleteWorkspace(
    requestParameters: DeleteWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteWorkspaceRaw(requestParameters, initOverrides);
  }

  /**
   * Empties the trash of the specified workspace.
   * Empty Trash
   */
  async emptyTrashRaw(
    requestParameters: EmptyTrashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling emptyTrash.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/trash`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Empties the trash of the specified workspace.
   * Empty Trash
   */
  async emptyTrash(
    requestParameters: EmptyTrashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.emptyTrashRaw(requestParameters, initOverrides);
  }

  /**
   * Enables the provided bundle for the specified workspace.
   * Enable Bundle
   */
  async enableBundleRaw(
    requestParameters: EnableBundleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling enableBundle.',
      );
    }

    if (
      requestParameters.bundleSpec === null ||
      requestParameters.bundleSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'bundleSpec',
        'Required parameter requestParameters.bundleSpec was null or undefined when calling enableBundle.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/bundles`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BundleSpecToJSON(requestParameters.bundleSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Enables the provided bundle for the specified workspace.
   * Enable Bundle
   */
  async enableBundle(
    requestParameters: EnableBundleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.enableBundleRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves workspace access level of the current user.
   * Get Access Level
   */
  async getAccessLevelRaw(
    requestParameters: GetAccessLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getAccessLevel.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/access`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves workspace access level of the current user.
   * Get Access Level
   */
  async getAccessLevel(
    requestParameters: GetAccessLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getAccessLevelRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of available bundles to increase the space available or user count.
   * Get Available Bundles
   */
  async getAvailableBundlesRaw(
    requestParameters: GetAvailableBundlesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultPriceSpec>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getAvailableBundles.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/bundles`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultPriceSpecFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of available bundles to increase the space available or user count.
   * Get Available Bundles
   */
  async getAvailableBundles(
    requestParameters: GetAvailableBundlesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultPriceSpec> {
    const response = await this.getAvailableBundlesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the branding image of the workspace.
   * Get Branding Icon
   */
  async getBrandingIconRaw(
    requestParameters: GetBrandingIconRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getBrandingIcon.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/branding`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the branding image of the workspace.
   * Get Branding Icon
   */
  async getBrandingIcon(
    requestParameters: GetBrandingIconRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getBrandingIconRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of the custom entities associated with the workspace.
   * Get Custom Entities
   */
  async getCustomEntitiesRaw(
    requestParameters: GetCustomEntitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultMapStringObject>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getCustomEntities.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/entities`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultMapStringObjectFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of the custom entities associated with the workspace.
   * Get Custom Entities
   */
  async getCustomEntities(
    requestParameters: GetCustomEntitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultMapStringObject> {
    const response = await this.getCustomEntitiesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a the members of the workspace.
   * Get Workspace Members
   */
  async getWorkspaceMembersRaw(
    requestParameters: GetWorkspaceMembersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PagerMemberInfo>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceMembers.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.desc !== undefined) {
      queryParameters['desc'] = requestParameters.desc;
    }

    if (requestParameters.start !== undefined) {
      queryParameters['start'] = requestParameters.start;
    }

    if (requestParameters.end !== undefined) {
      queryParameters['end'] = requestParameters.end;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/members`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PagerMemberInfoFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a the members of the workspace.
   * Get Workspace Members
   */
  async getWorkspaceMembers(
    requestParameters: GetWorkspaceMembersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PagerMemberInfo> {
    const response = await this.getWorkspaceMembersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves current plan of the workspace.
   * Get Workspace Plan
   */
  async getWorkspacePlanRaw(
    requestParameters: GetWorkspacePlanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PriceSpec>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspacePlan.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/plan`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PriceSpecFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves current plan of the workspace.
   * Get Workspace Plan
   */
  async getWorkspacePlan(
    requestParameters: GetWorkspacePlanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PriceSpec> {
    const response = await this.getWorkspacePlanRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the root folder of the workspace.
   * Get Workspace Root
   */
  async getWorkspaceRootRaw(
    requestParameters: GetWorkspaceRootRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceRoot.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/root`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the root folder of the workspace.
   * Get Workspace Root
   */
  async getWorkspaceRoot(
    requestParameters: GetWorkspaceRootRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getWorkspaceRootRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the trash folder of the workspace.
   * Get Workspace Trash
   */
  async getWorkspaceTrashRaw(
    requestParameters: GetWorkspaceTrashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceTrash.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/trash`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the trash folder of the workspace.
   * Get Workspace Trash
   */
  async getWorkspaceTrash(
    requestParameters: GetWorkspaceTrashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getWorkspaceTrashRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Adds the specified email as a new member of the workspace.
   * Invite Workspace Member
   */
  async inviteWorkspaceMemberRaw(
    requestParameters: InviteWorkspaceMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling inviteWorkspaceMember.',
      );
    }

    if (
      requestParameters.inviteMemberSpec === null ||
      requestParameters.inviteMemberSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'inviteMemberSpec',
        'Required parameter requestParameters.inviteMemberSpec was null or undefined when calling inviteWorkspaceMember.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/members`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.inviteMemberSpec.map(InviteMemberSpecToJSON),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Adds the specified email as a new member of the workspace.
   * Invite Workspace Member
   */
  async inviteWorkspaceMember(
    requestParameters: InviteWorkspaceMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<string>> {
    const response = await this.inviteWorkspaceMemberRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of the workspaces the current user is a member of.
   * List Workspaces
   */
  async listWorkspacesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultProjectInformation>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultProjectInformationFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of the workspaces the current user is a member of.
   * List Workspaces
   */
  async listWorkspaces(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultProjectInformation> {
    const response = await this.listWorkspacesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Removes the specified file as a custom entity definition.
   * Remove Custom Entity
   */
  async removeCustomEntityRaw(
    requestParameters: RemoveCustomEntityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling removeCustomEntity.',
      );
    }

    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling removeCustomEntity.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/entities/{entity}`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Removes the specified file as a custom entity definition.
   * Remove Custom Entity
   */
  async removeCustomEntity(
    requestParameters: RemoveCustomEntityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.removeCustomEntityRaw(requestParameters, initOverrides);
  }

  /**
   * Revokes the membership of the specified user from the workspace.
   * Remove Workspace Member
   */
  async removeWorkspaceMemberRaw(
    requestParameters: RemoveWorkspaceMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling removeWorkspaceMember.',
      );
    }

    if (
      requestParameters.user === null ||
      requestParameters.user === undefined
    ) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter requestParameters.user was null or undefined when calling removeWorkspaceMember.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/members/{user}`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'user'}}`,
            encodeURIComponent(String(requestParameters.user)),
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Revokes the membership of the specified user from the workspace.
   * Remove Workspace Member
   */
  async removeWorkspaceMember(
    requestParameters: RemoveWorkspaceMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.removeWorkspaceMemberRaw(requestParameters, initOverrides);
  }

  /**
   * Marks the specified task as completed or not.
   * Mark Task Complete/Incomplete
   */
  async setTaskCompletedRaw(
    requestParameters: SetTaskCompletedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling setTaskCompleted.',
      );
    }

    if (
      requestParameters.task === null ||
      requestParameters.task === undefined
    ) {
      throw new runtime.RequiredError(
        'task',
        'Required parameter requestParameters.task was null or undefined when calling setTaskCompleted.',
      );
    }

    if (
      requestParameters.completedSpec === null ||
      requestParameters.completedSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'completedSpec',
        'Required parameter requestParameters.completedSpec was null or undefined when calling setTaskCompleted.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/tasks/{task}/completed`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'task'}}`,
            encodeURIComponent(String(requestParameters.task)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CompletedSpecToJSON(requestParameters.completedSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Marks the specified task as completed or not.
   * Mark Task Complete/Incomplete
   */
  async setTaskCompleted(
    requestParameters: SetTaskCompletedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.setTaskCompletedRaw(requestParameters, initOverrides);
  }

  /**
   * Updates the specified task with the provided information.
   * Update Task
   */
  async updateTaskRaw(
    requestParameters: UpdateTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling updateTask.',
      );
    }

    if (
      requestParameters.task === null ||
      requestParameters.task === undefined
    ) {
      throw new runtime.RequiredError(
        'task',
        'Required parameter requestParameters.task was null or undefined when calling updateTask.',
      );
    }

    if (
      requestParameters.taskSpec === null ||
      requestParameters.taskSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'taskSpec',
        'Required parameter requestParameters.taskSpec was null or undefined when calling updateTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/tasks/{task}`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'task'}}`,
            encodeURIComponent(String(requestParameters.task)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: TaskSpecToJSON(requestParameters.taskSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the specified task with the provided information.
   * Update Task
   */
  async updateTask(
    requestParameters: UpdateTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateTaskRaw(requestParameters, initOverrides);
  }

  /**
   * Updates the plan associated with the specified workspace.
   * Update Workspace Plan
   */
  async updateWorkspacePlanRaw(
    requestParameters: UpdateWorkspacePlanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling updateWorkspacePlan.',
      );
    }

    if (
      requestParameters.updatePlanSpec === null ||
      requestParameters.updatePlanSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'updatePlanSpec',
        'Required parameter requestParameters.updatePlanSpec was null or undefined when calling updateWorkspacePlan.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/plan`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdatePlanSpecToJSON(requestParameters.updatePlanSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the plan associated with the specified workspace.
   * Update Workspace Plan
   */
  async updateWorkspacePlan(
    requestParameters: UpdateWorkspacePlanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateWorkspacePlanRaw(requestParameters, initOverrides);
  }
}
