export const securityLevels = [
  { value: 'NONE', label: 'FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.NONE' },
  {
    value: 'PROTECTED_A',
    label: 'FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_A',
  },
  {
    value: 'PROTECTED_B',
    label: 'FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_B',
  },
];
