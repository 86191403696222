export enum AuthenticationStatusEnum {
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum AuthErrorCodesEnum {
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGOUT_FAILED = 'LOGOUT_FAILED',
  REGISTRATION_FAILED = 'REGISTRATION_FAILED',
  REGISTRATION_CONFIRMATION_FAILED = 'REGISTRATION_CONFIRMATION_FAILED',
  RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED',
  RESET_PASSWORD_CONFIRMATION_FAILED = 'RESET_PASSWORD_CONFIRMATION_FAILED',
  GET_COMPLETED_ONBOARDING_STATUS_FAILED = 'GET_COMPLETED_ONBOARDING_STATUS_FAILED',
  REFRESH_USER_SESSION_STATUS_FAILED = 'REFRESH_USER_SESSION_STATUS_FAILED',
  GET_COMPLETED_ONBOARDING_STATUS = 'GET_COMPLETED_ONBOARDING_STATUS',
  RESET_EMAIL_FAILED = 'RESET_EMAIL_FAILED',
  RESET_EMAIL_CONFIRMATION_FAILED = 'RESET_EMAIL_CONFIRMATION_FAILED',
  DISABLE_MFA_FAILED = 'DISABLE_MFA_FAILED',
}
export interface IAuthState {
  readonly isAuthenticated: boolean;
  readonly isLocked: boolean;
  readonly financeWebtoken: string | null;
  readonly uid: string | null;
  readonly expiredAuth: boolean;
  readonly completedOnboarding: boolean;
  readonly resetEmail: boolean;
  readonly disabledMFA: boolean;
  readonly resetEmailDispatched: boolean;
  readonly requiresMFA: boolean;
  readonly resetPasswordEmailDispatched: boolean;
  readonly registrationEmailDispatched: boolean;
  readonly emailConfirmed: boolean;
  readonly completedEmailConfirmation: boolean;
  readonly passwordReset: boolean;
  readonly completedPasswordReset: boolean;
  readonly displayPasswordText: boolean;
  readonly receivedEmailResetDetails: boolean;
  readonly receivedPasswordResetConfirmationDetails: boolean;
  readonly loading: boolean;
  readonly error: {
    code: AuthErrorCodesEnum;
    message: string;
  } | null;
}
