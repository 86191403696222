import { yupResolver } from '@hookform/resolvers/yup';
import {
  toggleDisplayEditColumnModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { themes } from './use-create-task-column-form.hook';

const editTaskColumnSchema = yup
  .object({
    name: yup.string(),
    colour: yup.string(),
  })
  .required();

type EditTaskColumnFormValues = yup.InferType<typeof editTaskColumnSchema>;

export const useEditTaskColumnForm = (lastOpenedColumn: string | null) => {
  const dispatch = useAppDispatch();
  const { displayEditColumnModal } = useAppSelector((state) => state.tasks);
  const column = useAppSelector((state) =>
    state.tasks.columns.find((column) => column.id === lastOpenedColumn),
  );

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
    register: registerField,
  } = useForm<EditTaskColumnFormValues>({
    resolver: yupResolver(editTaskColumnSchema),
    defaultValues: {
      name: column?.name,
      colour: column?.theme,
    },
  });

  const name = watch('name');
  const colour = watch('colour');

  useEffect(() => {
    if (column) {
      setValue('name', column.name);
      setValue('colour', column.theme);
    }
  }, [column]);

  const { ref: nameRef, ...registerName } = registerField('name');
  const { ref: colourRef, ...registerColour } = registerField('colour');

  const onFormSubmit: SubmitHandler<EditTaskColumnFormValues> = ({
    name,
    colour,
  }) => {
    if (name) {
      console.log(name);
    }
    if (colour) {
      console.log(colour);
    }
  };

  const toggle = () => {
    dispatch(toggleDisplayEditColumnModal());
    reset();
  };

  return {
    name,
    colour,
    themes,
    errors,
    toggle,
    nameRef,
    setValue,
    colourRef,
    handleSubmit,
    onFormSubmit,
    registerName,
    registerColour,
    displayEditColumnModal,
  };
};
