/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GroupInfo
 */
export interface GroupInfo {
  /**
   *
   * @type {string}
   * @memberof GroupInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GroupInfo
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof GroupInfo
   */
  memberCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof GroupInfo
   */
  isDefaultGroup?: boolean;
}

/**
 * Check if a given object implements the GroupInfo interface.
 */
export function instanceOfGroupInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GroupInfoFromJSON(json: any): GroupInfo {
  return GroupInfoFromJSONTyped(json, false);
}

export function GroupInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GroupInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    memberCount: !exists(json, 'memberCount') ? undefined : json['memberCount'],
    isDefaultGroup: !exists(json, 'isDefaultGroup')
      ? undefined
      : json['isDefaultGroup'],
  };
}

export function GroupInfoToJSON(value?: GroupInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    memberCount: value.memberCount,
    isDefaultGroup: value.isDefaultGroup,
  };
}
