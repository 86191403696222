import { useAppSelector } from '@oproma/prividox-store';
import { TaskCard } from './task-card.component';

interface KanbanCardListProps {
  taskIds: string[];
  columnId: string;
}

export const TasksCardList = ({ taskIds, columnId }: KanbanCardListProps) => {
  const { tasks } = useAppSelector((state) => state.tasks);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {taskIds.length > 0 ? (
        taskIds.map((taskId, index) => {
          const task = tasks.find((task) => task.id === taskId);
          if (!task) return undefined;
          return (
            <TaskCard
              columnId={columnId}
              task={task}
              key={taskId}
              index={index}
            />
          );
        })
      ) : (
        <div className="kanban-drag"></div>
      )}
    </>
  );
};
