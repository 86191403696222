import { yupResolver } from '@hookform/resolvers/yup';
import { TaskSpec } from '@oproma/prividox-orchestration-open-api';
import {
  editTask,
  toggleDisplayEditTaskModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import moment from 'moment';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const editTaskSchema = yup
  .object({
    name: yup.string().required(),
    description: yup.string(),
    assignee: yup.string().required(),
    attachments: yup.array().of(yup.string()),
    expiry: yup.date(),
  })
  .required();

type EditTaskFormValues = yup.InferType<typeof editTaskSchema>;

export const useEditTaskForm = (lastOpenedTask: string | null) => {
  const { workspaceId } = useParams();
  const dispatch = useAppDispatch();
  const { displayEditTaskModal, lastOpenedColumn, columns, loading } =
    useAppSelector((state) => state.tasks);
  const task = useAppSelector((state) =>
    state.tasks.tasks.find((task) => task.id === lastOpenedTask),
  );
  const columnsOptions = columns.map((column) => ({
    value: column.id,
    label: column.name,
  }));
  const { members } = useAppSelector((state) => state.members);
  const memberOptions = members.map((member) => ({
    value: member.id!,
    label: member.name!,
  }));

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, dirtyFields },
    register: registerField,
  } = useForm<EditTaskFormValues>({
    resolver: yupResolver(editTaskSchema),
    defaultValues: {
      name: task?.name,
      description: task?.description,
      assignee: task?.metadata.users[0].value,
      attachments: task?.metadata.files?.map((file) => file.id),
    },
  });

  useEffect(() => {
    if (task) {
      setValue('name', task.name!);
      setValue('description', task.description!);
      setValue('assignee', task.metadata.users[0].value!);
      setValue(
        'attachments',
        task.metadata.files?.map((file) => file.id),
      );
      setValue('expiry', moment(task.metadata.due).toDate());
    }
  }, [task]);

  const expiry = watch('expiry');

  const { ref: nameRef, ...registerName } = registerField('name');
  const { ref: descriptionRef, ...registerDescription } =
    registerField('description');
  const { ref: assigneeRef, ...registerAssignee } = registerField('assignee');
  const { ref: attachmentRef, ...registerAttachment } =
    registerField('attachments');

  const onFormSubmit: SubmitHandler<EditTaskFormValues> = (values) => {
    if (!task?.id || !workspaceId) return;
    // Start with the full values object
    const taskSpec: TaskSpec = {
      ...values,
      user: values.assignee,
      expiry: moment(values.expiry).valueOf(),
      attachments:
        values.attachments?.filter((attachment): attachment is string =>
          Boolean(attachment),
        ) ?? [],
    };

    // If the expiry field has changed, convert it to Unix time
    if (dirtyFields.expiry && values.expiry) {
      taskSpec.expiry = moment(values.expiry).valueOf();
    }

    dispatch(
      editTask({
        task: task.id,
        workspaceId,
        taskSpec,
      }),
    );

    toggle();
  };

  const toggle = () => {
    dispatch(toggleDisplayEditTaskModal());
    reset();
  };

  return {
    task,
    expiry,
    errors,
    toggle,
    nameRef,
    loading,
    setValue,
    assigneeRef,
    registerName,
    handleSubmit,
    onFormSubmit,
    attachmentRef,
    memberOptions,
    columnsOptions,
    descriptionRef,
    lastOpenedColumn,
    registerAssignee,
    registerAttachment,
    registerDescription,
    displayEditTaskModal,
  };
};
