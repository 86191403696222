import { useTranslation } from '@oproma/i18n';
import { ChargeInfo } from '@oproma/prividox-orchestration-open-api';
import { useAppSelector } from '@oproma/prividox-store';
import moment from 'moment';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import Moment from 'react-moment';
import { Card, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Block } from '../block.component';
import { currencyFormatter } from '../utils';

export const PaymentHistory = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const { paymentTransactions, loading } = useAppSelector(
    (state) => state.finance,
  );

  const entriesPerPage = 5;
  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;
  const paginatedTransactions = paymentTransactions.slice(startIndex, endIndex);

  const totalPages = Math.ceil(paymentTransactions.length / entriesPerPage);

  const getPaymentDescription = (transaction: ChargeInfo) => {
    return transaction.description
      ?.split('-')[0]
      .replace(/comprehensive/gi, 'Starter')
      .replace(/specialized/gi, 'Comprehensive');
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Block>
      <Card className="card-bordered">
        <div className="card-inner card-inner-md">
          <div className="card-title-group">
            <h6 className="card-title">
              {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.PAYMENT_HISTORY.TITLE')}
            </h6>
            <div className="card-action">
              <CSVLink
                filename={`payment-history-${moment().format(
                  'YYYY-MM-DD',
                )}.csv`}
                data={paymentTransactions}
                target="_blank"
                className="link"
              >
                {t('COMMON.DOWNLOAD')}
              </CSVLink>
            </div>
          </div>
        </div>

        <table className="table-tranx">
          <thead>
            <tr className="tb-tnx-head">
              <th className="tb-tnx-id">
                <span className="">#</span>
              </th>
              <th className="tb-tnx-info">
                <span className="tb-tnx-desc d-sm-inline-block d-none">
                  <span>
                    {t(
                      'WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.PAYMENT_HISTORY.TABLE.BILL_FOR',
                    )}
                  </span>
                </span>
                <span className="tb-tnx-date d-md-inline-block d-none">
                  <span className="d-md-none">{t('COMMON.DATE')}</span>
                  <span className="d-none d-md-block">
                    <span>
                      {t(
                        'WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.PAYMENT_HISTORY.TABLE.BILLED_DATE',
                      )}
                    </span>
                  </span>
                </span>
              </th>
              <th className="tb-tnx-amount">
                <span className="tb-tnx-total">{t('COMMON.PRICE')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="tb-tnx-item">
                <td className="tb-tnx-id">
                  {t(
                    'WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.PAYMENT_HISTORY.LOADING',
                  )}
                </td>
              </tr>
            ) : paginatedTransactions && paginatedTransactions.length >= 1 ? (
              paginatedTransactions.map((transaction, i) => (
                <tr key={transaction.id} className="tb-tnx-item">
                  <td className="tb-tnx-id d-sm-inline-block">
                    <a
                      href="#ref"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <span>{transaction.id}</span>
                    </a>
                  </td>
                  <td className="tb-tnx-info">
                    <div className="tb-tnx-desc">
                      <span className="title">
                        {getPaymentDescription(transaction)}
                      </span>
                    </div>
                    <div className="tb-tnx-date">
                      <span className="date">
                        <Moment format="L">
                          {moment.unix(transaction.created!)}
                        </Moment>
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total">
                      <span className="amount">{`${currencyFormatter(
                        transaction.amount ?? -1,
                      )} ${transaction.currency?.toUpperCase()}`}</span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tb-tnx-item">
                <td className="tb-tnx-id">
                  {t(
                    'WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.PAYMENT_HISTORY.ERROR',
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Pagination className="m-2">
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink
              previous
              onClick={() => handlePageChange(currentPage - 1)}
            />
          </PaginationItem>
          {[...Array(totalPages)].map((_, i) => (
            <PaginationItem key={i} active={i + 1 === currentPage}>
              <PaginationLink onClick={() => handlePageChange(i + 1)}>
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink
              next
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </PaginationItem>
        </Pagination>
      </Card>
    </Block>
  );
};
