import { Entity, baseConfig } from '@oproma/prividox-store';

type MediaPreviewProps = {
  entity: Entity;
};

const generatePreviewFileLink = (entityId: string) => {
  return `${baseConfig.basePath}/download/${entityId}`;
};

export const MediaPreview = ({ entity }: MediaPreviewProps) => {
  const generateFilePreview = (entity: Entity) => {
    switch (entity.contentType) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/svg':
      case 'image/svg+xml':
        return (
          <img src={generatePreviewFileLink(entity.id)} alt={entity.name} />
        );
      case 'video/mp4':
      case 'video/webm':
      case 'video/ogg':
        return (
          <video controls>
            <source
              src={generatePreviewFileLink(entity.id)}
              type={entity.contentType}
            />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return "Preview couldn't not be generated";
    }
  };
  return generateFilePreview(entity);
};
