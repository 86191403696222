/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NotificationMessage
 */
export interface NotificationMessage {
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  entityId?: string;
  /**
   *
   * @type {number}
   * @memberof NotificationMessage
   */
  time?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  agent?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  agentName?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  target?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  projectName?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  eventType?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof NotificationMessage
   */
  meta?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  entityType?: string;
}

/**
 * Check if a given object implements the NotificationMessage interface.
 */
export function instanceOfNotificationMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function NotificationMessageFromJSON(json: any): NotificationMessage {
  return NotificationMessageFromJSONTyped(json, false);
}

export function NotificationMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NotificationMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    time: !exists(json, 'time') ? undefined : json['time'],
    agent: !exists(json, 'agent') ? undefined : json['agent'],
    agentName: !exists(json, 'agentName') ? undefined : json['agentName'],
    target: !exists(json, 'target') ? undefined : json['target'],
    projectId: !exists(json, 'projectId') ? undefined : json['projectId'],
    projectName: !exists(json, 'projectName') ? undefined : json['projectName'],
    eventType: !exists(json, 'eventType') ? undefined : json['eventType'],
    meta: !exists(json, 'meta') ? undefined : json['meta'],
    entityType: !exists(json, 'entityType') ? undefined : json['entityType'],
  };
}

export function NotificationMessageToJSON(
  value?: NotificationMessage | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entityId: value.entityId,
    time: value.time,
    agent: value.agent,
    agentName: value.agentName,
    target: value.target,
    projectId: value.projectId,
    projectName: value.projectName,
    eventType: value.eventType,
    meta: value.meta,
    entityType: value.entityType,
  };
}
