import { useTranslation } from '@oproma/i18n';
import { CardInfo } from '@oproma/prividox-orchestration-open-api';
import {
  setChosenCreditCard,
  toggleDisplayEditCreditCardModal,
  toggleDisplayOnboardCreditCardModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { MouseEvent } from 'react';
import { Badge, Card } from 'reactstrap';
import { Block } from '../block.component';

export const CreditCards = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { creditCardCount, creditCards } = useAppSelector(
    (state) => state.finance,
  );

  const getCardBrand = (brand: string) => {
    switch (brand) {
      case 'Visa':
        return 'ni ni-cc-visa';
      case 'MasterCard':
        return 'ni ni-master-card';
      case 'Paypal':
        return 'ni ni-paypal';
      default:
        return 'ni ni-sign-usd';
    }
  };

  const handleEditCreditCardClick = (
    ev: MouseEvent<HTMLAnchorElement>,
    card: CardInfo,
  ) => {
    ev.preventDefault();
    dispatch(setChosenCreditCard(card));
    dispatch(toggleDisplayEditCreditCardModal());
  };

  const handleOnboardCreditCardClick = (ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    dispatch(toggleDisplayOnboardCreditCardModal());
  };

  return (
    <Block>
      <Card className="card-bordered">
        <div className="card-inner card-inner-md">
          <div className="card-title-group">
            <h6 className="card-title">
              {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.CREDIT_CARDS.TITLE', {
                creditCardsCount: creditCardCount,
              })}
            </h6>
            <div className="card-action">
              <a
                href="/"
                className="link"
                onClick={handleOnboardCreditCardClick}
              >
                {t('WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.CREDIT_CARDS.ONBOARD')}
              </a>
            </div>
          </div>
        </div>
        <ul className="link-list-plain">
          {creditCards.map((card) => (
            <li key={card.cardToken}>
              <a
                href="/"
                className="cc-pay-item"
                onClick={(ev) => handleEditCreditCardClick(ev, card)}
              >
                <em
                  className={clsx(
                    'cc-pay-item-icon icon',
                    getCardBrand(card.brand!),
                  )}
                ></em>

                <span className="cc-pay-item-name">
                  <span className="cc-pay-item-method">
                    <span className="cc-pay-star">**** **** ****</span>{' '}
                    {card.last4}
                  </span>
                  <span className="cc-pay-item-meta">
                    {t(
                      'WORKSPACE_OPTIONS.TABS.1.OPTIONS.2.CREDIT_CARDS.EXPIRY',
                      {
                        expMonth: card.expMonth,
                        expYear: card.expYear,
                      },
                    )}
                  </span>
                </span>
                {card.def && <Badge>{t('COMMON.DEFAULT')}</Badge>}
              </a>
            </li>
          ))}
        </ul>
      </Card>
    </Block>
  );
};
