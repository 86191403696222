/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PriceSpec
 */
export interface PriceSpec {
  /**
   *
   * @type {number}
   * @memberof PriceSpec
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  interval?: string;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  id?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof PriceSpec
   */
  meta?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof PriceSpec
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof PriceSpec
   */
  count?: number;
}

/**
 * Check if a given object implements the PriceSpec interface.
 */
export function instanceOfPriceSpec(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PriceSpecFromJSON(json: any): PriceSpec {
  return PriceSpecFromJSONTyped(json, false);
}

export function PriceSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PriceSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amount: !exists(json, 'amount') ? undefined : json['amount'],
    interval: !exists(json, 'interval') ? undefined : json['interval'],
    name: !exists(json, 'name') ? undefined : json['name'],
    currency: !exists(json, 'currency') ? undefined : json['currency'],
    id: !exists(json, 'id') ? undefined : json['id'],
    meta: !exists(json, 'meta') ? undefined : json['meta'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    count: !exists(json, 'count') ? undefined : json['count'],
  };
}

export function PriceSpecToJSON(value?: PriceSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount: value.amount,
    interval: value.interval,
    name: value.name,
    currency: value.currency,
    id: value.id,
    meta: value.meta,
    enabled: value.enabled,
    count: value.count,
  };
}
