import {
  GroupMembers,
  getGroupMembers,
  getWorkspaceMembers,
  setGroupPaginatedPage,
  setLastOpenedMember,
  setOpenDeleteGroupMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { PaginationComponent } from '../../data-table-pagination.component';
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from '../../data-table.component';
import { Icon } from '../../icon.component';
import { Tooltip } from '../../tooltip.component';
import { UserAvatar } from '../../user-avatar.component';
import { findUpper, generateGravatarUrl } from '../../utils';

export const GroupMembersTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id: userId } = useAppSelector((state) => state.user);
  const { currentPaginatedPage, entriesPerPaginatedPage } = useAppSelector(
    (state) => state.members,
  );
  const { groupMembers } = useAppSelector((state) => state.groups);
  const { workspaceId, groupId } = useParams();
  const indexOfLastEntry = currentPaginatedPage * entriesPerPaginatedPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPaginatedPage;

  const paginatedMemberEntries = groupMembers.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );

  const onDeleteGroupMember = (memberId: string | undefined) => {
    dispatch(setLastOpenedMember(memberId as string));
    dispatch(setOpenDeleteGroupMemberModal(true));
  };

  useEffect(() => {
    dispatch(getGroupMembers(groupId as string));
    dispatch(getWorkspaceMembers(workspaceId as string));
  }, []);

  // Change Page
  const paginate = (page: number) => dispatch(setGroupPaginatedPage(page));

  return (
    <DataTable>
      <DataTableBody>
        <DataTableHead>
          <DataTableRow>
            <span className="sub-text">{t('GROUPS.MEMBER_TABLE.TITLE')}</span>
          </DataTableRow>
          <DataTableRow size="mb">
            <span className="sub-text">{t('GROUPS.MEMBER_TABLE.EMAIL')}</span>
          </DataTableRow>
          <DataTableRow size="mb">
            <span className="sub-text">{t('GROUPS.MEMBER_TABLE.OPTIONS')}</span>
          </DataTableRow>
        </DataTableHead>
        {groupMembers.map((groupMember: GroupMembers, i: number) => {
          return (
            <DataTableItem key={groupMember.id ?? i}>
              <DataTableRow>
                <div className="user-card">
                  {groupMember.thumbnail ? (
                    <img
                      src={generateGravatarUrl(groupMember.email!)}
                      alt={groupMember.name}
                      className="user-avatar rounded-circle mr-2"
                    ></img>
                  ) : (
                    <UserAvatar
                      theme={groupMember.thumbnailBackground}
                      text={findUpper(groupMember.name)}
                      image={groupMember.thumbnail}
                    ></UserAvatar>
                  )}
                  <div className="user-info">
                    <span className="tb-lead">
                      {groupMember.name ?? 'Unregistered'}
                    </span>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>
                <div className="user-card">
                  <div className="user-info">
                    <span className="tb-lead">{groupMember.email} </span>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools">
                {groupMember.id !== userId && (
                  <ul className="nk-tb-actions gx-1">
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteGroupMember(groupMember.id)}
                    >
                      <Tooltip
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={'remove_' + groupMember.id}
                        icon="user-cross-fill"
                        direction="top"
                        text={t('GROUPS.MEMBER_TABLE.REMOVE')}
                      />
                    </li>

                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-icon btn-trigger"
                        >
                          <Icon name="more-h"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li
                              onClick={() =>
                                onDeleteGroupMember(groupMember.id)
                              }
                            >
                              <DropdownItem
                                tag="a"
                                href="#remove"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="na"></Icon>
                                <span>{t('GROUPS.MEMBER_TABLE.REMOVE')}</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                )}
              </DataTableRow>
            </DataTableItem>
          );
        })}
      </DataTableBody>
      <div className="card-inner">
        {paginatedMemberEntries.length > 0 ? (
          <PaginationComponent
            itemPerPage={entriesPerPaginatedPage}
            totalItems={paginatedMemberEntries.length}
            paginate={paginate}
            currentPage={currentPaginatedPage}
          />
        ) : (
          <div className="text-center">
            <span className="text-silent">
              {t('GROUPS.MEMBER_TABLE.NO_MEMBER')}
            </span>
          </div>
        )}
      </div>
    </DataTable>
  );
};
