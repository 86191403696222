import clsx from 'clsx';
import { CSSProperties } from 'react';

interface IconProps {
  name?: string;
  id?: string;
  className?: string;
  style?: CSSProperties;
  [key: string]: unknown; // more props
}

export const Icon = ({ name, id, className, style, ...props }: IconProps) => {
  const iconClass = clsx({
    [`${className}`]: className,
    icon: true,
    ni: true,
    [`ni-${name}`]: true,
  });
  return <em className={iconClass} id={id} style={style} {...props}></em>;
};
