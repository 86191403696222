import {
  setLastOpenedTask,
  setLastOpenedTaskColumn,
  toggleDisplayCreateColumnModal,
  toggleDisplayCreateTaskModal,
  toggleDisplayDeleteTaskModal,
  toggleDisplayEditColumnModal,
  toggleDisplayEditTaskModal,
  useAppDispatch,
} from '@oproma/prividox-store';

export const useDisplayTaskModals = () => {
  const dispatch = useAppDispatch();
  const toggleCreateColumnModal = () => {
    dispatch(toggleDisplayCreateColumnModal());
  };

  const toggleCreateTaskModal = () => {
    dispatch(toggleDisplayCreateTaskModal());
  };

  const toggleEditColumnModal = () => {
    dispatch(toggleDisplayEditColumnModal());
  };

  const toggleEditTaskModal = (taskId: string, columnId: string) => {
    dispatch(setLastOpenedTask(taskId));
    dispatch(setLastOpenedTaskColumn(columnId));
    dispatch(toggleDisplayEditTaskModal());
  };

  const toggleDeleteTaskModal = (taskId: string, columnId: string) => {
    dispatch(setLastOpenedTask(taskId));
    dispatch(setLastOpenedTaskColumn(columnId));
    dispatch(toggleDisplayDeleteTaskModal());
  };

  return {
    toggleCreateColumnModal,
    toggleCreateTaskModal,
    toggleEditColumnModal,
    toggleEditTaskModal,
    toggleDeleteTaskModal,
  };
};
