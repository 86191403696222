/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MemberInformation
 */
export interface MemberInformation {
  /**
   *
   * @type {string}
   * @memberof MemberInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MemberInformation
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof MemberInformation
   */
  name?: string;
}

/**
 * Check if a given object implements the MemberInformation interface.
 */
export function instanceOfMemberInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function MemberInformationFromJSON(json: any): MemberInformation {
  return MemberInformationFromJSONTyped(json, false);
}

export function MemberInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MemberInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function MemberInformationToJSON(value?: MemberInformation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    name: value.name,
  };
}
