import { Entity, filesService } from '@oproma/prividox-store';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import { useEffect, useState } from 'react';

type MarkdownPreviewProps = {
  entity: Entity;
};

export const TextPreview = ({ entity }: MarkdownPreviewProps) => {
  const [content, setContent] = useState<string | undefined>();

  useEffect(() => {
    const fetchMarkdown = async () => {
      const response = await filesService.previewFile({
        entity: entity.id,
        // version: entity.metadata?.numversion ?? 0,
      });
      setContent(response);
    };
    fetchMarkdown();
  }, [entity]);

  const renderPreview = (type?: string) => {
    console.log('TYPE', type);
    switch (type) {
      case 'application/javascript':
      case 'text/x-config':
        return (
          <pre>
            <code className="language-html">
              <span
                dangerouslySetInnerHTML={{
                  __html: hljs.highlight(content ?? 'No Preview', {
                    language: 'javascript',
                  }).value,
                }}
              ></span>
            </code>
          </pre>
        );
      case 'text/markdown':
      case 'text/plain':
        return <pre>{content ?? "Preview couldn't be generated"}</pre>;
      default:
        return "Preview couldn't be generated";
    }
  };
  return renderPreview(entity?.contentType);
};
