/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ValidateLoginSpec
 */
export interface ValidateLoginSpec {
  /**
   * The email to use to authenticate the user.
   * @type {string}
   * @memberof ValidateLoginSpec
   */
  email: string;
  /**
   * The password to use to authenticate the user. Minimum length to be increased to 12.
   * @type {string}
   * @memberof ValidateLoginSpec
   */
  pass: string;
  /**
   * The TOTP token to use to authenticate the user.
   * @type {string}
   * @memberof ValidateLoginSpec
   */
  token?: string;
}

/**
 * Check if a given object implements the ValidateLoginSpec interface.
 */
export function instanceOfValidateLoginSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'pass' in value;

  return isInstance;
}

export function ValidateLoginSpecFromJSON(json: any): ValidateLoginSpec {
  return ValidateLoginSpecFromJSONTyped(json, false);
}

export function ValidateLoginSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ValidateLoginSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    pass: json['pass'],
    token: !exists(json, 'token') ? undefined : json['token'],
  };
}

export function ValidateLoginSpecToJSON(value?: ValidateLoginSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    pass: value.pass,
    token: value.token,
  };
}
