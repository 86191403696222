import { yupResolver } from '@hookform/resolvers/yup';
import {
  createTask,
  toggleDisplayCreateTaskModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import moment from 'moment';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const createTaskSchema = yup
  .object({
    name: yup.string().required(),
    description: yup.string(),
    assignee: yup.string().required(),
    attachments: yup.array().of(yup.string()),
    expiry: yup.date().required(),
    // Represents the column to create the task in
    columnId: yup.string().required(),
  })
  .required();

type CreateTaskFormValues = yup.InferType<typeof createTaskSchema>;

export const useCreateTaskForm = () => {
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const { displayCreateTaskModal, columns, loading } = useAppSelector(
    (state) => state.tasks,
  );
  const { members } = useAppSelector((state) => state.members);
  const columnsOptions = columns.map((column) => ({
    value: column.id,
    label: column.name,
  }));
  const memberOptions = members.map((member) => ({
    value: member.id!,
    label: member.name!,
  }));

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
    register: registerField,
  } = useForm<CreateTaskFormValues>({
    resolver: yupResolver(createTaskSchema),
  });

  const expiry = watch('expiry');

  const { ref: nameRef, ...registerName } = registerField('name');
  const { ref: descriptionRef, ...registerDescription } =
    registerField('description');
  const { ref: assigneeRef, ...registerAssignee } = registerField('assignee');
  const { ref: attachmentsRef, ...registerAttachments } =
    registerField('attachments');

  useEffect(() => {
    if (memberOptions.length >= 1) {
      setValue('assignee', memberOptions[0].value);
    }
    if (!expiry) {
      setValue('expiry', new Date());
    }
  }, [memberOptions, expiry]);

  const onFormSubmit: SubmitHandler<CreateTaskFormValues> = ({
    name,
    description,
    assignee,
    expiry,
    attachments,
    columnId,
  }) => {
    console.log(name, description, assignee, expiry, attachments);
    if (!workspaceId) return;
    const column = columns.find((column) => column.id === columnId);
    if (!column) return;
    const order = column.tasks.length;
    dispatch(
      createTask({
        columnId,
        columnName: column.name,
        columnOrder: column.order,
        columnTheme: column.theme,
        order: order.toString(),
        workspaceId,
        taskSpec: {
          name,
          description,
          user: assignee,
          expiry: moment(expiry).valueOf(),
          attachments:
            attachments?.filter((attachment): attachment is string =>
              Boolean(attachment),
            ) || [],
        },
      }),
    );
    reset();
    toggle();
  };

  const toggle = () => {
    dispatch(toggleDisplayCreateTaskModal());
    reset();
  };

  return {
    expiry,
    errors,
    toggle,
    loading,
    nameRef,
    setValue,
    assigneeRef,
    registerName,
    handleSubmit,
    onFormSubmit,
    attachmentsRef,
    memberOptions,
    descriptionRef,
    columnsOptions,
    registerAssignee,
    registerAttachments,
    registerDescription,
    displayCreateTaskModal,
  };
};
