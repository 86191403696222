import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Content,
  CreateTaskModal,
  EditTaskModal,
  Head,
  Icon,
  Layout,
  DeleteTaskModal,
  TasksBoard,
  TasksCreateColumnModal,
  TasksEditColumnModal,
  useDisplayTaskModals,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getTasks,
  getWorkspaceEntities,
  getWorkspaceMembers,
  setDisplayMobileMenu,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import clsx from 'clsx';
import { useEffect } from 'react';

const TasksPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const { toggleCreateColumnModal, toggleCreateTaskModal } =
    useDisplayTaskModals();
  // TODO: Move this into a custom hook as this logic is reused a lot in the codebase
  const { displayMobileMenu } = useAppSelector((state) => state.layout);

  const changeDisplayMobileMenu = (payload: boolean) =>
    dispatch(setDisplayMobileMenu(payload));

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getTasks(workspaceId));
    dispatch(getWorkspaceMembers(workspaceId));
    dispatch(getWorkspaceEntities(workspaceId));
  }, [workspaceId]);

  return (
    <>
      <Head heading={t('COMMON.TASKS')} />
      <Layout>
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>{t('COMMON.TASKS')}</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#toggle"
                    onClick={(ev) => {
                      ev.preventDefault();
                      changeDisplayMobileMenu(!displayMobileMenu);
                    }}
                    className="btn btn-icon btn-trigger toggle-expand me-n1"
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </a>
                  <div
                    className={clsx(`toggle-expand-content`, {
                      expanded: displayMobileMenu,
                    })}
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <Button
                          color="light"
                          outline
                          className="btn-white"
                          onClick={() => toggleCreateTaskModal()}
                        >
                          <Icon name="plus" />
                          <span>Create Task</span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          color="primary"
                          onClick={() => toggleCreateColumnModal()}
                        >
                          <Icon name="plus" />
                          <span>Create Category</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <TasksBoard />
          </Block>
        </Content>
      </Layout>

      <CreateTaskModal />
      <EditTaskModal />
      <TasksCreateColumnModal />
      <TasksEditColumnModal />
      <DeleteTaskModal />
    </>
  );
};

export default TasksPage;
