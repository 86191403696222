import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { createLogger } from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './auth';
import { fileManagerReducer } from './file-manager';
import { financeReducer } from './finance';
import { groupsReducer } from './groups';
import { layoutReducer } from './layout';
import { membersReducer } from './members';
import { notificationsReducer } from './notifications';
import { permissionsReducer } from './permissions';
import { tasksReducer } from './tasks';
import { userReducer } from './user';
import { workspaceReducer } from './workspace';
import { workspacesReducer } from './workspaces';
import { starredEntitiesReducer } from './file-manager/starred-entities';
import { dashboardReducer } from './dashboard';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'tasks',
    'workspaces',
    'fileManager',
    'notifications',
    'finance',
    'members',
    'permissions',
    'groups',
  ],
};

const reducer = combineReducers({
  layout: layoutReducer,
  auth: authReducer,
  user: userReducer,
  tasks: tasksReducer,
  workspace: workspaceReducer,
  workspaces: workspacesReducer,
  fileManager: fileManagerReducer,
  notifications: notificationsReducer,
  finance: financeReducer,
  members: membersReducer,
  permissions: permissionsReducer,
  groups: groupsReducer,
  dashboard: dashboardReducer,
  starred: starredEntitiesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      createLogger({
        duration: true,
        timestamp: false,
        collapsed: true,
        colors: {
          title: () => '#139BFE',
          prevState: () => '#1C5FAF',
          action: () => '#149945',
          nextState: () => '#A47104',
          error: () => '#ff0005',
        },
        predicate: () => typeof window !== 'undefined',
      }),
    ),
  // .prepend(routerMiddleware),
  //   .concat(usersApi.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
// top-level state
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
