import {
  Entity,
  EntityActionsEnum,
  addStarredEntity,
  copyEntity,
  entityUnsubscribe,
  filesService,
  removeStarredEntity,
  setSelectedEntityIds,
  useAppDispatch,
  useAppSelector,
  userEntitySubscribe,
} from '@oproma/prividox-store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type TooltipIds =
  | 'share'
  | 'download'
  | 'copy'
  | 'star'
  | 'trash'
  | 'subscribe'
  | 'move';

export const useFileActions = (multiple?: boolean) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    entities,
    bulkAction,
    lastOpenedEntity,
    selectedEntityIds,
    displayBulkDistributeModal,
    displayBulkDeleteEntityModal,
  } = useAppSelector((state) => state.fileManager);
  const { entityId } = useParams();
  const { starred } = useAppSelector((state) => state.starred);
  const selectedEntities = entities.filter((entity) =>
    selectedEntityIds.includes(entity.id as string),
  );

  const clearSelectedEntities = () => {
    dispatch(setSelectedEntityIds([]));
  };

  const selectorDownload = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    toast.info(t('FILE_MANAGER.UTILITY_ACTIONS.TOAST.DOWNLOADING_ENTITY'));
    const payload = {
      entities: selectedEntityIds.toString(),
      produce: false,
      fullPath: false,
    };
    try {
      const fileContents = await filesService.entitiesExport(payload);
      if (!fileContents)
        throw new Error(
          t('FILE_MANAGER.UTILITY_ACTIONS.TOAST.FAILED_DOWNLOAD'),
        );
      const url = URL.createObjectURL(fileContents);
      const link = document.createElement('a');
      link.href = url;
      const currentDate = new Date().toISOString().slice(0, 10);
      link.download = `Prividox-${currentDate}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t('FILE_MANAGER.UTILITY_ACTIONS.TOAST.ENTITY_DOWNLOADED'));
    } catch (error) {
      console.error(error);
      toast.error(t('FILE_MANAGER.UTILITY_ACTIONS.TOAST.FAILED_DOWNLOAD'));
    }
  };

  const generateCopyLink = (entityId: string) => {
    return `?action=copy&entity=${entityId}`;
  };

  useEffect(() => {
    if (bulkAction) {
      const selectorPaste = (ev: ClipboardEvent) => {
        const pastedContent = ev.clipboardData?.getData('text');
        if (!pastedContent) return;
        // Split the pasted content into separate URL-encoded strings
        const copiedEntities = pastedContent.split(';').filter(Boolean);
        let isCopied = false;
        for (let i = 0; i < copiedEntities.length; i++) {
          const urlSearchParams = new URLSearchParams(copiedEntities[i]);
          const action = urlSearchParams.get('action');
          const entityValue = urlSearchParams.get('entity');
          if (
            action === EntityActionsEnum.COPY &&
            entityValue &&
            lastOpenedEntity
          ) {
            dispatch(
              copyEntity({
                entity: entityValue,
                destination: entityId as string,
              }),
            );
            isCopied = true;
          }
        }
        if (isCopied) {
          toast.success(t('FILE_MANAGER.COPY.BULK_PASTED'));
        } else {
          toast.error(t('FILE_MANAGER.UTILITY_ACTIONS.TOAST.INVALID_ACTION'));
        }
      };

      window.addEventListener('paste', selectorPaste);

      return () => {
        window.removeEventListener('paste', selectorPaste);
      };
    }
  }, [selectedEntityIds, entityId]);

  const selectorSubscribe = async (entity: Entity) => {
    if (entity.subscribed) {
      dispatch(entityUnsubscribe({ subscribed: entity.id ?? 'N/A' }));
    } else {
      dispatch(userEntitySubscribe({ body: entity.id ?? 'N/A' }));
    }
  };

  const selectorArchive = async (entity: Entity) => {
    if (starred.includes(entity.id) && entity) {
      dispatch(removeStarredEntity(entity.id));
    } else {
      dispatch(addStarredEntity(entity.id));
    }
  };

  const [tooltips, setTooltips] = useState<Record<TooltipIds, boolean>>({
    share: false,
    download: false,
    copy: false,
    star: false,
    trash: false,
    subscribe: false,
    move: false,
  });

  const toggle = (tooltip: TooltipIds) => {
    setTooltips({
      ...tooltips,
      [tooltip]: !tooltips[tooltip],
    });
  };

  return {
    tooltips,
    selectedEntities,
    selectedEntityIds,
    displayBulkDistributeModal,
    displayBulkDeleteEntityModal,
    clearSelectedEntities,
    selectorDownload,
    selectorSubscribe,
    selectorArchive,
    generateCopyLink,
    dispatch,
    toggle,
    t,
  };
};
