/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResultMapStringObject
 */
export interface ResultMapStringObject {
  /**
   *
   * @type {Array<{ [key: string]: object; }>}
   * @memberof ResultMapStringObject
   */
  items?: Array<{ [key: string]: object }>;
}

/**
 * Check if a given object implements the ResultMapStringObject interface.
 */
export function instanceOfResultMapStringObject(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultMapStringObjectFromJSON(
  json: any,
): ResultMapStringObject {
  return ResultMapStringObjectFromJSONTyped(json, false);
}

export function ResultMapStringObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultMapStringObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items') ? undefined : json['items'],
  };
}

export function ResultMapStringObjectToJSON(
  value?: ResultMapStringObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: value.items,
  };
}
