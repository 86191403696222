import { yupResolver } from '@hookform/resolvers/yup';
import {
  setEntityMetadata,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const metadataSchema = yup
  .object({
    key: yup.string().required(),
    value: yup.string().required(),
  })
  .required();

type MetadataFormValues = yup.InferType<typeof metadataSchema>;

export const useMetadataForm = () => {
  const dispatch = useAppDispatch();
  const { lastOpenedEntity } = useAppSelector((state) => state.fileManager);
  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<MetadataFormValues>({ resolver: yupResolver(metadataSchema) });

  const { ref: keyRef, ...registerKey } = registerField('key');
  const { ref: valueRef, ...registerValue } = registerField('value');

  const onFormSubmit: SubmitHandler<MetadataFormValues> = ({ key, value }) => {
    dispatch(setEntityMetadata({ entity: lastOpenedEntity!.id, key, value }));
    reset();
  };

  return {
    errors,
    keyRef,
    valueRef,
    onFormSubmit,
    handleSubmit,
    registerKey,
    registerValue,
  };
};
