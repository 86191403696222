import { useTranslation } from '@oproma/i18n';
import {
  Entity,
  entityUnsubscribe,
  filesService,
  getEntityMetadata,
  getEntityParent,
  getWorkspacePermissions,
  removeDeletedEntity,
  restoreFile,
  setDisplayDistributeEntityModal,
  setDisplayMetadataModal,
  setDisplayMoveEntityModal,
  setDisplayRemoveEntityModal,
  setLastOpenedEntity,
  toggleDisplayPermissionsModal,
  toggleDisplayPreviewFileModal,
  toggleDisplayRenameEntityModal,
  useAppDispatch,
  useAppSelector,
  userEntitySubscribe,
} from '@oproma/prividox-store';
import { CSSProperties, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { ClipboardCopy } from '../clipboard-copy.component';
import { Icon } from '../icon.component';

export type LayoutType = 'trash';

interface FileProps {
  entity: Entity;
  layoutType?: LayoutType;
  display: boolean;
  position: { x: number; y: number };
  onClose: () => void;
}

export const FileManagerDropdown = ({
  entity,
  layoutType,
  display,
  position,
  onClose,
}: FileProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { workspaceId } = useParams();
  const style: CSSProperties = {
    position: 'fixed',
    top: `${position.y}px`,
    left: `${position.x}px`,
  };
  const { displayRemoveEntityModal } = useAppSelector(
    (state) => state.fileManager,
  );

  const handleDownloadClick = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    const toastId = toast.info(
      `${t('FILE_MANAGER.TOASTS.DOWNLOADING_ENTITY')} ${entity.name}: 0%`,
      {
        autoClose: false,
      },
    );
    try {
      const onProgress = (progress: number) => {
        toast.update(toastId, {
          render: `${t('FILE_MANAGER.TOASTS.DOWNLOADING_ENTITY')} ${
            entity.name
          }: ${progress.toFixed(2)}%`,
        });
      };
      const fileContents = await filesService.fetchFile(entity.id, onProgress);
      if (!fileContents) throw new Error('Failed to download file');
      const url = URL.createObjectURL(fileContents);
      const link = document.createElement('a');
      link.href = url;
      link.download = entity.name ?? 'prividox-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.update(toastId, {
        render: `${t('FILE_MANAGER.TOASTS.ENTITY_DOWNLOADED')} ${entity.name}!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: `${t('FILE_MANAGER.TOASTS.FAILED_DOWNLOAD')} ${entity.name}`,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    }
  };

  const handleDropdownClick = () => {
    if (!entity.fileId || !entity.folderId) {
      return;
    }
    dispatch(setLastOpenedEntity(entity));
    dispatch(getEntityParent(entity.fileId ?? entity.folderId));
  };

  const toggleRemoveEntityModal = () =>
    dispatch(setDisplayRemoveEntityModal(!displayRemoveEntityModal));

  const handleRestoringFile = (entityId: string) => {
    dispatch(restoreFile(entityId));
  };

  const handleRestoreEntity = (entityId: string) => {
    dispatch(removeDeletedEntity(entityId));
  };

  const generateCopyLink = (entity: Entity) => {
    if (!entity.fileId && !entity.folderId) {
      return "Couldn't copy";
    }
    return `?action=copy&entity=${entity.fileId ?? entity.folderId}`;
  };

  const handleMetadataClick = (entityId: string) => {
    dispatch(getEntityMetadata(entityId));
    dispatch(setDisplayMetadataModal(true));
  };

  const toggle = () => {
    if (display) {
      onClose();
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setLastOpenedEntity(entity));
  };
  return (
    <div className="nk-file-actions" onClick={handleContainerClick}>
      <Dropdown isOpen={display || isOpen} toggle={toggle}>
        <DropdownToggle
          tag="a"
          href="#folder"
          onClick={(ev) => {
            ev.preventDefault();
            handleDropdownClick();
          }}
          className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu end style={style} onBlur={onClose} tabIndex={0}>
          <ul className="link-list-opt no-bdr">
            {layoutType === 'trash' ? (
              <>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //   setDetailModal(true);
                      if (entity.fileId) {
                        handleRestoringFile(entity.fileId);
                      } else if (entity.folderId) {
                        handleRestoringFile(entity.folderId);
                      }
                      handleRestoreEntity(entity.id);
                    }}
                    href="#item"
                  >
                    <Icon name="back-arrow" />
                    <span>{t('FILE_MANAGER.DROPDOWN.RESTORE')}</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleRemoveEntityModal();
                    }}
                    href="#item"
                  >
                    <Icon name="trash"></Icon>
                    <span>{t('FILE_MANAGER.DROPDOWN.PERM_DELETE')}</span>
                  </DropdownItem>
                </li>
              </>
            ) : (
              <>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(toggleDisplayPreviewFileModal());
                    }}
                    href="#item"
                  >
                    <Icon name="eye"></Icon>
                    <span>{t('FILE_MANAGER.DROPDOWN.DETAILS')}</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(toggleDisplayRenameEntityModal());
                    }}
                    href="#item"
                  >
                    <Icon name="pen2"></Icon>
                    <span>{t('FILE_MANAGER.DROPDOWN.RENAME')}</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      if (entity.subscribed) {
                        dispatch(entityUnsubscribe({ subscribed: entity.id }));
                      } else {
                        dispatch(userEntitySubscribe({ body: entity.id }));
                      }
                    }}
                    href="#item"
                  >
                    {entity.subscribed ? (
                      <>
                        <Icon name="bell"></Icon>
                        <span>
                          {t('FILE_MANAGER.SUBSCRIPTION.UNSUBSCRIBE')}
                        </span>
                      </>
                    ) : (
                      <>
                        <Icon name="bell-fill"></Icon>
                        <span>{t('FILE_MANAGER.SUBSCRIPTION.SUBSCRIBE')}</span>
                      </>
                    )}
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(setDisplayDistributeEntityModal(true));
                    }}
                    href="#item"
                  >
                    <Icon name="share"></Icon>
                    <span>{t('FILE_MANAGER.DROPDOWN.DISTRIBUTE')}</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(setLastOpenedEntity(entity));
                      dispatch(toggleDisplayPermissionsModal());
                      if (entity.fileId) {
                        dispatch(
                          getWorkspacePermissions({
                            workspaceId: workspaceId as string,
                            entityId: entity.fileId,
                          }),
                        );
                      } else if (entity.folderId) {
                        dispatch(
                          getWorkspacePermissions({
                            workspaceId: workspaceId as string,
                            entityId: entity.folderId,
                          }),
                        );
                      }
                      dispatch(
                        getWorkspacePermissions({
                          workspaceId: workspaceId as string,
                          entityId: entity.id,
                        }),
                      );
                    }}
                    href="#item"
                  >
                    <Icon name="lock-alt-fill"></Icon>
                    <span>{t('FILE_MANAGER.PERMISSIONS.TITLE')}</span>
                  </DropdownItem>
                </li>
                <li>
                  <ClipboardCopy
                    text={generateCopyLink(entity)}
                    onCopy={() =>
                      toast.success(t('FILE_MANAGER.DROPDOWN.COPIED'))
                    }
                  >
                    <DropdownItem
                      tag="a"
                      onClick={(ev) => {
                        ev.preventDefault();
                        //   setCopyModal(true);
                      }}
                      href="#item"
                    >
                      <Icon name="copy"></Icon>
                      <span>{t('FILE_MANAGER.DROPDOWN.COPY')}</span>
                    </DropdownItem>
                  </ClipboardCopy>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(setDisplayMoveEntityModal(true));
                    }}
                    href="#item"
                  >
                    <Icon name="forward-arrow"></Icon>
                    <span>{t('FILE_MANAGER.DROPDOWN.MOVE')}</span>
                  </DropdownItem>
                </li>
                {entity.type === 'file' && (
                  <li>
                    <DropdownItem
                      tag="a"
                      onClick={handleDownloadClick}
                      href="#item"
                      className="file-dl-toast"
                    >
                      <Icon name="download"></Icon>
                      <span>{t('FILE_MANAGER.DROPDOWN.DOWNLOAD')}</span>
                    </DropdownItem>
                  </li>
                )}
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      if (entity.fileId) {
                        handleMetadataClick(entity.fileId);
                      } else if (entity.folderId) {
                        handleMetadataClick(entity.folderId);
                      }
                      handleMetadataClick(entity.id);
                    }}
                    href="#item"
                  >
                    <Icon name="list-index"></Icon>
                    <span>{t('FILE_MANAGER.DROPDOWN.METADATA')}</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleRemoveEntityModal();
                    }}
                    href="#item"
                  >
                    <Icon name="trash"></Icon>
                    <span>{t('FILE_MANAGER.DROPDOWN.DELETE')}</span>
                  </DropdownItem>
                </li>
              </>
            )}
          </ul>
        </DropdownMenu>
      </Dropdown>

      {/* <Modal isOpen={detailModal} size="md" toggle={toggleDetailModal}>
        <Details
          file={file}
          toggle={toggleDetailModal}
          toggleShare={toggleShareModal}
        />
      </Modal>

      <Modal isOpen={shareModal} size="md" toggle={toggleShareModal}>
        <Share file={file} toggle={toggleShareModal} />
      </Modal>

      <Modal isOpen={copyModal} size="md" toggle={toggleCopyModal}>
        <Copy
          file={file}
          toggle={toggleCopyModal}
          toggleCreateModal={toggleCreateModal}
        />
      </Modal>

      <Modal isOpen={moveModal} size="md" toggle={toggleMoveModal}>
        <Move
          file={file}
          toggle={toggleMoveModal}
          toggleCreateModal={toggleCreateModal}
        />
      </Modal>

      <Modal isOpen={createModal} size="md" toggle={toggleCreateModal}>
        <CreateFolder toggle={toggleCreateModal} />
      </Modal> */}
    </div>
  );
};
