import {
  deleteGroup,
  setDeletedMessage,
  setOpenDeleteGroupModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { Icon } from '../../icon.component';
import { toast } from 'react-toastify';
import { useTranslation } from '@oproma/i18n';
import { useParams } from '@oproma/router';

export const DeleteGroupModal = () => {
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const dispatch = useAppDispatch();
  const { displayDeleteGroupModal, deletedGroupMessage } = useAppSelector(
    (state) => state.groups,
  );
  const lastOpenedGroup = useAppSelector((state) =>
    state.groups.groups.find(
      (group) => group.id === state.groups.lastOpenedGroup,
    ),
  );
  const [workspace] = useAppSelector((state) =>
    state.workspaces.workspaces.filter(
      (workspace) => workspace.id === workspaceId,
    ),
  );
  const { reset } = useForm();

  const toggleModal = () => {
    dispatch(setOpenDeleteGroupModal(!displayDeleteGroupModal));
  };

  // submit function to add a new item
  const handleDeleteConfirm = () => {
    //setData([name, ...data]);
    if (lastOpenedGroup) {
      dispatch(
        deleteGroup({
          workspaceId: workspace.id as string,
          group: lastOpenedGroup.id as string,
        }),
      );

      if (deletedGroupMessage) {
        toast.success(t('GROUPS.GROUP_DELETED'));
      }
    }
    dispatch(setDeletedMessage(false));
    toggleModal();
    reset();
  };

  return (
    <Modal
      isOpen={displayDeleteGroupModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('GROUPS.REMOVE_GROUP.TITLE')}</h5>
          <div className="mt-4">
            <Col md="12">
              <div className="form-group">
                <p className="form-label">{t('GROUPS.REMOVE_GROUP.HEADING')}</p>
              </div>
            </Col>

            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li>
                  <Button
                    className="btn btn-primary mt-3"
                    color="primary"
                    size="md"
                    onClick={handleDeleteConfirm}
                  >
                    {t('GROUPS.REMOVE_GROUP.CONFIRM')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
