import {
  deleteEntityMetadata,
  setDisplayMetadataModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Input, Label, Modal } from 'reactstrap';
import { Button } from '../../button.component';
import { Col, Row } from '../../grid.component';
import { useMetadataForm } from '../../hooks';
import { Icon } from '../../icon.component';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from '../svg-thumbnails.component';

const nonEditableKeys = [
  'SHA1',
  'type',
  'subscribed',
  'starred',
  'modified',
  'creatorname',
  'uploading',
  'id',
  'contentType',
  'editor',
  'creator',
  'comments',
  'level',
  'created',
  'time::creation',
  'tsize',
  'SHA256',
  'deleted',
  'encrypted',
  'size',
  'numversion',
  'name',
  'locktype',
  'MD5',
  'editorname',
];

export const FileManagerMetadataModal = () => {
  const {
    keyRef,
    valueRef,
    registerKey,
    handleSubmit,
    onFormSubmit,
    registerValue,
    errors,
  } = useMetadataForm();
  const dispatch = useAppDispatch();
  const { lastOpenedEntity, displayMetadataModal } = useAppSelector(
    (state) => state.fileManager,
  );

  const toggle = () => {
    dispatch(setDisplayMetadataModal(!displayMetadataModal));
  };

  const handleDeleteMetadata = (key: string) => {
    dispatch(
      deleteEntityMetadata({
        entity: lastOpenedEntity?.id ?? '',
        key,
      }),
    );
  };

  return (
    <Modal isOpen={displayMetadataModal} size="xl" toggle={toggle}>
      <div className="modal-header align-center">
        <div className="nk-file-title">
          <div className="nk-file-icon">
            <div className="nk-file-icon-type">
              {getEntityThumbnail(
                (lastOpenedEntity?.contentType ??
                  lastOpenedEntity?.type ??
                  'others') as EntityThumbnail,
              )}
            </div>
          </div>
          <div className="nk-file-name">
            <div className="nk-file-name-text">
              <span className="title">{lastOpenedEntity?.name}</span>
            </div>
            <div className="nk-file-name-sub">
              {lastOpenedEntity?.type?.toUpperCase()}
            </div>
          </div>
        </div>
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
      </div>
      <div className="modal-body mt-3">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="g-4">
            <Col lg="6">
              <div className="form-group">
                <Label htmlFor="key">Key</Label>
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="key"
                    className="form-control"
                    innerRef={keyRef}
                    {...registerKey}
                  />
                  {errors.key && (
                    <p className="invalid">{errors.key.message}</p>
                  )}
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <label className="form-label" htmlFor="value">
                  Value
                </label>
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="value"
                    className="form-control"
                    innerRef={valueRef}
                    {...registerValue}
                  />
                  {errors.value && (
                    <p className="invalid">{errors.value.message}</p>
                  )}
                </div>
              </div>
            </Col>
            <Col xl="12">
              <Button color="primary" type="submit">
                Confirm
              </Button>
            </Col>
          </Row>
        </form>
        <table className="table table-ulogs mt-4">
          <thead className="bg-lighter">
            <tr>
              <th className="tb-col-os">
                <span className="overline-title">Key</span>
              </th>
              <th className="tb-col-ip">
                <span className="overline-title">Value</span>
              </th>
              <th className="tb-col-action">
                <span className="overline-title">&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(lastOpenedEntity?.metadata ?? {})
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{JSON.stringify(value)}</td>
                  <td className="tb-col-action">
                    {!nonEditableKeys.includes(key) && (
                      <a
                        href="#delete"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDeleteMetadata(key);
                        }}
                        className="link-cross me-sm-n1"
                      >
                        <Icon name="cross"></Icon>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
