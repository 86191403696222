import { ReactNode } from 'react';
import SimpleBar from 'simplebar-react';

interface CardSidebarProps {
  toggleState: boolean;
  children: ReactNode;
}

export const CardSidebar = ({ toggleState, children }: CardSidebarProps) => {
  return (
    <div
      className={`card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl ${
        toggleState && 'content-active'
      }`}
      data-content="userAside"
      data-toggle-screen="xxl"
      data-toggle-overlay="true"
      data-toggle-body="true"
      id="sidePanel_01"
    >
      <SimpleBar className="card-inner-group">{children}</SimpleBar>
    </div>
  );
};
