/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AnswersSpec,
  BulkOperationSpec,
  CommentSpec,
  ErrorsSpec,
  LockSpec,
  NewItemSpec,
  PdfStats,
  ProtectionSpec,
  QuerySpec,
  RedactSpec,
  ResultChild,
  ResultChildPermission,
  ResultFolderInformation,
  ResultMapStringObject,
  ResultNotificationMessage,
  SearchResults,
  UploadStatus,
} from '../models/index';
import {
  AnswersSpecFromJSON,
  AnswersSpecToJSON,
  BulkOperationSpecFromJSON,
  BulkOperationSpecToJSON,
  CommentSpecFromJSON,
  CommentSpecToJSON,
  ErrorsSpecFromJSON,
  ErrorsSpecToJSON,
  LockSpecFromJSON,
  LockSpecToJSON,
  NewItemSpecFromJSON,
  NewItemSpecToJSON,
  PdfStatsFromJSON,
  PdfStatsToJSON,
  ProtectionSpecFromJSON,
  ProtectionSpecToJSON,
  QuerySpecFromJSON,
  QuerySpecToJSON,
  RedactSpecFromJSON,
  RedactSpecToJSON,
  ResultChildFromJSON,
  ResultChildToJSON,
  ResultChildPermissionFromJSON,
  ResultChildPermissionToJSON,
  ResultFolderInformationFromJSON,
  ResultFolderInformationToJSON,
  ResultMapStringObjectFromJSON,
  ResultMapStringObjectToJSON,
  ResultNotificationMessageFromJSON,
  ResultNotificationMessageToJSON,
  SearchResultsFromJSON,
  SearchResultsToJSON,
  UploadStatusFromJSON,
  UploadStatusToJSON,
} from '../models/index';

export interface ExportRequest {
  entities: string;
  produce: boolean;
  full?: boolean;
  report?: boolean;
  fullPath?: boolean;
}

export interface AddCommentRequest {
  entity: string;
  commentSpec: CommentSpec;
}

export interface AnswerFormRequest {
  entity: string;
  answersSpec: AnswersSpec;
}

export interface ApplyRedactionRequest {
  entity: string;
  redactSpec: RedactSpec;
}

export interface BulkDeleteRequest {
  querySpec: QuerySpec;
}

export interface BulkMetadataImportRequest {
  body: object;
}

export interface ConvertDocumentRequest {
  entity: string;
}

export interface CopyToRequest {
  entity: string;
  destination: string;
}

export interface CreateDocumentRequest {
  entity: string;
  newItemSpec?: NewItemSpec;
}

export interface CreateFolderRequest {
  entity: string;
  newItemSpec: NewItemSpec;
}

export interface DeleteChildrenRequest {
  folder: string;
  requestBody: Array<string>;
}

export interface DeleteCommentRequest {
  entity: string;
}

export interface DeleteItemRequest {
  entity: string;
}

export interface DownloadRequest {
  entity: string;
  preview?: boolean;
  vnum?: number;
  range?: string;
  ifRange?: string;
}

export interface ExpandArchiveToRequest {
  entity: string;
  destination: string;
}

export interface FacetFolderRequest {
  workspaceId: string;
  folder: string;
  q?: string;
  recursive?: boolean;
}

export interface FacetWorkspaceRequest {
  workspaceId: string;
  q?: string;
  recursive?: boolean;
}

export interface GetActivityRequest {
  entity: string;
}

export interface GetChildMetadataRequest {
  entity: string;
  fo?: boolean;
  start?: number;
  sort?: string;
  desc?: string;
  limit?: number;
}

export interface GetChildPermissionsRequest {
  entity: string;
  principal: string;
  fo?: boolean;
  start?: number;
  sort?: string;
  desc?: string;
  limit?: number;
}

export interface GetContainerWorkspaceRequest {
  entity: string;
}

export interface GetContentsRequest {
  entity: string;
  version?: number;
}

export interface GetDocStatsRequest {
  entity: string;
  pass?: string;
  record?: boolean;
  person?: boolean;
  telephone?: boolean;
  cc?: boolean;
  sin?: boolean;
  ssn?: boolean;
  company?: boolean;
  email?: boolean;
  ipv4?: boolean;
  ipv6?: boolean;
  country?: boolean;
}

export interface GetDuplicatesRequest {
  entity: string;
}

export interface GetGrantedUsersRequest {
  entity: string;
  verb: GetGrantedUsersVerbEnum;
  includeInactive?: boolean;
}

export interface GetMD5Request {
  entity: string;
}

export interface GetParentRequest {
  entity: string;
}

export interface GetParentsRequest {
  entity: string;
}

export interface GetPathRequest {
  entity: string;
}

export interface GetThumbnailRequest {
  entity: string;
  vnum?: number;
}

export interface GetVersionsMetaRequest {
  entity: string;
}

export interface LockRequest {
  entity: string;
  lockSpec: LockSpec;
}

export interface MoveToRequest {
  entity: string;
  destination: string;
}

export interface PerformBulkOperationRequest {
  folder: string;
  bulkOperationSpec: BulkOperationSpec;
}

export interface RecognizeRequest {
  entity: string;
}

export interface RestoreToRequest {
  entity: string;
  destination: string;
}

export interface SearchFolderRequest {
  workspaceId: string;
  folder: string;
  q: string;
  recursive?: boolean;
  start?: string;
  end?: number;
  sort?: string;
  fq?: string;
  lang?: string;
}

export interface SearchWorkspaceRequest {
  workspaceId: string;
  q: string;
  start?: string;
  end?: number;
  sort?: string;
  fq?: string;
  lang?: string;
}

export interface SetAnswerersRequest {
  entity: string;
  requestBody: Set<string>;
}

export interface SetProtectionLevelRequest {
  entity: string;
  protectionSpec: ProtectionSpec;
}

export interface SetVersionRequest {
  entity: string;
  body: number;
}

export interface UploadRequest {
  target: string;
  name: string;
  body: object;
  upload?: string;
  filesize?: number;
  index?: number;
  count?: number;
  rename?: boolean;
  original?: string;
  encrypted?: boolean;
  level?: UploadLevelEnum;
}

/**
 *
 */
export class FilesApi extends runtime.BaseAPI {
  /**
   * Exports a selection of files/folders as an archive containing the items, a report containing information about the items, or both.
   * Data Export
   */
  async _exportRaw(
    requestParameters: ExportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entities === null ||
      requestParameters.entities === undefined
    ) {
      throw new runtime.RequiredError(
        'entities',
        'Required parameter requestParameters.entities was null or undefined when calling _export.',
      );
    }

    if (
      requestParameters.produce === null ||
      requestParameters.produce === undefined
    ) {
      throw new runtime.RequiredError(
        'produce',
        'Required parameter requestParameters.produce was null or undefined when calling _export.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.entities !== undefined) {
      queryParameters['entities'] = requestParameters.entities;
    }

    if (requestParameters.produce !== undefined) {
      queryParameters['produce'] = requestParameters.produce;
    }

    if (requestParameters.full !== undefined) {
      queryParameters['full'] = requestParameters.full;
    }

    if (requestParameters.report !== undefined) {
      queryParameters['report'] = requestParameters.report;
    }

    if (requestParameters.fullPath !== undefined) {
      queryParameters['fullPath'] = requestParameters.fullPath;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/export`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Exports a selection of files/folders as an archive containing the items, a report containing information about the items, or both.
   * Data Export
   */
  async _export(
    requestParameters: ExportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this._exportRaw(requestParameters, initOverrides);
  }

  /**
   * Adds the provided comment to the specified file.
   * Add Comment
   */
  async addCommentRaw(
    requestParameters: AddCommentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling addComment.',
      );
    }

    if (
      requestParameters.commentSpec === null ||
      requestParameters.commentSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'commentSpec',
        'Required parameter requestParameters.commentSpec was null or undefined when calling addComment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/notifications/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CommentSpecToJSON(requestParameters.commentSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Adds the provided comment to the specified file.
   * Add Comment
   */
  async addComment(
    requestParameters: AddCommentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.addCommentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Answers the specified form, appending the answers to the answers file.
   * Answer Form
   */
  async answerFormRaw(
    requestParameters: AnswerFormRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling answerForm.',
      );
    }

    if (
      requestParameters.answersSpec === null ||
      requestParameters.answersSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'answersSpec',
        'Required parameter requestParameters.answersSpec was null or undefined when calling answerForm.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/answer`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AnswersSpecToJSON(requestParameters.answersSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Answers the specified form, appending the answers to the answers file.
   * Answer Form
   */
  async answerForm(
    requestParameters: AnswerFormRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.answerFormRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Applies the provided redactions to the specified redactable document and creates a new file with the redacted contents.
   * Apply Redactions
   */
  async applyRedactionRaw(
    requestParameters: ApplyRedactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling applyRedaction.',
      );
    }

    if (
      requestParameters.redactSpec === null ||
      requestParameters.redactSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'redactSpec',
        'Required parameter requestParameters.redactSpec was null or undefined when calling applyRedaction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/redact`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RedactSpecToJSON(requestParameters.redactSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Applies the provided redactions to the specified redactable document and creates a new file with the redacted contents.
   * Apply Redactions
   */
  async applyRedaction(
    requestParameters: ApplyRedactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.applyRedactionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Deletes all items matching the specified search query.
   * Bulk Delete
   */
  async bulkDeleteRaw(
    requestParameters: BulkDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: string }>> {
    if (
      requestParameters.querySpec === null ||
      requestParameters.querySpec === undefined
    ) {
      throw new runtime.RequiredError(
        'querySpec',
        'Required parameter requestParameters.querySpec was null or undefined when calling bulkDelete.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/delete`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: QuerySpecToJSON(requestParameters.querySpec),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Deletes all items matching the specified search query.
   * Bulk Delete
   */
  async bulkDelete(
    requestParameters: BulkDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: string }> {
    const response = await this.bulkDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Post a CSV document in the same format as provided by the export functionality to update the review pane field values.
   * Bulk Metadata Import
   */
  async bulkMetadataImportRaw(
    requestParameters: BulkMetadataImportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: object }>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling bulkMetadataImport.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'text/csv';

    const response = await this.request(
      {
        path: `/import`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Post a CSV document in the same format as provided by the export functionality to update the review pane field values.
   * Bulk Metadata Import
   */
  async bulkMetadataImport(
    requestParameters: BulkMetadataImportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: object }> {
    const response = await this.bulkMetadataImportRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Converts a document to PDF.
   * Convert Document
   */
  async convertDocumentRaw(
    requestParameters: ConvertDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling convertDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/convert`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Converts a document to PDF.
   * Convert Document
   */
  async convertDocument(
    requestParameters: ConvertDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.convertDocumentRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Copies the specified entity to the specified destination.
   * Copy To
   */
  async copyToRaw(
    requestParameters: CopyToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling copyTo.',
      );
    }

    if (
      requestParameters.destination === null ||
      requestParameters.destination === undefined
    ) {
      throw new runtime.RequiredError(
        'destination',
        'Required parameter requestParameters.destination was null or undefined when calling copyTo.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/copy/{destination}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'destination'}}`,
            encodeURIComponent(String(requestParameters.destination)),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Copies the specified entity to the specified destination.
   * Copy To
   */
  async copyTo(
    requestParameters: CopyToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.copyToRaw(requestParameters, initOverrides);
  }

  /**
   * Creates a new document as a child of the specified parent.
   * Create Document
   */
  async createDocumentRaw(
    requestParameters: CreateDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling createDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/document`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewItemSpecToJSON(requestParameters.newItemSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Creates a new document as a child of the specified parent.
   * Create Document
   */
  async createDocument(
    requestParameters: CreateDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.createDocumentRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Creates a new folder as a child of the specified parent.
   * Create Folder
   */
  async createFolderRaw(
    requestParameters: CreateFolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling createFolder.',
      );
    }

    if (
      requestParameters.newItemSpec === null ||
      requestParameters.newItemSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'newItemSpec',
        'Required parameter requestParameters.newItemSpec was null or undefined when calling createFolder.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/folder`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewItemSpecToJSON(requestParameters.newItemSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Creates a new folder as a child of the specified parent.
   * Create Folder
   */
  async createFolder(
    requestParameters: CreateFolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.createFolderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Deletes a set of children from the specified parent.
   * Delete Children
   */
  async deleteChildrenRaw(
    requestParameters: DeleteChildrenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ErrorsSpec>> {
    if (
      requestParameters.folder === null ||
      requestParameters.folder === undefined
    ) {
      throw new runtime.RequiredError(
        'folder',
        'Required parameter requestParameters.folder was null or undefined when calling deleteChildren.',
      );
    }

    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling deleteChildren.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{folder}/delete`.replace(
          `{${'folder'}}`,
          encodeURIComponent(String(requestParameters.folder)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ErrorsSpecFromJSON(jsonValue),
    );
  }

  /**
   * Deletes a set of children from the specified parent.
   * Delete Children
   */
  async deleteChildren(
    requestParameters: DeleteChildrenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ErrorsSpec> {
    const response = await this.deleteChildrenRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Marks the specified comment as deleted/hidden.
   * Delete Comment
   */
  async deleteCommentRaw(
    requestParameters: DeleteCommentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling deleteComment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/notifications/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Marks the specified comment as deleted/hidden.
   * Delete Comment
   */
  async deleteComment(
    requestParameters: DeleteCommentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteCommentRaw(requestParameters, initOverrides);
  }

  /**
   * Deletes the specified item.
   * Delete Item
   */
  async deleteItemRaw(
    requestParameters: DeleteItemRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling deleteItem.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes the specified item.
   * Delete Item
   */
  async deleteItem(
    requestParameters: DeleteItemRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteItemRaw(requestParameters, initOverrides);
  }

  /**
   * Downloads a file or a specific version of a file.
   * Download File
   */
  async downloadRaw(
    requestParameters: DownloadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling download.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.preview !== undefined) {
      queryParameters['preview'] = requestParameters.preview;
    }

    if (requestParameters.vnum !== undefined) {
      queryParameters['vnum'] = requestParameters.vnum;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.range !== undefined &&
      requestParameters.range !== null
    ) {
      headerParameters['Range'] = String(requestParameters.range);
    }

    if (
      requestParameters.ifRange !== undefined &&
      requestParameters.ifRange !== null
    ) {
      headerParameters['if-range'] = String(requestParameters.ifRange);
    }

    const response = await this.request(
      {
        path: `/download/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Downloads a file or a specific version of a file.
   * Download File
   */
  async download(
    requestParameters: DownloadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.downloadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Expands a file archive to the specified destination.
   * Expand Archive To
   */
  async expandArchiveToRaw(
    requestParameters: ExpandArchiveToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling expandArchiveTo.',
      );
    }

    if (
      requestParameters.destination === null ||
      requestParameters.destination === undefined
    ) {
      throw new runtime.RequiredError(
        'destination',
        'Required parameter requestParameters.destination was null or undefined when calling expandArchiveTo.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/expand/{destination}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'destination'}}`,
            encodeURIComponent(String(requestParameters.destination)),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Expands a file archive to the specified destination.
   * Expand Archive To
   */
  async expandArchiveTo(
    requestParameters: ExpandArchiveToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.expandArchiveToRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves facets for the folder for the provided query.
   * Folder Facets
   */
  async facetFolderRaw(
    requestParameters: FacetFolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SearchResults>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling facetFolder.',
      );
    }

    if (
      requestParameters.folder === null ||
      requestParameters.folder === undefined
    ) {
      throw new runtime.RequiredError(
        'folder',
        'Required parameter requestParameters.folder was null or undefined when calling facetFolder.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.q !== undefined) {
      queryParameters['q'] = requestParameters.q;
    }

    if (requestParameters.recursive !== undefined) {
      queryParameters['recursive'] = requestParameters.recursive;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/search/{workspaceId}/{folder}/facets`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'folder'}}`,
            encodeURIComponent(String(requestParameters.folder)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchResultsFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves facets for the folder for the provided query.
   * Folder Facets
   */
  async facetFolder(
    requestParameters: FacetFolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SearchResults> {
    const response = await this.facetFolderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves facets for the whole workspace for the provided query.
   * Workspace Facets
   */
  async facetWorkspaceRaw(
    requestParameters: FacetWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SearchResults>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling facetWorkspace.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.q !== undefined) {
      queryParameters['q'] = requestParameters.q;
    }

    if (requestParameters.recursive !== undefined) {
      queryParameters['recursive'] = requestParameters.recursive;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/search/{workspaceId}/facets`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchResultsFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves facets for the whole workspace for the provided query.
   * Workspace Facets
   */
  async facetWorkspace(
    requestParameters: FacetWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SearchResults> {
    const response = await this.facetWorkspaceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of the activities/comments targeting the specified file.
   * Get File Activity
   */
  async getActivityRaw(
    requestParameters: GetActivityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultNotificationMessage>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getActivity.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/notifications/{entity}`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultNotificationMessageFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of the activities/comments targeting the specified file.
   * Get File Activity
   */
  async getActivity(
    requestParameters: GetActivityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultNotificationMessage> {
    const response = await this.getActivityRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves metadata about the children of the specified folder.
   * Get Child Metadata
   */
  async getChildMetadataRaw(
    requestParameters: GetChildMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultChild>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getChildMetadata.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fo !== undefined) {
      queryParameters['fo'] = requestParameters.fo;
    }

    if (requestParameters.start !== undefined) {
      queryParameters['start'] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.desc !== undefined) {
      queryParameters['desc'] = requestParameters.desc;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/metadata/{entity}/children`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultChildFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves metadata about the children of the specified folder.
   * Get Child Metadata
   */
  async getChildMetadata(
    requestParameters: GetChildMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultChild> {
    const response = await this.getChildMetadataRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves metadata and permissions for the specified user about the children of the specified folder.
   * Get Child Permissions
   */
  async getChildPermissionsRaw(
    requestParameters: GetChildPermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultChildPermission>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getChildPermissions.',
      );
    }

    if (
      requestParameters.principal === null ||
      requestParameters.principal === undefined
    ) {
      throw new runtime.RequiredError(
        'principal',
        'Required parameter requestParameters.principal was null or undefined when calling getChildPermissions.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fo !== undefined) {
      queryParameters['fo'] = requestParameters.fo;
    }

    if (requestParameters.start !== undefined) {
      queryParameters['start'] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.desc !== undefined) {
      queryParameters['desc'] = requestParameters.desc;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/permissions/{entity}/children/{principal}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'principal'}}`,
            encodeURIComponent(String(requestParameters.principal)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultChildPermissionFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves metadata and permissions for the specified user about the children of the specified folder.
   * Get Child Permissions
   */
  async getChildPermissions(
    requestParameters: GetChildPermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultChildPermission> {
    const response = await this.getChildPermissionsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the workspace to which the specified entity belongs.
   * Get Workspace
   */
  async getContainerWorkspaceRaw(
    requestParameters: GetContainerWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getContainerWorkspace.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/workspace`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the workspace to which the specified entity belongs.
   * Get Workspace
   */
  async getContainerWorkspace(
    requestParameters: GetContainerWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getContainerWorkspaceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a browser-digestible format of the contents of a file.
   * Get Contents
   */
  async getContentsRaw(
    requestParameters: GetContentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getContents.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.version !== undefined) {
      queryParameters['version'] = requestParameters.version;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/contents`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Retrieves a browser-digestible format of the contents of a file.
   * Get Contents
   */
  async getContents(
    requestParameters: GetContentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.getContentsRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves information about the contents of the specified redactable document.
   * Get Doc Stats
   */
  async getDocStatsRaw(
    requestParameters: GetDocStatsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PdfStats>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getDocStats.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.record !== undefined) {
      queryParameters['record'] = requestParameters.record;
    }

    if (requestParameters.person !== undefined) {
      queryParameters['person'] = requestParameters.person;
    }

    if (requestParameters.telephone !== undefined) {
      queryParameters['telephone'] = requestParameters.telephone;
    }

    if (requestParameters.cc !== undefined) {
      queryParameters['cc'] = requestParameters.cc;
    }

    if (requestParameters.sin !== undefined) {
      queryParameters['sin'] = requestParameters.sin;
    }

    if (requestParameters.ssn !== undefined) {
      queryParameters['ssn'] = requestParameters.ssn;
    }

    if (requestParameters.company !== undefined) {
      queryParameters['company'] = requestParameters.company;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.ipv4 !== undefined) {
      queryParameters['ipv4'] = requestParameters.ipv4;
    }

    if (requestParameters.ipv6 !== undefined) {
      queryParameters['ipv6'] = requestParameters.ipv6;
    }

    if (requestParameters.country !== undefined) {
      queryParameters['country'] = requestParameters.country;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.pass !== undefined &&
      requestParameters.pass !== null
    ) {
      headerParameters['pass'] = String(requestParameters.pass);
    }

    const response = await this.request(
      {
        path: `/files/{entity}/docstats`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PdfStatsFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves information about the contents of the specified redactable document.
   * Get Doc Stats
   */
  async getDocStats(
    requestParameters: GetDocStatsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PdfStats> {
    const response = await this.getDocStatsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of duplicates of the specified file.
   * Get Duplicates
   */
  async getDuplicatesRaw(
    requestParameters: GetDuplicatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultMapStringObject>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getDuplicates.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/duplicates`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultMapStringObjectFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of duplicates of the specified file.
   * Get Duplicates
   */
  async getDuplicates(
    requestParameters: GetDuplicatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultMapStringObject> {
    const response = await this.getDuplicatesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of users having been granted {verb} access to the specified entity.
   * Get Granted Users
   */
  async getGrantedUsersRaw(
    requestParameters: GetGrantedUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultMapStringObject>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getGrantedUsers.',
      );
    }

    if (
      requestParameters.verb === null ||
      requestParameters.verb === undefined
    ) {
      throw new runtime.RequiredError(
        'verb',
        'Required parameter requestParameters.verb was null or undefined when calling getGrantedUsers.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.includeInactive !== undefined) {
      queryParameters['includeInactive'] = requestParameters.includeInactive;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/granted/{verb}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'verb'}}`,
            encodeURIComponent(String(requestParameters.verb)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultMapStringObjectFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of users having been granted {verb} access to the specified entity.
   * Get Granted Users
   */
  async getGrantedUsers(
    requestParameters: GetGrantedUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultMapStringObject> {
    const response = await this.getGrantedUsersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the MD5 hash of the contents of the specified file.
   * Get MD5
   */
  async getMD5Raw(
    requestParameters: GetMD5Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getMD5.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/md5`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the MD5 hash of the contents of the specified file.
   * Get MD5
   */
  async getMD5(
    requestParameters: GetMD5Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getMD5Raw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves the specified entity\'s parent.
   * Get Parent
   */
  async getParentRaw(
    requestParameters: GetParentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getParent.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/parent`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the specified entity\'s parent.
   * Get Parent
   */
  async getParent(
    requestParameters: GetParentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getParentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of all of the specified entity\'s parents, up to the root folder of the workspace.
   * Get Parents
   */
  async getParentsRaw(
    requestParameters: GetParentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultFolderInformation>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getParents.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/parents`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultFolderInformationFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of all of the specified entity\'s parents, up to the root folder of the workspace.
   * Get Parents
   */
  async getParents(
    requestParameters: GetParentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultFolderInformation> {
    const response = await this.getParentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves the string representation of the path to the specified entity.
   * Get Path
   */
  async getPathRaw(
    requestParameters: GetPathRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getPath.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/path`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the string representation of the path to the specified entity.
   * Get Path
   */
  async getPath(
    requestParameters: GetPathRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getPathRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a thumbnail of the specified document.
   * Get Thumbnail
   */
  async getThumbnailRaw(
    requestParameters: GetThumbnailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getThumbnail.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.vnum !== undefined) {
      queryParameters['vnum'] = requestParameters.vnum;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/thumbnail`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Retrieves a thumbnail of the specified document.
   * Get Thumbnail
   */
  async getThumbnail(
    requestParameters: GetThumbnailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.getThumbnailRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves metadata about the specified file\'s versions
   * Get Versions Metadata
   */
  async getVersionsMetaRaw(
    requestParameters: GetVersionsMetaRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<{ [key: string]: { [key: string]: object } }>
  > {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling getVersionsMeta.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/versions`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves metadata about the specified file\'s versions
   * Get Versions Metadata
   */
  async getVersionsMeta(
    requestParameters: GetVersionsMetaRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: { [key: string]: object } }> {
    const response = await this.getVersionsMetaRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Locks or unlocks the specified resource.
   * Lock / Unlock
   */
  async lockRaw(
    requestParameters: LockRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling lock.',
      );
    }

    if (
      requestParameters.lockSpec === null ||
      requestParameters.lockSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'lockSpec',
        'Required parameter requestParameters.lockSpec was null or undefined when calling lock.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/lock`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: LockSpecToJSON(requestParameters.lockSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Locks or unlocks the specified resource.
   * Lock / Unlock
   */
  async lock(
    requestParameters: LockRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.lockRaw(requestParameters, initOverrides);
  }

  /**
   * Moves the specified entity to the specified destination.
   * Move To
   */
  async moveToRaw(
    requestParameters: MoveToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling moveTo.',
      );
    }

    if (
      requestParameters.destination === null ||
      requestParameters.destination === undefined
    ) {
      throw new runtime.RequiredError(
        'destination',
        'Required parameter requestParameters.destination was null or undefined when calling moveTo.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/move/{destination}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'destination'}}`,
            encodeURIComponent(String(requestParameters.destination)),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Moves the specified entity to the specified destination.
   * Move To
   */
  async moveTo(
    requestParameters: MoveToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.moveToRaw(requestParameters, initOverrides);
  }

  /**
   * Performs a bulk operation to all items matching the provided search query.
   * Bulk Operation
   */
  async performBulkOperationRaw(
    requestParameters: PerformBulkOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: string }>> {
    if (
      requestParameters.folder === null ||
      requestParameters.folder === undefined
    ) {
      throw new runtime.RequiredError(
        'folder',
        'Required parameter requestParameters.folder was null or undefined when calling performBulkOperation.',
      );
    }

    if (
      requestParameters.bulkOperationSpec === null ||
      requestParameters.bulkOperationSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'bulkOperationSpec',
        'Required parameter requestParameters.bulkOperationSpec was null or undefined when calling performBulkOperation.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{folder}/bulk`.replace(
          `{${'folder'}}`,
          encodeURIComponent(String(requestParameters.folder)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BulkOperationSpecToJSON(requestParameters.bulkOperationSpec),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Performs a bulk operation to all items matching the provided search query.
   * Bulk Operation
   */
  async performBulkOperation(
    requestParameters: PerformBulkOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: string }> {
    const response = await this.performBulkOperationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of items with confidence of entities recognized by a neural network.
   * Recognize
   */
  async recognizeRaw(
    requestParameters: RecognizeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: number }>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling recognize.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/recognize`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves a list of items with confidence of entities recognized by a neural network.
   * Recognize
   */
  async recognize(
    requestParameters: RecognizeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: number }> {
    const response = await this.recognizeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Restores the specified entity from the trash to a specified destination.
   * Restore To
   */
  async restoreToRaw(
    requestParameters: RestoreToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling restoreTo.',
      );
    }

    if (
      requestParameters.destination === null ||
      requestParameters.destination === undefined
    ) {
      throw new runtime.RequiredError(
        'destination',
        'Required parameter requestParameters.destination was null or undefined when calling restoreTo.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/files/{entity}/restore/{destination}`
          .replace(
            `{${'entity'}}`,
            encodeURIComponent(String(requestParameters.entity)),
          )
          .replace(
            `{${'destination'}}`,
            encodeURIComponent(String(requestParameters.destination)),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Restores the specified entity from the trash to a specified destination.
   * Restore To
   */
  async restoreTo(
    requestParameters: RestoreToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.restoreToRaw(requestParameters, initOverrides);
  }

  /**
   * Searches the folder for the provided query.
   * Search Folder
   */
  async searchFolderRaw(
    requestParameters: SearchFolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SearchResults>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling searchFolder.',
      );
    }

    if (
      requestParameters.folder === null ||
      requestParameters.folder === undefined
    ) {
      throw new runtime.RequiredError(
        'folder',
        'Required parameter requestParameters.folder was null or undefined when calling searchFolder.',
      );
    }

    if (requestParameters.q === null || requestParameters.q === undefined) {
      throw new runtime.RequiredError(
        'q',
        'Required parameter requestParameters.q was null or undefined when calling searchFolder.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.q !== undefined) {
      queryParameters['q'] = requestParameters.q;
    }

    if (requestParameters.recursive !== undefined) {
      queryParameters['recursive'] = requestParameters.recursive;
    }

    if (requestParameters.start !== undefined) {
      queryParameters['start'] = requestParameters.start;
    }

    if (requestParameters.end !== undefined) {
      queryParameters['end'] = requestParameters.end;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.fq !== undefined) {
      queryParameters['fq'] = requestParameters.fq;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/search/{workspaceId}/{folder}`
          .replace(
            `{${'workspaceId'}}`,
            encodeURIComponent(String(requestParameters.workspaceId)),
          )
          .replace(
            `{${'folder'}}`,
            encodeURIComponent(String(requestParameters.folder)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchResultsFromJSON(jsonValue),
    );
  }

  /**
   * Searches the folder for the provided query.
   * Search Folder
   */
  async searchFolder(
    requestParameters: SearchFolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SearchResults> {
    const response = await this.searchFolderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Searches the whole workspace for the provided query.
   * Search Workspace
   */
  async searchWorkspaceRaw(
    requestParameters: SearchWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SearchResults>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling searchWorkspace.',
      );
    }

    if (requestParameters.q === null || requestParameters.q === undefined) {
      throw new runtime.RequiredError(
        'q',
        'Required parameter requestParameters.q was null or undefined when calling searchWorkspace.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.q !== undefined) {
      queryParameters['q'] = requestParameters.q;
    }

    if (requestParameters.start !== undefined) {
      queryParameters['start'] = requestParameters.start;
    }

    if (requestParameters.end !== undefined) {
      queryParameters['end'] = requestParameters.end;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.fq !== undefined) {
      queryParameters['fq'] = requestParameters.fq;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/search/{workspaceId}`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchResultsFromJSON(jsonValue),
    );
  }

  /**
   * Searches the whole workspace for the provided query.
   * Search Workspace
   */
  async searchWorkspace(
    requestParameters: SearchWorkspaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SearchResults> {
    const response = await this.searchWorkspaceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Sets the list of email addresses allowed to answer the specified form.
   * Set Answerers
   */
  async setAnswerersRaw(
    requestParameters: SetAnswerersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling setAnswerers.',
      );
    }

    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling setAnswerers.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/answerers`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sets the list of email addresses allowed to answer the specified form.
   * Set Answerers
   */
  async setAnswerers(
    requestParameters: SetAnswerersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.setAnswerersRaw(requestParameters, initOverrides);
  }

  /**
   * Associated the provided protection level with the specified file.
   * Set Protection Level
   */
  async setProtectionLevelRaw(
    requestParameters: SetProtectionLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling setProtectionLevel.',
      );
    }

    if (
      requestParameters.protectionSpec === null ||
      requestParameters.protectionSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'protectionSpec',
        'Required parameter requestParameters.protectionSpec was null or undefined when calling setProtectionLevel.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/protection`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProtectionSpecToJSON(requestParameters.protectionSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Associated the provided protection level with the specified file.
   * Set Protection Level
   */
  async setProtectionLevel(
    requestParameters: SetProtectionLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.setProtectionLevelRaw(requestParameters, initOverrides);
  }

  /**
   * Creates a new version of the specified file whose contents are identical to the specified version number, rolling back to that version.
   * Set Version
   */
  async setVersionRaw(
    requestParameters: SetVersionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: object }>> {
    if (
      requestParameters.entity === null ||
      requestParameters.entity === undefined
    ) {
      throw new runtime.RequiredError(
        'entity',
        'Required parameter requestParameters.entity was null or undefined when calling setVersion.',
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling setVersion.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/files/{entity}/version`.replace(
          `{${'entity'}}`,
          encodeURIComponent(String(requestParameters.entity)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Creates a new version of the specified file whose contents are identical to the specified version number, rolling back to that version.
   * Set Version
   */
  async setVersion(
    requestParameters: SetVersionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: object }> {
    const response = await this.setVersionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Uploads a new file or a new version of a file.
   * Upload File
   */
  async uploadRaw(
    requestParameters: UploadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UploadStatus>> {
    if (
      requestParameters.target === null ||
      requestParameters.target === undefined
    ) {
      throw new runtime.RequiredError(
        'target',
        'Required parameter requestParameters.target was null or undefined when calling upload.',
      );
    }

    if (
      requestParameters.name === null ||
      requestParameters.name === undefined
    ) {
      throw new runtime.RequiredError(
        'name',
        'Required parameter requestParameters.name was null or undefined when calling upload.',
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling upload.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.target !== undefined &&
      requestParameters.target !== null
    ) {
      headerParameters['target'] = String(requestParameters.target);
    }

    if (
      requestParameters.name !== undefined &&
      requestParameters.name !== null
    ) {
      headerParameters['name'] = String(requestParameters.name);
    }

    if (
      requestParameters.upload !== undefined &&
      requestParameters.upload !== null
    ) {
      headerParameters['upload'] = String(requestParameters.upload);
    }

    if (
      requestParameters.filesize !== undefined &&
      requestParameters.filesize !== null
    ) {
      headerParameters['filesize'] = String(requestParameters.filesize);
    }

    if (
      requestParameters.index !== undefined &&
      requestParameters.index !== null
    ) {
      headerParameters['index'] = String(requestParameters.index);
    }

    if (
      requestParameters.count !== undefined &&
      requestParameters.count !== null
    ) {
      headerParameters['count'] = String(requestParameters.count);
    }

    if (
      requestParameters.rename !== undefined &&
      requestParameters.rename !== null
    ) {
      headerParameters['rename'] = String(requestParameters.rename);
    }

    if (
      requestParameters.original !== undefined &&
      requestParameters.original !== null
    ) {
      headerParameters['original'] = String(requestParameters.original);
    }

    if (
      requestParameters.encrypted !== undefined &&
      requestParameters.encrypted !== null
    ) {
      headerParameters['encrypted'] = String(requestParameters.encrypted);
    }

    if (
      requestParameters.level !== undefined &&
      requestParameters.level !== null
    ) {
      headerParameters['level'] = String(requestParameters.level);
    }

    const response = await this.request(
      {
        path: `/upload`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UploadStatusFromJSON(jsonValue),
    );
  }

  /**
   * Uploads a new file or a new version of a file.
   * Upload File
   */
  async upload(
    requestParameters: UploadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UploadStatus> {
    const response = await this.uploadRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetGrantedUsersVerbEnum {
  Read = 'Read',
  Write = 'Write',
  Delete = 'Delete',
}
/**
 * @export
 * @enum {string}
 */
export enum UploadLevelEnum {
  NONE = 'NONE',
  PROTECTED_A = 'PROTECTED_A',
  PROTECTED_B = 'PROTECTED_B',
}
