import { useTranslation } from '@oproma/i18n';
import {
  setPermissionLevelFilter,
  useAppDispatch,
} from '@oproma/prividox-store';
import { useState } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Col, Row } from '../grid.component';
import { Icon } from '../icon.component';
import { Select } from '../select.component';
import { permissionLevels } from './constants';

type SelectOption = {
  label: string;
  value: string;
};

export const MembersTableFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [permissionLevel, setPermissionLevel] = useState<SelectOption | null>(
    null,
  );

  const handlePermissionFilter = () => {
    if (permissionLevel?.value)
      dispatch(setPermissionLevelFilter(parseInt(permissionLevel.value, 10)));
  };

  const handleResetPermissionFilter = () => {
    setPermissionLevel(null);
    dispatch(setPermissionLevelFilter(null));
  };

  const translatedPermissionLevels = permissionLevels.map((level) => ({
    value: level.value,
    label: t(level.label),
  }));

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <div className="dot dot-primary"></div>
        <Icon name="filter-alt"></Icon>
      </DropdownToggle>
      <DropdownMenu
        end
        className="filter-wg dropdown-menu-xl"
        style={{ overflow: 'visible' }}
      >
        <div className="dropdown-head">
          <span className="sub-title dropdown-title">
            {t('MEMBERS.FILTER.FILTER_TITLE')}
          </span>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <div className="form-group">
                <label className="overline-title overline-title-alt">
                  {t('MEMBERS.FILTER.PERMISSION')}
                </label>
                <Select
                  options={translatedPermissionLevels}
                  placeholder={t('MEMBERS.FILTER.PERMISSION_PLACEHOLDER')}
                  isSearchable={false}
                  value={permissionLevel}
                  onChange={(value) => {
                    value && setPermissionLevel(value as SelectOption);
                  }}
                />
              </div>
            </Col>
            <Col size="12">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handlePermissionFilter}
                >
                  {t('MEMBERS.FILTER.FILTER_BUTTON')}
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="dropdown-foot between">
          <a
            href="#reset"
            onClick={(ev) => {
              ev.preventDefault();
              handleResetPermissionFilter();
            }}
            className="clickable"
          >
            {t('MEMBERS.FILTER.FILTER_RESET')}
          </a>
          {/* <a
            href="#save"
            onClick={(ev) => {
              ev.preventDefault();
            }}
          >
            Save Filter
          </a> */}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
