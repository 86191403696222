/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Result } from './Result';
import { ResultFromJSON, ResultFromJSONTyped, ResultToJSON } from './Result';

/**
 *
 * @export
 * @interface PermissionReportRow
 */
export interface PermissionReportRow {
  /**
   *
   * @type {string}
   * @memberof PermissionReportRow
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: Result; }}
   * @memberof PermissionReportRow
   */
  verbs?: { [key: string]: Result };
  /**
   *
   * @type {Array<PermissionReportRow>}
   * @memberof PermissionReportRow
   */
  children?: Array<PermissionReportRow>;
}

/**
 * Check if a given object implements the PermissionReportRow interface.
 */
export function instanceOfPermissionReportRow(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PermissionReportRowFromJSON(json: any): PermissionReportRow {
  return PermissionReportRowFromJSONTyped(json, false);
}

export function PermissionReportRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PermissionReportRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    verbs: !exists(json, 'verbs')
      ? undefined
      : mapValues(json['verbs'], ResultFromJSON),
    children: !exists(json, 'children')
      ? undefined
      : (json['children'] as Array<any>).map(PermissionReportRowFromJSON),
  };
}

export function PermissionReportRowToJSON(
  value?: PermissionReportRow | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    verbs:
      value.verbs === undefined
        ? undefined
        : mapValues(value.verbs, ResultToJSON),
    children:
      value.children === undefined
        ? undefined
        : (value.children as Array<any>).map(PermissionReportRowToJSON),
  };
}
