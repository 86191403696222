import {
  BlockTitle,
  FileManagerContainer,
  FileManagerFileActions,
  FileManagerFiles,
  FileManagerNavigationLayout,
  Layout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getWorkspaceDiskUsage,
  getWorkspaceEntities,
  getWorkspacePlan,
  setLastOpenedWorkspace,
  setSelectedEntityIds,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';

const EntitiesPreviewPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { entities } = useAppSelector((state) => state.fileManager);

  const filteredEntities = entities.filter(
    (entity) => !entity.metadata?.deleted,
  );

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceEntities(workspaceId as string));
    dispatch(getWorkspaceDiskUsage(workspaceId));
    dispatch(getWorkspacePlan(workspaceId));
    dispatch(setLastOpenedWorkspace(workspaceId));
    dispatch(setSelectedEntityIds([]));
  }, [workspaceId]);

  return (
    <Layout>
      <FileManagerContainer>
        <FileManagerNavigationLayout
          title={<BlockTitle page>{t('FILE_MANAGER.FILES.TITLE')}</BlockTitle>}
          entityFinder
          displayTypeFilter
        >
          <FileManagerFiles entities={filteredEntities} />
        </FileManagerNavigationLayout>
      </FileManagerContainer>
    </Layout>
  );
};

export default EntitiesPreviewPage;
