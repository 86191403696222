import { useTranslation } from '@oproma/i18n';
import {
  setMemberSortValue,
  setMemberSortOrder,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Icon } from '../icon.component';

export const MembersTableSort = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { memberSortValue, memberSortOrder } = useAppSelector(
    (state) => state.members,
  );

  const handleSelect = (sortValue: string) => {
    if (sortValue === memberSortValue) {
      const newSortOrder = memberSortOrder === 'asc' ? 'desc' : 'asc';
      dispatch(setMemberSortOrder(newSortOrder));
    } else {
      dispatch(setMemberSortValue(sortValue));
      dispatch(setMemberSortOrder('asc'));
    }
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <div className="dot dot-primary"></div>
        <Icon name="sort-v"></Icon>
      </DropdownToggle>
      <DropdownMenu
        end
        className="dropdown-menu-m"
        style={{ overflow: 'visible' }}
      >
        <ul className="link-check">
          <li>
            <span>{t('MEMBERS.SORT.SORT_TITLE')}</span>
          </li>
          <li className={clsx({ active: memberSortValue === 'name' })}>
            <DropdownItem
              tag="a"
              onClick={(ev) => {
                ev.preventDefault();
                handleSelect('name');
              }}
            >
              <span>
                {t('MEMBERS.SORT.NAME')}{' '}
                {memberSortValue === 'name' &&
                  (memberSortOrder === 'asc' ? (
                    <Icon name="sort-up-fill"></Icon>
                  ) : (
                    <Icon name="sort-down-fill"></Icon>
                  ))}
              </span>
            </DropdownItem>
          </li>
          <li className={clsx({ active: memberSortValue === 'email' })}>
            <DropdownItem
              tag="a"
              onClick={(ev) => {
                ev.preventDefault();
                handleSelect('email');
              }}
            >
              <span>
                {t('MEMBERS.SORT.EMAIL')}{' '}
                {memberSortValue === 'email' &&
                  (memberSortOrder === 'asc' ? (
                    <Icon name="sort-up-fill"></Icon>
                  ) : (
                    <Icon name="sort-down-fill"></Icon>
                  ))}
              </span>
            </DropdownItem>
          </li>
          <li
            className={clsx({ active: memberSortValue === 'workspaceOwner' })}
          >
            <DropdownItem
              tag="a"
              onClick={(ev) => {
                ev.preventDefault();
                handleSelect('workspaceOwner');
              }}
            >
              <span>
                {t('MEMBERS.SORT.WORKSPACE_OWNER')}{' '}
                {memberSortValue === 'workspaceOwner' &&
                  (memberSortOrder === 'asc' ? (
                    <Icon name="sort-up-fill"></Icon>
                  ) : (
                    <Icon name="sort-down-fill"></Icon>
                  ))}
              </span>
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
