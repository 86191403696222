import {
  AddCCardRequest,
  DeleteCCardRequest,
  ListCCardsRequest,
  ListTransactionRequest,
  UpdateCCardRequest,
} from '@oproma/prividox-orchestration-open-api';
import { financeApi } from './config';

const fetchPricingPlans = () => {
  return financeApi.listPlans();
};

const fetchTransactions = (payload: ListTransactionRequest) => {
  return financeApi.listTransaction(payload);
};

const fetchCreditCards = (payload: ListCCardsRequest) => {
  return financeApi.listCCards(payload);
};

const fetchCreditCardCount = () => {
  return financeApi.getCCardCount();
};

const putCreditCard = async (payload: AddCCardRequest) => {
  const response = await financeApi.addCCardRaw(payload);
  return response.raw.ok;
};

const patchCreditCard = async (payload: UpdateCCardRequest) => {
  const response = await financeApi.updateCCardRaw(payload);
  return response.raw.ok;
};

const deleteCreditCard = async (payload: DeleteCCardRequest) => {
  const response = await financeApi.deleteCCardRaw(payload);
  return response.raw.ok;
};

export const financeService = {
  fetchPricingPlans,
  fetchTransactions,
  fetchCreditCards,
  fetchCreditCardCount,
  putCreditCard,
  patchCreditCard,
  deleteCreditCard,
};
