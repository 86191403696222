/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ActivateAccountSpec
 */
export interface ActivateAccountSpec {
  /**
   * The password to associate with the user. Required when activating an invited user.
   * @type {string}
   * @memberof ActivateAccountSpec
   */
  pass?: string;
  /**
   * The display name to associate with the user. Required when activating an invited user.
   * @type {string}
   * @memberof ActivateAccountSpec
   */
  name?: string;
}

/**
 * Check if a given object implements the ActivateAccountSpec interface.
 */
export function instanceOfActivateAccountSpec(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ActivateAccountSpecFromJSON(json: any): ActivateAccountSpec {
  return ActivateAccountSpecFromJSONTyped(json, false);
}

export function ActivateAccountSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivateAccountSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pass: !exists(json, 'pass') ? undefined : json['pass'],
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function ActivateAccountSpecToJSON(
  value?: ActivateAccountSpec | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pass: value.pass,
    name: value.name,
  };
}
