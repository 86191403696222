/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { ResultTaskInformation, TaskSpec } from '../models/index';
import {
  ResultTaskInformationFromJSON,
  ResultTaskInformationToJSON,
  TaskSpecFromJSON,
  TaskSpecToJSON,
} from '../models/index';

export interface CreateTaskRequest {
  workspaceId: string;
  taskSpec: TaskSpec;
}

export interface GetTasksRequest {
  workspaceId: string;
  all?: boolean;
  sort?: string;
  desc?: boolean;
}

/**
 *
 */
export class TasksApi extends runtime.BaseAPI {
  /**
   * Creates a new task
   * Create Task
   */
  async createTaskRaw(
    requestParameters: CreateTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling createTask.',
      );
    }

    if (
      requestParameters.taskSpec === null ||
      requestParameters.taskSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'taskSpec',
        'Required parameter requestParameters.taskSpec was null or undefined when calling createTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/tasks`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TaskSpecToJSON(requestParameters.taskSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Creates a new task
   * Create Task
   */
  async createTask(
    requestParameters: CreateTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.createTaskRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of tasks for the workspace.
   * Get Tasks
   */
  async getTasksRaw(
    requestParameters: GetTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultTaskInformation>> {
    if (
      requestParameters.workspaceId === null ||
      requestParameters.workspaceId === undefined
    ) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getTasks.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.all !== undefined) {
      queryParameters['all'] = requestParameters.all;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.desc !== undefined) {
      queryParameters['desc'] = requestParameters.desc;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/tasks`.replace(
          `{${'workspaceId'}}`,
          encodeURIComponent(String(requestParameters.workspaceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultTaskInformationFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of tasks for the workspace.
   * Get Tasks
   */
  async getTasks(
    requestParameters: GetTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultTaskInformation> {
    const response = await this.getTasksRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
