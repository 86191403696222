/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthFooter,
  AuthLayout,
  AuthLogo,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Head,
  Icon,
  PreviewCard,
  retrieveApiErrorMessage,
  useRegisterForm,
} from '@oproma/core-components';
import { FeatureToggles } from '@oproma/feature-toggles';
import { useTranslation } from '@oproma/i18n';
import {
  AuthErrorCodesEnum,
  HashActionsEnum,
  registerConfirmation,
  setCompletedEmailConfirmation,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link } from '@oproma/router';
import { useFlag } from '@unleash/proxy-client-react';
import { useEffect, useState } from 'react';

import {
  Alert,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';

const RegisterPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // Managing the registration as member
  const [registerAsMember, setRegisterAsMember] = useState<string>();
  const {
    errors,
    reset,
    nameRef,
    password,
    emailRef,
    ReCAPTCHA,
    passwordRef,
    handleSubmit,
    onFormSubmit,
    registerName,
    registerEmail,
    confirmPassword,
    registerPassword,
    passwordConfirmRef,
    displayPasswordText,
    registerConfirmPassword,
    displayConfirmPasswordText,
    toggleDisplayingPasswordText,
    resetRegistrationEmailDispatched,
    toggleDisplayingConfirmPasswordText,
  } = useRegisterForm(registerAsMember);

  const {
    error,
    loading,
    emailConfirmed,
    completedEmailConfirmation,
    registrationEmailDispatched,
  } = useAppSelector((state) => state.auth);

  // TODO: Move to redux auth state
  const [displayEmailConfirmedModal, setDisplayEmailConfirmedModal] =
    useState<boolean>(emailConfirmed && !completedEmailConfirmation);
  const [displayPasswordRequirements, setDisplayPasswordRequirements] =
    useState<boolean>(false);
  const [
    displayConfirmPasswordRequirements,
    setDisplayConfirmPasswordRequirements,
  ] = useState<boolean>(false);

  const isConfirmPasswordEnabled = useFlag(
    FeatureToggles.PRIVIDOX_CONFIRM_REGISTRATION,
  );

  useEffect(() => {
    if (completedEmailConfirmation) {
      setDisplayEmailConfirmedModal(false);
    } else {
      setDisplayEmailConfirmedModal(emailConfirmed);
    }
  }, [emailConfirmed, completedEmailConfirmation]);

  useEffect(() => {
    const processConfirmationEncoding = () => {
      const url = new URL(window.location.href);
      const hash = url.hash.substring(1);
      if (hash) {
        const [action, code] = hash.split('&');
        if (action === HashActionsEnum.CONFIRM_REGISTRATION) {
          const decodedCode = decodeURIComponent(escape(window.atob(code)));

          if (isConfirmPasswordEnabled) {
            dispatch(
              registerConfirmation({
                user: decodedCode,
                activateAccountSpec: {},
              }),
            );
          }
        } else if (action === HashActionsEnum.REGISTER_AS_MEMEBER) {
          const decodedUser = decodeURIComponent(escape(window.atob(code)));
          setRegisterAsMember(decodedUser);
        }
      }
    };

    processConfirmationEncoding();
  }, []);

  const toggle = () => {
    dispatch(setCompletedEmailConfirmation(true));
    setDisplayEmailConfirmedModal(!displayEmailConfirmedModal);
    reset();
  };

  return (
    <>
      <Head heading={t('AUTH.REGISTER.TITLE')} />
      <AuthLayout>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo pb-4 text-center">
            <AuthLogo />
          </div>
          {registrationEmailDispatched && !emailConfirmed ? (
            <BlockHead>
              {error?.code ===
                AuthErrorCodesEnum.REGISTRATION_CONFIRMATION_FAILED && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(retrieveApiErrorMessage(error.message)),
                      }}
                    />
                  </Alert>
                </div>
              )}
              <BlockContent>
                <BlockTitle tag="h4">
                  {t('AUTH.REGISTER.REGISTER_CONFIRMATION.HEADING')}
                </BlockTitle>
                <BlockDes className="text-success">
                  <p>{t('AUTH.REGISTER.REGISTER_CONFIRMATION.BODY')}</p>
                  <Link
                    className="link link-primary link-sm"
                    to="/auth/register"
                    onClick={resetRegistrationEmailDispatched}
                  >
                    {t('AUTH.REGISTER.REGISTER_CONFIRMATION.REDO')}
                  </Link>
                </BlockDes>
              </BlockContent>
            </BlockHead>
          ) : (
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">{t('AUTH.REGISTER.HEADING')}</BlockTitle>
                  <BlockDes>
                    <p>{t('AUTH.REGISTER.SUBHEADING')}</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              {error?.code === AuthErrorCodesEnum.REGISTRATION_FAILED && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(retrieveApiErrorMessage(error.message)),
                      }}
                    ></p>
                  </Alert>
                </div>
              )}
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <FormGroup>
                  <Label className="form-label" for="name">
                    {t('AUTH.REGISTER.FORM.FULL_NAME')}
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      type="text"
                      id="name"
                      placeholder={t(
                        'AUTH.REGISTER.FORM.FULL_NAME_PLACEHOLDER',
                      )}
                      className="form-control-lg"
                      innerRef={nameRef}
                      {...registerName}
                    />
                    {errors.name && (
                      <p className="invalid">{errors.name.message}</p>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-label-group">
                    <Label className="form-label" for="email">
                      {t('AUTH.REGISTER.FORM.EMAIL')}
                    </Label>
                  </div>
                  <div className="form-control-wrap">
                    <Input
                      id="email"
                      type="email"
                      // bsSize="lg"
                      className="form-control-lg"
                      placeholder={t('AUTH.REGISTER.FORM.EMAIL_PLACEHOLDER')}
                      innerRef={emailRef}
                      {...registerEmail}
                    />
                    {errors.email && (
                      <p className="invalid">{errors.email.message}</p>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-label-group">
                    <Label className="form-label" for="password">
                      {t('AUTH.REGISTER.FORM.PASSWORD')}
                    </Label>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#password"
                      onClick={toggleDisplayingPasswordText}
                      className={`form-icon lg form-icon-right passcode-switch ${
                        displayPasswordText ? 'is-hidden' : 'is-shown'
                      }`}
                    >
                      <Icon
                        name="eye"
                        className="passcode-icon icon-show"
                      ></Icon>

                      <Icon
                        name="eye-off"
                        className="passcode-icon icon-hide"
                      ></Icon>
                    </a>
                    <Input
                      type={displayPasswordText ? 'text' : 'password'}
                      id="password"
                      placeholder={t('AUTH.REGISTER.FORM.PASSWORD_PLACEHOLDER')}
                      className={`form-control-lg ${
                        displayPasswordText ? 'is-hidden' : 'is-shown'
                      }`}
                      innerRef={passwordRef}
                      {...registerPassword}
                      onFocus={() => setDisplayPasswordRequirements(true)}
                      onBlur={() => setDisplayPasswordRequirements(false)}
                    />
                    {errors.password && (
                      <span className="invalid">{errors.password.message}</span>
                    )}
                    {displayPasswordRequirements && password.length < 12 && (
                      <div className="password-requirements">
                        {t('AUTH.REGISTER.FORM.PASSWORD_ERROR')}
                      </div>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-label-group">
                    <Label className="form-label" for="confirmPassword">
                      {t('AUTH.REGISTER.FORM.CONFIRM_PASSWORD')}
                    </Label>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#password"
                      onClick={toggleDisplayingConfirmPasswordText}
                      className={`form-icon lg form-icon-right passcode-switch ${
                        displayConfirmPasswordText ? 'is-hidden' : 'is-shown'
                      }`}
                    >
                      <Icon
                        name="eye"
                        className="passcode-icon icon-show"
                      ></Icon>

                      <Icon
                        name="eye-off"
                        className="passcode-icon icon-hide"
                      ></Icon>
                    </a>
                    <Input
                      type={displayConfirmPasswordText ? 'text' : 'password'}
                      id="password"
                      placeholder={t(
                        'AUTH.REGISTER.FORM.CONFIRM_PASSWORD_PLACEHOLDER',
                      )}
                      className={`form-control-lg ${
                        displayConfirmPasswordText ? 'is-hidden' : 'is-shown'
                      }`}
                      innerRef={passwordConfirmRef}
                      {...registerConfirmPassword}
                      onFocus={() =>
                        setDisplayConfirmPasswordRequirements(true)
                      }
                      onBlur={() =>
                        setDisplayConfirmPasswordRequirements(false)
                      }
                    />
                    {errors.password && (
                      <span className="invalid">{errors.password.message}</span>
                    )}
                    {displayConfirmPasswordRequirements &&
                      confirmPassword.length < 12 && (
                        <div className="password-requirements">
                          {t('AUTH.REGISTER.FORM.CONFIRM_PASSWORD_ERROR')}
                        </div>
                      )}
                  </div>
                </FormGroup>
                <FormGroup>{ReCAPTCHA}</FormGroup>
                <FormGroup>
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    className="btn-block"
                  >
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      t('AUTH.REGISTER.BUTTON')
                    )}
                  </Button>
                </FormGroup>
              </Form>
              <div className="form-note-s2 pt-4 text-center">
                {t('AUTH.REGISTER.LOGIN_LINK.TEXT')}{' '}
                <Link to="/" className="link link-primary">
                  <strong>{t('AUTH.REGISTER.LOGIN_LINK.LINK')}</strong>
                </Link>
              </div>
            </PreviewCard>
          )}
        </Block>
        <AuthFooter />
      </AuthLayout>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={displayEmailConfirmedModal}
        toggle={toggle}
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              toggle();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              {t('AUTH.REGISTER.EMAIL_REGISTRATION_CONFIRMATION.HEADING')}
            </h5>
            <div className="mt-4">
              <p>{t('AUTH.REGISTER.EMAIL_REGISTRATION_CONFIRMATION.BODY')}</p>
              <Link to="/">
                <Button color="primary" size="md" type="submit">
                  {t('AUTH.REGISTER.EMAIL_REGISTRATION_CONFIRMATION.CTA')}
                </Button>
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RegisterPage;
