import clsx from 'clsx';
import React from 'react';
import { Card } from 'reactstrap';

interface DataTableProps {
  className?: string;
  bodyClassName?: string;
  title?: string;
  children: React.ReactNode;
}

interface DataTableTitleProps {
  children: React.ReactNode;
}

interface DataTableBodyProps {
  compact?: boolean;
  className?: string;
  bodyclass?: string;
  children: React.ReactNode;
}

interface DataTableRowProps {
  className?: string;
  size?: number | string;
  children: React.ReactNode;
}

interface DataTableItemProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const DataTable = ({
  className,
  bodyClassName,
  title,
  ...props
}: DataTableProps) => {
  return (
    <Card className={`card-bordered ${className ? className : ''}`}>
      <div className="card-inner-group">{props.children}</div>
    </Card>
  );
};

export const DataTableTitle = ({ ...props }: DataTableTitleProps) => {
  return (
    <div className="card-inner position-relative card-tools-toggle">
      <div className="card-title-group">{props.children}</div>
    </div>
  );
};

export const DataTableBody = ({
  compact,
  className,
  bodyclass,
  ...props
}: DataTableBodyProps) => {
  return (
    <div className={`card-inner p-0 ${className ? className : ''}`}>
      <div
        className={`nk-tb-list nk-tb-ulist ${bodyclass ? bodyclass : ''} ${
          compact ? 'is-compact' : ''
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export const DataTableHead = ({ ...props }: DataTableTitleProps) => {
  return <div className="nk-tb-item nk-tb-head">{props.children}</div>;
};

export const DataTableRow = ({
  className,
  size,
  ...props
}: DataTableRowProps) => {
  const rowClass = clsx({
    'nk-tb-col': true,
    [`${className}`]: className,
    [`tb-col-${size}`]: size,
  });
  return <div className={rowClass}>{props.children}</div>;
};

export const DataTableItem = ({
  className,
  onClick,
  ...props
}: DataTableItemProps) => {
  return (
    <div
      className={`nk-tb-item ${className ? className : ''}`}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
};
