import {
  GetGroupsRequest,
  NameSpec,
} from '@oproma/prividox-orchestration-open-api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { extractApiError, groupsService } from '../api';
import {
  GroupMembers,
  GroupsErrorCodesEnum,
  IGroupsState,
  SortGroupOrder,
} from './types';
import { AppState } from '../store';

const initialState: IGroupsState = {
  groups: [],
  groupId: '',
  error: null,
  loading: false,
  groupQuery: '',
  groupReport: [],
  groupMembers: [],
  onboardedGroups: [],
  groupSortOrder: 'asc',
  lastOpenedGroup: null,
  currentPaginatedPage: 1,
  deletedGroupMessage: false,
  displayDeleteGroupModal: false,
  displayBulkDeleteGroupModal: false,
  displayEditGroupModal: false,
  displayGroupFinder: false,
  entriesPerPaginatedPage: 10,
  permissionLevelFilter: null,
  displayMobileGroupsTable: false,
  displayOnboardGroupsModal: false,
  displayDeleteGroupMemberModal: false,
};

export const getWorkspaceGroups = createAsyncThunk(
  '@@groups/getWorkspaceGroups',
  async (payload: GetGroupsRequest, { rejectWithValue }) => {
    try {
      const response = await groupsService.fetchWorkspaceGroups(payload);

      if (!response.list || !response.list.length) {
        return rejectWithValue('Failed to fetch workspace groups.');
      }

      const groups = response.list.map((group) => ({
        ...group,
        thumbnailBackground: 'purple',
        thumbnail: `https://ui-avatars.com/api/?name=${encodeURIComponent(
          group.name || '',
        )}`,
        checked: false,
      }));

      return groups;
    } catch (e) {
      console.error((e as Error).message);
      return rejectWithValue("Failed to get workspace's groups.");
    }
  },
);

export const getGroupMembers = createAsyncThunk(
  '@@groups/getGroupMembers',
  async (group: string, { rejectWithValue }) => {
    try {
      const rawGroupMembers = await groupsService.fetchGroupMembers(group);

      if (!rawGroupMembers) {
        return rejectWithValue('Failed to fetch group members.');
      }

      const groupMembers: GroupMembers[] =
        rawGroupMembers.items?.map((groupMember) => ({
          ...groupMember,
          thumbnailBackground: 'purple',
          thumbnail: `https://ui-avatars.com/api/?name=${encodeURIComponent(
            groupMember.name || '',
          )}`,
          checked: false,
        })) || [];

      return groupMembers;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const createGroupMember = createAsyncThunk(
  '@@groups/createGroupMember',
  async (
    { groupId, body }: { groupId: string; body: string },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { lastOpenedWorkspace: workspaceId } = (getState() as AppState)
        .workspaces;
      await groupsService.createGroupMember(groupId, body);
      if (workspaceId) {
        dispatch(getWorkspaceGroups({ workspaceId }));
      }
      await groupsService.createGroupMember(groupId, body);
      dispatch(getGroupMembers(groupId));
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const deleteGroupMember = createAsyncThunk(
  '@@groups/deleteGroupMember',
  async (
    { groupId, user }: { groupId: string; user: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await groupsService.deleteGroupMember(groupId, user);
      dispatch(getGroupMembers(groupId));
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const deleteGroup = createAsyncThunk(
  '@@groups/deleteGroup',
  async (
    { workspaceId, group }: { workspaceId: string; group: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await groupsService.deleteGroup(workspaceId, group);
      dispatch(getWorkspaceGroups({ workspaceId }));
      dispatch(setDeletedMessage(true));
      return response;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const renameGroup = createAsyncThunk(
  '@@groups/renameGroup',
  async (
    {
      groupId,
      nameSpec,
      workspaceId,
    }: { workspaceId: string; groupId: string; nameSpec: NameSpec },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await groupsService.renameGroup(groupId, nameSpec);
      if (!response) {
        return rejectWithValue('Failed to rename group.');
      }
      dispatch(getWorkspaceGroups({ workspaceId }));
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const createGroup = createAsyncThunk(
  '@@groups/createGroup',
  async (
    { workspaceId, nameSpec }: { workspaceId: string; nameSpec: NameSpec },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const groups = await groupsService.fetchWorkspaceGroups({ workspaceId });
      const existingGroup = groups.list?.find(
        (group) => group.name === nameSpec.name,
      );
      if (!existingGroup) {
        const response = await groupsService.createGroup(workspaceId, nameSpec);
        dispatch(getWorkspaceGroups({ workspaceId }));
        return response;
      } else {
        toast.error('Group with the same name already exists');
      }
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editDefaultGroup = createAsyncThunk(
  '@@groups/editDefaultGroup',
  async (
    { workspaceId, body }: { workspaceId: string; body: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await groupsService.patchDefaultGroup(workspaceId, body);
      dispatch(getWorkspaceGroups({ workspaceId }));
      return response;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

const groupsSlice = createSlice({
  name: '@@groups',
  initialState,
  reducers: {
    setLastOpenedGroup: (state, action: PayloadAction<string | null>) => {
      state.lastOpenedGroup = action.payload;
    },
    setDisplayOnboardGroupsModal: (state, action: PayloadAction<boolean>) => {
      state.displayOnboardGroupsModal = action.payload;
    },
    setDisplayEditGroupModal: (state, action: PayloadAction<boolean>) => {
      state.displayEditGroupModal = action.payload;
    },
    setDisplayGroupFinder: (state, action: PayloadAction<boolean>) => {
      state.displayGroupFinder = action.payload;
    },
    setOpenDeleteGroupModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteGroupModal = action.payload;
    },
    setOpenBulkDeleteGroupModal: (state, action: PayloadAction<boolean>) => {
      state.displayBulkDeleteGroupModal = action.payload;
    },
    setOpenDeleteGroupMemberModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteGroupMemberModal = action.payload;
    },
    setDisplayMobileGroupsTable: (state, action: PayloadAction<boolean>) => {
      state.displayMobileGroupsTable = action.payload;
    },
    setGroupQuery: (state, action: PayloadAction<string>) => {
      state.groupQuery = action.payload;
    },
    setDeletedMessage: (state, action: PayloadAction<boolean>) => {
      state.deletedGroupMessage = action.payload;
    },
    setGroupPaginatedPage: (state, action: PayloadAction<number>) => {
      state.currentPaginatedPage = action.payload;
    },
    setGroupEntriesPerPaginatedPage: (state, action: PayloadAction<number>) => {
      state.entriesPerPaginatedPage = action.payload;
    },
    setGroupSortOrder: (state, action: PayloadAction<SortGroupOrder>) => {
      state.groupSortOrder = action.payload;
    },
    setGroupPermissionLevelFilter: (
      state,
      action: PayloadAction<boolean | null>,
    ) => {
      state.permissionLevelFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspaceGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload;
        state.error = null;
      })
      .addCase(getWorkspaceGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.GET_WORKSPACE_GROUPS_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(createGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupId = action.payload as string;
        state.error = null;
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.CREATE_GROUP_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getGroupMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGroupMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.groupMembers = action.payload as [];
        state.error = null;
      })
      .addCase(getGroupMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.GET_GROUP_MEMBERS_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(deleteGroupMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGroupMember.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteGroupMember.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.DELETE_GROUP_MEMBERS_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(renameGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(renameGroup.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(renameGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.RENAME_GROUP_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(deleteGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGroup.fulfilled, (state) => {
        state.loading = false;
        state.deletedGroupMessage = true;
        state.error = null;
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.DELETE_GROUP_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(createGroupMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGroupMember.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createGroupMember.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.CREATE_GROUP_MEMBER_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(editDefaultGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editDefaultGroup.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editDefaultGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: GroupsErrorCodesEnum.EDIT_DEFAULT_GROUP_FAILED,
          message: action.payload as string,
        };
      });
  },
});

export const {
  setDeletedMessage,
  setOpenDeleteGroupModal,
  setOpenBulkDeleteGroupModal,
  setGroupQuery,
  setGroupSortOrder,
  setLastOpenedGroup,
  setGroupPaginatedPage,
  setDisplayGroupFinder,
  setDisplayEditGroupModal,
  setDisplayMobileGroupsTable,
  setDisplayOnboardGroupsModal,
  setOpenDeleteGroupMemberModal,
  setGroupPermissionLevelFilter,
  setGroupEntriesPerPaginatedPage,
} = groupsSlice.actions;

export const groupsReducer = groupsSlice.reducer;
