/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DiskUsageSpec
 */
export interface DiskUsageSpec {
  /**
   *
   * @type {number}
   * @memberof DiskUsageSpec
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof DiskUsageSpec
   */
  folderCount?: number;
  /**
   *
   * @type {number}
   * @memberof DiskUsageSpec
   */
  fileCount?: number;
}

/**
 * Check if a given object implements the DiskUsageSpec interface.
 */
export function instanceOfDiskUsageSpec(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function DiskUsageSpecFromJSON(json: any): DiskUsageSpec {
  return DiskUsageSpecFromJSONTyped(json, false);
}

export function DiskUsageSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DiskUsageSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    size: !exists(json, 'size') ? undefined : json['size'],
    folderCount: !exists(json, 'folderCount') ? undefined : json['folderCount'],
    fileCount: !exists(json, 'fileCount') ? undefined : json['fileCount'],
  };
}

export function DiskUsageSpecToJSON(value?: DiskUsageSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    size: value.size,
    folderCount: value.folderCount,
    fileCount: value.fileCount,
  };
}
