/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AnswersSpec
 */
export interface AnswersSpec {
  /**
   * The collected answers as a CSV row.
   * @type {string}
   * @memberof AnswersSpec
   */
  answers: string;
  /**
   * The email of the person submitting answers. Required when the publication is restricted to certain email addresses.
   * @type {string}
   * @memberof AnswersSpec
   */
  email?: string;
  /**
   * The name to give to the answers file. Required when submitting the CSV headers at publication time.
   * @type {string}
   * @memberof AnswersSpec
   */
  name?: string;
}

/**
 * Check if a given object implements the AnswersSpec interface.
 */
export function instanceOfAnswersSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'answers' in value;

  return isInstance;
}

export function AnswersSpecFromJSON(json: any): AnswersSpec {
  return AnswersSpecFromJSONTyped(json, false);
}

export function AnswersSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnswersSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    answers: json['answers'],
    email: !exists(json, 'email') ? undefined : json['email'],
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function AnswersSpecToJSON(value?: AnswersSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    answers: value.answers,
    email: value.email,
    name: value.name,
  };
}
