import {
  AuthLayout,
  Block,
  BlockContent,
  Button,
  Head,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import { Link } from '@oproma/router';
import ErrorMedia from '../assets/error-404.svg';

const Error = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head heading={t('ERROR.404.TITLE')} />
      <AuthLayout>
        <Block className="nk-block nk-block-middle wide-md mx-auto">
          <BlockContent className="nk-block-content nk-error-ld text-center">
            <img
              className="nk-error-gfx"
              src={ErrorMedia}
              alt="error"
              width={500}
              height={300}
            />
            <div className="wide-xs mx-auto">
              <h3 className="nk-error-title">{t('ERROR.404.HEADING')}</h3>
              <p className="nk-error-text">{t('ERROR.404.BODY')}</p>
              <Link to="/">
                <Button color="primary" size="lg" className="mt-2">
                  {t('COMMON.BACK_TO_LANDING')}
                </Button>
              </Link>
            </div>
          </BlockContent>
        </Block>
      </AuthLayout>
    </>
  );
};

export default Error;
