/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SubmitEmailUpdateSpec
 */
export interface SubmitEmailUpdateSpec {
  /**
   * The old/current email address of the user being updated.
   * @type {string}
   * @memberof SubmitEmailUpdateSpec
   */
  old: string;
  /**
   * The new email address of to associate with the user.
   * @type {string}
   * @memberof SubmitEmailUpdateSpec
   */
  email: string;
}

/**
 * Check if a given object implements the SubmitEmailUpdateSpec interface.
 */
export function instanceOfSubmitEmailUpdateSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'old' in value;
  isInstance = isInstance && 'email' in value;

  return isInstance;
}

export function SubmitEmailUpdateSpecFromJSON(
  json: any,
): SubmitEmailUpdateSpec {
  return SubmitEmailUpdateSpecFromJSONTyped(json, false);
}

export function SubmitEmailUpdateSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubmitEmailUpdateSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    old: json['old'],
    email: json['email'],
  };
}

export function SubmitEmailUpdateSpecToJSON(
  value?: SubmitEmailUpdateSpec | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    old: value.old,
    email: value.email,
  };
}
