import {
  setDisplayType,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { Icon } from '../icon.component';

export const displayTypeOptions = [
  {
    title: 'Grid View',
    icon: 'view-grid3-wd',
    option: 'grid',
  },
  {
    title: 'Group View',
    icon: 'view-group-wd',
    option: 'group',
  },
  {
    title: 'List View',
    icon: 'view-row-wd',
    option: 'list',
  },
];

type FileManagerDisplayTypeFilterProps = {
  displayTooltip?: boolean;
};
export const FileManagerDisplayTypeFilter = ({
  displayTooltip = false,
}: FileManagerDisplayTypeFilterProps) => {
  const dispatch = useAppDispatch();
  const { displayType } = useAppSelector((state) => state.fileManager);

  const mainClass = clsx({
    [`nk-block-tools g-3`]: !displayTooltip,
    [`link-list-opt no-bdr`]: displayTooltip,
  });
  const linkClass = clsx({
    [`nk-switch-icon`]: !displayTooltip,
  });

  return (
    <ul className={mainClass}>
      {displayTypeOptions.map((item) => (
        <li key={item.option}>
          <a
            href="#link"
            onClick={(ev) => {
              ev.preventDefault();
              dispatch(setDisplayType(item.option));
            }}
            className={clsx(linkClass, {
              active: displayType === item.option,
            })}
          >
            <Icon name={item.icon}></Icon>
            {displayTooltip && <span>{item.title}</span>}
          </a>
        </li>
      ))}
    </ul>
  );
};
