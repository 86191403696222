import {
  Entity,
  EntityMetadata,
  getEntitiesMetadata,
  getEntityMetadata,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const useStarredEntities = () => {
  const dispatch = useAppDispatch();
  const { starred } = useAppSelector((state) => state.starred);
  const [starredEntities, setStarredEntities] = useState<Entity[]>([]);

  useEffect(() => {
    const fetchEntities = async () => {
      const entitiesPromises = starred.map((id) =>
        dispatch(getEntityMetadata(id)).unwrap(),
      );
      dispatch(getEntitiesMetadata(starred));
      const entitiesMetadata: EntityMetadata[] = await Promise.all(
        entitiesPromises,
      );
      const entities: Entity[] = entitiesMetadata.map((entity) => ({
        ...entity,
        id: entity.id!,
        svg: entity.type!,
        created: moment(entity.created).toISOString() as string,
        encrypted: Boolean(entity.encrypted!),
      }));
      setStarredEntities(entities);
    };

    if (starred.length > 0) {
      fetchEntities();
    } else {
      setStarredEntities([]);
    }
  }, [dispatch, starred]);

  return starredEntities;
};
