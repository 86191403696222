import { useAppSelector } from '@oproma/prividox-store';

// Hook for getting a users profile from the store
export const useProfile = () => {
  const getInitials = (name: string | null) => {
    if (name) {
      const initials = name
        .split(' ')
        .map((n) => n[0])
        .join('');
      return initials.toUpperCase();
    } else {
      return 'NF';
    }
  };
  const profile = useAppSelector((state) => state.user);
  return {
    ...profile,
    name: profile.name,
    email: profile.email,
    initials: getInitials(profile.name),
  };
};
