import {
  ChangeMemberAccessLevelRequest,
  CreateProjectSpec,
  EmptyTrashRequest,
  EnableBundleRequest,
  GetWorkspaceMembersRequest,
  InviteWorkspaceMemberRequest,
  RemoveWorkspaceMemberRequest,
  UpdateWorkspacePlanRequest,
} from '@oproma/prividox-orchestration-open-api';
import { metadataApi, workspaceApi } from './config';

const fetchUserAccessLevelForWorkspace = (workspaceId: string) => {
  return workspaceApi.getAccessLevel({ workspaceId });
};

const fetchWorkspaces = () => {
  return workspaceApi.listWorkspaces();
};

const fetchWorkspaceMembers = (payload: GetWorkspaceMembersRequest) => {
  return workspaceApi.getWorkspaceMembers(payload);
};

const fetchWorkspaceRoot = (workspaceId: string) => {
  return workspaceApi.getWorkspaceRoot({
    workspaceId,
  });
};

const fetchAccessLevelForUser = (workspaceId: string) => {
  return workspaceApi.getAccessLevel({
    workspaceId,
  });
};

const fetchWorkspaceTrash = (workspaceId: string) => {
  return workspaceApi.getWorkspaceTrash({
    workspaceId,
  });
};

const fetchWorkspacePlan = (workspaceId: string) => {
  return workspaceApi.getWorkspacePlan({
    workspaceId,
  });
};

const fetchBundles = (workspaceId: string) => {
  return workspaceApi.getAvailableBundles({
    workspaceId,
  });
};

const createWorkspace = (createProjectSpec: CreateProjectSpec) => {
  return workspaceApi.createWorkspace({
    createProjectSpec,
  });
};

const createWorkspaceMember = async (payload: InviteWorkspaceMemberRequest) => {
  return workspaceApi.inviteWorkspaceMember(payload);
};

const deleteWorkspaceMember = async (payload: RemoveWorkspaceMemberRequest) => {
  return workspaceApi.removeWorkspaceMember(payload);
};

const patchWorkspaceMember = async (
  payload: ChangeMemberAccessLevelRequest,
) => {
  return workspaceApi.changeMemberAccessLevel(payload);
};

const patchWorkspaceBundle = async (payload: EnableBundleRequest) => {
  const response = await workspaceApi.enableBundleRaw(payload);
  return response.raw.ok;
};

const patchWorkspaceName = async (
  workspaceId: string,
  workspaceName: string,
) => {
  const response = await metadataApi.setMetadata({
    entity: workspaceId,
    key: 'name',
    valueSpec: {
      value: workspaceName,
    },
  });
  return response;
};

const patchWorkspacePlan = async ({
  workspaceId,
  updatePlanSpec,
}: UpdateWorkspacePlanRequest) => {
  const response = await workspaceApi.updateWorkspacePlanRaw({
    workspaceId,
    updatePlanSpec,
  });
  return response.raw.ok;
};

const deleteWorkspace = (workspaceId: string) => {
  return workspaceApi.deleteWorkspace({
    workspaceId,
  });
};

const deleteTrash = async (payload: EmptyTrashRequest) => {
  const response = await workspaceApi.emptyTrashRaw(payload);
  return response.raw.ok;
};

export const workspacesService = {
  deleteTrash,
  fetchBundles,
  createWorkspace,
  deleteWorkspace,
  fetchWorkspaces,
  fetchWorkspaceRoot,
  patchWorkspaceName,
  fetchWorkspacePlan,
  patchWorkspacePlan,
  fetchWorkspaceTrash,
  patchWorkspaceMember,
  patchWorkspaceBundle,
  fetchWorkspaceMembers,
  createWorkspaceMember,
  deleteWorkspaceMember,
  fetchAccessLevelForUser,
  fetchUserAccessLevelForWorkspace,
};
