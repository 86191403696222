import { useState } from 'react';
import { Button } from '../button.component';
import { Icon } from '../icon.component';
import { Select } from '../select.component';
import { bulkOperationOptions } from './constants';
import { useTranslation } from '@oproma/i18n';
import {
  setOpenBulkDeleteMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { BulkDeleteMembersModal } from './modals';

export const MemberBulkOperation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { members } = useAppSelector((state) => state.members);

  const [bulkOperationOption, setBulkOperationOption] = useState<string>(
    bulkOperationOptions[0].value,
  );

  // function which fires on applying a bulk operation
  const onBulkOperationOptionClick = () => {
    if (bulkOperationOption === 'delete') {
      dispatch(setOpenBulkDeleteMemberModal(true));
    }
  };
  return (
    <div className="card-tools">
      <div className="form-inline gx-3 flex-nowrap">
        <div className="form-wrap">
          <Select
            options={bulkOperationOptions}
            className="w-200px"
            placeholder={t('MEMBERS.TABLE_HEADER.BULK_PLACEHOLDER')}
            isSearchable={false}
            onChange={(ev) =>
              setBulkOperationOption(ev?.value ?? bulkOperationOptions[0].value)
            }
          />
        </div>
        <div className="btn-wrap">
          <span className="d-none d-md-block">
            <Button
              disabled={!bulkOperationOption}
              color="light"
              outline
              className="btn-dim"
              onClick={onBulkOperationOptionClick}
            >
              {t('MEMBERS.TABLE_HEADER.BULK_CONFIRM')}
            </Button>
          </span>
          <span className="d-md-none">
            <Button
              color="light"
              outline
              disabled={!bulkOperationOption}
              className="btn-dim btn-icon"
              onClick={onBulkOperationOptionClick}
            >
              <Icon name="arrow-right"></Icon>
            </Button>
          </span>
        </div>
      </div>
      <BulkDeleteMembersModal
        selectedMembers={members.filter((member) => member.checked)}
      />
    </div>
  );
};
