import { Link } from '@oproma/router';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Icon } from './icon.component';

interface BlockProps {
  className?: string;
  size?: string;
  children?: ReactNode;
}

export const Block: React.FC<BlockProps> = ({ className, size, children }) => {
  const blockClass = clsx({
    'nk-block': true,
    [`nk-block-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={blockClass}>{children}</div>;
};

interface BlockContentProps {
  className?: string;
  children?: ReactNode;
}

export const BlockContent: React.FC<BlockContentProps> = ({
  className,
  children,
}) => {
  const blockContentClass = clsx({
    'nk-block-content': true,
    [`${className}`]: className,
  });
  return <div className={blockContentClass}>{children}</div>;
};

interface BlockBetweenProps {
  className?: string;
  children?: ReactNode;
}

export const BlockBetween: React.FC<BlockBetweenProps> = ({
  className,
  children,
}) => {
  return (
    <div className={`nk-block-between ${className ? className : ''}`}>
      {children}
    </div>
  );
};

interface BlockHeadProps {
  className?: string;
  size?: string;
  wide?: string;
  children?: ReactNode;
}

export const BlockHead: React.FC<BlockHeadProps> = ({
  className,
  size,
  wide,
  children,
}) => {
  const blockHeadClass = clsx({
    'nk-block-head': true,
    [`nk-block-head-${size}`]: size,
    [`wide-${wide}`]: wide,
    [`${className}`]: className,
  });
  return <div className={blockHeadClass}>{children}</div>;
};

interface BlockHeadContentProps {
  className?: string;
  children?: ReactNode;
}

export const BlockHeadContent: React.FC<BlockHeadContentProps> = ({
  className,
  children,
}) => {
  return (
    <div className={`nk-block-head-content${className ? ' ' + className : ''}`}>
      {children}
    </div>
  );
};

interface BlockTitleProps {
  className?: string;
  page?: boolean;
  tag?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
}

export const BlockTitle: React.FC<BlockTitleProps> = ({
  className,
  page,
  tag,
  children,
}) => {
  const classes = `nk-block-title ${page ? 'page-title' : 'title'}${
    className ? ' ' + className : ''
  }`;

  const Tag = tag as 'p';

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {tag ? (
        <Tag className={classes}>{children}</Tag>
      ) : (
        <h3 className={classes}>{children}</h3>
      )}
    </>
  );
};

interface BlockImageProps {
  classNames?: string;
  children?: ReactNode;
}

export const BlockImage = ({ classNames, ...props }: BlockImageProps) => {
  return (
    <div className={`nk-block-image ${classNames ? classNames : ''}`}>
      {props.children}
    </div>
  );
};

interface BlockDesProps {
  className?: string;
  page?: boolean;
  children?: ReactNode;
}

export const BlockDes: React.FC<BlockDesProps> = ({ className, children }) => {
  const classes = `nk-block-des${className ? ' ' + className : ''}`;
  return <div className={classes}>{children}</div>;
};

interface BackToProps {
  className?: string;
  link?: string;
  icon: string;
  children?: ReactNode;
}

export const BackTo: React.FC<BackToProps> = ({
  className,
  link,
  icon,
  children,
}) => {
  const classes = `back-to${className ? ' ' + className : ''}`;
  return (
    <div className="nk-block-head-sub">
      <Link className={classes} to={link || '#'}>
        <Icon name={icon} />
        <span>{children} </span>
      </Link>
    </div>
  );
};
