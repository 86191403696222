import clsx from 'clsx';
import { ReactNode } from 'react';

type UserGroupProps = {
  className?: string;
  children: ReactNode;
};

export const UserGroup = ({ children, className }: UserGroupProps) => {
  return <div className={clsx(`user-avatar-group`, className)}>{children}</div>;
};
