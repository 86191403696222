import {
  toggleDisplayPasswordText,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { MouseEvent } from 'react';

export const useToggleDisplayingPassswordText = () => {
  const dispatch = useAppDispatch();
  const { displayPasswordText } = useAppSelector((state) => state.auth);

  const toggleDisplayingPasswordText = (ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    dispatch(toggleDisplayPasswordText());
  };

  return { displayPasswordText, toggleDisplayingPasswordText };
};
