import { yupResolver } from '@hookform/resolvers/yup';
import {
  confirmEmailChange,
  setReceivedEmailResetDetails,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const resetEmailSchema = yup
  .object({
    email: yup.string().email('ERROR.InvalidEmail').required(),
    emailResetCode: yup.string().required('ERROR.InvalidResetEmailCode'),
  })
  .required();

type ResetEmailFormValues = yup.InferType<typeof resetEmailSchema>;

export const useResetEmailForm = () => {
  const dispatch = useAppDispatch();
  const { receivedEmailResetDetails, resetEmailDispatched, loading, error } =
    useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);

  const {
    register: registerField,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ResetEmailFormValues>({
    resolver: yupResolver(resetEmailSchema),
  });

  const { ref: emailRef, ...registerEmail } = registerField('email');

  const onFormSubmit: SubmitHandler<ResetEmailFormValues> = (payload) => {
    if (receivedEmailResetDetails) {
      if (!user.email) {
        return;
      }
      dispatch(
        confirmEmailChange({
          token: payload.emailResetCode,
          submitEmailUpdateSpec: {
            old: user.email,
            email: payload.email,
          },
        }),
      );
    }
  };

  const setReceivedEmailResetDetailsStatus = (status: boolean) => {
    dispatch(setReceivedEmailResetDetails(status));
  };

  return {
    errors,
    emailRef,
    onFormSubmit,
    handleSubmit,
    registerEmail,
    receivedEmailResetDetails,
    resetEmailDispatched,
    loading,
    error,
    setValue,
    user,
    getValues,
    setReceivedEmailResetDetailsStatus,
  };
};
