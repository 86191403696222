import { yupResolver } from '@hookform/resolvers/yup';
import {
  deleteCreditCard,
  editCreditCard,
  toggleDisplayEditCreditCardModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Badge,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Col, Row } from '../../grid.component';

const editCreditCardSchema = yup
  .object({
    month: yup.number().min(1).max(12).typeError("Month can't be empty"),
    year: yup
      .number()
      .min(new Date().getFullYear())
      .typeError("Year can't be empty"),
  })
  .required();

type EditCreditCardFormValues = yup.InferType<typeof editCreditCardSchema>;

export const EditCreditCardModal = () => {
  const dispatch = useAppDispatch();
  const {
    chosenCreditCard,
    displayEditCreditCardModal,
    deletedCreditCard,
    loading,
  } = useAppSelector((state) => state.finance);

  useEffect(() => {
    if (deletedCreditCard) {
      toggle();
    }
  }, [deletedCreditCard]);

  const {
    register: registerField,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<EditCreditCardFormValues>({
    resolver: yupResolver(editCreditCardSchema),
    mode: 'onChange',
  });

  const { ref: monthRef, ...registerMonth } = registerField('month');
  const { ref: yearRef, ...registerYear } = registerField('year');

  const onFormSubmit: SubmitHandler<EditCreditCardFormValues> = ({
    month,
    year,
  }) => {
    if (!chosenCreditCard?.cardToken) {
      return;
    }
    dispatch(
      editCreditCard({
        card: chosenCreditCard.cardToken,
        updateCardSpec: {
          expMonth: month?.toString(),
          expYear: year?.toString(),
        },
      }),
    );
    reset();
    toggle();
  };

  const toggle = () => {
    dispatch(toggleDisplayEditCreditCardModal());
  };

  const handleDeleteCreditCardClick = () => {
    dispatch(deleteCreditCard(chosenCreditCard?.cardToken ?? 'N/A'));
    toggle();
  };

  const handleEditCreditCardStatusClick = () => {
    if (!chosenCreditCard?.cardToken) {
      return;
    }
    dispatch(
      editCreditCard({
        card: chosenCreditCard.cardToken,
        updateCardSpec: {
          def: true,
        },
      }),
    );
    toggle();
  };

  return (
    <Modal isOpen={displayEditCreditCardModal} size="lg" toggle={toggle}>
      <a
        href="/"
        className="close"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
      >
        <em className="icon ni ni-cross"></em>
      </a>
      <ModalBody className="modal-body-md">
        <h4 className="nk-modal-title title">
          Editing {chosenCreditCard?.brand} **** **** ****{' '}
          {chosenCreditCard?.last4}
        </h4>
        <p className="sub-text-sm">
          Card Status:{' '}
          <Badge
            color={chosenCreditCard?.def ? 'success' : 'light'}
            className="rounded-pill"
          >
            {chosenCreditCard?.def ? 'Default' : 'Not Default'}
          </Badge>
        </p>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Row>
            <Col>
              <FormGroup>
                <div className="form-control-wrap">
                  <Label className="form-label">Card</Label>
                  <Input
                    type="password"
                    className="form-control"
                    placeholder={`**** **** **** ${chosenCreditCard?.last4}`}
                    disabled
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <Label htmlFor="default-5" className="form-label">
                  Expiry Month
                </Label>
                <div className="form-control-wrap">
                  <Input
                    type="number"
                    id="month"
                    placeholder="Enter a month"
                    className="form-control"
                    innerRef={monthRef}
                    {...registerMonth}
                    defaultValue={chosenCreditCard?.expMonth}
                  />
                  {errors.month && (
                    <span className="invalid">{errors.month.message}</span>
                  )}
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <Label htmlFor="default-5" className="form-label">
                  Expiry Year
                </Label>
                <div className="form-control-wrap">
                  <Input
                    type="number"
                    id="year"
                    placeholder="Enter a year"
                    className="form-control"
                    innerRef={yearRef}
                    {...registerYear}
                    defaultValue={chosenCreditCard?.expYear}
                  />
                  {errors.year && (
                    <span className="invalid">{errors.year.message}</span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="g-3">
            <Col lg="12">
              <div className="form-group mt-2">
                <Button
                  color="primary"
                  className="btn-block"
                  size="md"
                  type="submit"
                  disabled={
                    loading || !!errors.month || !!errors.year || !isDirty
                  }
                >
                  Persist
                </Button>
              </div>
            </Col>
            <Col lg="5">
              <div className="form-group mt-2">
                <Button
                  color="danger"
                  outline
                  className="btn-block"
                  size="sm"
                  onClick={handleDeleteCreditCardClick}
                  disabled={loading}
                >
                  Delete
                </Button>
              </div>
            </Col>
            <Col lg="7">
              <div className="form-group mt-2">
                <Button
                  color="primary"
                  outline
                  className="btn-block"
                  size="sm"
                  onClick={handleEditCreditCardStatusClick}
                  disabled={loading || chosenCreditCard?.def}
                >
                  Mark as Default
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
