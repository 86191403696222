/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
} from './Project';

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  passwordHash?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  active?: boolean;
  /**
   *
   * @type {Set<string>}
   * @memberof User
   */
  fingerprints?: Set<string>;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  twoFARequired?: boolean;
  /**
   *
   * @type {Set<number>}
   * @memberof User
   */
  scratchCodes?: Set<number>;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  lastPasswordReset?: Date;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  lastLogin?: Date;
  /**
   *
   * @type {number}
   * @memberof User
   */
  loginCount?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  failedLoginCount?: number;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  failedLoginTimeStamp?: Date;
  /**
   *
   * @type {string}
   * @memberof User
   */
  customerId?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  ignoringAbsoluteTimeout?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  passwordResetId?: string;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  passwordResetDate?: Date;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof User
   */
  notificationProviders?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof User
   */
  notificationTypes?: { [key: string]: boolean };
  /**
   *
   * @type {number}
   * @memberof User
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  gauthSecret?: string;
  /**
   *
   * @type {Project}
   * @memberof User
   */
  project?: Project;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    passwordHash: !exists(json, 'passwordHash')
      ? undefined
      : json['passwordHash'],
    email: !exists(json, 'email') ? undefined : json['email'],
    active: !exists(json, 'active') ? undefined : json['active'],
    fingerprints: !exists(json, 'fingerprints')
      ? undefined
      : json['fingerprints'],
    twoFARequired: !exists(json, 'twoFARequired')
      ? undefined
      : json['twoFARequired'],
    scratchCodes: !exists(json, 'scratchCodes')
      ? undefined
      : json['scratchCodes'],
    lastPasswordReset: !exists(json, 'lastPasswordReset')
      ? undefined
      : new Date(json['lastPasswordReset']),
    lastLogin: !exists(json, 'lastLogin')
      ? undefined
      : new Date(json['lastLogin']),
    loginCount: !exists(json, 'loginCount') ? undefined : json['loginCount'],
    failedLoginCount: !exists(json, 'failedLoginCount')
      ? undefined
      : json['failedLoginCount'],
    failedLoginTimeStamp: !exists(json, 'failedLoginTimeStamp')
      ? undefined
      : new Date(json['failedLoginTimeStamp']),
    customerId: !exists(json, 'customerId') ? undefined : json['customerId'],
    ignoringAbsoluteTimeout: !exists(json, 'ignoringAbsoluteTimeout')
      ? undefined
      : json['ignoringAbsoluteTimeout'],
    passwordResetId: !exists(json, 'passwordResetId')
      ? undefined
      : json['passwordResetId'],
    passwordResetDate: !exists(json, 'passwordResetDate')
      ? undefined
      : new Date(json['passwordResetDate']),
    notificationProviders: !exists(json, 'notificationProviders')
      ? undefined
      : json['notificationProviders'],
    notificationTypes: !exists(json, 'notificationTypes')
      ? undefined
      : json['notificationTypes'],
    version: !exists(json, 'version') ? undefined : json['version'],
    id: !exists(json, 'id') ? undefined : json['id'],
    gauthSecret: !exists(json, 'gauthSecret') ? undefined : json['gauthSecret'],
    project: !exists(json, 'project')
      ? undefined
      : ProjectFromJSON(json['project']),
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    passwordHash: value.passwordHash,
    email: value.email,
    active: value.active,
    fingerprints:
      value.fingerprints === undefined
        ? undefined
        : Array.from(value.fingerprints as Set<any>),
    twoFARequired: value.twoFARequired,
    scratchCodes:
      value.scratchCodes === undefined
        ? undefined
        : Array.from(value.scratchCodes as Set<any>),
    lastPasswordReset:
      value.lastPasswordReset === undefined
        ? undefined
        : value.lastPasswordReset.toISOString(),
    lastLogin:
      value.lastLogin === undefined ? undefined : value.lastLogin.toISOString(),
    loginCount: value.loginCount,
    failedLoginCount: value.failedLoginCount,
    failedLoginTimeStamp:
      value.failedLoginTimeStamp === undefined
        ? undefined
        : value.failedLoginTimeStamp.toISOString(),
    customerId: value.customerId,
    ignoringAbsoluteTimeout: value.ignoringAbsoluteTimeout,
    passwordResetId: value.passwordResetId,
    passwordResetDate:
      value.passwordResetDate === undefined
        ? undefined
        : value.passwordResetDate.toISOString(),
    notificationProviders: value.notificationProviders,
    notificationTypes: value.notificationTypes,
    version: value.version,
    id: value.id,
    gauthSecret: value.gauthSecret,
    project: ProjectToJSON(value.project),
  };
}
