import { yupResolver } from '@hookform/resolvers/yup';
import { CardInfo } from '@oproma/prividox-orchestration-open-api';
import {
  setCreateWorkpaceFormField,
  toggleDisplayOnboardCreditCardModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link } from '@oproma/router';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useWizard } from '../hooks';
import { Icon } from '../icon.component';

interface OnboardWorkspaceCCFormValues {
  couponCode?: string;
}

type PaymentMethod = 'cc' | 'coupon';

// TODO: Extract to utils file
const getCardBrand = (brand: string | undefined) => {
  switch (brand) {
    case 'Visa':
      return 'visa';
    case 'MasterCard':
      return 'master-card';
    case 'Paypal':
      return 'paypal';
    case undefined:
    default:
      return 'sign-usd';
  }
};

const getCardBrandEm = (brand: string | undefined) => {
  switch (brand) {
    case 'Visa':
      return 'ni ni-cc-visa';
    case 'MasterCard':
      return 'ni ni-master-card';
    case 'Paypal':
      return 'ni ni-paypal';
    case undefined:
    default:
      return 'ni ni-sign-usd';
  }
};

export const OnboardWorkspaceCCForm = () => {
  const { metadata } = useAppSelector((state) => state.workspace);
  const { creditCards, creditCardCount } = useAppSelector(
    (state) => state.finance,
  );
  const defaultCreditCard = creditCards.find((cc) => cc.def);
  const { next, setCurrent, prev } = useWizard();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  // TODO: Export to redux store
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [paymentCard, setPaymentCard] = useState<CardInfo>(
    defaultCreditCard ?? creditCards[0],
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('cc');
  const [elementError, setElementError] = useState<{
    complete: boolean;
    message?: string;
  }>();

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const onboardWorkspaceCCFormSchema = (paymentMethod: PaymentMethod) =>
    yup.object({
      couponCode:
        paymentMethod === 'coupon'
          ? yup.string().required()
          : yup.string().optional(),
    });

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardWorkspaceCCFormValues>({
    resolver: yupResolver(onboardWorkspaceCCFormSchema(paymentMethod)),
  });

  const { ref: couponCodeRef, ...registerCouponCode } =
    registerField('couponCode');

  const onFormSubmit: SubmitHandler<OnboardWorkspaceCCFormValues> = async ({
    couponCode,
  }) => {
    if (paymentMethod === 'cc') {
      // Process credit card information
      if (!stripe || !elements)
        return console.error('Stripe.js has not loaded yet.');

      let token: string | undefined = undefined;

      if (creditCardCount && creditCardCount >= 1) {
        token = paymentCard.cardToken;
      } else {
        if (!elementError?.complete || elementError?.message) {
          return console.error('Card error.');
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) return console.error('Card element not found.');

        const tokenResponse = await stripe.createToken(cardElement);
        token = tokenResponse.token?.id;
      }
      dispatch(
        setCreateWorkpaceFormField({
          token,
        }),
      );
      next();
    } else if (paymentMethod === 'coupon' && couponCode) {
      // Process coupon code
      // TODO: Process coupon code onboarding
      setCurrent(4);
    }
  };

  const navigateBack = () => prev();

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} className="gy-3">
      <Row className="g-3 align-center">
        <Col lg="5">
          <div className="form-group">
            <label className="form-label" htmlFor="payment-method">
              Payment Method
            </label>
          </div>
        </Col>
        <Col lg="7">
          <div className="form-group">
            <div className="form-control-select">
              <Input
                type="select"
                id="payment-method"
                value={paymentMethod}
                onChange={(ev) =>
                  setPaymentMethod(ev.target.value as PaymentMethod)
                }
              >
                <option value="cc">Credit Card</option>
                <option value="coupon">Coupon Code</option>
              </Input>
            </div>
          </div>
        </Col>
      </Row>

      {paymentMethod === 'cc' && (
        <Row className="g-3 align-center">
          <Col lg="5">
            <FormGroup>
              <label className="form-label" htmlFor="site-name">
                Credit Card
              </label>
              <span className="form-note">Provide credit card details.</span>
            </FormGroup>
          </Col>
          <Col lg="7">
            {creditCardCount && creditCardCount >= 1 ? (
              // <div className="sp-plan-payopt">
              <div className="cc-pay">
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  className="cc-pay-dd"
                  direction="down"
                >
                  <DropdownToggle
                    tag="a"
                    className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator"
                  >
                    <Icon name={getCardBrand(paymentCard?.brand)}></Icon>
                    <span>
                      <span className="cc-pay-star">**** **** ****</span>{' '}
                      {paymentCard?.last4}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-auto">
                    <ul className="link-list-plain">
                      {creditCards.map((card) => (
                        <li
                          key={card.cardToken}
                          onClick={() => {
                            setPaymentCard(card);
                            toggle();
                          }}
                        >
                          <a href="#" className="cc-pay-item">
                            <em
                              className={clsx(
                                'cc-pay-item-icon icon',
                                getCardBrandEm(card.brand),
                              )}
                            ></em>
                            <span className="cc-pay-item-name">
                              <span className="cc-pay-item-method">
                                <span className="cc-pay-star">
                                  {card.brand} **** **** ****
                                </span>{' '}
                                {card.last4}
                              </span>
                              <span className="cc-pay-item-meta">
                                Expires on {card.expMonth}/{card.expYear}
                              </span>
                            </span>
                          </a>
                        </li>
                      ))}
                      <li>
                        <Link
                          to={`${metadata?.id}/options`}
                          className="cc-pay-item"
                          onClick={(ev) => {
                            dispatch(toggleDisplayOnboardCreditCardModal());
                          }}
                        >
                          <span className="cc-pay-item-name">
                            <span className="cc-pay-item-method-new">
                              Onboard a Credit Card
                            </span>
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : (
              <FormGroup>
                <div className="form-control-wrap">
                  <CardElement
                    className="form-control"
                    options={{
                      style: {
                        base: {
                          fontFamily: 'var(--font-lato)',
                          color: '#ebeef2',
                          ':-webkit-autofill': {
                            color: '#FFFFFF',
                          },
                        },
                      },
                    }}
                    onChange={({ error, complete }) =>
                      setElementError({
                        complete,
                        message: error?.message,
                      })
                    }
                  />
                  {elementError?.message && (
                    <p className="invalid">{elementError.message}</p>
                  )}
                </div>
              </FormGroup>
            )}
          </Col>
        </Row>
      )}

      {paymentMethod === 'coupon' && (
        <Row className="g-3 align-center">
          <Col lg="5">
            <FormGroup>
              <label className="form-label" htmlFor="site-name">
                Coupon Code
              </label>
              <span className="form-note">Provide a coupon code.</span>
            </FormGroup>
          </Col>
          <Col lg="7">
            <FormGroup>
              <div className="form-control-wrap">
                <Input
                  type="text"
                  id="name"
                  placeholder="Code"
                  innerRef={couponCodeRef}
                  {...registerCouponCode}
                />
                {errors.couponCode && (
                  <p className="invalid">{errors.couponCode.message}</p>
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
      )}

      <Row className="g-3">
        <Col lg="5">
          <div className="form-group mt-2">
            <Button
              color="primary"
              outline
              className="btn-block"
              size="md"
              onClick={navigateBack}
            >
              Back
            </Button>
          </div>
        </Col>
        <Col lg="7">
          <div className="form-group mt-2">
            <Button
              color="primary"
              className="btn-block"
              size="md"
              type="submit"
              disabled={
                (!creditCardCount || creditCardCount < 1) &&
                paymentMethod === 'cc'
                  ? !elementError?.complete || !!elementError?.message
                  : !!errors.couponCode?.message
              }
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
