/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
  /**
   *
   * @type {string}
   * @memberof FileInfo
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof FileInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof FileInfo
   */
  tsize?: number;
  /**
   *
   * @type {string}
   * @memberof FileInfo
   */
  path?: string;
}

/**
 * Check if a given object implements the FileInfo interface.
 */
export function instanceOfFileInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FileInfoFromJSON(json: any): FileInfo {
  return FileInfoFromJSONTyped(json, false);
}

export function FileInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FileInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    size: !exists(json, 'size') ? undefined : json['size'],
    tsize: !exists(json, 'tsize') ? undefined : json['tsize'],
    path: !exists(json, 'path') ? undefined : json['path'],
  };
}

export function FileInfoToJSON(value?: FileInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    size: value.size,
    tsize: value.tsize,
    path: value.path,
  };
}
