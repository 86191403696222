/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InviteMemberSpec
 */
export interface InviteMemberSpec {
  /**
   * The email address of the member to be invited..
   * @type {string}
   * @memberof InviteMemberSpec
   */
  email: string;
  /**
   * The access level to give to the new member.
   * @type {string}
   * @memberof InviteMemberSpec
   */
  access: string;
  /**
   * The language in which to send the invitation. Should be one of 'en', 'fr', 'en_fr', 'fr_en'.
   * @type {string}
   * @memberof InviteMemberSpec
   */
  lang: string;
}

/**
 * Check if a given object implements the InviteMemberSpec interface.
 */
export function instanceOfInviteMemberSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'access' in value;
  isInstance = isInstance && 'lang' in value;

  return isInstance;
}

export function InviteMemberSpecFromJSON(json: any): InviteMemberSpec {
  return InviteMemberSpecFromJSONTyped(json, false);
}

export function InviteMemberSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InviteMemberSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    access: json['access'],
    lang: json['lang'],
  };
}

export function InviteMemberSpecToJSON(value?: InviteMemberSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    access: value.access,
    lang: value.lang,
  };
}
