import { HTMLAttributes, ReactNode } from 'react';

interface ContentProps extends HTMLAttributes<HTMLDivElement> {
  page?: 'component';
  children: ReactNode;
}

export const Content = ({ page, children }: ContentProps) => {
  return (
    <div className="nk-content">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            {page
              ? page === 'component' && (
                  <div className="components-preview wide-md mx-auto">
                    {children}
                  </div>
                )
              : children}
          </div>
        </div>
      </div>
    </div>
  );
};
