/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UploadStatus
 */
export interface UploadStatus {
  /**
   *
   * @type {string}
   * @memberof UploadStatus
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof UploadStatus
   */
  newFile?: boolean;
}

/**
 * Check if a given object implements the UploadStatus interface.
 */
export function instanceOfUploadStatus(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UploadStatusFromJSON(json: any): UploadStatus {
  return UploadStatusFromJSONTyped(json, false);
}

export function UploadStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UploadStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    newFile: !exists(json, 'newFile') ? undefined : json['newFile'],
  };
}

export function UploadStatusToJSON(value?: UploadStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    newFile: value.newFile,
  };
}
