/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
} from './Project';

/**
 *
 * @export
 * @interface SystemMessage
 */
export interface SystemMessage {
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  contentFR?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  titleFR?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  theme?: SystemMessageThemeEnum;
  /**
   *
   * @type {Date}
   * @memberof SystemMessage
   */
  expiryDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof SystemMessage
   */
  effectiveDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof SystemMessage
   */
  active?: boolean;
  /**
   *
   * @type {number}
   * @memberof SystemMessage
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  id?: string;
  /**
   *
   * @type {Project}
   * @memberof SystemMessage
   */
  project?: Project;
}

/**
 * @export
 * @enum {string}
 */
export enum SystemMessageThemeEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  INFO = 'INFO',
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

/**
 * Check if a given object implements the SystemMessage interface.
 */
export function instanceOfSystemMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SystemMessageFromJSON(json: any): SystemMessage {
  return SystemMessageFromJSONTyped(json, false);
}

export function SystemMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SystemMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    content: !exists(json, 'content') ? undefined : json['content'],
    contentFR: !exists(json, 'contentFR') ? undefined : json['contentFR'],
    title: !exists(json, 'title') ? undefined : json['title'],
    titleFR: !exists(json, 'titleFR') ? undefined : json['titleFR'],
    theme: !exists(json, 'theme') ? undefined : json['theme'],
    expiryDate: !exists(json, 'expiryDate')
      ? undefined
      : new Date(json['expiryDate']),
    effectiveDate: !exists(json, 'effectiveDate')
      ? undefined
      : new Date(json['effectiveDate']),
    active: !exists(json, 'active') ? undefined : json['active'],
    version: !exists(json, 'version') ? undefined : json['version'],
    name: !exists(json, 'name') ? undefined : json['name'],
    id: !exists(json, 'id') ? undefined : json['id'],
    project: !exists(json, 'project')
      ? undefined
      : ProjectFromJSON(json['project']),
  };
}

export function SystemMessageToJSON(value?: SystemMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    content: value.content,
    contentFR: value.contentFR,
    title: value.title,
    titleFR: value.titleFR,
    theme: value.theme,
    expiryDate:
      value.expiryDate === undefined
        ? undefined
        : value.expiryDate.toISOString(),
    effectiveDate:
      value.effectiveDate === undefined
        ? undefined
        : value.effectiveDate.toISOString(),
    active: value.active,
    version: value.version,
    name: value.name,
    id: value.id,
    project: ProjectToJSON(value.project),
  };
}
