/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
} from './Project';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 *
 * @export
 * @interface Group
 */
export interface Group {
  /**
   *
   * @type {Set<User>}
   * @memberof Group
   */
  members?: Set<User>;
  /**
   *
   * @type {Project}
   * @memberof Group
   */
  project?: Project;
  /**
   *
   * @type {string}
   * @memberof Group
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Group
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Group
   */
  id?: string;
}

/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GroupFromJSON(json: any): Group {
  return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Group {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    members: !exists(json, 'members')
      ? undefined
      : new Set((json['members'] as Array<any>).map(UserFromJSON)),
    project: !exists(json, 'project')
      ? undefined
      : ProjectFromJSON(json['project']),
    name: !exists(json, 'name') ? undefined : json['name'],
    version: !exists(json, 'version') ? undefined : json['version'],
    id: !exists(json, 'id') ? undefined : json['id'],
  };
}

export function GroupToJSON(value?: Group | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    members:
      value.members === undefined
        ? undefined
        : Array.from(value.members as Set<any>).map(UserToJSON),
    project: ProjectToJSON(value.project),
    name: value.name,
    version: value.version,
    id: value.id,
  };
}
