import { useTranslation } from '@oproma/i18n';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

type HeadProps = {
  heading: ReactNode;
};

export const Head = ({ heading }: HeadProps) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title
        dangerouslySetInnerHTML={{ __html: heading || t('GLOBAL_TITLE') }}
      />
    </Helmet>
  );
};
