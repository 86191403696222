/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The set of redactions to apply to the document
 * @export
 * @interface Redaction
 */
export interface Redaction {
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  top?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  left?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  height?: number;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  notes?: string;
}

/**
 * Check if a given object implements the Redaction interface.
 */
export function instanceOfRedaction(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RedactionFromJSON(json: any): Redaction {
  return RedactionFromJSONTyped(json, false);
}

export function RedactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Redaction {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    page: !exists(json, 'page') ? undefined : json['page'],
    top: !exists(json, 'top') ? undefined : json['top'],
    left: !exists(json, 'left') ? undefined : json['left'],
    width: !exists(json, 'width') ? undefined : json['width'],
    height: !exists(json, 'height') ? undefined : json['height'],
    user: !exists(json, 'user') ? undefined : json['user'],
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
  };
}

export function RedactionToJSON(value?: Redaction | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    page: value.page,
    top: value.top,
    left: value.left,
    width: value.width,
    height: value.height,
    user: value.user,
    name: value.name,
    type: value.type,
    notes: value.notes,
  };
}
