/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChildPermission } from './ChildPermission';
import {
  ChildPermissionFromJSON,
  ChildPermissionFromJSONTyped,
  ChildPermissionToJSON,
} from './ChildPermission';

/**
 *
 * @export
 * @interface ResultChildPermission
 */
export interface ResultChildPermission {
  /**
   *
   * @type {Array<ChildPermission>}
   * @memberof ResultChildPermission
   */
  items?: Array<ChildPermission>;
}

/**
 * Check if a given object implements the ResultChildPermission interface.
 */
export function instanceOfResultChildPermission(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultChildPermissionFromJSON(
  json: any,
): ResultChildPermission {
  return ResultChildPermissionFromJSONTyped(json, false);
}

export function ResultChildPermissionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultChildPermission {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(ChildPermissionFromJSON),
  };
}

export function ResultChildPermissionToJSON(
  value?: ResultChildPermission | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(ChildPermissionToJSON),
  };
}
