import { useAppSelector } from '@oproma/prividox-store';
import { ReactNode } from 'react';
import { Head } from '../head.component';
import { Content } from '../layout/content.component';
import { FileManagerNavbar } from './file-manager-navbar.component';

type FileManagerContainerProps = {
  children: ReactNode;
};

export const FileManagerContainer = ({
  children,
}: FileManagerContainerProps) => {
  const { contentHeight } = useAppSelector((state) => state.fileManager);
  return (
    <>
      <Head heading="File Manager" />
      <Content>
        <div className="nk-fmg">
          <FileManagerNavbar />
          <div
            className="nk-fmg-body"
            style={{ minHeight: `${contentHeight}px` }}
          >
            {children}
          </div>
        </div>
      </Content>
    </>
  );
};
