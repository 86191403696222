import { useTranslation } from '@oproma/i18n';
import {
  setContentHeight,
  setDisplayMobileNavbar,
  toggleDisplayMobileNavbar,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link, useLocation, useParams } from '@oproma/router';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { Icon } from '../icon.component';
import { prettyBytes } from '../utils';
import { navbarMenu } from './menu';

export const FileManagerNavbar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { workspaceId } = useParams();
  const navbarRef = useRef<HTMLDivElement>(null);

  const { plan, diskUsage, metadata } = useAppSelector(
    (state) => state.workspace,
  );
  const { displayMobileNavbar } = useAppSelector((state) => state.fileManager);

  useEffect(() => {
    if (navbarRef.current)
      dispatch(setContentHeight(navbarRef.current?.clientHeight + 10));
  }, [navbarRef.current]);

  return (
    <>
      <SimpleBar
        className={clsx('nk-fmg-aside', 'toggle-screen-lg', {
          'content-active': displayMobileNavbar,
        })}
      >
        <div className="nk-fmg-aside-wrap">
          <div ref={navbarRef}>
            <SimpleBar className="nk-fmg-aside-top">
              <ul className="nk-fmg-menu">
                {navbarMenu.map((item) => (
                  <li
                    key={item.id}
                    className={clsx({
                      active:
                        item.link === ''
                          ? pathname === `/${workspaceId}/file-manager` ||
                            pathname === `/${workspaceId}/file-manager/`
                          : pathname.includes(
                              `/${workspaceId}/file-manager/${item.link}`,
                            ),
                    })}
                  >
                    <Link
                      className="nk-fmg-menu-item"
                      to={`/${workspaceId}/file-manager/${item.link}`}
                      onClick={() => {
                        dispatch(setDisplayMobileNavbar(false));
                      }}
                    >
                      <Icon name={item.icon}></Icon>
                      <span className="nk-fmg-menu-text">{t(item.text)}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </SimpleBar>
            <div className="nk-fmg-aside-bottom">
              <div className="nk-fmg-status">
                <h6 className="nk-fmg-status-title">
                  <Icon name="hard-drive"></Icon>
                  <span>{t('FILE_MANAGER.NAVBAR.STORAGE')}</span>
                </h6>
                <div className="progress progress-md bg-light">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${
                        (diskUsage?.size ?? 0) /
                        (metadata?.['bytes-limit'] ?? 0)
                      }%`,
                    }}
                  ></div>
                </div>
                <div className="nk-fmg-status-info">
                  {prettyBytes(diskUsage?.size ?? 0)} of{' '}
                  {prettyBytes(metadata?.['bytes-limit'] ?? 0)} used
                </div>
                <div className="nk-fmg-status-action">
                  <Link
                    to={`/${workspaceId}/options`}
                    className="link link-primary link-sm"
                  >
                    {t('FILE_MANAGER.NAVBAR.EDIT_STORAGE')}
                  </Link>
                </div>
              </div>
              <div className="nk-fmg-switch">
                <UncontrolledDropdown direction="up">
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="dropdown-toggle"
                    // dropdown-indicator-unfold"
                  >
                    <div className="lead-text">
                      {plan?.meta?.['package-i18n-name'] ??
                        t('FILE_MANAGER.PLANS.FAILED.HEADING')}
                    </div>
                    <div className="sub-text">
                      {plan?.meta?.['package-i18n-desc'] ??
                        t('FILE_MANAGER.PLANS.FAILED.DESCRIPTION')}
                    </div>
                  </DropdownToggle>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
      {displayMobileNavbar && (
        <div
          className="toggle-overlay"
          onClick={() => dispatch(toggleDisplayMobileNavbar())}
        />
      )}
    </>
  );
};
