/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FolderInformation } from './FolderInformation';
import {
  FolderInformationFromJSON,
  FolderInformationFromJSONTyped,
  FolderInformationToJSON,
} from './FolderInformation';

/**
 *
 * @export
 * @interface ResultFolderInformation
 */
export interface ResultFolderInformation {
  /**
   *
   * @type {Array<FolderInformation>}
   * @memberof ResultFolderInformation
   */
  items?: Array<FolderInformation>;
}

/**
 * Check if a given object implements the ResultFolderInformation interface.
 */
export function instanceOfResultFolderInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultFolderInformationFromJSON(
  json: any,
): ResultFolderInformation {
  return ResultFolderInformationFromJSONTyped(json, false);
}

export function ResultFolderInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultFolderInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(FolderInformationFromJSON),
  };
}

export function ResultFolderInformationToJSON(
  value?: ResultFolderInformation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(FolderInformationToJSON),
  };
}
