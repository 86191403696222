/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Folder } from './Folder';
import { FolderFromJSON, FolderFromJSONTyped, FolderToJSON } from './Folder';
import type { Group } from './Group';
import { GroupFromJSON, GroupFromJSONTyped, GroupToJSON } from './Group';
import type { Task } from './Task';
import { TaskFromJSON, TaskFromJSONTyped, TaskToJSON } from './Task';

/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  subscriptionId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  visible?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Project
   */
  disabledDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Project
   */
  expiry?: Date;
  /**
   *
   * @type {Folder}
   * @memberof Project
   */
  root?: Folder;
  /**
   *
   * @type {Folder}
   * @memberof Project
   */
  trash?: Folder;
  /**
   *
   * @type {Group}
   * @memberof Project
   */
  defaultGroup?: Group;
  /**
   *
   * @type {Set<Group>}
   * @memberof Project
   */
  groups?: Set<Group>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Project
   */
  members?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof Project
   */
  monthlyMaxSpaceUsage?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof Project
   */
  monthlyMaxSpaceTimestamp?: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof Project
   */
  softLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  bytesLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  fileCountLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  folderCountLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  memberCountLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  expansionAllowed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  publicationAllowed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  enhancedSecurityForced?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  folderProductionEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  redactionsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  reviewEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  conversionsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  globalEntityPublicationAllowed?: boolean;
  /**
   *
   * @type {Set<string>}
   * @memberof Project
   */
  customEntityDefinitions?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  adminNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  clientName?: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  projectType?: ProjectProjectTypeEnum;
  /**
   *
   * @type {Set<Task>}
   * @memberof Project
   */
  tasks?: Set<Task>;
  /**
   *
   * @type {{ [key: string]: Set<string>; }}
   * @memberof Project
   */
  webhooks?: { [key: string]: Set<string> };
  /**
   *
   * @type {number}
   * @memberof Project
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  id?: string;
  /**
   *
   * @type {Project}
   * @memberof Project
   */
  project?: Project;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  totalSize?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ProjectProjectTypeEnum {
  OPEN = 'OPEN',
  MIXED = 'MIXED',
}
/**
 * @export
 * @enum {string}
 */
export enum ProjectWebhooksEnum {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SESSION_REFRESHED = 'SESSION_REFRESHED',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED',
  EMAIL_CHANGE_REQUESTED = 'EMAIL_CHANGE_REQUESTED',
  EMAIL_CHANGED = 'EMAIL_CHANGED',
  UPDATED_PASSWORD = 'UPDATED_PASSWORD',
  UPDATED_DISPLAY_NAME = 'UPDATED_DISPLAY_NAME',
  APPLICATION_ACCESS_GRANTED = 'APPLICATION_ACCESS_GRANTED',
  APPLICATION_ACCESS_REVOKED = 'APPLICATION_ACCESS_REVOKED',
  CREATED = 'CREATED',
  CHILD_ADDED = 'CHILD_ADDED',
  CHILD_REMOVED = 'CHILD_REMOVED',
  DOWNLOADED = 'DOWNLOADED',
  PREVIEWED = 'PREVIEWED',
  MOVED = 'MOVED',
  COPIED = 'COPIED',
  DELETED = 'DELETED',
  TRASHED = 'TRASHED',
  RESTORED = 'RESTORED',
  COMMENTED = 'COMMENTED',
  CARD_ADDED = 'CARD_ADDED',
  CARD_REMOVED = 'CARD_REMOVED',
  DEFAULT_CARD_CHANGED = 'DEFAULT_CARD_CHANGED',
  LOCKED = 'LOCKED',
  PUBLISHED = 'PUBLISHED',
  UNLOCKED = 'UNLOCKED',
  RENAMED = 'RENAMED',
  METADATA_SET = 'METADATA_SET',
  METADATA_DELETED = 'METADATA_DELETED',
  PERMISSION_SET = 'PERMISSION_SET',
  PERMISSION_DELETED = 'PERMISSION_DELETED',
  NOTIFICATION_PROVIDER_ADDED = 'NOTIFICATION_PROVIDER_ADDED',
  NOTIFICATION_PROVIDER_REMOVED = 'NOTIFICATION_PROVIDER_REMOVED',
  PAYMENT_PLAN_UPDATED = 'PAYMENT_PLAN_UPDATED',
  PROJECT_LEFT = 'PROJECT_LEFT',
  CUSTOM_ENTITY_ADDED = 'CUSTOM_ENTITY_ADDED',
  CUSTOM_ENTITY_REMOVED = 'CUSTOM_ENTITY_REMOVED',
  TRASH_EMPTIED = 'TRASH_EMPTIED',
  MEMBER_ADDED = 'MEMBER_ADDED',
  MEMBER_REMOVED = 'MEMBER_REMOVED',
  DEFAULT_GROUP_SET = 'DEFAULT_GROUP_SET',
  PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED',
  TEST = 'TEST',
  SCRIPT_NOTIFICATION = 'SCRIPT_NOTIFICATION',
  CARD_UPDATED = 'CARD_UPDATED',
  NOTIFICATION_TYPE_TOGGLED = 'NOTIFICATION_TYPE_TOGGLED',
  ACCESS_LEVEL_SET = 'ACCESS_LEVEL_SET',
  REDACTED = 'REDACTED',
  UPDATED = 'UPDATED',
  COMPLETED = 'COMPLETED',
  PERMISSION_ASSIGNED = 'PERMISSION_ASSIGNED',
  FAILED_LOGIN = 'FAILED_LOGIN',
}

/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ProjectFromJSON(json: any): Project {
  return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Project {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    subscriptionId: !exists(json, 'subscriptionId')
      ? undefined
      : json['subscriptionId'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    visible: !exists(json, 'visible') ? undefined : json['visible'],
    disabledDate: !exists(json, 'disabledDate')
      ? undefined
      : new Date(json['disabledDate']),
    expiry: !exists(json, 'expiry') ? undefined : new Date(json['expiry']),
    root: !exists(json, 'root') ? undefined : FolderFromJSON(json['root']),
    trash: !exists(json, 'trash') ? undefined : FolderFromJSON(json['trash']),
    defaultGroup: !exists(json, 'defaultGroup')
      ? undefined
      : GroupFromJSON(json['defaultGroup']),
    groups: !exists(json, 'groups')
      ? undefined
      : new Set((json['groups'] as Array<any>).map(GroupFromJSON)),
    members: !exists(json, 'members') ? undefined : json['members'],
    monthlyMaxSpaceUsage: !exists(json, 'monthlyMaxSpaceUsage')
      ? undefined
      : json['monthlyMaxSpaceUsage'],
    monthlyMaxSpaceTimestamp: !exists(json, 'monthlyMaxSpaceTimestamp')
      ? undefined
      : json['monthlyMaxSpaceTimestamp'],
    softLimit: !exists(json, 'softLimit') ? undefined : json['softLimit'],
    bytesLimit: !exists(json, 'bytesLimit') ? undefined : json['bytesLimit'],
    fileCountLimit: !exists(json, 'fileCountLimit')
      ? undefined
      : json['fileCountLimit'],
    folderCountLimit: !exists(json, 'folderCountLimit')
      ? undefined
      : json['folderCountLimit'],
    memberCountLimit: !exists(json, 'memberCountLimit')
      ? undefined
      : json['memberCountLimit'],
    expansionAllowed: !exists(json, 'expansionAllowed')
      ? undefined
      : json['expansionAllowed'],
    publicationAllowed: !exists(json, 'publicationAllowed')
      ? undefined
      : json['publicationAllowed'],
    enhancedSecurityForced: !exists(json, 'enhancedSecurityForced')
      ? undefined
      : json['enhancedSecurityForced'],
    folderProductionEnabled: !exists(json, 'folderProductionEnabled')
      ? undefined
      : json['folderProductionEnabled'],
    redactionsEnabled: !exists(json, 'redactionsEnabled')
      ? undefined
      : json['redactionsEnabled'],
    reviewEnabled: !exists(json, 'reviewEnabled')
      ? undefined
      : json['reviewEnabled'],
    conversionsEnabled: !exists(json, 'conversionsEnabled')
      ? undefined
      : json['conversionsEnabled'],
    globalEntityPublicationAllowed: !exists(
      json,
      'globalEntityPublicationAllowed',
    )
      ? undefined
      : json['globalEntityPublicationAllowed'],
    customEntityDefinitions: !exists(json, 'customEntityDefinitions')
      ? undefined
      : json['customEntityDefinitions'],
    adminNumber: !exists(json, 'adminNumber') ? undefined : json['adminNumber'],
    clientName: !exists(json, 'clientName') ? undefined : json['clientName'],
    projectType: !exists(json, 'projectType') ? undefined : json['projectType'],
    tasks: !exists(json, 'tasks')
      ? undefined
      : new Set((json['tasks'] as Array<any>).map(TaskFromJSON)),
    webhooks: !exists(json, 'webhooks') ? undefined : json['webhooks'],
    version: !exists(json, 'version') ? undefined : json['version'],
    id: !exists(json, 'id') ? undefined : json['id'],
    project: !exists(json, 'project')
      ? undefined
      : ProjectFromJSON(json['project']),
    totalSize: !exists(json, 'totalSize') ? undefined : json['totalSize'],
  };
}

export function ProjectToJSON(value?: Project | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    subscriptionId: value.subscriptionId,
    enabled: value.enabled,
    visible: value.visible,
    disabledDate:
      value.disabledDate === undefined
        ? undefined
        : value.disabledDate.toISOString(),
    expiry: value.expiry === undefined ? undefined : value.expiry.toISOString(),
    root: FolderToJSON(value.root),
    trash: FolderToJSON(value.trash),
    defaultGroup: GroupToJSON(value.defaultGroup),
    groups:
      value.groups === undefined
        ? undefined
        : Array.from(value.groups as Set<any>).map(GroupToJSON),
    members: value.members,
    monthlyMaxSpaceUsage: value.monthlyMaxSpaceUsage,
    monthlyMaxSpaceTimestamp: value.monthlyMaxSpaceTimestamp,
    softLimit: value.softLimit,
    bytesLimit: value.bytesLimit,
    fileCountLimit: value.fileCountLimit,
    folderCountLimit: value.folderCountLimit,
    memberCountLimit: value.memberCountLimit,
    expansionAllowed: value.expansionAllowed,
    publicationAllowed: value.publicationAllowed,
    enhancedSecurityForced: value.enhancedSecurityForced,
    folderProductionEnabled: value.folderProductionEnabled,
    redactionsEnabled: value.redactionsEnabled,
    reviewEnabled: value.reviewEnabled,
    conversionsEnabled: value.conversionsEnabled,
    globalEntityPublicationAllowed: value.globalEntityPublicationAllowed,
    customEntityDefinitions:
      value.customEntityDefinitions === undefined
        ? undefined
        : Array.from(value.customEntityDefinitions as Set<any>),
    adminNumber: value.adminNumber,
    clientName: value.clientName,
    projectType: value.projectType,
    tasks:
      value.tasks === undefined
        ? undefined
        : Array.from(value.tasks as Set<any>).map(TaskToJSON),
    webhooks: value.webhooks,
    version: value.version,
    id: value.id,
    project: ProjectToJSON(value.project),
    totalSize: value.totalSize,
  };
}
