import {
  BlockTitle,
  FileManagerBreadcrumbs,
  FileManagerContainer,
  FileManagerFiles,
  FileManagerNavigationLayout,
  Layout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getChildEntities,
  getEntityMetadata,
  getEntityParents,
  setDisplayPreviewFileModal,
  setSelectedEntityIds,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams, useSearchParams } from '@oproma/router';
import { useEffect } from 'react';

const EntitiesEntityPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { entityId } = useParams();
  const [queryParams] = useSearchParams();
  const { entities } = useAppSelector((state) => state.fileManager);

  const filteredEntities = entities.filter((entity) => !entity.deleted);

  useEffect(() => {
    if (!entityId) return;
    dispatch(getEntityMetadata(entityId));
    dispatch(getEntityParents(entityId));
    dispatch(
      getChildEntities({
        entityId,
      }),
    );
    dispatch(setSelectedEntityIds([]));
  }, [entityId]);

  useEffect(() => {
    // Logic for previewing the file if found in the query params
    const file = queryParams.get('file');
    if (file) {
      dispatch(getEntityMetadata(file));
      dispatch(setDisplayPreviewFileModal(true));
    }
  }, [queryParams]);

  return (
    <Layout>
      <FileManagerContainer>
        <FileManagerNavigationLayout
          title={
            <BlockTitle page>
              {t('FILE_MANAGER.EXPLORER')}
              <FileManagerBreadcrumbs type="general" />
            </BlockTitle>
          }
          entityFinder
          displayTypeFilter
        >
          <FileManagerFiles entities={filteredEntities} />
        </FileManagerNavigationLayout>
      </FileManagerContainer>
    </Layout>
  );
};

export default EntitiesEntityPage;
