import {
  createCreditCard,
  toggleDisplayOnboardCreditCardModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Col, Row } from '../../grid.component';

export const OnboardCreditCardModal = () => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [elementError, setElementError] = useState<{
    complete: boolean;
    message?: string;
  }>();
  const { displayOnboardCreditCardModal, loading, createdCreditCard } =
    useAppSelector((state) => state.finance);

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (createdCreditCard) {
      toggle();
    }
  }, [createCreditCard]);

  const onFormSubmit = async () => {
    if (!stripe || !elements)
      return console.error('Stripe.js has not loaded yet.');

    if (!elementError?.complete || elementError?.message) {
      return console.error('Card error.');
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return console.error('Card element not found.');

    const tokenResponse = await stripe.createToken(cardElement);
    // TODO: Dispatch onboarding credit card token
    dispatch(createCreditCard(tokenResponse.token?.id || ''));

    cardElement.clear();

    toggle();
  };

  const toggle = () => {
    dispatch(toggleDisplayOnboardCreditCardModal());
  };

  return (
    <Modal isOpen={displayOnboardCreditCardModal} size="lg" toggle={toggle}>
      <a
        href="/"
        className="close"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
      >
        <em className="icon ni ni-cross"></em>
      </a>
      <ModalBody className="modal-body-md">
        <div className="p-2">
          <h5 className="title">Onboard Credit Card</h5>
          <Form onSubmit={handleSubmit(onFormSubmit)} className="gy-3">
            <Row className="g-3 align-center">
              <Col lg="5">
                <FormGroup>
                  <label className="form-label" htmlFor="site-name">
                    Credit Card
                  </label>
                  <span className="form-note">
                    Provide details to onboard credit card.
                  </span>
                </FormGroup>
              </Col>
              <Col lg="7">
                <FormGroup>
                  <div className="form-control-wrap">
                    <CardElement
                      className="form-control"
                      options={{
                        style: {
                          base: {
                            fontFamily: 'var(--font-lato)',
                            color: '#ebeef2',
                            ':-webkit-autofill': {
                              color: '#FFFFFF',
                            },
                          },
                        },
                      }}
                      onChange={({ error, complete }) =>
                        setElementError({
                          complete,
                          message: error?.message,
                        })
                      }
                    />
                    {elementError?.message && (
                      <p className="invalid">{elementError.message}</p>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="g-3">
              <Col lg="12">
                <div className="form-group mt-2">
                  <Button
                    color="primary"
                    className="btn-block"
                    size="md"
                    type="submit"
                    disabled={
                      !elementError?.complete ||
                      !!elementError?.message ||
                      loading
                    }
                  >
                    Onboard
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};
