import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  size?: string;
  className?: string;
  outline?: boolean;
  disabled?: boolean;
}

export const Button = ({
  color,
  size,
  className,
  outline,
  disabled,
  ...props
}: ButtonProps) => {
  const buttonClass = clsx({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });

  return (
    <button className={buttonClass} disabled={disabled} {...props}>
      {props.children}
    </button>
  );
};
