import clsx from 'clsx';
import { ElementType } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Icon } from './icon.component';

interface TooltipProps {
  iconClass?: string;
  icon?: string;
  id: string;
  direction:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end';
  text?: string;
  containerClassName?: string;
  tag?: ElementType;
}

export const Tooltip = ({
  iconClass,
  icon,
  id,
  direction,
  text,
  containerClassName,
  ...props
}: TooltipProps) => {
  return (
    <>
      {props.tag ? (
        <props.tag className={containerClassName} id={id}>
          <Icon className={clsx(iconClass)} name={icon}></Icon>
        </props.tag>
      ) : (
        <Icon className={clsx(iconClass)} name={icon} id={id}></Icon>
      )}
      <UncontrolledTooltip autohide={false} placement={direction} target={id}>
        {text}
      </UncontrolledTooltip>
    </>
  );
};
