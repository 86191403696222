/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FolderInformation
 */
export interface FolderInformation {
  /**
   *
   * @type {string}
   * @memberof FolderInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FolderInformation
   */
  name?: string;
}

/**
 * Check if a given object implements the FolderInformation interface.
 */
export function instanceOfFolderInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FolderInformationFromJSON(json: any): FolderInformation {
  return FolderInformationFromJSONTyped(json, false);
}

export function FolderInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FolderInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function FolderInformationToJSON(value?: FolderInformation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
  };
}
