import { SubmitHandler, useForm } from 'react-hook-form';
import { Card, Form } from 'reactstrap';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useWizard } from '../hooks';

export const MFAOnboardAuthenticator = () => {
  const { next } = useWizard();

  const { handleSubmit } = useForm({});

  const onFormSubmit: SubmitHandler<Record<string, unknown>> = () => {
    next();
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} className="gy-3">
      <div className="p-2">
        <h5 className="title">Onboard Authenticator</h5>
        <div className="mt-4">
          <p>
            If you do not use an authentication application, download and
            install an Authenticator application from your device’s (mobile or
            computer) application store or from one of the following links.
          </p>
        </div>
      </div>
      <Row className="g-3">
        <Col md="6" lg="6">
          <Card className="card-bordered pricing text-center">
            <div className="pricing-body">
              <div className="pricing-title mx-auto">
                <h5 className="title">Google Authenticator</h5>
              </div>
              <div className="pricing-action">
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    Android
                  </Button>
                </a>
                <a
                  href="https://apps.apple.com/ca/app/google-authenticator/id388497605?l=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    iOS
                  </Button>
                </a>
              </div>
            </div>
          </Card>
        </Col>
        <Col md="6" lg="6">
          <Card className="card-bordered pricing text-center">
            <div className="pricing-body">
              <div className="pricing-title mx-auto">
                <h5 className="title">Microsoft Authenticator</h5>
              </div>
              <div className="pricing-action">
                <a
                  href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    Android
                  </Button>
                </a>
                <a
                  href="https://apps.apple.com/ca/app/microsoft-authenticator/id983156458?l=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    iOS
                  </Button>
                </a>
              </div>
            </div>
          </Card>
        </Col>
        <Col md="6" lg="6">
          <Card className="card-bordered pricing text-center">
            <div className="pricing-body">
              <div className="pricing-title mx-auto">
                <h5 className="title">iOS 15</h5>
                <span className="sub-text">
                  {/* TODO: Translate */}
                  Starting with iOS 15 this functionality is built directly
                  within iOS
                </span>
              </div>
            </div>
          </Card>
        </Col>
        <Col md="6" lg="6">
          <Card className="card-bordered pricing text-center">
            <div className="pricing-body">
              <div className="pricing-title mx-auto">
                <h5 className="title">Other</h5>
                <span className="sub-text">
                  There exists many other application which support the TOTP
                  protocol.
                </span>
              </div>
              <div className="pricing-action">
                <a
                  href="https://play.google.com/store/search?q=TOTP&c=apps&hl=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    Android
                  </Button>
                </a>
                <a
                  href="https://apps.apple.com/ca/app/otp-auth/id659877384?l=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    iOS
                  </Button>
                </a>
                <a
                  href="https://apps.apple.com/ca/app/otp-manager/id928941247?l=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    macOS
                  </Button>
                </a>
                <a
                  href="https://apps.microsoft.com/detail/9nblggh6hngn?activetab=pivot%3Aoverviewtab&hl=en-ca&gl=CA"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button color="link" type="button">
                    Windows
                  </Button>
                </a>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <p>
          Once the selected authenticator application is installed, open the
          application, then click on “Next” to continue to the next step.
        </p>
      </Row>
      <Row className="g-3">
        <Col lg="7" className="offset-lg-5">
          <div className="form-group mt-2">
            <Button
              color="primary"
              className="btn-block"
              size="md"
              type="submit"
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
      <div className="actions clearfix"></div>
    </Form>
  );
};
