import { useTranslation } from '@oproma/i18n';
import {
  getChildEntities,
  getEntityParentsForMove,
  moveEntity,
  useAppDispatch,
  useAppSelector,
  setDisplayMoveEntityModal,
  setBulkAction,
} from '@oproma/prividox-store';
import { Link, useParams } from '@oproma/router';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'reactstrap';
import { Icon } from '../../icon.component';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from '../svg-thumbnails.component';

export const FileManagerMoveFileEntityModal = () => {
  const dispatch = useAppDispatch();
  const { entityId } = useParams();
  const { t } = useTranslation();
  const {
    displayMoveEntityModal,
    previewEntities: entities,
    lastOpenedEntity,
    selectedEntityIds,
    bulkAction,
    entityParents,
  } = useAppSelector((state) => state.fileManager);
  const openedWorkspace = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === state.workspaces.lastOpenedWorkspace,
    ),
  );
  const { metadata } = useAppSelector((state) => state.workspace);
  const [selected, setSelected] = useState(openedWorkspace?.root);
  const [parentEntities, setParentEntities] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);

  const selectEntity = (entityId: string, entityName: string) => {
    const entityIndex = parentEntities.findIndex(
      (entity) => entity.id === entityId,
    );
    if (entityIndex !== -1) {
      setParentEntities(parentEntities.slice(0, entityIndex + 1));
      setSelected(entityId);
      dispatch(getChildEntities({ entityId, previewEntities: true }));
    } else {
      setParentEntities([
        ...parentEntities,
        { id: entityId, name: entityName },
      ]);
      setSelected(entityId);
      dispatch(getChildEntities({ entityId, previewEntities: true }));
    }
  };

  useEffect(() => {
    if (!metadata?.root) return;

    dispatch(
      getChildEntities({
        entityId: metadata.root,
        previewEntities: true,
      }),
    );
  }, [metadata]);

  const toggle = () => {
    dispatch(setDisplayMoveEntityModal(false));
    setSelected('');
    setParentEntities([]);
  };

  const renderRootFolder = () => {
    if (openedWorkspace?.root) {
      dispatch(
        getEntityParentsForMove(metadata?.root ?? openedWorkspace?.root),
      );
      dispatch(
        getChildEntities({
          entityId: metadata?.root ?? openedWorkspace?.root,
          previewEntities: true,
        }),
      );
    }
    setSelected(openedWorkspace?.root);
    setParentEntities([]);
  };

  const handleMoveEntity = () => {
    if (
      !selectedEntityIds &&
      (!lastOpenedEntity ||
        (!lastOpenedEntity.id && !lastOpenedEntity.folderId))
    )
      return;

    if (
      selected === lastOpenedEntity?.id ||
      selected === lastOpenedEntity?.folderId ||
      !selected
    ) {
      toast.error(t('FILE_MANAGER.TOASTS.LOCATION_MOVE_ERROR'));
      return;
    }
    if ((selected || openedWorkspace) && !bulkAction) {
      dispatch(
        moveEntity({
          entity:
            (lastOpenedEntity?.id as string) ??
            (lastOpenedEntity?.folderId as string),
          destination: selected ?? (openedWorkspace?.root as string),
          folderId: entityId,
        }),
      );
      toggle();
    }
    if ((selectedEntityIds || openedWorkspace) && bulkAction) {
      selectedEntityIds.forEach((entityId) => {
        dispatch(
          moveEntity({
            entity: entityId,
            destination: selected ?? (openedWorkspace?.root as string),
            folderId: entityId,
          }),
        );
      });
      setTimeout(() => {
        dispatch(
          getChildEntities({
            entityId: entityParents[0].id as string,
          }),
        );
      }, 100);
      toast.success(t('FILE_MANAGER.MOVE.BULK_MOVED'));
      setParentEntities([]);
      dispatch(setDisplayMoveEntityModal(false));
    }
  };

  return (
    <Modal isOpen={displayMoveEntityModal} size="xl" toggle={toggle}>
      <div className="modal-header align-center border-bottom-0">
        <h5 className="modal-title">{t('FILE_MANAGER.MOVE.TITLE')}</h5>
      </div>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="modal-body pt-0 mt-n2">
        <ul className="breadcrumb breadcrumb-alt breadcrumb-xs breadcrumb-arrow mb-1">
          <li className="breadcrumb-item">
            <Link to="" onClick={renderRootFolder}>
              {t('FILE_MANAGER.MOVE.FILE_PATH')} <Icon name="home" />
            </Link>
          </li>
          {parentEntities.map((entity, index) => (
            <li
              className={`breadcrumb-item ${
                index === parentEntities.length - 1 ? 'active' : ''
              }`}
              key={index}
            >
              <Link to="#" onClick={() => selectEntity(entity.id, entity.name)}>
                {entity.name}
              </Link>
            </li>
          ))}
          {/* {selected && <li className="breadcrumb-item">{selected}</li>} */}
        </ul>
        <div className="nk-fmg-listing is-scrollable">
          <div className="nk-files nk-files-view-list is-compact">
            <div className="nk-files-list">
              {entities
                .filter((item) => item.type !== 'file')
                .map((item) => {
                  return (
                    <div
                      className={clsx(`nk-file-item nk-file`, {
                        selected: item.folderId === selected,
                      })}
                      key={item.id}
                      onClick={() => {
                        if (!item.name) {
                          return;
                        }
                        selectEntity(item.folderId as string, item.name);
                      }}
                      onKeyDown={(event) => {
                        if (!item.name || !item.folderId) {
                          return;
                        }
                        // dispatch using "Enter" or "Space" key
                        if (event.key === 'Enter' || event.key === ' ') {
                          selectEntity(item.folderId, item.name);
                          event.preventDefault();
                        }
                      }}
                      tabIndex={0} // Making div focusable
                    >
                      <div className="nk-file-info">
                        <a
                          className="nk-file-link"
                          href="#link"
                          onClick={(ev) => ev.preventDefault()}
                        >
                          <div className="nk-file-title">
                            <div className="nk-file-icon">
                              <div className="nk-file-icon-type">
                                {getEntityThumbnail(
                                  item.type as EntityThumbnail,
                                )}
                              </div>
                            </div>
                            <div className="nk-file-name">
                              <div className="nk-file-name-text">
                                <span className="title">{item.name}</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="nk-file-actions">
                        <a
                          href="#link"
                          onClick={(ev) => ev.preventDefault()}
                          className="btn btn-sm btn-icon btn-trigger"
                        >
                          <Icon name="chevron-right"></Icon>
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer bg-light">
        <div className="modal-footer-between">
          <div className="g">
            <a
              href="link"
              onClick={(ev) => {
                ev.preventDefault();
                toggle();
                // toggleCreate();
              }}
              className="link link-primary"
            >
              {t('FILE_MANAGER.MOVE.NEW_FOLDER')} {/*TODO: Do we need this?*/}
            </a>
          </div>
          <div className="g">
            <ul className="btn-toolbar g-3">
              <li>
                <a
                  href="#file-share"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggle();
                  }}
                  className="btn btn-outline-light btn-white"
                >
                  {t('FILE_MANAGER.MOVE.CANCEL')}
                </a>
              </li>
              <li>
                <a
                  href="link"
                  onClick={(ev) => {
                    ev.preventDefault();
                    handleMoveEntity();
                  }}
                  className="btn btn-primary"
                >
                  {t('FILE_MANAGER.MOVE.MOVE')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};
