import {
  GroupInfo,
  MemberInformation,
  NotificationMessage,
} from '@oproma/prividox-orchestration-open-api';

export enum GroupsErrorCodesEnum {
  GET_WORKSPACE_GROUPS_FAILED = 'GET_WORKSPACE_GROUPS_FAILED',
  GET_GROUP_MEMBERS_FAILED = 'GET_GROUP_MEMBERS_FAILED',
  DELETE_GROUP_MEMBERS_FAILED = 'DELETE_GROUP_MEMBERS_FAILED',
  RENAME_GROUP_FAILED = 'RENAME_GROUP_FAILED',
  DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED',
  CREATE_GROUP_MEMBER_FAILED = 'CREATE_GROUP_MEMBER_FAILED',
  EDIT_DEFAULT_GROUP_FAILED = 'EDIT_DEFAULT_GROUP_FAILED',
  CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED',
}

export type SortGroupOrder = 'asc' | 'desc';

export interface GroupRow extends GroupInfo {
  thumbnailBackground: string;
  thumbnail: string;
  checked: boolean;
}

export interface GroupMembers extends MemberInformation {
  thumbnailBackground: string;
  thumbnail: string;
}

export interface IGroupsState {
  readonly loading: boolean;
  readonly groups: GroupRow[];
  readonly groupId: string;
  readonly groupMembers: GroupMembers[];
  readonly groupQuery: string;
  readonly deletedGroupMessage: boolean;
  readonly permissionLevelFilter: boolean | null;
  readonly groupSortOrder: SortGroupOrder;
  readonly currentPaginatedPage: number;
  readonly entriesPerPaginatedPage: number;
  readonly onboardedGroups: string[];
  readonly lastOpenedGroup: string | null;
  readonly groupReport: NotificationMessage[];
  readonly displayGroupFinder: boolean;
  readonly displayMobileGroupsTable: boolean;
  readonly displayDeleteGroupModal: boolean;
  readonly displayBulkDeleteGroupModal: boolean;
  readonly displayEditGroupModal: boolean;
  readonly displayOnboardGroupsModal: boolean;
  readonly displayDeleteGroupMemberModal: boolean;
  readonly error: {
    code: GroupsErrorCodesEnum;
    message: string;
  } | null;
}
