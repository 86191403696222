import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAppDispatch,
  useAppSelector,
  validateMFACode,
} from '@oproma/prividox-store';
import QRCode from 'qrcode.react';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useWizard } from '../hooks';
import { Icon } from '../icon.component';

const configureAutheticatorFormSchema = yup
  .object({
    code: yup.number().required('Please provide a valid MFA code.'),
  })
  .required();

type ConfigureAutheticatorFormValues = yup.InferType<
  typeof configureAutheticatorFormSchema
>;

const extractOTPSecret = (otpLink: string | undefined) => {
  if (!otpLink) return;
  // Create a URL object
  const url = new URL(otpLink);

  // Get the secret from the URL parameters
  const secret = url.searchParams.get('secret');

  // Split the secret into chunks of 4 characters
  const secretArray: string[] = [];
  if (!secret?.length) return secretArray;
  for (let i = 0; i < secret.length; i += 4) {
    secretArray.push(secret.slice(i, i + 4));
  }

  return secretArray;
};

export const MFAConfigureAuthenticator = () => {
  const dispatch = useAppDispatch();
  const { next, prev } = useWizard();
  const { mfa, validMFACode, loading } = useAppSelector((state) => state.user);
  const mfaURL = mfa?.url;
  const mfaCodes = extractOTPSecret(mfaURL);

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfigureAutheticatorFormValues>({
    resolver: yupResolver(configureAutheticatorFormSchema),
  });

  const { ref: codeRef, ...registerCode } = registerField('code');

  const onFormSubmit: SubmitHandler<ConfigureAutheticatorFormValues> = async ({
    code,
  }) => {
    console.log(code);
    dispatch(validateMFACode(code));
  };

  useEffect(() => {
    if (validMFACode) {
      next();
    }
  }, [validMFACode]);

  const navigateBack = () => prev();

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} className="gy-3">
      <div className="p-2">
        <h5 className="title">Configure Authenticator</h5>
        <div className="mt-4">
          <p>
            Add an account within your authenticator application and scan the
            following QR code with your camera. Alternatively, you may use the
            32-character code present under the QR code for a manual entry.
          </p>
        </div>
      </div>
      <Row>
        <div className="d-flex justify-content-center my-4">
          {mfaURL && <QRCode value={mfaURL} size={200} includeMargin />}
        </div>
        <div className="pricing-body">
          <ul className="pricing-features">
            {mfaCodes?.map((code, index) => (
              <li key={index}>
                <span className="w-50">Code {index + 1}</span> -{' '}
                <span className="ms-auto">{code}</span>
              </li>
            ))}
          </ul>
        </div>
      </Row>
      <Row className="g-3">
        <Col>
          {validMFACode === false && (
            <Alert className="alert-icon" color="danger">
              <Icon name="cross-circle" />
              Failed to validate MFA code. Please try again!
            </Alert>
          )}
          <FormGroup>
            <div className="form-label-group">
              <Label className="form-label" for="code">
                Authenticator Code
              </Label>
            </div>
            <div className="form-control-wrap">
              <Input
                type="number"
                id="code"
                placeholder={'Code'}
                innerRef={codeRef}
                {...registerCode}
                tabIndex={1}
              />
              {errors.code && (
                <span className="invalid">{errors.code.message}</span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg="5">
          <div className="form-group mt-2">
            <Button
              color="primary"
              outline
              className="btn-block"
              size="md"
              onClick={navigateBack}
            >
              Back
            </Button>
          </div>
        </Col>

        <Col lg="7">
          <div className="form-group mt-2">
            <Button
              color="primary"
              className="btn-block"
              size="md"
              type="submit"
              disabled={loading}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
