import { useTranslation } from '@oproma/i18n';
import {
  AuthErrorCodesEnum,
  toggleDisplayDisableMFAModel,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import {
  Alert,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useDisableMFAForm } from '../hooks';
import { Icon } from '../icon.component';
import { retrieveApiErrorMessage } from '../utils';

export const DisableMFAModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { displayDisableMFAModel } = useAppSelector((state) => state.workspace);

  const {
    error,
    displayPasswordText,
    loading,
    errors,
    handleSubmit,
    onFormSubmit,
    passwordRef,
    registerPassword,
    toggleDisplayingPasswordText,
  } = useDisableMFAForm();

  const toggle = () => {
    dispatch(toggleDisplayDisableMFAModel());
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={displayDisableMFAModel}
      toggle={toggle}
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          {error?.code === AuthErrorCodesEnum.DISABLE_MFA_FAILED && (
            <Alert className="alert-icon" color="danger">
              <Icon name="cross-circle" />
              <p
                dangerouslySetInnerHTML={{
                  __html: t(retrieveApiErrorMessage(error.message)),
                }}
              />
            </Alert>
          )}
          <Row className="gy-4">
            <Col md="12">
              <FormGroup>
                <div className="form-label-group">
                  <Label for="password">{t('AUTH.DISABLE_MFA')}</Label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={toggleDisplayingPasswordText}
                    className={clsx(
                      'form-icon lg form-icon-right passcode-switch',
                      displayPasswordText ? 'is-hidden' : 'is-shown',
                    )}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <Input
                    type={displayPasswordText ? 'text' : 'password'}
                    id="password"
                    placeholder={t('AUTH.LOGIN.FORM.ENTER_PASSWORD')}
                    autoComplete="off"
                    className={clsx(
                      'form-control-lg',
                      displayPasswordText ? 'is-hidden' : 'is-shown',
                    )}
                    innerRef={passwordRef}
                    {...registerPassword}
                  />
                  {errors.password && (
                    <span className="invalid">{errors.password.message}</span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li>
                  <Button color="primary" className="btn-block" type="submit">
                    {loading ? <Spinner size="sm" color="light" /> : 'Confirm'}
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
