import { useTranslation } from '@oproma/i18n';
import { useAppSelector } from '@oproma/prividox-store';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../../block.component';

export const WorkspaceOptionsHeader = () => {
  const { metadata } = useAppSelector((state) => state.workspace);
  const { t } = useTranslation();
  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
          <BlockTitle tag="h3" page>
            {t('WORKSPACE_OPTIONS.HEADING')}
          </BlockTitle>
          <BlockDes className="text-soft">
            <ul className="list-inline">
              <li
                dangerouslySetInnerHTML={{
                  __html: t('WORKSPACE_OPTIONS.SUBHEADING', {
                    workspace: metadata?.name,
                  }),
                }}
              />
            </ul>
          </BlockDes>
        </BlockHeadContent>
      </BlockBetween>
    </BlockHead>
  );
};
