/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupInfo } from './GroupInfo';
import {
  GroupInfoFromJSON,
  GroupInfoFromJSONTyped,
  GroupInfoToJSON,
} from './GroupInfo';

/**
 *
 * @export
 * @interface PagerGroupInfo
 */
export interface PagerGroupInfo {
  /**
   *
   * @type {Array<GroupInfo>}
   * @memberof PagerGroupInfo
   */
  list?: Array<GroupInfo>;
  /**
   *
   * @type {boolean}
   * @memberof PagerGroupInfo
   */
  more?: boolean;
}

/**
 * Check if a given object implements the PagerGroupInfo interface.
 */
export function instanceOfPagerGroupInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PagerGroupInfoFromJSON(json: any): PagerGroupInfo {
  return PagerGroupInfoFromJSONTyped(json, false);
}

export function PagerGroupInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PagerGroupInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    list: !exists(json, 'list')
      ? undefined
      : (json['list'] as Array<any>).map(GroupInfoFromJSON),
    more: !exists(json, 'more') ? undefined : json['more'],
  };
}

export function PagerGroupInfoToJSON(value?: PagerGroupInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    list:
      value.list === undefined
        ? undefined
        : (value.list as Array<any>).map(GroupInfoToJSON),
    more: value.more,
  };
}
