import { HTMLAttributes, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Button, Card } from 'reactstrap';
import { ClipboardCopy } from './clipboard-copy.component';
import { OverlineTitle } from './text.component';

interface PreviewCardProps extends HTMLAttributes<HTMLDivElement> {
  bodyClass?: string;
}

export const PreviewCard = ({
  className,
  bodyClass,
  ...props
}: PreviewCardProps) => (
  <Card className={`card-preview ${className ? className : ''}`}>
    <div className={`card-inner ${bodyClass ? bodyClass : ''}`}>
      {props.children}
    </div>
  </Card>
);

export const PreviewAltCard = ({
  className,
  bodyClass,
  ...props
}: PreviewCardProps) => (
  <Card className={`${className ? className : ''}`}>
    <div className={`card-inner ${bodyClass ? bodyClass : ''}`}>
      {props.children}
    </div>
  </Card>
);

interface PreviewTableProps extends HTMLAttributes<HTMLTableElement> {
  size?: string;
}

export const PreviewTable = ({ size, ...props }: PreviewTableProps) => (
  <Card className="card-preview">
    <table className={`preview-reference table ${size ? `table-${size}` : ''}`}>
      {props.children}
    </table>
  </Card>
);

interface CodeBlockProps extends HTMLAttributes<HTMLDivElement> {
  language?: string;
  title?: string;
  children: string;
}

export const CodeBlock = ({ language, title, ...props }: CodeBlockProps) => {
  const [copyText] = useState(props.children);
  const [copyState, setCopyState] = useState(false);

  const onCopyClick = () => {
    setCopyState(true);
    setTimeout(() => setCopyState(false), 2000);
  };

  return (
    <div
      className={`code-block code-block-clean ${
        copyState ? 'clipboard-success' : ''
      }`}
    >
      <OverlineTitle className="title">{title || 'Code Example'}</OverlineTitle>
      <ClipboardCopy text={copyText} onCopy={onCopyClick}>
        <Button color="blank" size="sm" className="clipboard-init">
          {copyState ? 'Copied' : 'Copy'}
        </Button>
      </ClipboardCopy>
      <SyntaxHighlighter
        className="bg-lighter h-max-150px m-0"
        language="javascript"
        style={darcula}
      >
        {props.children}
      </SyntaxHighlighter>
    </div>
  );
};
