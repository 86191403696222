import clsx from 'clsx';
import { useState } from 'react';
import { Icon } from './icon.component';

type OutlinedInputProps = {
  label: string;
  size?: 'sm' | 'lg' | 'xl';
  id: string;
  icon?: string;
};

export const OutlinedInput = ({
  label,
  size,
  id,
  icon,
}: OutlinedInputProps) => {
  const [focus, setFocus] = useState(false);
  return (
    <div className={clsx(`form-control-wrap`, { focused: focus })}>
      {icon && (
        <div className="form-icon form-icon-right xl">
          <Icon name={icon}></Icon>
        </div>
      )}
      <input
        type="text"
        className={clsx(`form-control-outlined form-control`, {
          [`form-control-${size}`]: size,
        })}
        id={id}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <label className="form-label-outlined" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
