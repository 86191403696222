import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StarredEntitiesState {
  starred: string[];
}

const initialState: StarredEntitiesState = {
  starred: [],
};

export const starredEntitiesSlice = createSlice({
  name: '@@starredEntities',
  initialState,
  reducers: {
    addStarredEntity: (state, action: PayloadAction<string>) => {
      const entityId = action.payload;
      const uniqueEntities = new Set([...state.starred, entityId]);
      state.starred = Array.from(uniqueEntities);
    },
    removeStarredEntity: (state, action: PayloadAction<string>) => {
      state.starred = state.starred.filter((id) => id !== action.payload);
    },
  },
});

export const { addStarredEntity, removeStarredEntity } =
  starredEntitiesSlice.actions;

export const starredEntitiesReducer = starredEntitiesSlice.reducer;
