export const bulkOperationOptions = [{ value: 'delete', label: 'Delete' }];

export const groupStatusLabel = [
  { value: true, label: 'Default' },
  { value: false, label: 'Set as Default' },
];

export type LanguageType = 'en' | 'fr' | 'en_fr' | 'fr_en';

export const languages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'en_fr', label: 'English · Français' },
  { value: 'fr_en', label: 'Français · English' },
];
