import { useTranslation } from '@oproma/i18n';
import {
  deleteGroupMember,
  setOpenDeleteGroupMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { Col, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';

export const RemoveGroupMemberModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, groupId } = useParams();
  const { displayDeleteGroupMemberModal } = useAppSelector(
    (state) => state.groups,
  );

  const lastOpenedGroupMember = useAppSelector((state) =>
    state.groups.groupMembers.find(
      (member) => member.id === state.members.lastOpenedMember,
    ),
  );

  const toggleModal = () =>
    dispatch(setOpenDeleteGroupMemberModal(!displayDeleteGroupMemberModal));

  const onDeleteGroupMember = (
    groupId: string | undefined,
    user: string | undefined,
  ) => {
    if (groupId && workspaceId) {
      dispatch(
        deleteGroupMember({
          groupId: groupId,
          user: user ?? '',
        }),
      );

      toggleModal();
    }
  };

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={displayDeleteGroupMemberModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('GROUPS.REMOVE_MEMBER.TITLE')}</h5>
          <div className="mt-4">
            <p>{t('GROUPS.REMOVE_MEMBER.HEADING')}</p>
            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li className="me-4">
                  <Button
                    color="primary"
                    onClick={() =>
                      onDeleteGroupMember(groupId, lastOpenedGroupMember?.id)
                    }
                  >
                    {t('GROUPS.REMOVE_MEMBER.CONFIRM')}
                  </Button>
                </li>
                <li>
                  <Button onClick={handleCancel}>
                    {t('GROUPS.REMOVE_MEMBER.CANCEL')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
