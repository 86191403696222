import { ProjectInformation } from '@oproma/prividox-orchestration-open-api';
import { useEffect, useState } from 'react';

// Filter workspaces by owner or member
export const useFilteredWorkspaces = (
  workspaces: ProjectInformation[],
  filterStatus: string,
) => {
  const [filteredWorkspaces, setFilteredWorkspaces] = useState<
    ProjectInformation[]
  >([]);

  useEffect(() => {
    if (filterStatus === 'Owner') {
      setFilteredWorkspaces(
        workspaces.filter((workspace) => workspace.amOwner),
      );
    } else if (filterStatus === 'Member') {
      setFilteredWorkspaces(
        workspaces.filter((workspace) => !workspace.amOwner),
      );
    } else {
      setFilteredWorkspaces(workspaces);
    }
  }, [workspaces, filterStatus]);

  return filteredWorkspaces;
};
