/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
} from './Project';

/**
 *
 * @export
 * @interface IPrincipal
 */
export interface IPrincipal {
  /**
   *
   * @type {string}
   * @memberof IPrincipal
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IPrincipal
   */
  id?: string;
  /**
   *
   * @type {Project}
   * @memberof IPrincipal
   */
  project?: Project;
}

/**
 * Check if a given object implements the IPrincipal interface.
 */
export function instanceOfIPrincipal(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function IPrincipalFromJSON(json: any): IPrincipal {
  return IPrincipalFromJSONTyped(json, false);
}

export function IPrincipalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): IPrincipal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    id: !exists(json, 'id') ? undefined : json['id'],
    project: !exists(json, 'project')
      ? undefined
      : ProjectFromJSON(json['project']),
  };
}

export function IPrincipalToJSON(value?: IPrincipal | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
    project: ProjectToJSON(value.project),
  };
}
