import {
  BlockTitle,
  FileManagerContainer,
  FileManagerNavigationLayout,
  FileManagerPreview,
  Layout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getWorkspaceDiskUsage,
  getWorkspaceEntities,
  getWorkspaceMembers,
  getWorkspaceMetadata,
  getWorkspacePlan,
  setLastOpenedWorkspace,
  setSelectedEntityIds,
  useAppDispatch,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';

const WorkspaceFilesPage = () => {
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(setLastOpenedWorkspace(workspaceId));
    dispatch(getWorkspaceEntities(workspaceId));
    dispatch(getWorkspaceMembers(workspaceId));
    dispatch(getWorkspaceMetadata(workspaceId));
    dispatch(getWorkspaceDiskUsage(workspaceId));
    dispatch(getWorkspacePlan(workspaceId));
    dispatch(setSelectedEntityIds([]));
  }, [workspaceId]);

  return (
    <Layout>
      <FileManagerContainer>
        <FileManagerNavigationLayout
          title={<BlockTitle page>{t('FILE_MANAGER.TITLE')}</BlockTitle>}
          entityFinder
        >
          <FileManagerPreview />
        </FileManagerNavigationLayout>
      </FileManagerContainer>
    </Layout>
  );
};

export default WorkspaceFilesPage;
