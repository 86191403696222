import {
  WorkspacesErrorCodesEnum,
  toggleDisplayOnboardWorkspaceModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Alert } from 'reactstrap';
import { Loader } from '../loader.component';
import { retrieveApiErrorMessage } from '../utils';

export const OnboardWorkspaceCompletedBanner = () => {
  const dispatch = useAppDispatch();
  const { error, loading, createdWorkspace } = useAppSelector(
    (state) => state.workspaces,
  );

  const toggle = () => {
    dispatch(toggleDisplayOnboardWorkspaceModal());
  };

  useEffect(() => {
    if (error?.code === WorkspacesErrorCodesEnum.CREATE_WORKSPACE_FAILED) {
      toast.error(retrieveApiErrorMessage(error.message));
    }
  }, [error]);

  useEffect(() => {
    if (createdWorkspace) {
      toggle();
    }
  }, [createdWorkspace]);

  return (
    <div className="mb-3">
      {loading && <Loader />}
      {error?.code === WorkspacesErrorCodesEnum.CREATE_WORKSPACE_FAILED && (
        <Alert color="danger" className="alert-pro">
          <div className="alert-text">
            {/* TODO: Move to translation file */}
            <h6>Failed to create workspace. Please try again!</h6>
            <p>{retrieveApiErrorMessage(error.message)}</p>
          </div>
        </Alert>
      )}
    </div>
  );
};
