import { useTranslation } from '@oproma/i18n';
import {
  GeneralThemeTypeEnum,
  LayoutThemeTypeEnum,
  ThemeModeEnum,
  setCompletedOnboarding,
  setHeaderThemeType,
  setLayoutThemeType,
  setSidebarThemeType,
  setThemeMode,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useNavigate } from '@oproma/router';
import clsx from 'clsx';
import { Form } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { BlockHeadContent } from '../../block.component';
import { Button } from '../../button.component';
type AuthOnboardingPreferencesPhaseProps = {
  triggerNextPhase?: () => void;
  triggerPreviousPhase: () => void;
};

export const AuthOnboardingPreferencesPhase = ({
  triggerPreviousPhase,
}: AuthOnboardingPreferencesPhaseProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { layoutThemeType, headerThemeType, sidebarThemeType, themeMode } =
    useAppSelector((state) => state.layout);

  const changeThemeMode = (mode: ThemeModeEnum) => {
    dispatch(setThemeMode(mode));
  };

  const changeLayoutTheme = (theme: LayoutThemeTypeEnum) => {
    dispatch(setLayoutThemeType(theme));
  };

  const changeHeaderTheme = (theme: GeneralThemeTypeEnum) => {
    dispatch(setHeaderThemeType(theme));
  };

  const changeSidebarTheme = (theme: GeneralThemeTypeEnum) => {
    dispatch(setSidebarThemeType(theme));
  };

  const completeOnboarding = () => {
    dispatch(setCompletedOnboarding(true));
    // triggerNextPhase();
    navigate('/');
  };

  const goBack = () => triggerPreviousPhase();

  return (
    <>
      <BlockHeadContent>
        <h4>{t('ONBOARDING.CONFIGURATOR.3.HEADER')}</h4>
        <span className="sub-text sub-text-sm text-soft">
          {t('ONBOARDING.CONFIGURATOR.3.TIME_ESTIMATE')}
        </span>
      </BlockHeadContent>
      <p>{t('ONBOARDING.CONFIGURATOR.3.BODY')}</p>
      <SimpleBar>
        <Form>
          <div className="nk-opt-set">
            <div className="nk-opt-set-title">
              {t('ONBOARDING.CONFIGURATOR.3.FORM.MAIN_UI_STYLE')}
            </div>
            <div className="nk-opt-list col-2x">
              <div
                className={clsx('nk-opt-item only-text', {
                  active: layoutThemeType === LayoutThemeTypeEnum.DEFAULT,
                })}
                onClick={() => changeLayoutTheme(LayoutThemeTypeEnum.DEFAULT)}
              >
                <span className="nk-opt-item-bg">
                  <span className="nk-opt-item-name">
                    {t('COMMON.DEFAULT')}
                  </span>
                </span>
              </div>
              <div
                className={clsx('nk-opt-item only-text', {
                  active: layoutThemeType === LayoutThemeTypeEnum.CLEAN,
                })}
                onClick={() => changeLayoutTheme(LayoutThemeTypeEnum.CLEAN)}
              >
                <span className="nk-opt-item-bg">
                  <span className="nk-opt-item-name">{t('COMMON.CLEAN')}</span>
                </span>
              </div>
              <div
                className={clsx('nk-opt-item only-text', {
                  active: layoutThemeType === LayoutThemeTypeEnum.SHADY,
                })}
                onClick={() => changeLayoutTheme(LayoutThemeTypeEnum.SHADY)}
              >
                <span className="nk-opt-item-bg">
                  <span className="nk-opt-item-name">{t('COMMON.SHADY')}</span>
                </span>
              </div>
              <div
                className={clsx('nk-opt-item only-text', {
                  active: layoutThemeType === LayoutThemeTypeEnum.SOFTY,
                })}
                onClick={() => changeLayoutTheme(LayoutThemeTypeEnum.SOFTY)}
              >
                <span className="nk-opt-item-bg">
                  <span className="nk-opt-item-name">{t('COMMON.SOFTY')}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="nk-opt-set">
            <div className="nk-opt-set-title">
              {t('ONBOARDING.CONFIGURATOR.3.FORM.HEADER_STYLE')}
            </div>
            <div className="nk-opt-list col-4x">
              <div
                className={clsx('nk-opt-item', {
                  active: headerThemeType === GeneralThemeTypeEnum.WHITE,
                })}
                onClick={() => changeHeaderTheme(GeneralThemeTypeEnum.WHITE)}
              >
                <span className="nk-opt-item-bg is-light">
                  <span className="bg-lighter" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.WHITE')}</span>
              </div>
              <div
                className={clsx('nk-opt-item', {
                  active: headerThemeType === GeneralThemeTypeEnum.LIGHT,
                })}
                onClick={() => changeHeaderTheme(GeneralThemeTypeEnum.LIGHT)}
              >
                <span className="nk-opt-item-bg is-light">
                  <span className="bg-light" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.LIGHT')}</span>
              </div>
              <div
                className={clsx('nk-opt-item', {
                  active: headerThemeType === GeneralThemeTypeEnum.DARK,
                })}
                onClick={() => changeHeaderTheme(GeneralThemeTypeEnum.DARK)}
              >
                <span className="nk-opt-item-bg">
                  <span className="bg-dark" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.DARK')}</span>
              </div>
              <div
                className={clsx('nk-opt-item', {
                  active: headerThemeType === GeneralThemeTypeEnum.THEME,
                })}
                onClick={() => changeHeaderTheme(GeneralThemeTypeEnum.THEME)}
              >
                <span className="nk-opt-item-bg ">
                  <span className="bg-theme" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.THEME')}</span>
              </div>
            </div>
          </div>
          <div className="nk-opt-set">
            <div className="nk-opt-set-title">
              {t('ONBOARDING.CONFIGURATOR.3.FORM.NAVBAR_STYLE')}
            </div>
            <div className="nk-opt-list col-4x">
              <div
                className={clsx('nk-opt-item', {
                  active: sidebarThemeType === GeneralThemeTypeEnum.WHITE,
                })}
                onClick={() => changeSidebarTheme(GeneralThemeTypeEnum.WHITE)}
              >
                <span className="nk-opt-item-bg is-light">
                  <span className="bg-lighter" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.WHITE')}</span>
              </div>
              <div
                className={clsx('nk-opt-item', {
                  active: sidebarThemeType === GeneralThemeTypeEnum.LIGHT,
                })}
                onClick={() => changeSidebarTheme(GeneralThemeTypeEnum.LIGHT)}
              >
                <span className="nk-opt-item-bg is-light">
                  <span className="bg-light" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.LIGHT')}</span>
              </div>
              <div
                className={clsx('nk-opt-item', {
                  active: sidebarThemeType === GeneralThemeTypeEnum.DARK,
                })}
                onClick={() => changeSidebarTheme(GeneralThemeTypeEnum.DARK)}
              >
                <span className="nk-opt-item-bg ">
                  <span className="bg-dark" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.DARK')}</span>
              </div>
              <div
                className={clsx('nk-opt-item', {
                  active: sidebarThemeType === GeneralThemeTypeEnum.THEME,
                })}
                onClick={() => changeSidebarTheme(GeneralThemeTypeEnum.THEME)}
              >
                <span className="nk-opt-item-bg ">
                  <span className="bg-theme" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.THEME')}</span>
              </div>
            </div>
          </div>
          <div className="nk-opt-set">
            <div className="nk-opt-set-title">
              {t('ONBOARDING.CONFIGURATOR.3.FORM.THEME_MODE')}
            </div>
            <div className="nk-opt-list col-2x">
              <div
                className={clsx('nk-opt-item', {
                  active: themeMode === ThemeModeEnum.LIGHT,
                })}
                onClick={() => changeThemeMode(ThemeModeEnum.LIGHT)}
              >
                <span className="nk-opt-item-bg is-light">
                  <span className="theme-light" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.LIGHT')}</span>
              </div>
              <div
                className={clsx('nk-opt-item', {
                  active: themeMode === ThemeModeEnum.DARK,
                })}
                onClick={() => changeThemeMode(ThemeModeEnum.DARK)}
              >
                <span className="nk-opt-item-bg ">
                  <span className="theme-dark" />
                </span>
                <span className="nk-opt-item-name">{t('COMMON.DARK')}</span>
              </div>
            </div>
          </div>
          <div className="nk-opt-reset">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                <Button color="primary" onClick={completeOnboarding}>
                  {t('ONBOARDING.CONFIGURATOR.3.BUTTON')}
                </Button>
              </li>
              <li>
                <Button color="link link-light" onClick={goBack}>
                  {t('COMMON.BACK')}
                </Button>
              </li>
            </ul>
          </div>
        </Form>
      </SimpleBar>
    </>
  );
};
