import {
  setDisplayEditGroupModal,
  setDisplayMobileMenu,
  setLastOpenedGroup,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../../block.component';
import { Button } from 'reactstrap';
import { Icon } from '../../icon.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const GroupsDetailsHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { displayMobileMenu } = useAppSelector((state) => state.layout);
  const { displayEditGroupModal, groups } = useAppSelector(
    (state) => state.groups,
  );
  const { groupId } = useParams();
  const group = groups.find((group) => group.id === groupId);

  const changeDisplayMobileMenu = (payload: boolean) => {
    dispatch(setDisplayMobileMenu(payload));
  };

  const toggleOnboardmemberModal = () => {
    dispatch(setLastOpenedGroup(groupId as string));
    dispatch(setDisplayEditGroupModal(!displayEditGroupModal));
  };

  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
          <BlockTitle tag="h3" page>
            {t('GROUPS.HEADER.TITLE')}/{' '}
            <strong className="text-primary small">{group?.name}</strong>
          </BlockTitle>
          <BlockDes className="text-soft">
            <p>
              {group?.memberCount} {t('MEMBERS.HEADER.COUNT')}
            </p>
          </BlockDes>
        </BlockHeadContent>
        <BlockHeadContent>
          <div className="toggle-wrap nk-block-tools-toggle">
            <Button
              className={clsx('btn-icon btn-trigger toggle-expand me-n1', {
                active: displayMobileMenu,
              })}
              onClick={() => changeDisplayMobileMenu(!displayMobileMenu)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
            <div
              className="toggle-expand-content"
              style={{ display: displayMobileMenu ? 'block' : 'none' }}
            >
              <ul className="nk-block-tools g-3">
                <li className="nk-block-tools-opt">
                  <Button
                    color="light"
                    outline
                    className="d-none d-sm-inline-flex bg-white"
                    onClick={() => navigate(-1)}
                  >
                    <Icon name="arrow-left"></Icon>
                    <span>{t('MEMBERS.MEMBER_HEADER.BACK_BUTTON')}</span>
                  </Button>
                  <a
                    href="#back"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                    className="btn btn-icon btn-outline-light d-inline-flex d-sm-none bg-white"
                  >
                    <Icon name="arrow-left"></Icon>
                  </a>
                </li>
                <li className="nk-block-tools-opt">
                  <Button
                    color="primary"
                    className="btn-icon"
                    onClick={toggleOnboardmemberModal}
                    title="Add Member"
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </BlockHeadContent>
      </BlockBetween>
    </BlockHead>
  );
};
