import { useTranslation } from '@oproma/i18n';
import {
  setDashboardQuery,
  useAppSelector,
  setDisplayDashboardFinder,
  setDashboardPaginatedPage,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Button } from '../button.component';
import { Icon } from '../icon.component';

const DashboardQuery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { displayDashboardFinder, dashboardQuery } = useAppSelector(
    (state) => state.dashboard,
  );

  const toggleDashboardFinder = () => {
    dispatch(setDisplayDashboardFinder(!displayDashboardFinder));
  };

  return (
    <div
      className={clsx('card-search search-wrap', {
        active: displayDashboardFinder,
      })}
    >
      <div className="card-body">
        <div className="search-content">
          <Button
            className="search-back btn-icon toggle-search active"
            onClick={() => {
              dispatch(setDashboardQuery(''));
              toggleDashboardFinder();
            }}
          >
            <Icon name="arrow-left"></Icon>
          </Button>
          <input
            type="text"
            className="form-focus-none form-control border-transparent"
            placeholder={t('DASHBOARD.TABLE_HEADER.SEARCH_PLACEHOLDER')}
            value={dashboardQuery}
            onChange={(ev) => {
              dispatch(setDashboardQuery(ev.target.value));
              dispatch(setDashboardPaginatedPage(1));
            }}
          />
          <Button className="search-submit btn-icon">
            <Icon name="search"></Icon>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DashboardQuery;
