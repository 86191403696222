/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuerySpec } from './QuerySpec';
import {
  QuerySpecFromJSON,
  QuerySpecFromJSONTyped,
  QuerySpecToJSON,
} from './QuerySpec';

/**
 *
 * @export
 * @interface BulkOperationSpec
 */
export interface BulkOperationSpec {
  /**
   *
   * @type {QuerySpec}
   * @memberof BulkOperationSpec
   */
  query: QuerySpec;
  /**
   * The operation to perform in bulk. One of 'move' or 'copy'.
   * @type {string}
   * @memberof BulkOperationSpec
   */
  operation: BulkOperationSpecOperationEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum BulkOperationSpecOperationEnum {
  move = 'move',
  copy = 'copy',
}

/**
 * Check if a given object implements the BulkOperationSpec interface.
 */
export function instanceOfBulkOperationSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'query' in value;
  isInstance = isInstance && 'operation' in value;

  return isInstance;
}

export function BulkOperationSpecFromJSON(json: any): BulkOperationSpec {
  return BulkOperationSpecFromJSONTyped(json, false);
}

export function BulkOperationSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BulkOperationSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    query: QuerySpecFromJSON(json['query']),
    operation: json['operation'],
  };
}

export function BulkOperationSpecToJSON(value?: BulkOperationSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    query: QuerySpecToJSON(value.query),
    operation: value.operation,
  };
}
