import { Task } from '@oproma/prividox-store';
import clsx from 'clsx';
import moment from 'moment';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDisplayTaskModals } from '../hooks';
import { Icon } from '../icon.component';
import { UserAvatar } from '../user-avatar.component';
import { findUpper } from '../utils';

interface TaskCardProps {
  task: Task;
  index: number; // usually the index is the task.id but draggables need a number index. So either uuid is converted to a number of the enumerated number is used.
  columnId: string;
}

export const TaskCard = ({ task, index, columnId }: TaskCardProps) => {
  const [open, setOpen] = useState(false);
  const { toggleEditTaskModal, toggleDeleteTaskModal } = useDisplayTaskModals();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const dueDate = () => {
    const due = moment(task.metadata.due);
    const now = moment();
    return due.diff(now, 'days');
  };

  return (
    <Draggable draggableId={task.id} key={task.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="mt-2"
        >
          <div className="kanban-item">
            <div className="kanban-item-title">
              <h6 className="title">{task.name}</h6>
              <Dropdown isOpen={open} toggle={toggleOpen}>
                <DropdownToggle
                  tag="a"
                  href="#toggle"
                  className="dropdown-toggle"
                  onClick={(ev) => ev.preventDefault()}
                >
                  <div className="user-avatar-group">
                    {task.metadata.users.map((user, index) => (
                      <UserAvatar
                        key={index}
                        className="xs"
                        theme={user.theme}
                        text={user.label?.[0]}
                      />
                    ))}
                  </div>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr g-2 p-3">
                    {task.metadata.users.map((user, index) => (
                      <li key={index}>
                        <div className="user-card" onClick={toggleOpen}>
                          <UserAvatar
                            className="sm"
                            theme={user.theme}
                            text={findUpper(user.label)}
                          />
                          <div className="user-name">
                            <span className="tb-lead">{user.label}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="kanban-item-text">
              <p>{task.description}</p>
            </div>
            <ul className="kanban-item-tags">
              {task.metadata.tags.map((tag, index) => (
                <li key={index}>
                  <Badge color={tag.theme}>{tag.label}</Badge>
                </li>
              ))}
            </ul>
            <div className="kanban-item-meta">
              <ul className="kanban-item-meta-list">
                {task.metadata.date && (
                  <li
                    className={clsx({
                      'text-danger': dueDate() < 5,
                    })}
                  >
                    <Icon name="calendar"></Icon>
                    <span>
                      {dueDate() < 0
                        ? `Overdue: ${Math.abs(dueDate())} days`
                        : dueDate() === 0
                        ? `Due today`
                        : `Due in: ${dueDate()} days`}
                    </span>
                  </li>
                )}

                {task.metadata.files && task.metadata.files?.length >= 1 && (
                  <li>
                    <Icon name="folder"></Icon>
                    <span>{task.metadata.files?.length} files</span>
                  </li>
                )}
              </ul>
              <ul className="kanban-item-meta-list">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    href="toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
                  >
                    <Icon name="more-v"></Icon>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleEditTaskModal(task.id, columnId);
                          }}
                        >
                          <Icon name="edit"></Icon>
                          <span>Edit Task</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleDeleteTaskModal(task.id, columnId);
                          }}
                        >
                          <Icon name="trash"></Icon>
                          <span>Delete Task</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ul>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
