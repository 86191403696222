/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectPermissionResult } from './DirectPermissionResult';
import {
  DirectPermissionResultFromJSON,
  DirectPermissionResultFromJSONTyped,
  DirectPermissionResultToJSON,
} from './DirectPermissionResult';

/**
 *
 * @export
 * @interface ResultDirectPermissionResult
 */
export interface ResultDirectPermissionResult {
  /**
   *
   * @type {Array<DirectPermissionResult>}
   * @memberof ResultDirectPermissionResult
   */
  items?: Array<DirectPermissionResult>;
}

/**
 * Check if a given object implements the ResultDirectPermissionResult interface.
 */
export function instanceOfResultDirectPermissionResult(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ResultDirectPermissionResultFromJSON(
  json: any,
): ResultDirectPermissionResult {
  return ResultDirectPermissionResultFromJSONTyped(json, false);
}

export function ResultDirectPermissionResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultDirectPermissionResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(DirectPermissionResultFromJSON),
  };
}

export function ResultDirectPermissionResultToJSON(
  value?: ResultDirectPermissionResult | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(DirectPermissionResultToJSON),
  };
}
