import {
  Block,
  Button,
  DisableMFAModal,
  Layout,
  MFAModal,
  PreferencesHeader,
  PreferencesLayout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  toggleDisplayDisableMFAModel,
  toggleDisplayMFAModel,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Badge, Card } from 'reactstrap';

const PrivacyPreferencesPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enhancedSecurity } = useAppSelector((state) => state.user);

  const toggleMFAModal = () => {
    dispatch(toggleDisplayMFAModel());
  };

  const toggleDisableMFAModal = () => {
    dispatch(toggleDisplayDisableMFAModel());
  };

  return (
    <Layout>
      <PreferencesLayout type="privacy">
        <PreferencesHeader type="privacy" />
        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-body">
                <div className="between-center flex-md-nowrap g-3 flex-wrap">
                  <div className="nk-block-text">
                    <h6>
                      {t(
                        'PREFERENCES.PRIVACY.MULTIPLE_FACTOR_AUTHENTICATION.TITLE',
                      )}{' '}
                      &nbsp;{' '}
                      <Badge color="success" className="ms-0">
                        {enhancedSecurity
                          ? t(
                              'PREFERENCES.PRIVACY.MULTIPLE_FACTOR_AUTHENTICATION.BADGE.ENABLED',
                            )
                          : t(
                              'PREFERENCES.PRIVACY.MULTIPLE_FACTOR_AUTHENTICATION.BADGE.DISABLED',
                            )}
                      </Badge>
                    </h6>
                    <p>
                      {t(
                        'PREFERENCES.PRIVACY.MULTIPLE_FACTOR_AUTHENTICATION.DESCRIPTION',
                      )}{' '}
                    </p>
                  </div>
                  <div className="nk-block-actions">
                    <Button
                      color="primary"
                      onClick={() =>
                        enhancedSecurity
                          ? toggleDisableMFAModal()
                          : toggleMFAModal()
                      }
                    >
                      {enhancedSecurity
                        ? t(
                            'PREFERENCES.PRIVACY.MULTIPLE_FACTOR_AUTHENTICATION.DISABLE',
                          )
                        : t(
                            'PREFERENCES.PRIVACY.MULTIPLE_FACTOR_AUTHENTICATION.ENABLE',
                          )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </PreferencesLayout>
      <MFAModal />
      <DisableMFAModal />
    </Layout>
  );
};

export default PrivacyPreferencesPage;
