import { useTranslation } from '@oproma/i18n';
import { useAppSelector } from '@oproma/prividox-store';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../block.component';
import { FileManagerDisplayTypeFilter } from './file-manager-display-type-filter.component';
import { FileManagerFiles } from './file-manager-files.component';
import { FileManagerQuickNavigation } from './file-manager-quick-navigation.component';
import { FileManagerFileActions } from './file-manager-file-actions.component';

export const FileManagerPreview = () => {
  const { entities } = useAppSelector((state) => state.fileManager);
  const { starred } = useAppSelector((state) => state.starred);
  const { t } = useTranslation();
  const quickView = [
    ...entities.filter(
      (entity) => starred.includes(entity.id) && !entity.deleted,
    ),
  ];
  const filteredEntities = [...entities.filter((entity) => !entity.deleted)];

  return (
    <>
      {quickView.length > 0 && (
        <FileManagerQuickNavigation entities={quickView} />
      )}
      <Block className="nk-fmg-listing">
        <BlockHead size="xs">
          <BlockBetween className="g-2">
            <BlockHeadContent>
              <BlockTitle tag="h6">
                {t('FILE_MANAGER.PREVIEW.HEADING')}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <FileManagerDisplayTypeFilter />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <FileManagerFiles entities={filteredEntities} />
      </Block>
    </>
  );
};
