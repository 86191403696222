/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProtectionSpec
 */
export interface ProtectionSpec {
  /**
   * The protection level to set.
   * @type {string}
   * @memberof ProtectionSpec
   */
  level: ProtectionSpecLevelEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ProtectionSpecLevelEnum {
  NONE = 'NONE',
  PROTECTED_A = 'PROTECTED_A',
  PROTECTED_B = 'PROTECTED_B',
}

/**
 * Check if a given object implements the ProtectionSpec interface.
 */
export function instanceOfProtectionSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'level' in value;

  return isInstance;
}

export function ProtectionSpecFromJSON(json: any): ProtectionSpec {
  return ProtectionSpecFromJSONTyped(json, false);
}

export function ProtectionSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProtectionSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    level: json['level'],
  };
}

export function ProtectionSpecToJSON(value?: ProtectionSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    level: value.level,
  };
}
