import { ProjectInformation } from '@oproma/prividox-orchestration-open-api';

export type Theme =
  | 'bg-purple-dim'
  | 'bg-orange-dim'
  | 'bg-primary-dim'
  | 'bg-success-dim'
  | 'bg-info-dim';
interface SubMenu {
  text: string;
  link?: string;
  icon?: string;
  theme?: Theme;
  newTab?: boolean;
  active?: boolean;
  subMenu?: SubMenu[];
}
export interface MenuItem {
  heading?: string;
  icon?: string;
  text?: string;
  link?: string;
  dashboard?: boolean;
  badge?: undefined;
  active?: boolean;
  subMenu?: SubMenu[];
  newTab?: boolean;
  theme?: string;
}

export interface PatchWorkspaceNamePayload {
  workspaceId: string;
  workspaceName: string;
}

export interface CreateWorkspaceForm {
  name?: string;
  plan?: string;
  rootFolderPermissions?: string;
  token?: string;
}

export interface Workspace extends ProjectInformation {
  usage?: number;
  folderCount?: number;
  fileCount?: number;
}

export enum WorkspacesErrorCodesEnum {
  GET_WORKSPACES_FAILED = 'GET_WORKSPACES_FAILED',
  CREATE_WORKSPACE_FAILED = 'CREATE_WORKSPACE_FAILED',
}
export interface IWorkspacesState {
  readonly workspaces: Workspace[];
  readonly fetchingDiskMetrics: boolean;
  readonly displayWorkspaceModal: boolean;
  readonly createdWorkspace: string | null;
  readonly workspaceName: string | null;
  readonly createWorkspaceForm: CreateWorkspaceForm | null;
  readonly displayOnboardWorkspaceModal: boolean;
  readonly lastOpenedWorkspace: string | null;
  readonly loading: boolean;
  readonly error: {
    code: WorkspacesErrorCodesEnum;
    message: string;
  } | null;
}
