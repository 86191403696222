import { useTranslation } from '@oproma/i18n';
import {
  SortOrder,
  setMemberEntriesPerPaginatedPage,
  setMemberSortOrder,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Icon } from '../icon.component';

export const MembersTablePaginator = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { memberSortOrder, entriesPerPaginatedPage } = useAppSelector(
    (state) => state.members,
  );

  const handleEntriesPerPage = (value: number) => {
    dispatch(setMemberEntriesPerPaginatedPage(value));
  };

  const handleSort = (value: SortOrder) => {
    dispatch(setMemberSortOrder(value));
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        color="tranparent"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <Icon name="setting"></Icon>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xs">
        <ul className="link-check">
          <li>
            <span>{t('MEMBERS.PAGINATOR.DISPLAY')}</span>
          </li>
          <li className={clsx({ active: entriesPerPaginatedPage === 10 })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleEntriesPerPage(10);
              }}
            >
              10
            </DropdownItem>
          </li>
          <li className={clsx({ active: entriesPerPaginatedPage === 15 })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleEntriesPerPage(15);
              }}
            >
              15
            </DropdownItem>
          </li>
        </ul>
        <ul className="link-check">
          <li>
            <span>{t('MEMBERS.PAGINATOR.ORDER')}</span>
          </li>
          <li className={clsx({ active: memberSortOrder === 'asc' })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleSort('asc');
                // sortFunc('asc');
              }}
            >
              ASC
            </DropdownItem>
          </li>
          <li className={clsx({ active: memberSortOrder === 'desc' })}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                handleSort('desc');
                // sortFunc('dsc');
              }}
            >
              DESC
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
