/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TaskSpec
 */
export interface TaskSpec {
  /**
   * The title of the task.
   * @type {string}
   * @memberof TaskSpec
   */
  name: string;
  /**
   * The ID of the user or group or users to whom the task is assigned.
   * @type {string}
   * @memberof TaskSpec
   */
  user: string;
  /**
   * The due date of the task.
   * @type {number}
   * @memberof TaskSpec
   */
  expiry?: number;
  /**
   * A description of the task.
   * @type {string}
   * @memberof TaskSpec
   */
  description?: string;
  /**
   * The ID of the files attached to the task.
   * @type {Array<string>}
   * @memberof TaskSpec
   */
  attachments: Array<string>;
}

/**
 * Check if a given object implements the TaskSpec interface.
 */
export function instanceOfTaskSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'user' in value;
  isInstance = isInstance && 'attachments' in value;

  return isInstance;
}

export function TaskSpecFromJSON(json: any): TaskSpec {
  return TaskSpecFromJSONTyped(json, false);
}

export function TaskSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TaskSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    user: json['user'],
    expiry: !exists(json, 'expiry') ? undefined : json['expiry'],
    description: !exists(json, 'description') ? undefined : json['description'],
    attachments: json['attachments'],
  };
}

export function TaskSpecToJSON(value?: TaskSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    user: value.user,
    expiry: value.expiry,
    description: value.description,
    attachments: value.attachments,
  };
}
