import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  editEmailNotificationProvider,
  editSystemLanguage,
  useAppDispatch,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form, FormGroup, Input } from 'reactstrap';
import * as yup from 'yup';
import { Block, BlockHeadContent } from '../../block.component';
import { Button } from '../../button.component';
import { Col, Row } from '../../grid.component';

type AuthOnboardingNotificationsPhaseProps = {
  triggerNextPhase: () => void;
  triggerPreviousPhase: () => void;
};

const onboardingNotificationsSchema = yup
  .object({
    emailNotifications: yup.boolean().required(),
    emailLanguage: yup
      .string()
      .oneOf(['en', 'fr', 'en_fr', 'fr_en'])
      .required(),
  })
  .required();

type OnboardingNotificationsFormValues = yup.InferType<
  typeof onboardingNotificationsSchema
>;

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'en-fr', label: 'English · Français' },
  { value: 'fr-en', label: 'Français · English' },
];

export const AuthOnboardingNotificationsPhase = ({
  triggerNextPhase,
  triggerPreviousPhase,
}: AuthOnboardingNotificationsPhaseProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardingNotificationsFormValues>({
    resolver: yupResolver(onboardingNotificationsSchema),
    defaultValues: {
      emailNotifications: false,
      emailLanguage: 'en',
    },
  });

  const { ref: emailNotificationsRef, ...registerEmailNotifications } =
    registerField('emailNotifications');
  const { ref: emailLanguageRef, ...registerEmailLanguage } =
    registerField('emailLanguage');

  const onFormSubmit: SubmitHandler<OnboardingNotificationsFormValues> = ({
    emailNotifications,
    emailLanguage,
  }) => {
    dispatch(editSystemLanguage(emailLanguage));
    dispatch(editEmailNotificationProvider(emailNotifications));
    triggerNextPhase();
  };

  const goBack = () => triggerPreviousPhase();

  return (
    <>
      <BlockHeadContent>
        <h4>{t('ONBOARDING.CONFIGURATOR.2.HEADER')}</h4>
        <span className="sub-text sub-text-sm text-soft">
          {t('ONBOARDING.CONFIGURATOR.2.TIME_ESTIMATE')}
        </span>
      </BlockHeadContent>
      <p>{t('ONBOARDING.CONFIGURATOR.2.BODY')}</p>
      <Block>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <FormGroup>
            <Row className="g-3 align-center">
              <Col lg="5">
                <div className="form-group">
                  <label className="form-label">
                    {t('ONBOARDING.CONFIGURATOR.2.FORM.EMAIL')}
                  </label>
                  <span className="form-note">
                    {t('ONBOARDING.CONFIGURATOR.2.FORM.EMAIL_BODY')}
                  </span>
                </div>
              </Col>
              <Col lg="7">
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      id="emailNotifications"
                      innerRef={emailNotificationsRef}
                      {...registerEmailNotifications}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="emailNotifications"
                    >
                      {t('COMMON.ENABLE')}
                    </label>
                  </div>
                  {errors.emailNotifications && (
                    <span className="invalid">
                      {errors.emailNotifications.message}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="g-3 align-center">
              <Col lg="5">
                <div className="form-group">
                  <label className="form-label">
                    {t('ONBOARDING.CONFIGURATOR.2.FORM.LANGUAGE')}
                  </label>
                  <span className="form-note">
                    {t('ONBOARDING.CONFIGURATOR.2.FORM.LANGUAGE_BODY')}
                  </span>
                </div>
              </Col>
              <Col lg="7">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <Input
                        type="select"
                        id="notifications-select"
                        innerRef={emailLanguageRef}
                        {...registerEmailLanguage}
                      >
                        {languageOptions.map((option, i) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Input>
                    </div>
                    {errors.emailLanguage && (
                      <span className="invalid">
                        {errors.emailLanguage.message}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </FormGroup>
          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
            <li>
              <Button color="primary" type="submit">
                {t('COMMON.CONFIRM')}
              </Button>
            </li>
            <li>
              <Button color="link link-light" onClick={goBack}>
                {t('COMMON.BACK')}
              </Button>
            </li>
          </ul>
        </Form>
      </Block>
    </>
  );
};
