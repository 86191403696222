import { yupResolver } from '@hookform/resolvers/yup';
import {
  createWorkspace,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form, FormGroup } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useWizard } from '../hooks';
import { Loader } from '../loader.component';
import { PricingPlans } from './pricing-plans.component';

const onboardWorkspacePricingPlansFormValues = yup
  .object({
    pricingPlan: yup.string().required('Please choose a plan'),
  })
  .required();

type OnboardWorkspacePricingPlansFormValues = yup.InferType<
  typeof onboardWorkspacePricingPlansFormValues
>;

export const OnboardWorkspacePricingPlansForm = () => {
  const { next, prev } = useWizard();
  const dispatch = useAppDispatch();
  const form = useAppSelector((state) => state.workspaces.createWorkspaceForm);
  const { pricingPlans, loading } = useAppSelector((state) => state.finance);

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<OnboardWorkspacePricingPlansFormValues>({
    resolver: yupResolver(onboardWorkspacePricingPlansFormValues),
  });

  const plan = watch('pricingPlan');

  const onFormSubmit: SubmitHandler<
    OnboardWorkspacePricingPlansFormValues
  > = () => {
    if (!form?.name) return;
    dispatch(
      createWorkspace({
        name: form.name,
        plan,
        token: form?.token,
      }),
    );
    next();
  };

  const handlePlanSelect = (plan?: string) => {
    if (!plan) return;
    setValue('pricingPlan', plan, { shouldValidate: true });
  };

  const navigateBack = () => prev();

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <FormGroup>
        <PricingPlans
          plans={pricingPlans}
          selectedPlan={plan}
          handlePlanSelection={handlePlanSelect}
          errorMessage={errors.pricingPlan?.message}
        />
      </FormGroup>
      <Row className="g-3">
        <Col lg="5">
          <div className="form-group mt-2">
            <Button
              color="primary"
              outline
              className="btn-block"
              size="md"
              onClick={navigateBack}
            >
              Back
            </Button>
          </div>
        </Col>
        <Col lg="7">
          <div className="form-group mt-2">
            <Button
              color="primary"
              className="btn-block"
              size="md"
              type="submit"
              disabled={!!errors.pricingPlan}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
