import { useEffect } from 'react';

import { logout, useAppDispatch } from '@oproma/prividox-store';
import { Spinner } from 'reactstrap';

const LogoutPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center mt-5 flex-row">
      <Spinner size="lg" type="grow" color="primary"></Spinner>
    </div>
  );
};

export default LogoutPage;
