/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LRUInfo
 */
export interface LRUInfo {
  /**
   *
   * @type {string}
   * @memberof LRUInfo
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof LRUInfo
   */
  modified?: number;
  /**
   *
   * @type {number}
   * @memberof LRUInfo
   */
  lastAccessed?: number;
  /**
   *
   * @type {string}
   * @memberof LRUInfo
   */
  path?: string;
}

/**
 * Check if a given object implements the LRUInfo interface.
 */
export function instanceOfLRUInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function LRUInfoFromJSON(json: any): LRUInfo {
  return LRUInfoFromJSONTyped(json, false);
}

export function LRUInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LRUInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    modified: !exists(json, 'modified') ? undefined : json['modified'],
    lastAccessed: !exists(json, 'lastAccessed')
      ? undefined
      : json['lastAccessed'],
    path: !exists(json, 'path') ? undefined : json['path'],
  };
}

export function LRUInfoToJSON(value?: LRUInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    modified: value.modified,
    lastAccessed: value.lastAccessed,
    path: value.path,
  };
}
