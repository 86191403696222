/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LoginSpec
 */
export interface LoginSpec {
  /**
   * The email belonging to the authenticating user.
   * @type {string}
   * @memberof LoginSpec
   */
  email: string;
  /**
   * The password belonging to the authenticating user. Minimum length to be increased to 12.
   * @type {string}
   * @memberof LoginSpec
   */
  pass: string;
  /**
   * A fingerprint to associate with the environment to identify logins on new devices.
   * @type {string}
   * @memberof LoginSpec
   */
  fingerprint: string;
  /**
   * The TOTP token to use to authenticate the user.
   * @type {string}
   * @memberof LoginSpec
   */
  token?: string;
  /**
   * The session inactivity timeout value.
   * @type {number}
   * @memberof LoginSpec
   */
  exp?: number;
}

/**
 * Check if a given object implements the LoginSpec interface.
 */
export function instanceOfLoginSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'pass' in value;
  isInstance = isInstance && 'fingerprint' in value;

  return isInstance;
}

export function LoginSpecFromJSON(json: any): LoginSpec {
  return LoginSpecFromJSONTyped(json, false);
}

export function LoginSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoginSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    pass: json['pass'],
    fingerprint: json['fingerprint'],
    token: !exists(json, 'token') ? undefined : json['token'],
    exp: !exists(json, 'exp') ? undefined : json['exp'],
  };
}

export function LoginSpecToJSON(value?: LoginSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    pass: value.pass,
    fingerprint: value.fingerprint,
    token: value.token,
    exp: value.exp,
  };
}
