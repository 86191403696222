import clsx from 'clsx';
import { useState } from 'react';
import { Icon } from './icon.component';

interface RatingProps {
  type: string;
  start: number;
  className?: string;
}

export const Rating = ({ type, start, className }: RatingProps) => {
  const [rating, setRating] = useState<number>(start);

  const renderRatingItem = (value: number) => (
    <li onClick={() => setRating(value)} style={{ cursor: 'pointer' }}>
      <Icon name={rating >= value ? `${type}-fill` : type}></Icon>
    </li>
  );

  return (
    <ul className={clsx('rating', className)}>
      {Array.from({ length: 5 }, (_, i) => renderRatingItem(i + 1))}
    </ul>
  );
};
