import { useStarredEntities } from '../hooks';
import { FileManagerFiles } from './file-manager-files.component';

export const FileManagerStarredEntities = () => {
  const starredEntities = useStarredEntities();

  return (
    <FileManagerFiles entities={starredEntities} entityDisplayType="list" />
  );
};
