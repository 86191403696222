import {
  toggleDisplayPreviewFileModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link, useNavigate, useParams } from '@oproma/router';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Icon } from '../icon.component';

interface BreadcrumbProps {
  type: string;
}

export const FileManagerBreadcrumbs = ({ type }: BreadcrumbProps) => {
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { entityParents } = useAppSelector((state) => state.fileManager);
  const reversedEntityParents = [...entityParents].reverse();
  const togglePreviewModal = () => dispatch(toggleDisplayPreviewFileModal());

  return (
    <Breadcrumb className="breadcrumb-arrow">
      {reversedEntityParents.map((entity, index) => (
        <BreadcrumbItem key={index} active={index === entityParents.length - 1}>
          {type === 'preview' ? (
            <button
              className="btn btn-sm btn-link"
              style={{ height: 'auto', padding: 0 }}
              onClick={(ev) => {
                ev.preventDefault();
                navigate({
                  pathname: `/${workspaceId}/file-manager/entities/${entity.id}`,
                });
                togglePreviewModal();
              }}
            >
              {index === 0 ? 'Root' : entity.name}
            </button>
          ) : (
            <Link to={`/${workspaceId}/file-manager/entities/${entity.id}`}>
              {index === 0 ? (
                <>
                  {t('FILE_MANAGER.TITLE')} <Icon name="home" />
                </>
              ) : (
                entity.name
              )}
            </Link>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
