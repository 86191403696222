import { Column, Task } from '@oproma/prividox-store';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDisplayTaskModals } from '../hooks';
import { Icon } from '../icon.component';
import { TasksCardList } from './tasks-card-list.component';

interface TasksColumnProps {
  tasks: Task[];
  columnOrder: string[];
  column: Column;
  index: number;
}

export const TasksColumn = ({
  columnOrder,
  tasks,
  column,
  index,
}: TasksColumnProps) => {
  const { toggleCreateTaskModal, toggleEditColumnModal } =
    useDisplayTaskModals();

  const deleteBoard = (item: Column) => {
    console.log('Delete the column');
    // const defaultData = data;
    // delete defaultData.columns[item.id];
    // defaultData.columnOrder = defaultData.columnOrder.filter(
    //   (el) => el !== item.id,
    // );

    // setData({ ...defaultData });
  };

  const emptyColumnTaskList = (item: Column) => {
    console.log('Empty the column task list');
    // const defaultData = data;
    // defaultData.columns[item.id].tasks = [];

    // setData({ ...defaultData });
  };

  return (
    <Draggable draggableId={column.id} key={column.id} index={index}>
      {(provided) => (
        <div
          className="kanban-board"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            className={`kanban-board-header kanban-${column.theme}`}
            {...provided.dragHandleProps}
          >
            <div className="kanban-title-board">
              <div className="kanban-title-content">
                <h6 className="title">{column.name}</h6>
                <Badge className="text-dark" pill color="outline-light">
                  {column.tasks.length}
                </Badge>
              </div>
              <div className="kanban-title-content">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    href="toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                  >
                    <Icon name="more-h"></Icon>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleEditColumnModal();
                          }}
                        >
                          <Icon name="edit"></Icon>
                          <span>Edit Column</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            deleteBoard(column);
                          }}
                        >
                          <Icon name="trash"></Icon>
                          <span>Delete Column</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            emptyColumnTaskList(column);
                          }}
                        >
                          <Icon name="trash-empty"></Icon>
                          <span>Empty Column</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleCreateTaskModal();
                          }}
                        >
                          <Icon name="plus-sm"></Icon>
                          <span>Create Task</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <Droppable droppableId={column.id} key={column.id} type="task">
            {(provided) => (
              <div
                className="kanban-drag"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <TasksCardList taskIds={column.tasks} columnId={column.id} />
                <button
                  className="kanban-add-task btn btn-block mt-2"
                  onClick={toggleCreateTaskModal}
                >
                  <Icon name="plus-sm"></Icon>
                  <span>Create Task</span>
                </button>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};
