/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProduceInformation
 */
export interface ProduceInformation {
  /**
   *
   * @type {string}
   * @memberof ProduceInformation
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof ProduceInformation
   */
  parent?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof ProduceInformation
   */
  metadata?: { [key: string]: object };
}

/**
 * Check if a given object implements the ProduceInformation interface.
 */
export function instanceOfProduceInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ProduceInformationFromJSON(json: any): ProduceInformation {
  return ProduceInformationFromJSONTyped(json, false);
}

export function ProduceInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProduceInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    path: !exists(json, 'path') ? undefined : json['path'],
    parent: !exists(json, 'parent') ? undefined : json['parent'],
    metadata: !exists(json, 'metadata') ? undefined : json['metadata'],
  };
}

export function ProduceInformationToJSON(
  value?: ProduceInformation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    path: value.path,
    parent: value.parent,
    metadata: value.metadata,
  };
}
