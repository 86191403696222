import {
  DiskUsageSpec,
  NotificationMessage,
  PlanInformation,
  PriceSpec,
} from '@oproma/prividox-orchestration-open-api';

export enum WorkspaceErrorCodesEnum {
  GET_WORKSPACE_FAILED = 'GET_WORKSPACE_FAILED',
  DELETE_WORKSPACE_FAILED = 'DELETE_WORKSPACE_FAILED',
  GET_WORKSPACE_PLAN_FAILED = 'GET_WORKSPACES_PLAN_FAILED',
  EDIT_WORKSPACE_NAME_FAILED = 'EDIT_WORKSPACE_NAME_FAILED',
  EDIT_WORKSPACE_PLAN_FAILED = 'EDIT_WORKSPACE_PLAN_FAILED',
  EDIT_WORKSPACE_BUNDLE_FAILED = 'EDIT_WORKSPACE_BUNDLE_FAILED',
  GET_WORKSPACE_BUNDLES_FAILED = 'GET_WORKSPACE_BUNDLES_FAILED',
  GET_WORKSPACE_METADATA_FAILED = 'GET_WORKSPACE_METADATA_FAILED',
  GET_WORKSPACE_ACTIVITY_FAILED = 'GET_WORKSPACE_ACTIVITY_FAILED',
  GET_WORKSPACE_DISK_USAGE_FAILED = 'GET_WORKSPACE_DISK_USAGE_FAILED',
}

export interface Deletion {
  userId: string;
  entityId: string;
  deletedAt: Date;
}

export interface WorkspaceMetadata {
  access?: string;
  large?: string;
  level?: number;
  allowConversions?: boolean;
  allowGlobals?: boolean;
  amOwner?: boolean;
  'files-limit'?: null;
  hasPlan?: boolean;
  isAdmin?: boolean;
  'bytes-limit'?: number;
  type?: string;
  forceSecurity?: boolean;
  enabled?: boolean;
  subscribed?: boolean;
  'folders-limit'?: null;
  filePublish?: boolean;
  root?: string;
  name?: string;
  allowRedactions?: boolean;
  'members-limit'?: number;
  id?: string;
  zipExpand?: boolean;
  allowProduction?: boolean;
  allowReview?: boolean;
  // Properties gathered from requesting the root folder of the workspace. Because a workspace is basically a master folder
  modified?: number;
  created?: number;
  deletedEntities?: Deletion[];
}

export interface IWorkspaceState {
  readonly name: string | null;
  readonly accessLevel: string | null;
  readonly metadata: WorkspaceMetadata | null;
  readonly plan: PlanInformation | null;
  readonly diskUsage: DiskUsageSpec | null;
  readonly bundles: PriceSpec[];
  readonly displayChangePlanModal: boolean;
  readonly displayEditDescriptionModal: boolean;
  readonly displayBundlesModal: boolean;
  readonly displayMFAModal: boolean;
  readonly displayDisableMFAModel: boolean;
  readonly loading: boolean;
  readonly error: {
    code: WorkspaceErrorCodesEnum;
    message: string;
  } | null;
  readonly deletedEntities: Deletion[];
  readonly activity: NotificationMessage[];
  readonly dashboardTitle: string;
  readonly dashboardDescription: string;
}
