/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentInformation } from './AttachmentInformation';
import {
  AttachmentInformationFromJSON,
  AttachmentInformationFromJSONTyped,
  AttachmentInformationToJSON,
} from './AttachmentInformation';
import type { PrincipalInformation } from './PrincipalInformation';
import {
  PrincipalInformationFromJSON,
  PrincipalInformationFromJSONTyped,
  PrincipalInformationToJSON,
} from './PrincipalInformation';

/**
 *
 * @export
 * @interface TaskInformation
 */
export interface TaskInformation {
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  title?: string;
  /**
   *
   * @type {boolean}
   * @memberof TaskInformation
   */
  completed?: boolean;
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  project?: string;
  /**
   *
   * @type {PrincipalInformation}
   * @memberof TaskInformation
   */
  assigner?: PrincipalInformation;
  /**
   *
   * @type {PrincipalInformation}
   * @memberof TaskInformation
   */
  assignee?: PrincipalInformation;
  /**
   *
   * @type {PrincipalInformation}
   * @memberof TaskInformation
   */
  updater?: PrincipalInformation;
  /**
   *
   * @type {number}
   * @memberof TaskInformation
   */
  created?: number;
  /**
   *
   * @type {number}
   * @memberof TaskInformation
   */
  updated?: number;
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof TaskInformation
   */
  expiry?: number;
  /**
   *
   * @type {Set<AttachmentInformation>}
   * @memberof TaskInformation
   */
  attachments?: Set<AttachmentInformation>;
}

/**
 * Check if a given object implements the TaskInformation interface.
 */
export function instanceOfTaskInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TaskInformationFromJSON(json: any): TaskInformation {
  return TaskInformationFromJSONTyped(json, false);
}

export function TaskInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TaskInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    completed: !exists(json, 'completed') ? undefined : json['completed'],
    project: !exists(json, 'project') ? undefined : json['project'],
    assigner: !exists(json, 'assigner')
      ? undefined
      : PrincipalInformationFromJSON(json['assigner']),
    assignee: !exists(json, 'assignee')
      ? undefined
      : PrincipalInformationFromJSON(json['assignee']),
    updater: !exists(json, 'updater')
      ? undefined
      : PrincipalInformationFromJSON(json['updater']),
    created: !exists(json, 'created') ? undefined : json['created'],
    updated: !exists(json, 'updated') ? undefined : json['updated'],
    description: !exists(json, 'description') ? undefined : json['description'],
    expiry: !exists(json, 'expiry') ? undefined : json['expiry'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : new Set(
          (json['attachments'] as Array<any>).map(
            AttachmentInformationFromJSON,
          ),
        ),
  };
}

export function TaskInformationToJSON(value?: TaskInformation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    completed: value.completed,
    project: value.project,
    assigner: PrincipalInformationToJSON(value.assigner),
    assignee: PrincipalInformationToJSON(value.assignee),
    updater: PrincipalInformationToJSON(value.updater),
    created: value.created,
    updated: value.updated,
    description: value.description,
    expiry: value.expiry,
    attachments:
      value.attachments === undefined
        ? undefined
        : Array.from(value.attachments as Set<any>).map(
            AttachmentInformationToJSON,
          ),
  };
}
