/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthFooter,
  AuthLayout,
  AuthLogo,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Head,
  Icon,
  PreviewCard,
  retrieveApiErrorMessage,
  useResetEmailForm,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import { AuthErrorCodesEnum, HashActionsEnum } from '@oproma/prividox-store';
import { Link } from '@oproma/router';
import { Buffer } from 'buffer';
import { useEffect } from 'react';

import { Alert, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';

const ResetEmailPage = () => {
  const { t } = useTranslation();
  const {
    user,
    error,
    errors,
    loading,
    emailRef,
    setValue,
    getValues,
    onFormSubmit,
    handleSubmit,
    registerEmail,
    resetEmailDispatched,
    receivedEmailResetDetails,
    setReceivedEmailResetDetailsStatus,
  } = useResetEmailForm();

  useEffect(() => {
    const processHash = () => {
      const url = new URL(window.location.href);
      const hash = url.hash.substring(1);
      if (hash) {
        const [action, email, code] = hash.split('&');
        if (action === HashActionsEnum.RESET_EMAIL) {
          const decodedEmail = Buffer.from(email, 'base64');
          const emailResetCode = Buffer.from(code, 'base64');

          setValue('email', decodedEmail.toString());
          setValue('emailResetCode', emailResetCode.toString());
          return setReceivedEmailResetDetailsStatus(true);
        }
      }
      setReceivedEmailResetDetailsStatus(false);
    };

    processHash();
  }, []);

  return (
    <>
      <Head heading={t('RESET_EMAIL.TITLE')} />

      <AuthLayout>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <AuthLogo />
          {resetEmailDispatched ? (
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">
                  {t('RESET_EMAIL.EMAIL_CHANGE_COMPLETED.HEADING', {
                    oldEmail: user.email,
                    currentEmail: getValues('email'),
                  })}
                </BlockTitle>
                <BlockDes className="text-success">
                  <p>{t('RESET_EMAIL.EMAIL_CHANGE_COMPLETED.BODY')}</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
          ) : (
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h5">{t('RESET_EMAIL.TITLE')}</BlockTitle>
                </BlockContent>
              </BlockHead>
              {error?.code ===
                AuthErrorCodesEnum.RESET_EMAIL_CONFIRMATION_FAILED && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" />{' '}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(retrieveApiErrorMessage(error.message)),
                      }}
                    ></p>
                  </Alert>
                </div>
              )}
              {receivedEmailResetDetails ? (
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <FormGroup>
                    <div className="form-label-group">
                      <Label className="form-label" for="email">
                        {t('RESET_EMAIL.FORM.EMAIL')}
                      </Label>
                    </div>
                    <Input
                      id="email"
                      type="email"
                      className="form-control-lg"
                      placeholder={t('AUTH.RESET_EMAIL.FORM.EMAIL')}
                      innerRef={emailRef}
                      {...registerEmail}
                    />
                    {errors.email && (
                      <p className="invalid">
                        {t(errors.email.message as string)}
                      </p>
                    )}
                    {errors.emailResetCode && (
                      <p className="invalid">
                        {t(errors.emailResetCode.message as string)}
                      </p>
                    )}
                  </FormGroup>
                  <div className="form-group">
                    <Button
                      size="lg"
                      className="btn-block"
                      type="submit"
                      color="primary"
                    >
                      {loading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        t('RESET_EMAIL.BUTTON')
                      )}
                    </Button>
                  </div>
                </Form>
              ) : (
                <Alert color="danger" className="alert-pro">
                  <Icon name="alert-circle" />{' '}
                  {t('RESET_EMAIL.EMAIL_CHANGE_ERROR.HEADING')}
                  <p>{t('RESET_EMAIL.EMAIL_CHANGE_ERROR.BODY')}</p>
                </Alert>
              )}
              <div className="form-note-s2 pt-4 text-center">
                <Link to="/auth/login">
                  <strong>{t('COMMON.BACK_TO_LOGIN')}</strong>
                </Link>
              </div>
            </PreviewCard>
          )}
        </Block>
        <AuthFooter />
      </AuthLayout>
    </>
  );
};

export default ResetEmailPage;
