/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AttachmentInformation
 */
export interface AttachmentInformation {
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  path?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttachmentInformation
   */
  encrypted?: boolean;
}

/**
 * Check if a given object implements the AttachmentInformation interface.
 */
export function instanceOfAttachmentInformation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AttachmentInformationFromJSON(
  json: any,
): AttachmentInformation {
  return AttachmentInformationFromJSONTyped(json, false);
}

export function AttachmentInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AttachmentInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    contentType: !exists(json, 'contentType') ? undefined : json['contentType'],
    name: !exists(json, 'name') ? undefined : json['name'],
    path: !exists(json, 'path') ? undefined : json['path'],
    encrypted: !exists(json, 'encrypted') ? undefined : json['encrypted'],
  };
}

export function AttachmentInformationToJSON(
  value?: AttachmentInformation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    contentType: value.contentType,
    name: value.name,
    path: value.path,
    encrypted: value.encrypted,
  };
}
