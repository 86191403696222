import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  WorkspaceMember,
  onboardWorkspaceMember,
  setDisplayOnboardMembersModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form, Input, Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { LanguageType, languages, permissionLevels } from '../constants';
import { toast } from 'react-toastify';
import { useState } from 'react';

const onboardMemberSchema = yup
  .object({
    email: yup.string().email().required(),
    permission: yup.string().required(),
    language: yup.string().oneOf(['en', 'fr', 'en_fr', 'fr_en']).required(),
  })
  .required();

type OnboardMemberFormValues = yup.InferType<typeof onboardMemberSchema>;

const memberExists = (
  members: WorkspaceMember[],
  email: string | undefined,
): boolean => {
  return members.some((member) => member.email === email);
};

export const OnboardMemberModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { displayOnboardMembersModal, members } = useAppSelector(
    (state) => state.members,
  );
  // Translate permission levels
  const translatedPermissionLevels = permissionLevels.map((option) => ({
    value: option.value,
    label: t(option.label),
  }));

  const [userExists, setUserExists] = useState<boolean>(false);

  const {
    register: registerField,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<OnboardMemberFormValues>({
    resolver: yupResolver(onboardMemberSchema),
    defaultValues: {
      permission: ' -127',
      language: 'en',
    },
  });

  const { ref: emailRef, ...registerEmail } = registerField('email');

  // submit function to add a new item
  const onFormSubmit: SubmitHandler<OnboardMemberFormValues> = ({
    email,
    permission,
    language,
  }) => {
    if (memberExists(members, email)) {
      setUserExists(true);
      toast.error(t('MEMBERS.ONBOARDING.ERROR.USER_EXISTS'));
      return;
    }

    if (!workspaceId) return;
    dispatch(
      onboardWorkspaceMember({
        workspaceId,
        inviteMemberSpec: [
          {
            access: permission,
            email,
            lang: language,
          },
        ],
      }),
    );

    toggleModal();
  };

  const toggleModal = () => {
    dispatch(setDisplayOnboardMembersModal(!displayOnboardMembersModal));
    setUserExists(false);
    reset();
  };

  return (
    <Modal
      isOpen={displayOnboardMembersModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('MEMBERS.ONBOARDING.TITLE')}</h5>
          <div className="mt-4">
            <Form
              className="row gy-4"
              noValidate
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    {t('MEMBERS.ONBOARDING.EMAIL')}
                  </label>
                  <Input
                    type="email"
                    placeholder="Enter email"
                    innerRef={emailRef}
                    {...registerEmail}
                  />
                  {errors.email && (
                    <span className="invalid">
                      {errors.email.message as string}
                    </span>
                  )}
                  {userExists && (
                    <p className="text-danger">
                      {t('MEMBERS.ONBOARDING.ERROR.USER_EXISTS')}
                    </p>
                  )}
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    {t('MEMBERS.ONBOARDING.PERMISSIONS.TITLE')}
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      options={translatedPermissionLevels}
                      defaultValue={translatedPermissionLevels[0]}
                      onChange={(ev) =>
                        // setFormData({ ...formData, status: e.value })
                        setValue(
                          'permission',
                          ev?.value ?? translatedPermissionLevels[0].value,
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    {t('MEMBERS.ONBOARDING.LANGUAGE')}
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      options={languages}
                      defaultValue={languages[0]}
                      onChange={(ev) =>
                        setValue(
                          'language',
                          (ev?.value as LanguageType) ??
                            (languages[0].value as LanguageType),
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {t('MEMBERS.ONBOARDING.CREATE_BUTTON')}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
