import { Icon, Loader } from '@oproma/core-components';
import '@oproma/core-styling';
import { config } from '@oproma/feature-toggles';
import { registerTranslations } from '@oproma/i18n';
import { appService, persistor, store } from '@oproma/prividox-store';
import {
  FileBasedRoutes,
  RouterProvider,
  createBrowserRouter,
  createRoutes,
} from '@oproma/router';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import FlagProvider from '@unleash/proxy-client-react';
import { StrictMode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

appService.fetchAppConfigs().then((keys) => {
  registerTranslations();

  const stripe = loadStripe(
    import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || keys.stripePubKey,
  );
  const options: StripeElementsOptions = {};

  const fileRoutes: FileBasedRoutes = import.meta.glob('./views/**/*.tsx', {
    eager: true,
  });
  const routes = createRoutes(fileRoutes);
  const router = createBrowserRouter(routes);

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <StrictMode>
      <Provider {...{ store }}>
        <PersistGate {...{ persistor, loading: <Loader /> }}>
          <FlagProvider {...{ config }}>
            <Elements {...{ stripe, options }}>
              <DndProvider {...{ backend: HTML5Backend }}>
                <RouterProvider {...{ router }} />
                <ToastContainer
                  position="top-right"
                  hideProgressBar
                  closeOnClick
                  pauseOnHover
                  draggable
                  closeButton={
                    <span
                      className="btn-trigger toast-close-button"
                      role="button"
                    >
                      <Icon name="cross"></Icon>
                    </span>
                  }
                />
              </DndProvider>
            </Elements>
          </FlagProvider>
        </PersistGate>
      </Provider>
    </StrictMode>,
  );
});
