import { Icon } from '../icon.component';

interface ToggleProps {
  className?: string;
  click: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  icon: string;
}

export const Toggle = ({ className, click, icon }: ToggleProps) => {
  return (
    <a
      href="#toggle"
      className={className ? className : ''}
      onClick={(ev) => {
        ev.preventDefault();
        click(ev);
      }}
    >
      <Icon name={icon} />
    </a>
  );
};
