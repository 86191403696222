import {
  Block,
  BundlesModal,
  Button,
  CardSidebar,
  ChangePlanModal,
  Col,
  Content,
  EditCreditCardModal,
  Head,
  Icon,
  Layout,
  OnboardCreditCardModal,
  OverlineTitle,
  Row,
  UserAvatar,
  WorkspaceOptionsGeneralTab,
  WorkspaceOptionsHeader,
  WorkspaceOptionsPermissionsTab,
  findUpper,
  prettyBytes,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getPaymentTransactions,
  getWorkspaceBundles,
  getWorkspaceGroups,
  getWorkspaceMetadata,
  getWorkspacePermissions,
  getWorkspacePlan,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';

const OptionsPage = () => {
  const { workspaceId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { metadata: workspaceMetadata } = useAppSelector(
    (state) => state.workspace,
  );
  const [workspace] = useAppSelector((state) =>
    state.workspaces.workspaces.filter(
      (workspace) => workspace.id === workspaceId,
    ),
  );
  const { groups } = useAppSelector((state) => state.groups);

  const [sideBar, setSidebar] = useState(false);
  const [activeTab, setActivetab] = useState('1');

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getPaymentTransactions({}));
    dispatch(getWorkspacePlan(workspaceId));
    dispatch(getWorkspaceBundles(workspaceId));
    dispatch(getWorkspaceMetadata(workspaceId));
    dispatch(getWorkspacePermissions({ workspaceId, entityId: null }));
    dispatch(
      getWorkspaceGroups({
        workspaceId,
      }),
    );
  }, [workspaceId]);

  const toggle = () => {
    setSidebar(!sideBar);
  };

  const copyToClipboard = () => {
    if (!workspaceId) return;
    navigator.clipboard.writeText(workspaceId);
    toast.success('Copied to clipboard!');
  };

  return (
    <>
      <Head heading={t('WORKSPACE_OPTIONS.TITLE')} />
      <Layout>
        <Content>
          <WorkspaceOptionsHeader />
          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <Nav tabs className="nav-tabs-mb-icon nav-tabs-card">
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={clsx({ active: activeTab === '1' })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setActivetab('1');
                        }}
                      >
                        <Icon name="shield" />{' '}
                        <span>{t('COMMON.GENERAL')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={clsx({ active: activeTab === '2' })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setActivetab('2');
                        }}
                      >
                        <Icon name="lock-alt" />{' '}
                        <span>{t('COMMON.PERMISSIONS')}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <div className="card-inner">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <WorkspaceOptionsGeneralTab />
                      </TabPane>
                      <TabPane tabId="2">
                        <WorkspaceOptionsPermissionsTab
                          workspaceId={workspaceId}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </div>

                <CardSidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-xxl-0 mt-5">
                      <UserAvatar
                        className="lg"
                        theme="primary"
                        text={findUpper(workspaceMetadata?.name)}
                      />
                      <div className="user-info">
                        <Badge color="outline-light" pill className="ucap">
                          Workspace
                        </Badge>
                        <UncontrolledTooltip
                          placement="right"
                          target="workspace-id"
                          className="tooltip-inner-sm"
                          autohide={false}
                        >
                          {workspaceId}
                          <Button onClick={copyToClipboard}>
                            <Icon name="copy"></Icon>
                          </Button>
                        </UncontrolledTooltip>
                        <h5 id="workspace-id">{workspaceMetadata?.name}</h5>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-inner card-inner-sm">
                  <ul className="btn-toolbar gx-1 justify-center">
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn-trigger btn-icon"
                      >
                        <Icon name="pen"></Icon>
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn-trigger btn-icon"
                      >
                        <Icon name="shield-off"></Icon>
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn-trigger btn-icon"
                      >
                        <Icon name="bookmark"></Icon>
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn-trigger btn-icon text-danger"
                      >
                        <Icon name="na"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div> */}
                  <div className="card-inner">
                    <Row className="text-center">
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">
                            {workspace?.membersLimit}
                          </span>
                          <span className="sub-text">
                            {t('COMMON.MAX_MEMBERS')}
                          </span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{workspace?.fileCount}</span>
                          <span className="sub-text">{t('COMMON.FILES')}</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{groups?.length}</span>
                          <span className="sub-text">{t('COMMON.GROUPS')}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Metadata</h6>
                    <Row className="g-3">
                      <Col size="6">
                        <span className="sub-text">Created:</span>
                        <span>
                          <Moment format="L">
                            {workspaceMetadata?.created}
                          </Moment>
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Last Modified:</span>
                        <span>
                          <Moment format="L">
                            {workspaceMetadata?.modified}
                          </Moment>
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Storage:</span>
                        <span>
                          {prettyBytes(workspaceMetadata?.['bytes-limit'] ?? 0)}
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Permission Level:</span>
                        <span>
                          {workspaceMetadata?.isAdmin ? 'Sudo' : 'Regular'}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                      Groups
                    </OverlineTitle>
                    <ul className="g-1">
                      {groups?.map((group) => (
                        <li className="btn-group" key={group.id}>
                          <Button
                            color="light"
                            size="xs"
                            className="btn-dim"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                          >
                            {group.name}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardSidebar>
                {sideBar && (
                  <div
                    className="toggle-overlay"
                    onClick={() => toggle()}
                  ></div>
                )}
              </div>
            </Card>
          </Block>
        </Content>
      </Layout>
      <BundlesModal />
      <ChangePlanModal />
      <EditCreditCardModal />
      <OnboardCreditCardModal />
    </>
  );
};

export default OptionsPage;
