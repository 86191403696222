/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  CodeSpec,
  PasswordSpec,
  ResetPasswordSpec,
  ResultUUID,
  SecretCodeResponse,
  UpdateNameSpec,
  UpdatePasswordSpec,
  UserInformation,
  ValidatePasswordResetSpec,
} from '../models/index';
import {
  CodeSpecFromJSON,
  CodeSpecToJSON,
  PasswordSpecFromJSON,
  PasswordSpecToJSON,
  ResetPasswordSpecFromJSON,
  ResetPasswordSpecToJSON,
  ResultUUIDFromJSON,
  ResultUUIDToJSON,
  SecretCodeResponseFromJSON,
  SecretCodeResponseToJSON,
  UpdateNameSpecFromJSON,
  UpdateNameSpecToJSON,
  UpdatePasswordSpecFromJSON,
  UpdatePasswordSpecToJSON,
  UserInformationFromJSON,
  UserInformationToJSON,
  ValidatePasswordResetSpecFromJSON,
  ValidatePasswordResetSpecToJSON,
} from '../models/index';

export interface GetDisplayNameRequest {
  user: string;
}

export interface GetEmailRequest {
  user: string;
}

export interface GetIdFromEmailRequest {
  email: string;
}

export interface GetMemberImportsRequest {
  project: string;
}

export interface IsOnlineRequest {
  user: string;
}

export interface RemoveSecretCodeRequest {
  passwordSpec: PasswordSpec;
}

export interface RequestPasswordResetRequest {
  resetPasswordSpec: ResetPasswordSpec;
}

export interface SubscribeRequest {
  body: string;
}

export interface UnsubscribeRequest {
  subscribed: string;
}

export interface UpdateNameRequest {
  updateNameSpec: UpdateNameSpec;
}

export interface UpdatePasswordRequest {
  updatePasswordSpec: UpdatePasswordSpec;
}

export interface ValidateCodeRequest {
  codeSpec: CodeSpec;
}

export interface ValidateEmailRequest {
  email: string;
}

export interface ValidatePasswordResetRequest {
  validatePasswordResetSpec: ValidatePasswordResetSpec;
}

/**
 *
 */
export class UserApi extends runtime.BaseAPI {
  /**
   * Retrieves the display name of the specified user
   * Get Name
   */
  async getDisplayNameRaw(
    requestParameters: GetDisplayNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.user === null ||
      requestParameters.user === undefined
    ) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter requestParameters.user was null or undefined when calling getDisplayName.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/information/{user}/name`.replace(
          `{${'user'}}`,
          encodeURIComponent(String(requestParameters.user)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the display name of the specified user
   * Get Name
   */
  async getDisplayName(
    requestParameters: GetDisplayNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getDisplayNameRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the email address of the specified user.
   * Get User Email
   */
  async getEmailRaw(
    requestParameters: GetEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.user === null ||
      requestParameters.user === undefined
    ) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter requestParameters.user was null or undefined when calling getEmail.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/{user}/email`.replace(
          `{${'user'}}`,
          encodeURIComponent(String(requestParameters.user)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the email address of the specified user.
   * Get User Email
   */
  async getEmail(
    requestParameters: GetEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getEmailRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves the ID of the user associated with the specified email address.
   * Get ID From Email
   */
  async getIdFromEmailRaw(
    requestParameters: GetIdFromEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        'email',
        'Required parameter requestParameters.email was null or undefined when calling getIdFromEmail.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/information/{email}`.replace(
          `{${'email'}}`,
          encodeURIComponent(String(requestParameters.email)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the ID of the user associated with the specified email address.
   * Get ID From Email
   */
  async getIdFromEmail(
    requestParameters: GetIdFromEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getIdFromEmailRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the number of times the current user has logged in
   * Get Login Count
   */
  async getLoginCountRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/logins`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<number>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the number of times the current user has logged in
   * Get Login Count
   */
  async getLoginCount(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response = await this.getLoginCountRaw(initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of users with whom the current user shares a workspace but who aren\'t members of the specified workspace.
   * Get Member Imports
   */
  async getMemberImportsRaw(
    requestParameters: GetMemberImportsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: UserInformation }>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        'project',
        'Required parameter requestParameters.project was null or undefined when calling getMemberImports.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/imports/{project}`.replace(
          `{${'project'}}`,
          encodeURIComponent(String(requestParameters.project)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, UserInformationFromJSON),
    );
  }

  /**
   * Retrieves a list of users with whom the current user shares a workspace but who aren\'t members of the specified workspace.
   * Get Member Imports
   */
  async getMemberImports(
    requestParameters: GetMemberImportsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: UserInformation }> {
    const response = await this.getMemberImportsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves whether the current user\'s two factor setup code.
   * Get Secret Code
   */
  async getSecretCodeRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SecretCodeResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/secret/code`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SecretCodeResponseFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves whether the current user\'s two factor setup code.
   * Get Secret Code
   */
  async getSecretCode(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SecretCodeResponse> {
    const response = await this.getSecretCodeRaw(initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of entities to which the specified user is subscribed to.
   * Get Subscriptions
   */
  async getSubscriptionsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultUUID>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/subscriptions`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultUUIDFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of entities to which the specified user is subscribed to.
   * Get Subscriptions
   */
  async getSubscriptions(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultUUID> {
    const response = await this.getSubscriptionsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Retrieves whether the current user has two factor authentication enabled.
   * Has Secret Code
   */
  async hasSecretCodeRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/secret`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves whether the current user has two factor authentication enabled.
   * Has Secret Code
   */
  async hasSecretCode(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.hasSecretCodeRaw(initOverrides);
    return await response.value();
  }

  /**
   * Checks whether the specified user is online or not.
   * Is Online
   */
  async isOnlineRaw(
    requestParameters: IsOnlineRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    if (
      requestParameters.user === null ||
      requestParameters.user === undefined
    ) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter requestParameters.user was null or undefined when calling isOnline.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/{user}/online`.replace(
          `{${'user'}}`,
          encodeURIComponent(String(requestParameters.user)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Checks whether the specified user is online or not.
   * Is Online
   */
  async isOnline(
    requestParameters: IsOnlineRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.isOnlineRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of the current user\'s sessions.
   * List Sessions
   */
  async listSessionsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<{ [key: string]: { [key: string]: string } }>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/sessions`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves a list of the current user\'s sessions.
   * List Sessions
   */
  async listSessions(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: { [key: string]: string } }> {
    const response = await this.listSessionsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Removes the secret code associated with the current, disabling two factor authentication.
   * Remove Secret Code
   */
  async removeSecretCodeRaw(
    requestParameters: RemoveSecretCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.passwordSpec === null ||
      requestParameters.passwordSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'passwordSpec',
        'Required parameter requestParameters.passwordSpec was null or undefined when calling removeSecretCode.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user/secret`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PasswordSpecToJSON(requestParameters.passwordSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Removes the secret code associated with the current, disabling two factor authentication.
   * Remove Secret Code
   */
  async removeSecretCode(
    requestParameters: RemoveSecretCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.removeSecretCodeRaw(requestParameters, initOverrides);
  }

  /**
   * Requests a password for the specified email address.
   * Request Password Reset
   */
  async requestPasswordResetRaw(
    requestParameters: RequestPasswordResetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.resetPasswordSpec === null ||
      requestParameters.resetPasswordSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'resetPasswordSpec',
        'Required parameter requestParameters.resetPasswordSpec was null or undefined when calling requestPasswordReset.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user/password/reset`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordSpecToJSON(requestParameters.resetPasswordSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Requests a password for the specified email address.
   * Request Password Reset
   */
  async requestPasswordReset(
    requestParameters: RequestPasswordResetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.requestPasswordResetRaw(requestParameters, initOverrides);
  }

  /**
   * Marks the current user\'s account as requiring a two factor authentication code when authenticating.
   * Require Secret Code
   */
  async requireSecretCodeRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/secret`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Marks the current user\'s account as requiring a two factor authentication code when authenticating.
   * Require Secret Code
   */
  async requireSecretCode(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.requireSecretCodeRaw(initOverrides);
  }

  /**
   * Subscribes the current user to the provided entity.
   * Subscribe
   */
  async subscribeRaw(
    requestParameters: SubscribeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling subscribe.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user/subscriptions`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Subscribes the current user to the provided entity.
   * Subscribe
   */
  async subscribe(
    requestParameters: SubscribeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.subscribeRaw(requestParameters, initOverrides);
  }

  /**
   * Unsubscribes the current user to the provided entity.
   * Unsubscribe
   */
  async unsubscribeRaw(
    requestParameters: UnsubscribeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.subscribed === null ||
      requestParameters.subscribed === undefined
    ) {
      throw new runtime.RequiredError(
        'subscribed',
        'Required parameter requestParameters.subscribed was null or undefined when calling unsubscribe.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/user/subscriptions/{subscribed}`.replace(
          `{${'subscribed'}}`,
          encodeURIComponent(String(requestParameters.subscribed)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Unsubscribes the current user to the provided entity.
   * Unsubscribe
   */
  async unsubscribe(
    requestParameters: UnsubscribeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.unsubscribeRaw(requestParameters, initOverrides);
  }

  /**
   * Updates the display name of the user.
   * Update Name
   */
  async updateNameRaw(
    requestParameters: UpdateNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.updateNameSpec === null ||
      requestParameters.updateNameSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'updateNameSpec',
        'Required parameter requestParameters.updateNameSpec was null or undefined when calling updateName.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user/name`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateNameSpecToJSON(requestParameters.updateNameSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the display name of the user.
   * Update Name
   */
  async updateName(
    requestParameters: UpdateNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateNameRaw(requestParameters, initOverrides);
  }

  /**
   * Updates the current user\'s password.
   * Update Password
   */
  async updatePasswordRaw(
    requestParameters: UpdatePasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.updatePasswordSpec === null ||
      requestParameters.updatePasswordSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'updatePasswordSpec',
        'Required parameter requestParameters.updatePasswordSpec was null or undefined when calling updatePassword.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user/password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdatePasswordSpecToJSON(requestParameters.updatePasswordSpec),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the current user\'s password.
   * Update Password
   */
  async updatePassword(
    requestParameters: UpdatePasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updatePasswordRaw(requestParameters, initOverrides);
  }

  /**
   * Validates whether the provided two factor authentication code matches the one configured for the current user.
   * Validate Secret Code
   */
  async validateCodeRaw(
    requestParameters: ValidateCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    if (
      requestParameters.codeSpec === null ||
      requestParameters.codeSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'codeSpec',
        'Required parameter requestParameters.codeSpec was null or undefined when calling validateCode.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user/secret`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CodeSpecToJSON(requestParameters.codeSpec),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Validates whether the provided two factor authentication code matches the one configured for the current user.
   * Validate Secret Code
   */
  async validateCode(
    requestParameters: ValidateCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.validateCodeRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Verifies whether the specified email address is valid.
   * Validate Email Address
   */
  async validateEmailRaw(
    requestParameters: ValidateEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        'email',
        'Required parameter requestParameters.email was null or undefined when calling validateEmail.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/email/{email}`.replace(
          `{${'email'}}`,
          encodeURIComponent(String(requestParameters.email)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Verifies whether the specified email address is valid.
   * Validate Email Address
   */
  async validateEmail(
    requestParameters: ValidateEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.validateEmailRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Validates and performs a password reset given the provided reset token.
   * Validate Password Reset
   */
  async validatePasswordResetRaw(
    requestParameters: ValidatePasswordResetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.validatePasswordResetSpec === null ||
      requestParameters.validatePasswordResetSpec === undefined
    ) {
      throw new runtime.RequiredError(
        'validatePasswordResetSpec',
        'Required parameter requestParameters.validatePasswordResetSpec was null or undefined when calling validatePasswordReset.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user/password/validate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ValidatePasswordResetSpecToJSON(
          requestParameters.validatePasswordResetSpec,
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Validates and performs a password reset given the provided reset token.
   * Validate Password Reset
   */
  async validatePasswordReset(
    requestParameters: ValidatePasswordResetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.validatePasswordResetRaw(requestParameters, initOverrides);
  }
}
