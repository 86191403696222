import { useAppSelector } from '@oproma/prividox-store';
import Moment from 'react-moment';
import { useTranslation } from '@oproma/i18n';
import { useMemo } from 'react';
import { NotificationMessage } from '@oproma/prividox-orchestration-open-api';

interface ContributionItemProps {
  contribution: [string, NotificationMessage];
  id: string;
}

const ContributionItem = ({ contribution, id }: ContributionItemProps) => {
  const { t } = useTranslation();

  const state: Record<string, string | undefined> = useMemo(
    () => ({
      agent: contribution[1].agentName,
      child: contribution[1].meta?.CHILD_NAME,
      project: contribution[1].projectName,
      oldValue: contribution[1].meta?.OLD_VALUE,
      newValue: contribution[1].meta?.NEW_VALUE,
      from: contribution[1].meta?.FROM,
      to: contribution[1].meta?.TO,
      email: contribution[1].meta?.EMAIL,
      childName: contribution[1].meta?.CHILD_NAME,
      key: contribution[1].meta?.KEY,
      deleted: contribution[1].meta?.DELETED,
      version: contribution[1].meta?.VERSION,
      message: contribution[1].meta?.MESSAGE,
      location: contribution[1].meta?.LOCATION,
      childType: contribution[1].meta?.CHILD_TYPE,
      public: contribution[1].meta?.PUBLIC,
      type: contribution[1].meta?.TYPE,
      fromName: contribution[1].meta?.FROM_NAME,
      toName: contribution[1].meta?.TO_NAME,
      copy: contribution[1].meta?.COPY,
      cardId: contribution[1].meta?.CARD_ID,
      verb: contribution[1].meta?.VERB,
      grant: contribution[1].meta?.GRANT,
      added: contribution[1].meta?.ADDED,
      removed: contribution[1].meta?.REMOVED,
      name: contribution[1].meta?.NAME || '',
    }),
    [contribution],
  );

  // Get notification translation key
  const contributionContent = t(
    `NOTIFICATION_BODIES.${contribution[0]}`,
    state,
  );

  return (
    <tr>
      <th scope="row">
        <Moment format="LLL">{contribution[1].time}</Moment>
      </th>
      <td>{contributionContent}</td>
    </tr>
  );
};

export const MemberContributions = () => {
  const { memberActivityContent } = useAppSelector((state) => state.members);
  const { t } = useTranslation();
  return (
    <table className="table table-responsive">
      <thead>
        <tr>
          <th scope="col">{t('MEMBERS.MEMBER_CONTRIBUTIONS.WHEN')}?</th>
          <th scope="col">{t('MEMBERS.MEMBER_CONTRIBUTIONS.WHAT')}?</th>
        </tr>
      </thead>
      <tbody>
        {/* <SimpleBar> */}
        {memberActivityContent.map((contribution, index) => (
          <ContributionItem contribution={contribution} id={`${index}`} />
        ))}
        {/* </SimpleBar> */}
      </tbody>
    </table>
  );
};
