import { NotificationMessage } from '@oproma/prividox-orchestration-open-api';
import {
  Entity,
  NotificationMessages,
  useAppSelector,
  Workspace,
} from '@oproma/prividox-store';
import gravatar from 'gravatar';
import matter from 'gray-matter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrettyBytesOptions } from './types';
import i18n from 'i18next';

export const today = new Date();

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const BYTE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const BIBYTE_UNITS = [
  'B',
  'KiB',
  'MiB',
  'GiB',
  'TiB',
  'PiB',
  'EiB',
  'ZiB',
  'YiB',
];

const BIT_UNITS = [
  'b',
  'Kbit',
  'Mbit',
  'Gbit',
  'Tbit',
  'Pbit',
  'Ebit',
  'Zbit',
  'Ybit',
];

const BIBIT_UNITS = [
  'b',
  'Kibit',
  'Mibit',
  'Gibit',
  'Tibit',
  'Pibit',
  'Eibit',
  'Zibit',
  'Yibit',
];

/*
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
const toLocaleString = (
  number: number,
  locale: string | boolean,
  options: Intl.NumberFormatOptions,
) => {
  let result: string | number = number;
  if (typeof locale === 'string' || Array.isArray(locale)) {
    result = number.toLocaleString(locale, options);
  } else if (locale === true || options !== undefined) {
    result = number.toLocaleString(undefined, options);
  }

  return result;
};

const getUnits = (language: string, options: PrettyBytesOptions) => {
  const baseUnit = language.startsWith('fr') ? 'o' : 'B';
  const units = options.bits
    ? options.binary
      ? BIBIT_UNITS
      : BIT_UNITS
    : options.binary
    ? BIBYTE_UNITS
    : BYTE_UNITS;

  return units.map((unit) => unit.replace('B', baseUnit));
};

export const prettyBytes = (
  number: number,
  options: PrettyBytesOptions = {
    bits: false,
    binary: false,
    space: true,
    locale: false,
  },
) => {
  if (!Number.isFinite(number)) {
    throw new TypeError(
      `Expected a finite number, got ${typeof number}: ${number}`,
    );
  }

  options = {
    bits: false,
    binary: false,
    space: true,
    ...options,
  };

  const language = i18n.language;
  const UNITS = getUnits(language, options);

  const separator = options.space ? ' ' : '';

  if (options.signed && number === 0) {
    return ` 0${separator}${UNITS[0]}`;
  }

  const isNegative = number < 0;
  const prefix = isNegative ? '-' : options.signed ? '+' : '';

  if (isNegative) {
    number = -number;
  }

  let localeOptions: Intl.NumberFormatOptions | undefined;

  if (options.minimumFractionDigits !== undefined) {
    localeOptions = { minimumFractionDigits: options.minimumFractionDigits };
  }

  if (options.maximumFractionDigits !== undefined) {
    localeOptions = {
      maximumFractionDigits: options.maximumFractionDigits,
      ...localeOptions,
    };
  }

  if (number < 1) {
    const numberString = toLocaleString(
      number,
      options.locale,
      localeOptions as Intl.NumberFormatOptions,
    );
    return prefix + numberString + separator + UNITS[0];
  }

  const exponent = Math.min(
    Math.floor(
      options.binary
        ? Math.log(number) / Math.log(1024)
        : Math.log10(number) / 3,
    ),
    UNITS.length - 1,
  );
  number /= (options.binary ? 1024 : 1000) ** exponent;

  if (!localeOptions) {
    number = Number(number.toPrecision(3));
  }

  const numberString = toLocaleString(
    Number(number),
    options.locale,
    localeOptions as Intl.NumberFormatOptions,
  );

  const unit = UNITS[exponent];

  return prefix + numberString + separator + unit;
};

export const currencyFormatter = (quantity: number) =>
  (quantity / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

// TODO: Remove this
export const findUpper = (string: string | null | undefined) => {
  const extractedString = [];

  if (!string) return;

  for (let i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== ' '
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

export const currentTime = () => {
  let hours = today.getHours();
  let minutes = today.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? 0 + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const retrieveDateStructured = (date: Date | null) => {
  if (date) {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    const final = months[m] + ' ' + d + ', ' + y;
    return final;
  }
};

export const parseMarkdown = (markdownWithMetadata: string) => {
  // Parse markdown string
  const parsedMarkdown = matter(markdownWithMetadata);

  // Get frontmatter as an object and content as a string
  const metadata = parsedMarkdown.data;
  const content = parsedMarkdown.content;

  return { metadata, content };
};

export const retrieveApiErrorMessage = (error: string) => {
  let errorKey, errorType;

  try {
    if (!error) return 'ERROR.Unknown';
    const parsedError = JSON.parse(error);
    [errorKey, errorType] = parsedError.error.split('::');
  } catch {
    [errorKey, errorType] = error.split('::');
  }

  return `${errorKey}.${errorType}`;
};

// This is reuired to fix the routing for the gitlab deployment of the docs
export const url = (link: string): string => {
  const isProd = import.meta.env.PROD;
  return isProd ? `/ui-ux/ui-apps${link}` : link;
};

export const generateGravatarUrl = (email: string) => {
  return gravatar.url(email, {
    s: '100',
    d: 'identicon',
  });
};

export const trimFilename = (filename: string, maxLength = 11) => {
  const dotIndex = filename.lastIndexOf('.');
  const name = filename.slice(0, dotIndex);
  const extension = filename.slice(dotIndex);

  return name.length > maxLength
    ? `${name.slice(0, maxLength)}...${extension}`
    : filename;
};

export const createEntityDistributionLink = (
  lastOpenedEntity: Entity | null,
  workspaceId: string | undefined,
  entityParent: string | null,
  openedWorkspace: Workspace | undefined,
) => {
  if (!lastOpenedEntity) return;

  const { type } = lastOpenedEntity;
  const origin = window.location.origin;

  if (type === 'folder' || type === 'gallery' || type === 'calendar') {
    return `${origin}/${workspaceId}/file-manager/entities/${lastOpenedEntity.folderId}`;
  }

  if (type === 'file') {
    if (!entityParent)
      return `${origin}/${workspaceId}/file-manager/entities/${openedWorkspace?.root}?file=${lastOpenedEntity.fileId}`;
    return `${origin}/${workspaceId}/file-manager/entities/${entityParent}?file=${lastOpenedEntity.fileId}`;
  }
};

export const useNotificationState = (
  notification: NotificationMessage | NotificationMessages,
) => {
  return useMemo(
    () => ({
      agent: notification.agentName,
      child: notification.meta?.CHILD_NAME,
      project: notification.projectName,
      oldValue: notification.meta?.OLD_VALUE,
      newValue: notification.meta?.NEW_VALUE,
      from: notification.meta?.FROM,
      to: notification.meta?.TO,
      email: notification.meta?.EMAIL,
      childName: notification.meta?.CHILD_NAME,
      key: notification.meta?.KEY,
      deleted: notification.meta?.DELETED,
      version: notification.meta?.VERSION,
      message: notification.meta?.MESSAGE,
      location: notification.meta?.LOCATION,
      childType: notification.meta?.CHILD_TYPE,
      public: notification.meta?.PUBLIC,
      type: notification.meta?.TYPE,
      fromName: notification.meta?.FROM_NAME,
      toName: notification.meta?.TO_NAME,
      copy: notification.meta?.COPY,
      cardId: notification.meta?.CARD_ID,
      verb: notification.meta?.VERB,
      grant: notification.meta?.GRANT,
      added: notification.meta?.ADDED,
      removed: notification.meta?.REMOVED,
      name: notification.meta?.NAME || '',
    }),
    [notification],
  );
};

export const useNotificationContent = (
  notification: NotificationMessage | NotificationMessages,
  state: Record<string, string | undefined>,
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    let key: string | null = null;

    switch (notification.eventType) {
      case 'CREATED':
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case 'CREATED_TASK':
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case 'CHILD_REMOVED':
        if (
          notification.meta?.CHILD_NAME?.startsWith(
            `/${notification.projectName}/.Trash/`,
          )
        ) {
          key = 'CHILD_TRASHED';
        }
      // fallthrough
      case 'CHILD_ADDED':
        if (!key) {
          key = `${notification.eventType}_${notification.entityType}`;
        }
        if (notification.entityType === 'FOLDER') {
          key += `_${notification.meta?.CHILD_TYPE}`;
        }
        state.CHILD = notification.meta?.CHILD_NAME;
        break;
      case 'COMMENTED':
        state.message = notification.meta?.MESSAGE;
        break;
      case 'METADATA_SET':
        state.key = notification.meta?.KEY;
        state.newValue = notification.meta?.NEW_VALUE;
        if (notification.meta?.OLD_VALUE) {
          state.OLD_VALUE = notification.meta?.OLD_VALUE;
          key = 'METADATA_SET_OLD';
        }
        break;
      case 'METADATA_DELETED':
        state.key = notification.meta?.KEY;
        state.OLD_VALUE = notification.meta?.OLD_VALUE;
        break;
      case 'EMAIL_CHANGED':
      case 'UPDATED_DISPLAY_NAME':
      case 'RENAMED':
        state.newValue = notification.meta?.NEW_VALUE;
        state.OLD_VALUE = notification.meta?.OLD_VALUE;
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case 'MOVED':
        state.to = notification.meta?.TO_NAME;
        state.from = notification.meta?.FROM_NAME;
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case 'DEFAULT_GROUP_SET':
        state.to = notification.meta?.TO_NAME;
        state.from = notification.meta?.FROM_NAME;
        break;
      case 'COPIED':
      case 'RESTORED':
        state.to = notification.meta?.TO_NAME;
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case 'CUSTOM_ENTITY_ADDED':
      case 'CUSTOM_ENTITY_REMOVED':
        state.CHILD = notification.meta?.CHILD_NAME;
        break;
      case 'MEMBER_ADDED':
      case 'MEMBER_REMOVED':
        state.CHILD = notification.meta?.CHILD_NAME;
        state.email = notification.meta?.EMAIL;
        break;
      case 'DOWNLOADED':
      case 'PREVIEWED':
      case 'TRASHED':
      case 'DELETED':
        key = `${notification.eventType}_${notification.entityType}`;
        break;

      case 'LOCKED':
      case 'PUBLISHED':
      case 'UNLOCKED': {
        state.public = notification.meta?.PUBLIC;
        state.from = notification.meta?.FROM || 'unknown IP';
        state.location = notification.meta?.FROM_NAME || '';
        const childType = notification.meta?.CHILD_TYPE || '';
        if (childType !== '') {
          state.childType = childType;
          key = `${notification.eventType}_${childType}`;
        }
        break;
      }
      case 'CARD_ADDED':
      case 'CARD_REMOVED':
      case 'DEFAULT_CARD_CHANGED':
      case 'PASSWORD_RESET_REQUESTED':
      case 'TEST':
      case 'SCRIPT_NOTIFICATION':
      case 'CARD_UPDATED':
      case 'NOTIFICATION_TYPE_TOGGLED':
      case 'LOGIN':
      case 'LOGOUT':
      case 'SESSION_REFRESHED':
      case 'ACCOUNT_CREATED':
      case 'ACCOUNT_ACTIVATED':
      case 'EMAIL_CHANGE_REQUESTED':
      case 'UPDATED_PASSWORD':
      case 'NOTIFICATION_PROVIDER_ADDED':
      case 'NOTIFICATION_PROVIDER_REMOVED':
      case 'APPLICATION_ACCESS_GRANTED':
      case 'APPLICATION_ACCESS_REVOKED':
      case 'PAYMENT_PLAN_UPDATED':
      case 'PROJECT_LEFT':
      case 'TRASH_EMPTIED':
      case 'REDACTED':
      default:
        break;
    }

    if (!key) {
      key = notification.eventType as string;
    }

    return t(`NOTIFICATION_BODIES.${key}`, state);
  }, [t, notification, state]);
};

export const getActivityState = (activity: NotificationMessage) => {
  return {
    agent: activity.agentName,
    child: activity.meta?.CHILD_NAME,
    project: activity.projectName,
    oldValue: activity.meta?.OLD_VALUE,
    newValue: activity.meta?.NEW_VALUE,
    from: activity.meta?.FROM,
    to: activity.meta?.TO,
    email: activity.meta?.EMAIL,
    childName: activity.meta?.CHILD_NAME,
    key: activity.meta?.KEY,
    deleted: activity.meta?.DELETED,
    version: activity.meta?.VERSION,
    message: activity.meta?.MESSAGE,
    location: activity.meta?.LOCATION,
    childType: activity.meta?.CHILD_TYPE,
    public: activity.meta?.PUBLIC,
    type: activity.meta?.TYPE,
    fromName: activity.meta?.FROM_NAME,
    toName: activity.meta?.TO_NAME,
    copy: activity.meta?.COPY,
    cardId: activity.meta?.CARD_ID,
    verb: activity.meta?.VERB,
    grant: activity.meta?.GRANT,
    added: activity.meta?.ADDED,
    removed: activity.meta?.REMOVED,
    name: activity.meta?.NAME || '',
  };
};

export function getActivityContent(
  activity: NotificationMessage,
  state: Record<string, string | undefined>,
): { key: string; state: Record<string, string | undefined> } {
  let key: string | null = null;

  switch (activity.eventType) {
    case 'CREATED':
      key = `${activity.eventType}_${activity.entityType}`;
      break;
    case 'CREATED_TASK':
      key = `${activity.eventType}_${activity.entityType}`;
      break;
    case 'CHILD_REMOVED':
      if (
        activity.meta?.CHILD_NAME?.startsWith(
          `/${activity.projectName}/.Trash/`,
        )
      ) {
        key = 'CHILD_TRASHED';
      }
    // fallthrough
    case 'CHILD_ADDED':
      if (!key) {
        key = `${activity.eventType}_${activity.entityType}`;
      }
      if (activity.entityType === 'FOLDER') {
        key += `_${activity.meta?.CHILD_TYPE}`;
      }
      state.CHILD = activity.meta?.CHILD_NAME;
      break;
    case 'COMMENTED':
      state.message = activity.meta?.MESSAGE;
      break;
    case 'METADATA_SET':
      state.key = activity.meta?.KEY;
      state.newValue = activity.meta?.NEW_VALUE;
      if (activity.meta?.OLD_VALUE) {
        state.OLD_VALUE = activity.meta?.OLD_VALUE;
        key = 'METADATA_SET_OLD';
      }
      break;
    case 'METADATA_DELETED':
      state.key = activity.meta?.KEY;
      state.OLD_VALUE = activity.meta?.OLD_VALUE;
      break;
    case 'EMAIL_CHANGED':
    case 'UPDATED_DISPLAY_NAME':
    case 'RENAMED':
      state.newValue = activity.meta?.NEW_VALUE;
      state.OLD_VALUE = activity.meta?.OLD_VALUE;
      key = `${activity.eventType}_${activity.entityType}`;
      break;
    case 'MOVED':
      state.to = activity.meta?.TO_NAME;
      state.from = activity.meta?.FROM_NAME;
      key = `${activity.eventType}_${activity.entityType}`;
      break;
    case 'DEFAULT_GROUP_SET':
      state.to = activity.meta?.TO_NAME;
      state.from = activity.meta?.FROM_NAME;
      break;
    case 'COPIED':
    case 'RESTORED':
      state.to = activity.meta?.TO_NAME;
      key = `${activity.eventType}_${activity.entityType}`;
      break;
    case 'CUSTOM_ENTITY_ADDED':
    case 'CUSTOM_ENTITY_REMOVED':
      state.CHILD = activity.meta?.CHILD_NAME;
      break;
    case 'MEMBER_ADDED':
    case 'MEMBER_REMOVED':
      state.CHILD = activity.meta?.CHILD_NAME;
      state.email = activity.meta?.EMAIL;
      break;
    case 'DOWNLOADED':
    case 'PREVIEWED':
    case 'TRASHED':
    case 'DELETED':
    case 'LOCKED':
    case 'PUBLISHED':
    case 'UNLOCKED': {
      state.public = activity.meta?.PUBLIC;
      state.from = activity.meta?.FROM || 'unknown IP';
      state.location = activity.meta?.FROM_NAME || '';
      const childType = activity.meta?.CHILD_TYPE || '';
      if (childType !== '') {
        state.childType = childType;
        key = `${activity.eventType}_${childType}`;
      }
      break;
    }
    case 'CARD_ADDED':
    case 'CARD_REMOVED':
    case 'DEFAULT_CARD_CHANGED':
    case 'PASSWORD_RESET_REQUESTED':
    case 'TEST':
    case 'SCRIPT_NOTIFICATION':
    case 'CARD_UPDATED':
    case 'NOTIFICATION_TYPE_TOGGLED':
    case 'LOGIN':
    case 'LOGOUT':
    case 'SESSION_REFRESHED':
    case 'ACCOUNT_CREATED':
    case 'ACCOUNT_ACTIVATED':
    case 'EMAIL_CHANGE_REQUESTED':
    case 'UPDATED_PASSWORD':
    case 'NOTIFICATION_PROVIDER_ADDED':
    case 'NOTIFICATION_PROVIDER_REMOVED':
    case 'APPLICATION_ACCESS_GRANTED':
    case 'APPLICATION_ACCESS_REVOKED':
    case 'PAYMENT_PLAN_UPDATED':
    case 'PROJECT_LEFT':
    case 'TRASH_EMPTIED':
    case 'REDACTED':
    default:
      break;
  }

  if (!key) {
    key = activity.eventType as string;
  }

  return { key, state };
}

export const getSelectedEntitiesText = (
  folderCount: number,
  fileCount: number,
  t: (key: string) => string,
): string => {
  const folderLabel =
    folderCount === 1 ? t('COMMON.FOLDER') : t('COMMON.FOLDERS');
  const fileLabel = fileCount === 1 ? t('COMMON.FILE') : t('COMMON.FILES');

  let displayText = '';

  if (folderCount > 0) {
    displayText += `${folderCount} ${folderLabel}`;
  }

  if (fileCount > 0) {
    if (folderCount > 0) {
      displayText += ' / ';
    }
    displayText += `${fileCount} ${fileLabel}`;
  }

  return displayText;
};
