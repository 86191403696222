import { useTranslation } from '@oproma/i18n';
import {
  setDisplayMemberFinder,
  setMemberQuery,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Button } from '../button.component';
import { Icon } from '../icon.component';

const MemberQuery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { displayMemberFinder, memberQuery } = useAppSelector(
    (state) => state.members,
  );

  const toggleMemberFinder = () => {
    dispatch(setDisplayMemberFinder(!displayMemberFinder));
  };

  return (
    <div
      className={clsx('card-search search-wrap', {
        active: displayMemberFinder,
      })}
    >
      <div className="card-body">
        <div className="search-content">
          <Button
            className="search-back btn-icon toggle-search active"
            onClick={() => {
              dispatch(setMemberQuery(''));
              toggleMemberFinder();
            }}
          >
            <Icon name="arrow-left"></Icon>
          </Button>
          <input
            type="text"
            className="form-focus-none form-control border-transparent"
            placeholder={t('MEMBERS.TABLE_HEADER.SEARCH_PLACEHOLDER')}
            value={memberQuery}
            onChange={(ev) => dispatch(setMemberQuery(ev.target.value))}
          />
          <Button className="search-submit btn-icon">
            <Icon name="search"></Icon>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MemberQuery;
