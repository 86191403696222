import { MouseEvent, useState } from 'react';

interface InputSwitchProps {
  label?: string;
  id: string;
  checked?: boolean;
  onClick?: (e?: MouseEvent<HTMLInputElement>) => void;
}

export const InputSwitch = ({
  id,
  label,
  checked,
  onClick,
}: InputSwitchProps) => {
  const [inputCheck, setCheck] = useState<boolean>(!!checked);

  return (
    <>
      <input
        type="checkbox"
        className="custom-control-input"
        defaultChecked={inputCheck}
        onClick={(ev) => {
          setCheck(!inputCheck);
          if (onClick) onClick(ev);
        }}
        id={id}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </>
  );
};
