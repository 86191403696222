/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SearchDocument
 */
export interface SearchDocument {
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  projectId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchDocument
   */
  contents?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  creator?: string;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  filesize?: number;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  creationDate?: number;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  lastUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  parent?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchDocument
   */
  highlights?: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SearchDocument
   */
  customs?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  longitude?: number;
  /**
   *
   * @type {boolean}
   * @memberof SearchDocument
   */
  produce?: boolean;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  doctype?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  author?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  recipient?: string;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  importance?: number;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  relevance?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  notes?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchDocument
   */
  redactionNotes?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof SearchDocument
   */
  redacted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchDocument
   */
  converted?: boolean;
}

/**
 * Check if a given object implements the SearchDocument interface.
 */
export function instanceOfSearchDocument(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SearchDocumentFromJSON(json: any): SearchDocument {
  return SearchDocumentFromJSONTyped(json, false);
}

export function SearchDocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchDocument {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    projectId: !exists(json, 'projectId') ? undefined : json['projectId'],
    contents: !exists(json, 'contents') ? undefined : json['contents'],
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    creator: !exists(json, 'creator') ? undefined : json['creator'],
    filesize: !exists(json, 'filesize') ? undefined : json['filesize'],
    creationDate: !exists(json, 'creationDate')
      ? undefined
      : json['creationDate'],
    lastUpdated: !exists(json, 'lastUpdated') ? undefined : json['lastUpdated'],
    parent: !exists(json, 'parent') ? undefined : json['parent'],
    highlights: !exists(json, 'highlights') ? undefined : json['highlights'],
    customs: !exists(json, 'customs') ? undefined : json['customs'],
    latitude: !exists(json, 'latitude') ? undefined : json['latitude'],
    longitude: !exists(json, 'longitude') ? undefined : json['longitude'],
    produce: !exists(json, 'produce') ? undefined : json['produce'],
    date: !exists(json, 'date') ? undefined : json['date'],
    doctype: !exists(json, 'doctype') ? undefined : json['doctype'],
    author: !exists(json, 'author') ? undefined : json['author'],
    recipient: !exists(json, 'recipient') ? undefined : json['recipient'],
    importance: !exists(json, 'importance') ? undefined : json['importance'],
    relevance: !exists(json, 'relevance') ? undefined : json['relevance'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    redactionNotes: !exists(json, 'redactionNotes')
      ? undefined
      : json['redactionNotes'],
    redacted: !exists(json, 'redacted') ? undefined : json['redacted'],
    converted: !exists(json, 'converted') ? undefined : json['converted'],
  };
}

export function SearchDocumentToJSON(value?: SearchDocument | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entityId: value.entityId,
    projectId: value.projectId,
    contents: value.contents,
    name: value.name,
    type: value.type,
    creator: value.creator,
    filesize: value.filesize,
    creationDate: value.creationDate,
    lastUpdated: value.lastUpdated,
    parent: value.parent,
    highlights: value.highlights,
    customs: value.customs,
    latitude: value.latitude,
    longitude: value.longitude,
    produce: value.produce,
    date: value.date,
    doctype: value.doctype,
    author: value.author,
    recipient: value.recipient,
    importance: value.importance,
    relevance: value.relevance,
    notes: value.notes,
    redactionNotes: value.redactionNotes,
    redacted: value.redacted,
    converted: value.converted,
  };
}
