import { useTranslation } from '@oproma/i18n';
import {
  LanguageEnum,
  setLanguage,
  toggleDisplayFooterLanguageDropdownMenu,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { footerLinks } from '../footer-links.menu';
import { Col, Row } from '../grid.component';

const EnglishFlagPng = '/flags/english.png';
const FrenchFlagPng = '/flags/french.png';

const year = new Date().getFullYear();

export const AuthFooter = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { language, displayFooterLanguageDropdownMenu } = useAppSelector(
    (state) => state.layout,
  );

  const toggle = () => dispatch(toggleDisplayFooterLanguageDropdownMenu());

  const changeLanguage = (language: LanguageEnum) => {
    dispatch(setLanguage(language));
    const lang = language === LanguageEnum.EN ? 'en-US' : 'fr-FR';
    i18n.changeLanguage(lang);
  };
  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="wide-lg container">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              {footerLinks.map((link) => (
                <li className="nav-item" key={link.title}>
                  <a
                    className="nav-link"
                    target="_blank"
                    href={link.href}
                    rel="noreferrer"
                  >
                    {t(link.title)}
                  </a>
                </li>
              ))}
              <li className="nav-item ">
                <Dropdown
                  isOpen={displayFooterLanguageDropdownMenu}
                  toggle={toggle}
                  direction="up"
                >
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                  >
                    <span>
                      {language === LanguageEnum.EN ? 'English' : 'Francais'}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu end className="dropdown-menu-sm">
                    <ul className="language-list">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            changeLanguage(LanguageEnum.EN);
                          }}
                          className="language-item"
                        >
                          <img
                            src={EnglishFlagPng}
                            alt=""
                            className="language-flag"
                          />
                          <span className="language-name">English</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            changeLanguage(LanguageEnum.FR);
                          }}
                          className="language-item"
                        >
                          <img
                            src={FrenchFlagPng}
                            alt=""
                            className="language-flag"
                          />
                          <span className="language-name">Français</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>
          </Col>
          <Col lg="6">
            <div className="nk-block-content text-lg-start text-center">
              <p className="text-soft">
                {t('FOOTER.COPYRIGHT', {
                  year,
                  version: import.meta.env.VITE_APP_VERSION ?? '0.1',
                })}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
