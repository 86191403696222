import {
  WorkspaceMember,
  useAppDispatch,
  setLastOpenedMember,
  setDisplayEditMemberModal,
  setDisplayRemoveMemberModal,
  setMemberCheckedStatus,
} from '@oproma/prividox-store';

export const useMemberRow = (memberEntry: WorkspaceMember) => {
  const memberId = memberEntry.id;
  const dispatch = useAppDispatch();

  const onEditMember = (memberId: string | null) => {
    dispatch(setLastOpenedMember(memberId));
    dispatch(setDisplayEditMemberModal(true));
  };

  const onDeleteMember = (memberId: string | null) => {
    dispatch(setLastOpenedMember(memberId));
    dispatch(setDisplayRemoveMemberModal(true));
  };

  const onCheckMember = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setMemberCheckedStatus({
        memberId: memberEntry.id,
        checked: ev.target.checked,
      }),
    );
  };
  return { memberId, onEditMember, onDeleteMember, onCheckMember };
};
