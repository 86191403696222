import {
  GeneralThemeTypeEnum,
  ThemeModeEnum,
  setHeaderThemeType,
  setSidebarThemeType,
  setThemeMode,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Icon } from '../icon.component';

export const ThemeConfigurator = () => {
  const dispatch = useAppDispatch();
  const { themeMode } = useAppSelector((state) => state.layout);

  const changeThemeMode = (mode: ThemeModeEnum) => {
    dispatch(setThemeMode(mode));
  };

  const changeHeaderTheme = (theme: GeneralThemeTypeEnum) => {
    dispatch(setHeaderThemeType(theme));
  };

  const changeSidebarTheme = (theme: GeneralThemeTypeEnum) => {
    dispatch(setSidebarThemeType(theme));
  };

  return (
    <a
      href="javascript"
      className="nk-quick-nav-icon"
      onClick={(ev) => {
        ev.preventDefault();
        if (themeMode === ThemeModeEnum.LIGHT) {
          changeThemeMode(ThemeModeEnum.DARK);
          changeHeaderTheme(GeneralThemeTypeEnum.DARK);
          changeSidebarTheme(GeneralThemeTypeEnum.DARK);
        } else {
          changeThemeMode(ThemeModeEnum.LIGHT);
          changeHeaderTheme(GeneralThemeTypeEnum.WHITE);
          changeSidebarTheme(GeneralThemeTypeEnum.LIGHT);
        }
      }}
    >
      <div className="icon-status icon-status-na">
        {themeMode === ThemeModeEnum.LIGHT ? (
          <Icon name="moon" />
        ) : (
          <Icon name="sun" />
        )}
      </div>
    </a>
  );
};
