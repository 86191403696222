/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SecretCodeResponse
 */
export interface SecretCodeResponse {
  /**
   *
   * @type {string}
   * @memberof SecretCodeResponse
   */
  url?: string;
  /**
   *
   * @type {Set<number>}
   * @memberof SecretCodeResponse
   */
  codes?: Set<number>;
}

/**
 * Check if a given object implements the SecretCodeResponse interface.
 */
export function instanceOfSecretCodeResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SecretCodeResponseFromJSON(json: any): SecretCodeResponse {
  return SecretCodeResponseFromJSONTyped(json, false);
}

export function SecretCodeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SecretCodeResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: !exists(json, 'url') ? undefined : json['url'],
    codes: !exists(json, 'codes') ? undefined : json['codes'],
  };
}

export function SecretCodeResponseToJSON(
  value?: SecretCodeResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
    codes:
      value.codes === undefined
        ? undefined
        : Array.from(value.codes as Set<any>),
  };
}
