import {
  DirectPermissionResult,
  ResultString,
} from '@oproma/prividox-orchestration-open-api';

export enum PermissionsErrorCodesEnum {
  GET_MEMBER_PERMISSION_FAILED = 'GET_MEMBER_PERMISSION_FAILED',
  EDIT_MEMBER_PERMISSION_FAILED = 'EDIT_MEMBER_PERMISSION_FAILED',
  GET_GROUP_PERMISSION_FAILED = 'GET_GROUP_PERMISSION_FAILED',
  FETCH_MEMBER_PERMISSION_FAILED = 'FETCH_MEMBER_PERMISSION_FAILED',
  FETCH_GROUP_PERMISSION_FAILED = 'FETCH_GROUP_PERMISSION_FAILED',
}

export interface DirectPermissionResultWithType extends DirectPermissionResult {
  permissionType: 'member' | 'group';
}

export interface PermissionsPayload {
  workspaceId: string;
  entityId: string | null;
}

export interface IPermissionsState {
  readonly loading: boolean;
  readonly verbs: ResultString;
  readonly groupEntityPermissions: DirectPermissionResult;
  readonly memberEntityPermissions: DirectPermissionResult;
  readonly displayPermissionsModal: boolean;
  readonly displayMemberFinderForPermissions: boolean;
  readonly displayGroupFinderForPermissions: boolean;
  readonly permissions: DirectPermissionResultWithType[];
  readonly displayUnsavedChangesModal: boolean;
  readonly error: {
    code: PermissionsErrorCodesEnum;
    message: string;
  } | null;
}
