/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PasswordSpec
 */
export interface PasswordSpec {
  /**
   * The current user's password.
   * @type {string}
   * @memberof PasswordSpec
   */
  password: string;
}

/**
 * Check if a given object implements the PasswordSpec interface.
 */
export function instanceOfPasswordSpec(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'password' in value;

  return isInstance;
}

export function PasswordSpecFromJSON(json: any): PasswordSpec {
  return PasswordSpecFromJSONTyped(json, false);
}

export function PasswordSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PasswordSpec {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    password: json['password'],
  };
}

export function PasswordSpecToJSON(value?: PasswordSpec | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    password: value.password,
  };
}
