import { useTranslation } from '@oproma/i18n';
import {
  deleteWorkspaceMember,
  setDisplayRemoveMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { Col, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';

export const RemoveMemberModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { displayRemoveMemberModal } = useAppSelector((state) => state.members);
  const lastOpenedMember = useAppSelector((state) =>
    state.members.members.find(
      (member) => member.id === state.members.lastOpenedMember,
    ),
  );

  const toggleModal = () =>
    dispatch(setDisplayRemoveMemberModal(!displayRemoveMemberModal));

  const onDeleteMember = (memberId: string | undefined) => {
    if (!workspaceId || !memberId) return;
    dispatch(
      deleteWorkspaceMember({
        workspaceId: workspaceId,
        user: memberId,
      }),
    );
    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={displayRemoveMemberModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('MEMBERS.REMOVE_MEMBER.TITLE')}</h5>
          <div className="mt-4">
            <p>{t('MEMBERS.REMOVE_MEMBER.HEADING')}</p>
            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li className="me-4">
                  <Button
                    color="primary"
                    onClick={() => onDeleteMember(lastOpenedMember?.id)}
                  >
                    {t('MEMBERS.REMOVE_MEMBER.CONFIRM')}
                  </Button>
                </li>
                <li>
                  <Button onClick={handleCancel}>
                    {t('MEMBERS.REMOVE_MEMBER.CANCEL')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
