/* eslint-disable @typescript-eslint/no-explicit-any */
import exportFromJSON from 'export-from-json';
import React, { ReactNode, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { ClipboardCopy } from '../clipboard-copy.component';
import { ReactDataTablePagination } from './react-data-table-pagination.component';
import { ExpandableRowsComponent } from 'react-data-table-component/dist/src/DataTable/types';

interface ExportProps {
  data: any[];
}

const Export = ({ data }: ExportProps) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 2000);
    }
  }, [modal]);

  const fileName = 'user-data';

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  const copyToClipboard = () => {
    setModal(true);
  };

  return (
    <React.Fragment>
      <div className="dt-export-buttons d-flex align-center">
        <div className="dt-export-title d-none d-md-inline-block">Export</div>
        <div className="dt-buttons btn-group flex-wrap">
          <ClipboardCopy text={JSON.stringify(data)}>
            <Button
              className="buttons-copy buttons-html5"
              onClick={() => copyToClipboard()}
            >
              <span>Copy</span>
            </Button>
          </ClipboardCopy>{' '}
          <button
            className="btn btn-secondary buttons-csv buttons-html5"
            type="button"
            onClick={() => exportCSV()}
          >
            <span>CSV</span>
          </button>{' '}
          <button
            className="btn btn-secondary buttons-excel buttons-html5"
            type="button"
            onClick={() => exportExcel()}
          >
            <span>Excel</span>
          </button>{' '}
        </div>
      </div>
      <Modal
        isOpen={modal}
        className="modal-dialog-centered text-center"
        size="sm"
      >
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">
            Copied {data.length} rows to clipboard
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

interface ExpandableRowComponentProps {
  data: {
    company: string;
    gender: string;
    startDate: string;
    salary: string;
  };
}

export const ExpandableRowComponent = ({
  data,
}: ExpandableRowComponentProps) => {
  return (
    <ul className="dtr-details p-2 border-bottom ms-1">
      <li className="d-block d-sm-none">
        <span className="dtr-title">Company</span>{' '}
        <span className="dtr-data">{data.company}</span>
      </li>
      <li className="d-block d-sm-none">
        <span className="dtr-title ">Gender</span>{' '}
        <span className="dtr-data">{data.gender}</span>
      </li>
      <li>
        <span className="dtr-title">Start Date</span>{' '}
        <span className="dtr-data">{data.startDate}</span>
      </li>
      <li>
        <span className="dtr-title">Salary</span>{' '}
        <span className="dtr-data">{data.salary}</span>
      </li>
    </ul>
  );
};

interface CustomCheckboxProps {
  onClick: () => void;
  name: string;
}

const CustomCheckbox = React.forwardRef<HTMLInputElement, CustomCheckboxProps>(
  ({ onClick, ...rest }, ref) => (
    <div className="custom-control custom-control-sm custom-checkbox notext">
      <input
        id={rest.name}
        type="checkbox"
        className="custom-control-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="custom-control-label" htmlFor={rest.name} />
    </div>
  ),
);

interface ReactDataTableProps {
  data: any[];
  columns: any[];
  pagination?: boolean;
  actions?: boolean;
  className?: string;
  selectableRows?: boolean;
  expandableRows?: boolean;
  expandableRowsComponent?: ExpandableRowsComponent<any>;
}

export const ReactDataTable = ({
  data,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  expandableRowsComponent,
}: ReactDataTableProps) => {
  const [tableData, setTableData] = useState<typeof data>(data);
  const [searchText, setSearchText] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [mobileView, setMobileView] = useState<boolean>(false);

  useEffect(() => {
    let defaultData = tableData;
    if (searchText !== '') {
      defaultData = data.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });
      setTableData(defaultData);
    } else {
      setTableData(data);
    }
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener('load', viewChange);
    window.addEventListener('resize', viewChange);
    return () => {
      window.removeEventListener('resize', viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`dataTables_wrapper dt-bootstrap4 no-footer ${
        className ? className : ''
      }`}
    >
      <Row className={`justify-between g-2 ${actions ? 'with-export' : ''}`}>
        <Col className="col-7 text-start" sm="4">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <label>
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Search by name"
                onChange={(ev) => setSearchText(ev.target.value)}
              />
            </label>
          </div>
        </Col>
        <Col className="col-5 text-end" sm="8">
          <div className="datatable-filter">
            <div className="d-flex justify-content-end g-2">
              {actions && <Export data={data} />}
              <div className="dataTables_length" id="DataTables_Table_0_length">
                <label>
                  <span className="d-none d-sm-inline-block">Show</span>
                  <div className="form-control-select">
                    {' '}
                    <select
                      name="DataTables_Table_0_length"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      onChange={(ev) =>
                        setRowsPerPage(parseInt(ev.target.value, 10))
                      }
                      value={rowsPerPage}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>{' '}
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <DataTable
        data={tableData}
        columns={columns}
        className={className}
        selectableRows={selectableRows}
        selectableRowsComponent={CustomCheckbox as unknown as ReactNode}
        expandableRowsComponent={expandableRowsComponent}
        expandableRows
        noDataComponent={<div className="p-2">There are no records found</div>}
        sortIcon={
          <div>
            <span>&darr;</span>
            <span>&uarr;</span>
          </div>
        }
        pagination={pagination}
        paginationComponent={({
          currentPage,
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
        }) => (
          <ReactDataTablePagination
            customItemPerPage={rowsPerPage}
            itemPerPage={rowsPerPage}
            totalItems={rowCount}
            paginate={onChangePage}
            currentPage={currentPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      ></DataTable>
    </div>
  );
};
