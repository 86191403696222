import { useTranslation } from '@oproma/i18n';
import {
  Entity,
  removeStarredEntity,
  setLastOpenedEntity,
  toggleDisplayPreviewFileModal,
  toggleDisplayQuickNavigation,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useLocation, useNavigate } from '@oproma/router';
import clsx from 'clsx';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../block.component';
import { Icon } from '../icon.component';

import {
  EntityThumbnail,
  getEntityThumbnail,
} from './svg-thumbnails.component';

type FileManagerQuickNavigationProps = {
  entities: Entity[];
};
export const FileManagerQuickNavigation = ({
  entities,
}: FileManagerQuickNavigationProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { displayQuickNavigation: displayQuickAccess } = useAppSelector(
    (state) => state.fileManager,
  );

  const handleOnEntityClick = (entity: Entity) => {
    if (
      entity.type === 'folder' ||
      entity.type === 'gallery' ||
      entity.type === 'calendar'
    ) {
      // navigate to folder view page
      navigate(`${pathname}/entities/${entity.id}`);
    } else {
      dispatch(setLastOpenedEntity(entity));
      dispatch(toggleDisplayPreviewFileModal());
    }
  };

  return (
    <Block className="nk-fmg-quick-list">
      <BlockHead size="xs">
        <BlockBetween className="g-2">
          <BlockHeadContent>
            <BlockTitle tag="h6">
              {t('FILE_MANAGER.QUICK_NAV.TITLE')}
            </BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent>
            <a
              href="#toggle"
              onClick={(ev) => {
                ev.preventDefault();
                dispatch(toggleDisplayQuickNavigation());
              }}
              className={clsx('link link-primary toggle-opt', {
                active: displayQuickAccess,
              })}
            >
              <div className="inactive-text">
                {t('FILE_MANAGER.QUICK_NAV.DISPLAY')}
              </div>
              <div className="active-text">
                {t('FILE_MANAGER.QUICK_NAV.HIDE')}
              </div>
            </a>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <div
        className={`toggle-expand-content ${
          displayQuickAccess === true ? 'expanded' : ''
        }`}
      >
        <div className="nk-files nk-files-view-grid">
          <div className="nk-files-list">
            {entities.map((entity, index) => (
              <div className="nk-file-item nk-file" key={index}>
                <div className="nk-file-info">
                  <a
                    className="nk-file-link"
                    href="#link"
                    onClick={(ev) => {
                      ev.preventDefault();
                      handleOnEntityClick(entity);
                    }}
                  >
                    <div className="nk-file-title">
                      <div className="nk-file-icon">
                        <span className="nk-file-icon-type">
                          {getEntityThumbnail(entity.svg as EntityThumbnail)}
                        </span>
                      </div>
                      <div className="nk-file-name">
                        <div className="nk-file-name-text">
                          <span className="title">{entity.name}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="nk-file-actions hideable">
                  <a
                    href="#folder"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(removeStarredEntity(entity.id));
                    }}
                    className="btn btn-sm btn-icon btn-trigger"
                  >
                    <Icon name="cross"></Icon>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Block>
  );
};
