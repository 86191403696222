import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Content,
  DashboardActivityTable,
  EditDashboardDescriptionModal,
  Head,
  Icon,
  Layout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import { NotificationMessage } from '@oproma/prividox-orchestration-open-api';
import {
  getMetadataKey,
  getWorkspaceActivity,
  getWorkspaceMetadata,
  setDisplayDashboardFinder,
  toggleDisplayEditDescriptionModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { SetStateAction, useEffect, useState } from 'react';
import { Button } from 'reactstrap';

const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { dashboardTitle, dashboardDescription } = useAppSelector(
    (state) => state.workspace,
  );

  const toggleModal = () => {
    dispatch(toggleDisplayEditDescriptionModal());
  };

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceMetadata(workspaceId));
    dispatch(getWorkspaceActivity({ projectId: workspaceId }));
    dispatch(getMetadataKey(workspaceId));
    return () => {
      dispatch(setDisplayDashboardFinder(false));
    };
  }, [dispatch, workspaceId]);

  return (
    <>
      <Head heading={t('COMMON.DASHBOARD')} />
      <Layout>
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>{dashboardTitle}</BlockTitle>
                <BlockDes className="text-soft">
                  <p>{dashboardDescription}</p>
                </BlockDes>
              </BlockHeadContent>
              <Button className="btn-icon" onClick={toggleModal}>
                <Icon name="edit-alt-fill" />
              </Button>
            </BlockBetween>
          </BlockHead>
          <DashboardActivityTable />
        </Content>
      </Layout>
      <EditDashboardDescriptionModal />
    </>
  );
};

export default DashboardPage;
