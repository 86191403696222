export const navbarMenu = [
  {
    id: 0,
    text: 'COMMON.EXPLORE',
    icon: 'home-alt',
    link: '',
  },
  {
    id: 1,
    text: 'COMMON.FILES',
    icon: 'folders',
    link: 'entities',
  },
  {
    id: 2,
    text: 'COMMON.STARRED',
    icon: 'star',
    link: 'starred',
  },
  {
    id: 3,
    text: 'COMMON.TRASH',
    icon: 'trash',
    link: 'trash',
  },
];
