import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Content,
  Head,
  Icon,
  Layout,
  OnboardWorkspaceModal,
  Row,
  WorkspaceCard,
  WorkspaceFilterDropdown,
  WorkspaceFinder,
  useFilteredWorkspaces,
  useQueryWorkspaces,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  setDisplayMobileMenu,
  toggleDisplayOnboardWorkspaceModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';

import { useNavigate } from '@oproma/router';
import { useState } from 'react';

const IndexPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { displayMobileMenu } = useAppSelector((state) => state.layout);
  const [workspaceQuery, setWorkspaceQuery] = useState<string>();
  const [filterStatus, setFilterStatus] = useState('');
  const { workspaces, workspaceCount } = useQueryWorkspaces(workspaceQuery);
  const filteredWorkspaces = useFilteredWorkspaces(workspaces, filterStatus);

  const changeDisplayMobileMenu = (payload: boolean) => {
    dispatch(setDisplayMobileMenu(payload));
  };

  const navigateToWorkspaceOptions = (workspaceId: string) => {
    navigate({ pathname: `/${workspaceId}/options` });
  };

  const toggleOnboardingWorkspaceModal = () => {
    dispatch(toggleDisplayOnboardWorkspaceModal());
  };

  return (
    <>
      <Head heading="Workspaces" />
      <Layout>
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>{t('COMMON.WORKSPACES.HEADING')}</BlockTitle>
                <BlockDes className="text-soft">
                  {t('COMMON.WORKSPACES.SUBHEADING', { count: workspaceCount })}
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className={clsx(
                      'btn-icon btn-trigger toggle-expand me-n1',
                      {
                        active: displayMobileMenu,
                      },
                    )}
                    onClick={() => changeDisplayMobileMenu(!displayMobileMenu)}
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </Button>
                  <div
                    className="toggle-expand-content"
                    style={{ display: displayMobileMenu ? 'block' : 'none' }}
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <WorkspaceFinder onQuery={setWorkspaceQuery} />
                      </li>
                      <li>
                        <WorkspaceFilterDropdown
                          filter={filterStatus}
                          onFilter={setFilterStatus}
                        />
                      </li>
                      <li className="nk-block-tools-opt">
                        <Button
                          color="primary"
                          onClick={toggleOnboardingWorkspaceModal}
                        >
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Row className="g-gs">
              {filteredWorkspaces.map((workspace) => (
                <WorkspaceCard
                  key={workspace.id}
                  workspace={workspace}
                  workspaceQuery={workspaceQuery}
                  onEdit={() => navigateToWorkspaceOptions(workspace.id!)}
                />
              ))}
            </Row>
          </Block>
        </Content>
        <OnboardWorkspaceModal />
      </Layout>
    </>
  );
};

export default IndexPage;
